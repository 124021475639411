<template lang="html">
  <div class="">
    <Spinner v-if='loadingPage' />
    <template v-else>
      <!-- <button type="button" v-show='0' class="adset-21 btn" @click='openAdsetWizard'>Create Adset</button>
      <ScreenBlocker v-if='adsetWizardOpened' :onClose='e=>adsetWizardOpened=false'>
        <AdsetWizard></AdsetWizard>
      </ScreenBlocker> -->
      <ReportForm :accountId='accountId'></ReportForm>
    </template>
  </div>
</template>

<script>

import ReportForm from './shared/ReportForm.outbrain'
// import AdsetWizard from './components/AdsetWizard'

export default {
  props: {
    source: {
    }
  },
  components: {
    ReportForm
  },
  data() {
    return {
      adsets: null,
      adsetWizardOpened: false,
      loadingPage: false
    }
  },
  created() {
    this.adsets = this.$store.state.data.fbAdsets
  },
  computed: {
    accountId() {
      return this.$route.params.accountId
    }
  },
  watch: {
    accountId() {
      this.reloadPageVisual()
    }
  },
  methods: {
    reloadPageVisual() {
      this.loadingPage = true
      window.setTimeout(() => {
        this.loadingPage = false
      }, 350)
    },
    openAdsetWizard() {
      this.adsetWizardOpened = true
    },
    navigateToAdset(adset) {
      // this.$router.push({ name: "CampaignsReportsAdset", params: {adsetId: adset.id}})
    }
  }
}
</script>

<style lang="css" scoped>
.list12 {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  font-size: smaller;
  border-top: 1px solid gainsboro;
  border-left: 1px solid gainsboro;
}
.data123 {
  font-size: 26px;
}

.data123 span {
  padding-left: 5px;
  padding-top: 7px;
}
.data123 span:hover {
  cursor: pointer;
  background: #e8e8e8;
}
.data123 li {
  display: flex;
  flex-direction: column;
}
.adset-21 {

}
</style>
