import EditUser from "./EditUser.admin";
import EditRoles from "./EditRoles.admin";
import EditPermissions from "./EditPermissions.admin";
import EditPresets from "./EditPresets.admin";

export default {
  EditUser,
  EditRoles,
  EditPermissions,
  EditPresets,
};
