<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="7" lg="6" sm="10">
          <b-card no-body class="mx-4">
            <b-card-body class="p-4">
              <form @submit.prevent='submit'>
              <h1>Reset your password</h1>
              <p class="text-muted">Recover your account</p>
              <b-input-group class="mb-3">
                <b-input-group-prepend>
                  <b-input-group-text>@</b-input-group-text>
                </b-input-group-prepend>
                <input type="text" class="form-control" placeholder="Email" v-model='form.email'>
              </b-input-group>

              <div class="recaptcha" id='recaptcha'></div>
              <b-button variant="success" block type="submit">Get a reset link</b-button>
            </form>
            <router-link to="log-in">Back to log in</router-link>
            </b-card-body>
            <!-- FACEBOOK AND TWITTER LOGIN OPTIONS -->
            <!-- <b-card-footer class="p-4">
              <b-row>
                <b-col cols="6">
                  <b-button block class="btn btn-facebook" type="submit"><span>facebook</span></b-button>
                </b-col>
                <b-col cols="6">
                  <b-button block class="btn btn-twitter" type="button"><span>twitter</span></b-button>
                </b-col>
              </b-row>
            </b-card-footer> -->
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
window.onloadCallback = function() {
  // grecaptcha.render('recaptcha', {
  //   'sitekey' : '6LftrU8UAAAAAAe7XlLdSERtivRX_WIGSCZZ48px',
  //   'callback' : correctCaptcha
  // })
}
let recaptcha = null
let correctCaptcha = function(response) {
    recaptcha = response
}
export default {
  name: 'Register',
  data() {
    return {
      form: {
        email: null,
      },
      scriptTag: null
    }
  },
  created() {
    let script = this.scriptTag = document.createElement('script')
    script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
    document.getElementsByTagName('head')[0].appendChild(script)
  },
  destroyed() {
    this.scriptTag.parentElement.removeChild(this.scriptTag)
  },
  methods: {
    submit() {
      let form = this.form
      form.recaptcha = recaptcha
      this.$http.post('auth/reset', form)
        .then(() => {
          this.notifySuccess("A link was sent to your email address")
        })
        .catch(err => {
          this.notifyError("Error occured")
        })
    }
  }
}
</script>

<style scoped>
  p {
    margin-bottom: 1rem;
  }
  .logo-container {
    margin: 0 1.5rem;
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
  }
  .logo-container > img {

  }
  .recaptcha {
    display: flex;
    margin-bottom: 10px;
  }
</style>
