<template>
  <div class="app">
    <AppHeader />
    <div class="app-body">
      <Sidebar :navItems="nav" />
      <main class="main">       
        <div class="container-fluid mt-5">         
            <div class="width100">
              <router-view v-if="hasPermission"></router-view>
              <h3 v-else>
                You don't have the permission to view the current page.
              </h3>
            </div>          
        </div>
      </main>
      <AppAside />
    </div>   
  </div>
</template>

<script>
import nav from "../_nav";
import {
  Header as AppHeader,
  Sidebar,
  Aside as AppAside,  
} from "../components/";

export default {
  name: "full",
  components: {
    AppHeader,
    Sidebar,
    AppAside    
  },
  data() {
    return {
      nav: nav.items,
    };
  },
  computed: {
    hasPermission() {
      let matchedRoutes = this.$route.matched;
      let ok = true;
      matchedRoutes.forEach((route) => {
        let routeMeta = route.meta;
        if (!routeMeta) {
          return;
        }
        let routePermission = routeMeta.permission;
        if (!routePermission) {
          return;
        }
        if (!this.userHasPermission(routePermission)) {
          ok = false;
        }
      });
      return ok;
    },
    name() {
      return this.$route.name;
    },
 
    cmName() {
      return this.$route.name;
    },
  },
  methods: {
    fetchTwitterData() {
      return this.$http
        .get(this.resources.Reports.twitterData)
        .then((r) => r.json())
        .then((data) => {
          this.$store.state.data.twitterData = data;
          return data;
        });
    },
    setupFb() {
      this.fetchFbData().then((data) => {
        let accounts = data && Object.values(data.accounts);
        if (!accounts || accounts.length === 0) {
          return;
        }
        accounts.forEach((account) => {
          nav.items[3].children.push({
            name: account.name,
            url: `/fb/accounts/${account.id}/reports`,
          });
        });
      });
    },
    setupTwitter() {
      this.fetchTwitterData().then((data) => {
        let accounts = data && Object.values(data.accounts);
        if (!accounts || accounts.length === 0) {
          return;
        }
        accounts.forEach((account) => {
          nav.items[4].children.push({
            name: account.name,
            url: `/twitter/accounts/${account.id}/reports`,
          });
        });
      });
    },
  },
};
</script>

<style>
.route-card > div > .card-header {
  background: #f9f9f9;
  border: 0;
  color: #585858;
  border-bottom: 1px solid gainsboro;
  font-size: 17px;
}
</style>
