<template lang="html">
  <div class="reportFrom">
    <h3>Twitter Report</h3>
    <section class="report-form">
      <div class="account-list">
        <span>Choose Account:</span>
        <multiselect
          v-if="allAccounts"
          label="name"
          track-by="id"
          :multiple="true"
          :close-on-select="false"
          v-model="selectedAccounts"
          :options="allAccounts"
        ></multiselect>
      </div>

      <div class="date-range-3">
        <span class="s-151">Choose Date Range:</span>
        <div class="">
          <div class="div-15">
            <i class="active-circle" v-show="selectedDateRange"></i>
            <ul class="date-range-options-list">
              <li
                v-for="v in dateRangeOptions"
                @click="setFixedDateRange(v)"
                :class="{ active: v === selectedDateRange }"
              >
                {{ v }}
              </li>
            </ul>
          </div>

          <div class="date-section">
            <div class="div-15">
              <i class="active-circle" v-show="!selectedDateRange"></i>
              <span>Or custom date range:</span>
            </div>
            <div class="date-range-241">
              <DatePicker
                :onChange="
                  (v) => {
                    form.start_dt = v;
                    selectedDateRange = null;
                  }
                "
                :value="form.start_dt"
              />
              <DatePicker
                :onChange="
                  (v) => {
                    form.end_dt = v;
                    selectedDateRange = null;
                  }
                "
                :value="form.end_dt"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="search-box-container date-range-3">
        <span class="s-5231">Search</span>
        <div class="search">
          <label>Search adset:</label>
          <input v-model="searchForm.adset" class="def" />
        </div>
        <div>
          <label>Exclude adsets:</label>
          <input v-model="searchForm.excludeAdset" class="def" />
        </div>

        <div class="camp-status">
          <label>Campaign Status: </label>
          <select
            class="def"
            v-model="searchForm.status"
            @change="filterReport"
          >
            <option value="ALL">ALL</option>
            <option value="ACTIVE">ACTIVE</option>
            <option value="PAUSED">PAUSED</option>
          </select>
        </div>
      </div>

      <div class="search-box-container date-range-3">
        <FilteringForm
          :filteringForm="filteringForm"
          :fields="filterableFields"
        />
      </div>

      <div class="b-2412">
        <span class="s-325">Metrics:</span>

        <multiselect
          class="select-2"
          label="title"
          track-by="value"
          :multiple="true"
          :close-on-select="false"
          v-model="metricsSelectCompatible"
          :options="fieldsList"
        ></multiselect>
      </div>

      <br />
      <button
        type="button"
        class="btn btn-success"
        @click="fetchReport"
        :disabled="processingReport"
      >
        Run Report
        <Spinner :def="0" v-show="processingReport" />
      </button>

      <button
        type="button"
        class="btn btn-default ml-3"
        @click="downloadReportCsv"
        :disabled="processingCsvReport"
      >
        Download Report CSV
        <Spinner :def="0" v-show="processingCsvReport" />
      </button>

      <button
        type="button"
        class="btn btn-primary create-camp-btn"
        :disabled="processingReport"
        v-if="userHasPermission('twitter.create')"
        @click="
          (e) => {
            adsetWizardOpened = true;
          }
        "
      >
        Create Campaign
      </button>
    </section>
    <br />
    <hr />

    <div v-if="reportDataFiltered" class="report-table">
      <span class="am_last_fetched_info_box"
        >AM last fetched:
        {{ reportData.meta.ad_manager_last_updated | formatTime }}</span
      >
      <ul class="adsets-list">
        <li class="actions-row-12">
          <div class="adsets-selection-c1">
            <input
              type="checkbox"
              id="s-25124"
              v-show="false"
              @change="toggleSelectAllAdsets"
              v-model="allAdsetsSelectedCheckbox"
            />
            <label
              for="s-25124"
              class="s-checkbox-4 s-checkbox-4-panel"
              :class="{ checked: allAdsetsSelectedCheckbox }"
            >
              <i class="fa fa-check-square" aria-hidden="true"></i>
            </label>
            <span> {{ selectedAdsets.length }} Adsets selected </span>
          </div>
          <div class="actions-column flex">
            <button
              @click="actionSetActive"
              class="btn action-btn-25"
              type="button"
            >
              Set Active
            </button>
            <button
              @click="actionSetInactive"
              class="btn action-btn-25"
              type="button"
            >
              Set Inactive
            </button>
            <button
              @click="actionSetDailyBudget"
              class="btn action-btn-25"
              type="button"
            >
              Set Daily Budget
            </button>
            <button
              @click="actionSetBid"
              class="btn action-btn-25"
              type="button"
            >
              Set Bid
            </button>
          </div>
        </li>
        <li class="row12 header-row header-row-after">
          <span class="checkbox-column"></span>
          <span class="plus-td"></span>
          <span class="external-link-td"></span>
          <span class="plus-td expand-column"></span>
          <span class="row-name">Name(ID)</span>
          <span class="status-column">Status</span>
          <span
            v-for="(_, f) in fields"
            v-show="
              metricsSelectCompatible.some((v) => v.value === f) ||
                breakdowns.includes(f)
            "
            :class="
              Object.assign(
                {},
                fields[f].getClass && fields[f].getClass(sumReport[f])
              )
            "
            @click="onColumnClick(f)"
          >
            {{ fields[f].title }}
          </span>
        </li>
        <li class="row12 sum-row">
          <span class="checkbox-column"></span>
          <span class="plus-td"></span>
          <span class="external-link-td"></span>
          <span class="plus-td expand-column"></span>
          <span class="row-name">{{ sumReport["adsetsCount"] }} Adsets</span>
          <span class="status-column"></span>
          <span
            v-for="(_, f) in fields"
            v-show="
              metricsSelectCompatible.some((v) => v.value === f) ||
                breakdowns.includes(f)
            "
            :class="
              Object.assign(
                {},
                fields[f].getClass && fields[f].getClass(sumReport[f])
              )
            "
          >
            {{
              sumReport[f]
                | ifFloatDecimalN(fields[f].decimalDigits || 4)
                | suffix(fields[f].after)
            }}</span
          >
        </li>
        <template v-for="adset in reportDataFiltered.adsets">
          <li class="row12 row12-th value-row">
            <span class="checkbox-column">
              <input
                type="checkbox"
                v-show="false"
                :id="adset.id"
                :value="adset"
                v-model="selectedAdsets"
              />
              <label
                :for="adset.id"
                class="s-checkbox-4 s-checkbox-4-fill-container"
                :class="{ checked: selectedAdsets.includes(adset) }"
              >
                <i class="fa fa-check-square" aria-hidden="true"></i>
              </label>
            </span>
            <span class="plus-td">
              <i
                class="fa fa-clone dup-icon"
                aria-hidden="true"
                @click="duplicateAdset(adset)"
              ></i>
            </span>
            <span class="external-link-td">
              <a :href="getAdsetExternalLink(adset)" target="_blank">
                <i
                  class="fa fa-external-link ext-link-to-platform"
                  aria-hidden="true"
                ></i>
              </a>
            </span>
            <span class="plus-td expand-column expand-column-block">
              <template>
                <button
                  class="def expand-btn-3"
                  @click="toggleAdsetExpand(adset, 'ssp')"
                >
                  R
                </button>
              </template>
            </span>
            <span class="row-name">{{ adset.name }}({{ adset.id }})</span>
            <span :class="{ 'status-column': true }">
              <i
                @click="toggleAdsetStatus(adset)"
                v-if="adset['entity_status'] === 'ACTIVE'"
                class="toggle-icon fa fa-toggle-on"
                aria-hidden="true"
              ></i>
              <i
                @click="toggleAdsetStatus(adset)"
                v-else
                class="toggle-icon fa fa-toggle-off"
                aria-hidden="true"
              ></i>
            </span>
            <span
              v-for="(_, f) in fields"
              v-show="
                metricsSelectCompatible.some((v) => v.value === f) ||
                  breakdowns.includes(f)
              "
              :class="
                Object.assign(
                  {},
                  fields[f].getClass && fields[f].getClass(adset[f])
                )
              "
            >
              <span
                class="edit-column-btn"
                v-if="userHasPermission('twitter.edit') && fields[f].editable"
              >
                <i
                  v-if="
                    f === 'bid_amount' &&
                      adset.last_bid_change.is_increase !== null
                  "
                  :class="{
                    'last-bid-change': true,

                    'is-increase': adset.last_bid_change.is_increase,
                  }"
                ></i>

                <i
                  v-if="
                    f === 'camp_daily_budget' &&
                      adset.last_budget_change.is_increase !== null
                  "
                  :class="{
                    'last-bid-change': true,

                    'is-increase': adset.last_budget_change.is_increase,
                  }"
                ></i>

                <i
                  class="fa fa-info-circle"
                  aria-hidden="true"
                  title="View last bid changes"
                  @click="viewAdsetOpChanges(adset, f)"
                ></i>
                <i
                  class="fa fa-pencil-square-o"
                  aria-hidden="true"
                  @click="
                    openEditPopup(
                      adset,
                      f,
                      fields[f].max_field_name,
                      fields[f].min_field_name
                    )
                  "
                ></i>
              </span>
              {{
                adset[f]
                  | ifFloatDecimalN(fields[f].decimalDigits || 4)
                  | suffix(fields[f].after)
              }}</span
            >
          </li>
          <li
            v-for="row in adsetMicroReport[adset.id] &&
              adsetMicroReport[adset.id].adsets"
            class="row12 row12-country-td value-row"
          >
            <span class="checkbox-column"></span>
            <span class="plus-td"></span>
            <span class="external-link-td"></span>
            <span class="plus-td expand-column"></span>
            <span class="row-name"></span>
            <span
              :class="{
                'status-column': true,
                'status-td-active': row['status'] === 'RUNNING',
              }"
            ></span>
            <span
              v-for="(_, f) in fields"
              v-show="
                metricsSelectCompatible.some((v) => v.value === f) ||
                  breakdowns.includes(f)
              "
            >
              {{
                row[f]
                  | ifFloatDecimalN(fields[f].decimalDigits || 4)
                  | suffix(fields[f].after)
              }}</span
            >
          </li>
        </template>
      </ul>

      <ScreenBlocker
        :onClose="(e) => (editAdsetPopup.adset = null)"
        v-if="editAdsetPopup.adset"
      >
        <div class="edit-adset-popup">
          <div class="edit-adset-adset-635">
            <span class="edit-adset-name-626">
              {{ editAdsetPopup.adset.name }}({{ editAdsetPopup.adset.id }})
            </span>
          </div>
          <form
            class="edit-adset-form-line-24"
            @submit.prevent="confirmAdsetEdit"
          >
            <div class="edit-adset-div">
              <label for="">{{ fields[editAdsetPopup.field].title }}:</label>
              <input
                class="def"
                type="number"
                :step="
                  fields[editAdsetPopup.field].decimalDigits ? '0.001' : '0'
                "
                :max="
                  editAdsetPopup.max_field_value
                    ? editAdsetPopup.max_field_value
                    : ''
                "
                :min="
                  editAdsetPopup.min_field_value
                    ? editAdsetPopup.min_field_value
                    : ''
                "
                v-model.number="editAdsetPopup.value"
              />
              <label for="">{{ editAdsetPopup.max_field }}:</label>
              <input
                class="def"
                type="number"
                :step="
                  fields[editAdsetPopup.field].decimalDigits ? '0.001' : '0'
                "
                v-model.number="editAdsetPopup.max_field_value"
              />

              <label for="">{{ editAdsetPopup.min_field }}:</label>
              <input
                class="def"
                type="number"
                :step="
                  fields[editAdsetPopup.field].decimalDigits ? '0.001' : '0'
                "
                v-model.number="editAdsetPopup.min_field_value"
              />
            </div>
            <button
              type="submit"
              :disabled="ongoingActions.editAdset[editAdsetPopup.adset.id]"
              class="btn btn-primary"
            >
              Save
            </button>
          </form>
        </div>
      </ScreenBlocker>

      <ScreenBlocker
        :onClose="closeEditPopup"
        v-if="editMultipleAdsetsPopup.adsets"
      >
        <div class="edit-adset-popup">
          <div class="edit-adset-adset-635">
            <b-table-simple>
              <b-thead head-variant="dark">
                <b-tr>
                  <b-th>name</b-th>
                  <b-th>id</b-th>
                  <b-th
                    >current
                    {{ fields[editMultipleAdsetsPopup.field].title }}</b-th
                  >
                  <b-th
                    >updated
                    {{ fields[editMultipleAdsetsPopup.field].title }}</b-th
                  >
                  <b-th
                    v-show="editMultipleAdsetsPopup.field !== 'entity_status'"
                    >max {{ fields[editMultipleAdsetsPopup.field].title }}</b-th
                  >
                  <b-th
                    v-show="editMultipleAdsetsPopup.field !== 'entity_status'"
                    >min {{ fields[editMultipleAdsetsPopup.field].title }}</b-th
                  >
                  <b-th></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(adset, index) in editMultipleAdsetsPopup.adsets">
                  <b-td> {{ adset.name }}</b-td>
                  <b-td variant="success">{{ adset.id }}</b-td>
                  <b-td variant="warning">{{
                    adset[editMultipleAdsetsPopup.field] | ifFloatDecimalN(4)
                  }}</b-td>
                  <b-td variant="danger">{{
                    adset["updated_val"] | ifFloatDecimalN(4)
                  }}</b-td>
                  <b-td
                    v-show="editMultipleAdsetsPopup.field !== 'entity_status'"
                    >{{
                      editMultipleAdsetsPopup.field === "camp_daily_budget"
                        ? adset["max_daily_budget"]
                        : adset["max_bid"]
                    }}</b-td
                  >
                  <b-td
                    v-show="editMultipleAdsetsPopup.field !== 'entity_status'"
                    >{{
                      editMultipleAdsetsPopup.field === "camp_daily_budget"
                        ? adset["min_daily_budget"]
                        : adset["min_bid"]
                    }}</b-td
                  >
                  <b-td>
                    <span class="checkbox-column">
                      <input
                        type="checkbox"
                        v-show="false"
                        :id="adset.name"
                        :value="adset"
                        v-model="checkboxSelected"
                      />
                      <label
                        :for="adset.name"
                        class="s-checkbox-4 s-checkbox-4-fill-container"
                        :class="{ checked: checkboxSelected.includes(adset) }"
                      >
                        <i class="fa fa-check-square" aria-hidden="true"></i>
                      </label>
                    </span>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
          <form
            class="edit-adset-form-line-24"
            @submit.prevent="confirmMultipleAdsetEdit"
          >
            <template v-if="editMultipleAdsetsPopup.field === 'entity_status'">
              <span
                >Are you sure that you want to update these adsets' status to
                "{{ editMultipleAdsetsPopup.value }}"?</span
              >
              <button
                type="submit"
                :disabled="ongoingActions.editMultipleAdsets"
                class="btn btn-primary"
              >
                Update
              </button>
            </template>
            <template v-else>
              <div class="edit-adset-div">
                <div class="flex-center">
                  <b-form-checkbox
                    v-model="editMultipleAdsetsPopup.edit_field_chosed"
                  >
                  </b-form-checkbox>
                  <select
                    class="def"
                    @change="onEditAdsetValueChange"
                    v-model="editMultipleAdsetsPopup.setType"
                  >
                    <option value="absolute" default>Set</option>
                    <option value="changeBy">Increase/Decrease by</option>
                    <option value="changeByPercentage"
                      >Increase/Decrease by %</option
                    >
                  </select>

                  <div
                    v-if="
                      editMultipleAdsetsPopup.setType === 'changeByPercentage'
                    "
                  >
                    <label for="">Change by:</label>
                    <input
                      @input="onEditAdsetValueChange"
                      class="def"
                      type="number"
                      :step="1"
                      :max="200"
                      v-model.number="editMultipleAdsetsPopup.value"
                    />%
                  </div>

                  <div v-else>
                    <label for=""
                      >{{
                        editMultipleAdsetsPopup.field === "bid_amount"
                          ? "Bid "
                          : "Daily Budget "
                      }}value</label
                    >
                    <input
                      @input="onEditAdsetValueChange"
                      class="def"
                      type="number"
                      :step="0.001"
                      :max="fields[editMultipleAdsetsPopup.field].max"
                      v-model.number="editMultipleAdsetsPopup.value"
                    />
                  </div>
                </div>
                <div class="flex-center">
                  <b-form-checkbox
                    v-model="editMultipleAdsetsPopup.max_field_chosed"
                  >
                  </b-form-checkbox>
                  <label for="">{{
                    editMultipleAdsetsPopup.field === "bid_amount"
                      ? "Max Bid:"
                      : "Max Daily Budget:"
                  }}</label>
                  <input
                    class="def"
                    type="number"
                    :step="0.01"
                    v-model.number="editMultipleAdsetsPopup.max_field_value"
                  />
                </div>
                <div class="flex-center">
                  <b-form-checkbox
                    v-model="editMultipleAdsetsPopup.min_field_chosed"
                  >
                  </b-form-checkbox>
                  <label for="">{{
                    editMultipleAdsetsPopup.field === "bid_amount"
                      ? "Min Bid:"
                      : "Min Daily Budget:"
                  }}</label>
                  <input
                    class="def"
                    type="number"
                    :step="0.01"
                    v-model.number="editMultipleAdsetsPopup.min_field_value"
                  />
                </div>
              </div>
              <button
                type="submit"
                :disabled="ongoingActions.editMultipleAdsets"
                class="btn btn-primary"
              >
                Save
              </button>
            </template>
          </form>
        </div>
      </ScreenBlocker>
    </div>

    <ScreenBlocker v-if="adsetWizardOpened" :onClose="onWizardClose">
      <TwitterWizard
        :onClose="onWizardClose"
        :accounts="allAccounts"
        :data="toDuplicateAdset"
      ></TwitterWizard>
    </ScreenBlocker>

    <ScreenBlocker :allowContent="0" v-if="blockScreen"></ScreenBlocker>

    <ScreenBlocker
      v-if="displayAdsetOpChanges.adset"
      :onClose="(e) => (displayAdsetOpChanges.adset = null)"
    >
      <div class="bid-changes-container">
        <span class="bid-changes-adset-title">{{
          displayAdsetOpChanges.adset.name
        }}</span>

        <div class="bid-changes-results-container">
          <span v-if="!displayAdsetOpChanges.changes">
            Loading...
          </span>
          <span v-else-if="displayAdsetOpChanges.changes.length === 0">
            No {{ displayAdsetOpChanges.fieldTitle }} changes
          </span>
          <ul v-else class="bid-changes-list">
            <li>
              <span>Time</span>
              <span>From {{ displayAdsetOpChanges.fieldTitle }}</span>
              <span>To {{ displayAdsetOpChanges.fieldTitle }}</span>
            </li>
            <li v-for="b in displayAdsetOpChanges.changes">
              <span>{{ b.op_time_formatted }}</span>
              <span>{{ b.old_val }}</span>
              <span :class="b.new_val > b.old_val ? 'bid_i' : 'bid_d'">{{
                b.new_val
              }}</span>
            </li>
          </ul>
        </div>
      </div>
    </ScreenBlocker>
  </div>
</template>

<script>
import moment from "moment";
import FilteringForm from "@/views/reports/shared/FilteringForm.reports";
import TwitterWizard from "@/views/twitter/shared/TwitterWizard";
import Multiselect from "vue-multiselect";

export default {
  props: {
    accountId: {
      required: true,
    },
  },
  components: {
    FilteringForm,
    TwitterWizard,
    Multiselect,
  },
  data() {
    let dtNow = new Date();
    let fields = {
      ssp: {
        title: "SSP",
        type: String,
      },
      created_at_formated: {
        title: "Created At",
        type: String,
      },
      entity_status: {
        title: "status",
        type: String,
      },
      bid_amount: {
        title: "Bid",
        decimalDigits: 3,
        editable: true,
        after: "$",
        max_field_name: "max_bid",
        max_field_value: 0.3,
        min_field_name: "min_bid",
        min_field_value: 0.01,
        type: Number,
      },
      camp_daily_budget: {
        title: "Daily Budget",
        editable: true,
        after: "$",
        max_field_name: "max_daily_budget",
        max_field_value: 10000,
        min_field_name: "min_daily_budget",
        min_field_value: 1,
        type: Number,
      },
      active_users: {
        title: "Active Users",
        type: Number,
      },
      clicks: {
        title: "Clicks",
        type: Number,
      },
      cpc: {
        title: "CPC",
        decimalDigits: 3,
        after: "$",
        type: Number,
      },
      ctr: {
        title: "CTR",
        decimalDigits: 3,
        after: "%",
        type: Number,
      },
      admanager_ecpm: {
        title: "AM ECPM",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      spend: {
        title: "Cost",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      revenue: {
        title: "Revenue",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      revenue_v2: {
        title: "Revenue V2",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      admanager_rev: {
        title: "AM Rev",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      dfp_revenue: {
        title: "DFP Rev",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      header_bidding_revenue: {
        title: "H/B Rev",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      a9_revenue: {
        title: "A9 Rev",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      primis_rev: {
        title: "Primis Rev",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      aniview_rev: {
        title: "Aniv Rev",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      aniview_imps: {
        title: "Aniv Imps",
        type: Number,
      },
      avantis_rev: {
        title: "Avantis Rev",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      avantis_imps: {
        title: "Avantis Imps",
        type: Number,
      },
      vidazoo_rev: {
        title: "Vidazoo Rev",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      vidazoo_imps: {
        title: "Vidazoo Imps",
        type: Number,
      },
      underdog_rev: {
        title: "Underdog Rev",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      underdog_imps: {
        title: "Underdog Imps",
        type: Number,
      },
      ssp_impressions: {
        title: "Ad Impressions",
        type: Number,
      },
      rpm: {
        title: "RPM",
        decimalDigits: 4,
        after: "$",
        type: Number,
      },
      rpm_v2: {
        title: "RPM V2",
        decimalDigits: 4,
        after: "$",
        type: Number,
      },
      roi: {
        title: "ROI",
        decimalDigits: 2,
        after: "%",
        type: Number,
        getClass: function(val) {
          if (val < 100) {
            return { roi_bad: true };
          }
          if (val > 130) {
            return { roi_good: true };
          }
          return { roi_ok: true };
        },
      },
      roi_v2: {
        title: "ROI V2",
        decimalDigits: 2,
        after: "%",
        type: Number,
        getClass: function(val) {
          if (val < 100) {
            return { roi_bad: true };
          }
          if (val > 130) {
            return { roi_good: true };
          }
          return { roi_ok: true };
        },
      },
      profit_and_loss: {
        title: "P&L",
        after: "$",
        type: Number,
      },
      profit_and_loss_v2: {
        title: "P&L V2",
        decimalDigits: 2,
        after: "$",
        type: Number,
      },
      user_value: {
        title: "UV",
        after: "$",
        type: Number,
      },
      user_value_v2: {
        title: "UV V2",
        after: "$",
        type: Number,
      },
      bounce_rate: {
        title: "Bounce Rate",
        decimalDigits: 2,
        after: "%",
        type: Number,
      },
      page_views_per_session: {
        title: "Pages / Session",
        decimalDigits: 2,
        type: Number,
      },
      avg_session_duration: {
        title: "Avg. Session Duration",
        type: Number,
      },
    };

    let filterableFields = Object.assign(
      {
        created_at: {
          title: "Created At",
        },
      },
      fields
    );
    delete filterableFields["created_at_formated"];

    return {
      checkboxSelected: [],
      twitterData: null,
      selectedAccounts: [],
      processingCsvReport: false,
      toDuplicateAdset: null,
      blockScreen: false,
      displayAdsetOpChanges: {
        adset: null,
        changes: null,
      },
      processingReport: false,
      form: {
        start_dt: dtNow,
        end_dt: new Date(),
        breakdowns: [],
      },
      filteringForm: [],
      filterableFields,
      searchForm: {
        excludeAdset: null,
        adset: null,
        ad: null,
        status: "ALL",
      },
      sort: {
        field: "clicks",
        asc: false,
      },
      editAdsetPopup: {
        adset: null,
        field: null,
        value: null,
        max_field: null,
        max_field_value: null,
        min_field: null,
        min_field_value: null,
      },
      editMultipleAdsetsPopup: {
        adsets: null,
        field: null,
        value: null,
        setType: "absolute",
        max_field_value: null,
        min_field_value: null,
        edit_field_chosed: false,
        min_field_chosed: false,
        max_field_chosed: false,
      },
      adsetWizardOpened: false,
      selectedAdsets: [],
      selectedAccount: null,
      allAccounts: null,
      selectedDateRange: "",
      openAdsets: {},
      submitedBreakdowns: [],
      breakdownsOptions: ["ssp", "ssp_impressions"],
      reportData: null,
      reportDataFiltered: null,
      ongoingActions: {
        toggleAdsetStatus: {},
        editAdset: {},
        editMultipleAdsets: false,
      },
      allAdsetsSelectedCheckbox: false,
      metricsSelectCompatible: [
        "active_users",
        "bid_amount",
        "camp_daily_budget",
        "cpc",
        "ctr",
        "clicks",
        "spend",
        "revenue_v2",
        "rpm_v2",
        "roi_v2",
        "profit_and_loss_v2",
        "user_value_v2",
      ].map((v) => ({ title: fields[v].title, value: v })),
      metrics: [
        "active_users",
        "bid_amount",
        "camp_daily_budget",
        "cpc",
        "ctr",
        "clicks",
        "spend",
        "revenue_v2",
        "rpm_v2",
        "roi_v2",
        "profit_and_loss_v2",
        "user_value_v2",
      ],
      fields,
      dateRangeOptions: [
        "Today",
        "Yesterday",
        "Last 7 days",
        "Last 30 days",
        "This Month",
        "Last Month",
      ],
      adsetMicroReport: {},
    };
  },
  watch: {
    ["searchForm.adset"](val) {
      this.filterReport();
    },
    ["searchForm.excludeAdset"](val) {
      this.filterReport();
    },
    ["searchForm.ad"](val) {
      this.filterReport();
    },
    filteringForm: {
      handler: function(val) {
        if (this.reportData) this.filterReport();
      },
      deep: true,
    },
  },
  computed: {
    fieldsList() {
      let arr = [];
      for (let key in this.fields) {
        let v = this.fields[key];

        arr.push({
          title: v.title,
          value: key,
        });
      }
      arr.sort();

      return arr;
    },
    sumReport() {
      let adsets = this.reportDataFiltered.adsets;
      let totalReport = this.getTotalReport(adsets);

      return Object.assign({}, totalReport, {
        adsetsCount: this.getAdsetsCount(adsets),
        adsCount: this.getAdsCount(adsets),
      });
    },
  },
  filters: {
    formatTime(v) {
      if (!v) {
        return "n/a";
      }
      v = moment.utc(v);
      if (v.year() === 1) {
        return "n/a";
      }
      let isToday = v.isSame(new Date(), "day");
      if (isToday) {
        return "Today " + v.format("HH:mm") + " (Israel timezone)";
      } else {
        return v.format("MM-DD HH:mm");
      }
    },
  },
  methods: {
    addMetric(key) {
      return;
      this.metricsSelectCompatible.push({
        title: this.fields[key].title,
        value: key,
      });
    },
    getReportAdsetsList() {
      let accountIds = this.reportDataFiltered.adsets.map((v) => v);
      let adsetsList = {
        start_dt: moment(this.form.start_dt).format("YYYY-MM-DD"),
        end_dt: moment(this.form.end_dt).format("YYYY-MM-DD"),
        account_ids: accountIds,
        breakdowns: this.breakdowns,
      };

      return adsetsList;
    },
    createRow(adsetsList, metrics) {
      return Object.entries(adsetsList.account_ids).map((adset) => ({
        cells: [
          {
            coloumnName: "Name(ID)",
            value: `${adset[1]["name"]}(${adset[1]["id"]})`,
          },
          ...metrics.map((metric) => ({
            coloumnName: metric,
            value: adset[1][metric].toString(),
          })),
        ],
      }));
    },
    async downloadReportCsv() {
      const adsetsList = this.getReportAdsetsList();
      const metrics = this.metrics;
      const rows = this.createRow(adsetsList, metrics);
      this.processingCsvReport = true;
      var data = {
        header: ["Name(ID)", ...metrics],
        rows: rows,
      };
      try {
        const response = await this.$http.post(
          this.resources.cs.csv,
          JSON.stringify(data),
          { withCredentials: false },
          {
            responseType: "blob",
          }
        );

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.csv");
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (err) {
        console.error(err);
        this.notifyError("Could not download csv file.");
      }
      this.processingCsvReport = false;
    },
    async duplicateAdset(adset) {
      this.blockScreen = true;
      try {
        const response = await this.$http.get(
          this.resources.twitter.duplicateAdsetCSHARP(
            adset.account_id,
            adset.id
          )
        );
        const data = await response.json();
        this.toDuplicateAdset = data;
        this.adsetWizardOpened = true;
      } catch (error) {
        console.error(error);
      }
      this.blockScreen = false;
    },
    onWizardClose() {
      this.adsetWizardOpened = false;
      this.toDuplicateAdset = null;
    },
    async viewAdsetOpChanges(adset, field) {
      field = field.toLowerCase().includes("bid") ? "bid" : "daily_budget";
      this.displayAdsetOpChanges = {
        adset: adset,
        changes: null,
        fieldTitle: field === "bid" ? "bid" : "daily budget",
      };
      try {
        const response = await this.$http.get(
          this.resources.logs.adsetChanges(adset.id),
          {
            params: {
              field: field.toUpperCase(),
            },
          }
        );
        const changes = await response.json();
        this.displayAdsetOpChanges.changes = changes;
      } catch (err) {
        this.displayAdsetOpChanges.adset = null;
        this.notifyError("Could not fetch bid changes");
      }
    },
    onEditAdsetValueChange() {
      let ef = this.editMultipleAdsetsPopup;
      let val = ef.value;
      if (!val) {
        val = 0;
      }
      ef.adsets.forEach((ad) => {
        if (ef.setType === "changeBy") {
          ad["updated_val"] = ad[ef.field] + val;
        } else {
          ad["updated_val"] = val;
        }
        if (ef.setType === "changeBy") {
          ad["updated_val"] = ad[ef.field] + val;
        } else if (ef.setType === "changeByPercentage") {
          let v = ad[ef.field] * (ef.value / 100);
          const formatter = new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 3,
          });
          const roundedV = Number(formatter.format(v));
          v = ad[ef.field] + roundedV;
          v = Number(formatter.format(v));
          if (v <= 0) {
            return;
          }
          ad["updated_val"] = v;
        } else {
          ad["updated_val"] = val;
        }
      });
    },
    toggleSelectAllAdsets() {
      let adsets = this.reportDataFiltered.adsets;
      if (!this.allAdsetsSelectedCheckbox) {
        this.selectedAdsets = [];
      } else {
        this.selectedAdsets = adsets.slice(0);
      }
    },
    actionSetActive() {
      if (this.selectedAdsets.length === 0) {
        return;
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: "entity_status",
        value: "ACTIVE",
        edit_field_chosed: true,
      });
      this.selectedAdsets.forEach((adset) => {
        adset.updated_val = "ACTIVE";
      });
      this.checkboxSelected = this.selectedAdsets;
    },
    actionSetInactive() {
      if (this.selectedAdsets.length === 0) {
        return;
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: "entity_status",
        value: "PAUSED",
        edit_field_chosed: true,
      });
      this.selectedAdsets.forEach((adset) => {
        adset.updated_val = "PAUSED";
      });
      this.checkboxSelected = this.selectedAdsets;
    },
    actionSetDailyBudget() {
      if (this.selectedAdsets.length === 0) {
        return;
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: "camp_daily_budget",
        value: 0,
        max_field_value: this.fields["camp_daily_budget"].max_field_value,
        min_field_value: this.fields["camp_daily_budget"].min_field_value,
        edit_field_chosed: false,
        min_field_chosed: false,
        max_field_chosed: false,
      });
      this.checkboxSelected = this.selectedAdsets;
    },
    actionSetBid() {
      if (this.selectedAdsets.length === 0) {
        return;
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: "bid_amount",
        value: 0,
        max_field_value: this.fields["bid_amount"].max_field_value,
        min_field_value: this.fields["bid_amount"].min_field_value,
        edit_field_chosed: false,
        min_field_chosed: false,
        max_field_chosed: false,
      });
      this.checkboxSelected = this.selectedAdsets;
    },
    getAdsetExternalLink(adset) {
      return `https://ads.twitter.com/ads_manager/${
        adset.account_id
      }/fundingsources/61064041/campaigns/${parseInt(
        adset.campaign_id,
        36
      )}/adgroups/${parseInt(
        adset.id,
        36
      )}/ads/?columns=41.42.38.9.8.83.84.85.39.303.86.276.2.231.230.5&endDate=${moment().format(
        "YYYY-MM-DD"
      )}&startDate=${moment().format("YYYY-MM-DD")}`;
    },
    openEditPopup(adset, f, max_field_name, min_field_name) {
      this.editAdsetPopup = {
        adset: adset,
        field: f,
        value: adset[f],
        max_field: max_field_name,
        max_field_value:
          adset[max_field_name] !== 0
            ? adset[max_field_name]
            : this.fields[f].max_field_value,
        min_field: min_field_name,
        min_field_value:
          adset[min_field_name] !== 0
            ? adset[min_field_name]
            : this.fields[f].min_field_value,
      };
    },
    openMultipleEditPopup(adsets, f) {
      this.editMultipleAdsetsPopup = {
        adsets: adsets,
        field: f,
        value: null,
      };
    },
    async confirmMultipleAdsetEdit() {
      let ef = this.editMultipleAdsetsPopup;
      let adsets = this.checkboxSelected;
      let max_field = "";
      let min_field = "";

      if (this.ongoingActions.editMultipleAdsets) {
        return;
      }
      if (
        !ef.edit_field_chosed &&
        !ef.min_field_chosed &&
        !ef.max_field_chosed
      ) {
        this.closeEditPopup();
        return;
      }

      let invalidAction = false;
      let invalidMessage = "";
      let adsetsPayload = [];

      adsets.forEach((adset) => {
        if (adset.updated_val <= 0) {
          invalidAction = true;
          invalidMessage = "Invalid value for an adset";
          return;
        }
        if (ef.field === "bid_amount") {
          max_field = "max_bid";
          min_field = "min_bid";
        }

        if (ef.field === "camp_daily_budget") {
          max_field = "max_daily_budget";
          min_field = "min_daily_budget";
        }

        let params = {
          id: adset.id,
          account_id: adset.account_id,
          camp_id: adset.campaign_id,
        };

        if (ef.edit_field_chosed) {
          if (ef.value.bid_amount <= 0 || ef.value <= 0) {
            invalidAction = true;
            invalidMessage = "bid amount or daily budget <= 0";
            return;
          }
          params[ef.field] = adset.updated_val;
        }
        if (ef.max_field_chosed) {
          if (ef.max_field_value <= 0) {
            invalidAction = true;
            invalidMessage = "max field <= 0 ";
            return;
          }
          params[max_field] = ef.max_field_value;
        }
        if (ef.min_field_chosed) {
          if (ef.min_field_value <= 0) {
            invalidAction = true;
            invalidMessage = "min field <= 0 ";
            return;
          }
          params[min_field] = ef.min_field_value;
        }

        if (ef.field !== "status") {
          let bidValue;
          let maxValue;
          let minValue;
          if (ef.field === "camp_daily_budget") {
            bidValue = params[ef.field] || adset[ef.field];
            maxValue = params[max_field] || adset["max_daily_budget"];
            minValue = params[min_field] || adset["min_daily_budget"];
          }
          if (ef.field === "bid_amount") {
            bidValue = params[ef.field] || adset[ef.field];
            maxValue = params[max_field] || adset["max_bid"];
            minValue = params[min_field] || adset["min_bid"];
          }

          if (bidValue > maxValue || bidValue < minValue) {
            invalidAction = true;
            invalidMessage =
              "bid/daily budget < min or  bid/daily budget > max ";
            return;
          }
        }

        adsetsPayload.push(params);
      });

      if (invalidAction) {
        this.notifyError(invalidMessage);
        return;
      }

      let params = {
        adsets: adsetsPayload,
      };

      this.ongoingActions.editMultipleAdsets = true;
      try {
        await this.$http.put(this.resources.twitter.adsetsCSHARP, params);
        adsets.forEach((adset) => {
          if (ef.max_field_chosed) {
            adset[max_field] = ef.max_field_value;
          }
          if (ef.min_field_chosed) {
            adset[min_field] = ef.min_field_value;
          }
          if (ef.edit_field_chosed) {
            adset[ef.field] = adset["updated_val"];
          }
        });
        this.closeEditPopup();
        this.notifySuccess("edited successfully");
      } catch (error) {
        console.error(error);
        this.notifyError("An error has occured");
      }

      this.ongoingActions.editMultipleAdsets = false;
    },
    async confirmAdsetEdit() {
      let ef = this.editAdsetPopup;
      let adset = ef.adset;

      if (this.ongoingActions.editMultipleAdsets) {
        return;
      }

      if (ef.value >= ef.max_field_value) {
        this.notifyError(`${ef.field} is higher than the maximum limit`);
        return;
      }

      this.$set(this.ongoingActions.editAdset, adset.id, true);

      window.setTimeout(() => {
        this.$set(this.ongoingActions.editAdset, adset.id, false);
      }, 1200);

      let params = {
        account_id: adset.account_id,
        [ef.field]: ef.value,
        [ef.max_field]: ef.max_field_value,
        [ef.min_field]: ef.min_field_value,
        camp_id: adset.campaign_id,
      };
      try {
        await this.$http.put(
          this.resources.twitter.adsetCSHARP(adset.id),
          null,
          {
            params,
          }
        );
        adset[ef.field] = ef.value;
        adset[ef.max_field] = ef.max_field_value;
        adset[ef.min_field] = ef.min_field_value;
        this.closeEditPopup();
      } catch (error) {
        this.notifyError("An error has occured");
        this.ongoingActions.editAdset[adset.id] = false;
      }
    },
    closeEditPopup() {
      (this.editAdsetPopup = {
        adset: null,
        field: null,
        value: null,
        max_field: null,
        max_field_value: null,
        min_field: null,
        min_field_value: null,
      }),
        this.selectedAdsets.forEach((adset) => {
          delete adset["updated_val"];
        });
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: null,
        field: null,
        value: null,
        max_field_value: null,
        min_field_value: null,
        edit_field_chosed: false,
        min_field_chosed: false,
        max_field_chosed: false,
      });
    },
    async toggleAdsetStatus(adset) {
      if (!this.userHasPermission("twitter.edit")) {
        return;
      }

      if (this.ongoingActions.toggleAdsetStatus[adset.id]) {
        return;
      }

      this.ongoingActions.toggleAdsetStatus[adset.id] = true;

      let status = adset.entity_status;
      let toStatus;
      if (status == "ACTIVE") {
        toStatus = "PAUSED";
      } else {
        toStatus = "ACTIVE";
      }

      let params = {
        account_id: adset.account_id,
        status: toStatus,
      };
      try {
        await this.$http.put(
          this.resources.twitter.adsetCSHARP(adset.id),
          null,
          {
            params,
          }
        );
        window.setTimeout(() => {
          adset.entity_status = toStatus;
          this.ongoingActions.toggleAdsetStatus[adset.id] = false;
        }, 20);
      } catch (err) {
        this.ongoingActions.toggleAdsetStatus[adset.id] = false;
        this.notifyError("An error has occured");
      }
    },
    selectAccount(account) {
      this.selectedAccounts = account;
    },
    async fetchTwitterData(attempt = 0) {
      if (this.$store.state.data.twitterData) {
        this.twitterData = this.$store.state.data.twitterData;
        this.allAccounts = Object.values(this.twitterData.accounts);
        return;
      }
      try {
        const response = await this.$http.get(
          this.resources.Reports.twitterData
        );
        const data = await response.json();
        let accounts = data && Object.values(data.accounts);
        if (!accounts || accounts.length === 0) {
          return;
        }
        this.allAccounts = accounts;
        accounts.sort((a, b) => (a.name > b.name ? 1 : -1));
        this.$store.state.data.twitterData = data;
        this.twitterData = data;
        this.maxBidSet = data.meta.maxBid;
        this.allAccounts = accounts;
      } catch (error) {
        if (attempt > 10) {
          this.notifyError("Could not load all FB data");
          return;
        }
        window.setTimeout(() => {
          this.fetchTwitterData(attempt + 1);
        }, 2000);
      }
    },
    onColumnClick(field) {
      if (this.sort.field === field) {
        this.sort.asc = !this.sort.asc;
      } else {
        this.sort.field = field;
        this.sort.asc = false;
      }
      this.sortReport();
    },

    filterReport() {
      let adsetSearch = this.searchForm.adset;
      let excludeAdsetInput = this.searchForm.excludeAdset;
      let status = this.searchForm.status;
      let reportData = this.reportData;

      let data = {};
      Object.assign(data, reportData);

      if (excludeAdsetInput) {
        var reg = new RegExp(excludeAdsetInput.toLowerCase());

        data.adsets = data.adsets.filter((v) => {
          if (!v.name.toLowerCase().match(reg)) {
            return v;
          }
        });
      }

      if (status == "ACTIVE" || status == "PAUSED") {
        data.adsets = data.adsets.filter((v) => v.entity_status === status);
      }

      if (adsetSearch) {
        adsetSearch = adsetSearch.toLowerCase();
        data.adsets = reportData.adsets.filter(
          (v) =>
            v.id == adsetSearch || v.name.toLowerCase().includes(adsetSearch)
        );
      }

      let filteringForm = this.filteringForm;

      data.adsets = data.adsets.filter((adset) => {
        let passedFilters = true;

        for (let i = 0; i < filteringForm.length; i++) {
          let fo = filteringForm[i];

          let field = fo.field;
          let givenVal = adset[field];
          let requiredVal = fo.val;
          let op = fo.operator;

          if (!field || !requiredVal || !op) {
            continue;
          }

          if (field === "created_at") {
            givenVal = givenVal.substr(0, givenVal.indexOf("T"));
          }

          if (op === ">") {
            passedFilters = givenVal > requiredVal;
          } else if (op === "<") {
            passedFilters = givenVal < requiredVal;
          } else {
            passedFilters = givenVal == requiredVal;
          }

          if (!passedFilters) {
            break;
          }
        }

        return passedFilters;
      });

      this.reportDataFiltered = data;
    },
    sortReport() {
      let data = this.reportDataFiltered;
      if (!data || !data.adsets) {
        return;
      }

      let sort = this.sort;
      if (!sort.field) {
        return;
      }

      let sortField = sort.field;
      if (sort.field === "created_at_formated") {
        sortField = "created_at";
      }

      data.adsets.sort((a, b) => {
        a = a[sortField];
        b = b[sortField];

        let f;
        if (a > b) {
          f = 1;
        } else if (a < b) {
          f = -1;
        } else {
          f = 0;
        }

        if (!sort.asc) {
          f = f * -1;
        }

        return f;
      });
    },
    getTotalReport(adsets) {
      let r = {
        camp_daily_budget: 0,
        impressions: 0,
        clicks: 0,
        spend: 0,
        revenue: 0,
        profit_and_loss: 0,
        roi: 0,
        ctr: 0,
        aniview_rev: 0,
        primis_rev: 0,
        am_rev: 0,
        aniview_imps: 0,
        admanager_rev: 0,
        avantis_rev: 0,
        avantis_imps: 0,
        vidazoo_rev: 0,
        vidazoo_imps: 0,
        underdog_rev: 0,
        underdog_imps: 0,
        total_page_views: 0,
        rpm: 0,
        rpm_v2: 0,
        header_bidding_revenue: 0,
        a9_revenue: 0,
        dfp_revenue: 0,
        revenue_v2: 0,
        profit_and_loss_v2: 0,
        user_value_v2: 0,
        roi_v2: 0,
        active_users: 0,
      };

      let allCampsDailyBudget = {};

      adsets.forEach((adset) => {
        r.impressions += adset.impressions;
        r.clicks += adset.clicks;
        r.spend += adset.spend;
        r.revenue += adset.revenue;
        r.primis_rev += adset.primis_rev;
        r.am_rev += adset.am_rev;
        r.admanager_rev += adset.admanager_rev;
        r.aniview_rev += adset.aniview_rev;
        r.aniview_imps += adset.aniview_imps;
        r.avantis_rev += adset.avantis_rev;
        r.avantis_imps += adset.avantis_imps;
        r.vidazoo_rev += adset.vidazoo_rev;
        r.vidazoo_imps += adset.vidazoo_imps;
        r.underdog_rev += adset.underdog_rev;
        r.underdog_imps += adset.underdog_imps;
        r.dfp_revenue += adset.dfp_revenue;
        r.revenue_v2 += adset.revenue_v2;
        r.header_bidding_revenue += adset.header_bidding_revenue;
        r.a9_revenue += adset.a9_revenue;
        r.active_users += adset.active_users;
        r.total_page_views += adset.clicks * adset.page_views_per_session;
        if (!allCampsDailyBudget[adset.campaign_id]) {
          allCampsDailyBudget[adset.campaign_id] = adset.camp_daily_budget;
        }
      });

      for (let key in allCampsDailyBudget) {
        let val = allCampsDailyBudget[key];
        r.camp_daily_budget += val;
      }

      if (r.clicks > 0) {
        r.user_value = r.revenue / r.clicks;
        r.page_views_per_session = r.total_page_views / r.clicks;
        if (r.total_page_views > 0) {
          r.rpm = (r.revenue / r.total_page_views) * 1e3;
          r.rpm_v2 = (r.revenue_v2 / r.total_page_views) * 1e3;
        }
      }

      if (r.spend > 0) {
        r.roi = (r.revenue / r.spend) * 100;
        r.roi_v2 = (r.revenue_v2 / r.spend) * 100;
      }

      if (r.impressions > 0) {
        r.ctr = (r.clicks / r.impressions) * 100;
      }

      r.profit_and_loss = r.revenue - r.spend;
      r.profit_and_loss_v2 = r.revenue_v2 - r.spend;

      if (r.clicks > 0) {
        r.cpc = r.spend / r.clicks;
      }

      r.user_value_v2 = r.revenue_v2 / r.clicks;
      return r;
    },
    getAdsCount(adsets) {
      if (!adsets) {
        return 0;
      }
      let c = 0;
      adsets.forEach((adset) => {
        c += adset.ads ? adset.ads.length : 0;
      });
      return c;
    },
    getAdsetsCount(adsets) {
      if (!adsets) {
        return 0;
      }
      return Object.keys(adsets).length;
    },
    setFixedDateRange(val) {
      this.selectedDateRange = val;
      let dtNow = new Date();
      let endDt, startDt;
      let d = new Date();
      switch (val) {
        case "Today":
          startDt = dtNow;
          endDt = d;
          break;

        case "Yesterday":
          d.setDate(dtNow.getDate() - 1);
          startDt = d;
          endDt = d;
          break;

        case "Last 7 days":
          d.setDate(dtNow.getDate() - 7);
          startDt = d;
          endDt = dtNow;
          break;

        case "Last 30 days":
          d.setDate(dtNow.getDate() - 30);
          startDt = d;
          endDt = dtNow;
          break;

        case "This Month":
          d.setDate(1);
          startDt = d;
          endDt = dtNow;
          break;

        case "Last Month":
          let ms = new Date();
          let lms = new Date();
          ms.setDate(0);
          lms.setDate(0);
          lms.setDate(1);
          startDt = lms;
          endDt = ms;
          break;
      }

      this.form.start_dt = startDt;
      this.form.end_dt = endDt;
    },
    isAdsetOpen(id) {
      return this.openAdsets[id];
    },
    toggleAdsetExpand(adset, breakdown) {
      let lastReport = this.adsetMicroReport[adset.id];
      if (lastReport && lastReport.breakdown === breakdown) {
        this.$delete(this.adsetMicroReport, adset.id);
        return;
      }

      if (breakdown === "ssp") {
        if (!adset.detailed_revenue) {
          return;
        }
        let sspBreakdownRows = [];
        for (let ssp in adset.detailed_revenue) {
          let val = adset.detailed_revenue[ssp];
          let r = val && val.rev;
          let ssp_impressions = val.impressions;
          if (!r) {
            continue;
          }
          ssp = this.$options.filters.prettify_string(ssp);
          let rpm;
          if (ssp_impressions > 0) {
            rpm = (r / ssp_impressions) * 1e3;
          }
          sspBreakdownRows.push({
            ssp: ssp,
            revenue_v2: r,
            rpm_v2: rpm,
            ssp_impressions,
          });
        }
        sspBreakdownRows.sort((a, b) =>
          a.revenue_v2 > b.revenue_v2 ? -1 : b.revenue_v2 > a.revenue_v2 ? 1 : 0
        );
        this.metrics.push(breakdown);
        this.metrics.push("ssp_impressions");
        this.$set(this.adsetMicroReport, adset.id, {
          breakdown: breakdown,
          adsets: sspBreakdownRows,
        });
        return;
      }

      let payload = {
        start_dt: moment(adset.start_dt).format("YYYY-MM-DD"),
        end_dt: moment(adset.end_dt).format("YYYY-MM-DD"),
        campaign_id: adset.id,
        breakdowns: [breakdown],
      };
      if (breakdown === "date") {
        let dtNow = new Date();
        let d = new Date();
        d.setDate(dtNow.getDate() - 7);
        let startDt = d;
        let endDt = dtNow;
        payload.start_dt = moment(startDt).format("YYYY-MM-DD");
        payload.end_dt = moment(endDt).format("YYYY-MM-DD");
      }

      this._fetchReport(payload)
        .then((r) => r.json())
        .then((data) => {
          this.metrics.push(breakdown);
          this.addMetric(breakdown);

          this.$set(this.adsetMicroReport, adset.id, {
            breakdown: breakdown,
            adsets: data.adsets,
          });
        });
    },
    arrangeReport(data) {
      let adsets = data.adsets;

      for (let key in adsets) {
        let adset = adsets[key];
        adset.created_at_formated = moment(adset.created_at).format(
          "DD/MM HH:mm"
        );
      }
      return data;
    },
    async fetchReport() {
      this.breakdowns = [];

      this.selectedAdsets = [];
      this.allAdsetsSelectedCheckbox = false;

      let accountIds = this.selectedAccounts.map((v) => v.id);
      if (!accountIds) {
        accountIds = "";
      }
      let params = {
        start_dt: moment(this.form.start_dt).format("YYYY-MM-DD"),
        end_dt: moment(this.form.end_dt).format("YYYY-MM-DD"),
        account_id: accountIds,
      };
      this.processingReport = true;

      try {
        const response = await this.$http.get(
          this.resources.Reports.twitterCampAllInsights,
          { params }
        );
        let data = await response.json();

        data.adsets = data.adsets || {};
        data = this.arrangeReport(data);
        data.adsets = Object.values(data.adsets);
        this.reportData = data;
        this.filterReport();
        this.sortReport();

        let bd = this.breakdowns;
        let metrics = this.metricsSelectCompatible;
        this.lastReportBreakdowns = bd;
        this.breakdownsOptions.forEach((v) => {
          let i = metrics.indexOf(v);
          if (i > -1) {
            metrics.splice(i, 1);
          }
        });
      } catch (error) {
        console.error(error);
        this.notifyError("Could not fetch twitter data.");
      }
      this.processingReport = false;
    },
  },
  created() {
    this.setFixedDateRange("Today");
    this.fetchTwitterData();
  },
};
</script>
