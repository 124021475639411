<template lang="html">
  <div class="">
    <router-view v-if='dataLoaded'></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataLoaded: false
    }
  },
  created() {
    this.fetchFbData()
  },
  computed: {
    accountId() {
      return this.$route.params.accountId
    }
  },
  methods: {
    // fetchFbData(attempt = 0) {
    //   return this.$http.get(this.resources.Reports.fbAllData)
    //     .then(r => r.json())
    //     .then(data => {
    //       this.$store.state.data.fbData = data
    //       this.dataLoaded = true
    //     })
    //     .catch(err => {
    //       if(attempt > 3) {
    //         throw err
    //         return
    //       }
    //       window.setTimeout(() => {
    //         fetchFbData(attempt+1)
    //       }, 1500)
    //     })
    // },
    prepareAdsets(data) {
      let allAdsets = []

      let accounts = data.accounts || []
      accounts.forEach(account => {
        let camps = account.campaigns || []
        camps.forEach(camp => {
          let adsets = camp.adsets || []
          adsets.forEach(adset => {
            allAdsets.push(Object.assign({}, adset, {
              camp_name: camp.name,
              camp_id: camp.id
            }))
          })
        })
      })

      return allAdsets
    }
  }
}
</script>

<style lang="css" scoped>
</style>
