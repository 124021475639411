<template lang="html">
  <div class="list-parent">
    <strong class="list-title">{{title}}</strong>
    <div class="d2">
      <Checkbox :onChange='v => { selectAll = v }' />
      <input type="text" class="search-input" placeholder="Search for..." v-model='search'>
    </div>
    <ul class='scrollbar-1 list'>
      <li>
        <Spinner v-show='isLoading' />
      </li>
      <li v-for='(item, index) in filteredItems' @click='active=item' :key='index'>
        <div class="form-check">
          <div class="">
            <Checkbox :onChange='onCheckChange.bind(null, index)' :checked="isChecked(index)" :id="htmlId + '-' + index" />
          </div>
          <!-- TODO: isChcked() || isActive needs to be changed  -->
          <label :class="{'form-check-label': true, clickable: true, active: isChecked(index) || isActive(index)}" :for="htmlId + '-' + index">
            {{item}}
          </label>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import * as act from '@/act-types'
import buildUrl from 'build-url'

export default {
  props: {
    items: {

    },
    apiUrl: {

    },
    method: {
      default: 'get'
    },
    title: {
    },
    values: {},
    onChange: {
      type: Function,
      default: e => e
    }
  },
  data() {
    return {
      selectAll: false,
      isLoading: false,
      search: null,
      selected: [],
      active: null,
      htmlId: null,
      apiList: null,
      dataOptions: {
        filterscount: 0,
        groupscount: 0,
        pagenum: 0,
        pagesize: 25,
        recordstartindex: 0,
        recordendindex: 25,
        latestRecords: true,
        latestRecordsDate: undefined
      }
    }
  },
  computed: {
    filteredItems() {
      return this.filterData(this.apiList || this.items)
    }
  },
  watch: {
    selectAll(val) {
      if(val) {
        return this.selected = Object.keys(this.filteredItems)
      }
      this.selected = []
    },
    values(val) {
      this.selected = val
    },
    selected(vals) {
      this.onChange(vals)
    }
  },
  created() {
    this.$store.dispatch(act.GET_NEXT_ID)
      .then(id => {
        this.htmlId = id
      })
    if(!this.apiUrl) {
      return
    }
    this.fetchData()
  },
  methods: {
    filterData(items) {
      if(!items) { return items }
      let {search} = this
      if(Array.isArray(items)) {
        let temp = {}
        items.forEach(v => {temp[v] = v})
        items = temp
      }
      if(!search) {
        return items
      }
      search = search.toLowerCase()
      let that = {}
      for (let key in items) {
        let e = items[key]
        if(e && e.toString().toLowerCase().includes(search)) {
          that[key] = e
        }
      }
      return that
    },
    fetchData(options = {}) {
      options = Object.assign(this.dataOptions, options)
      let root; (root = this.$http.options.root) && (root = root[root.length - 1] === '/' ? root = root.substr(0, root.length - 1 ) : root)
      let url = buildUrl(root, {
        path: this.apiUrl,
        queryParams: this.dataOptions
      })
      this.isLoading = true
      this.$http[this.method](url)
        .then(r => r.json())
        .then(r => r.data)
        .then(data => {
          this.apiList = data.splice(0, 25)
        })
        .catch(err => {
          this.error = "Error fetching data"
          console.log(err)
        })
        .then(() => {
          this.isLoading = false
        })
    },
    isChecked(item) {
      return this.selected.includes(item)
    },
    isActive(item) {
      return this.active === item
    },
    onCheckChange(val) {
      this.selected.toggle(val)
    }
  },
}
</script>

<style lang="scss" scoped>
@import "scss/core-variables";

label {
  margin-bottom: initial;
}

.search-input {
  border: 0;
}
.list-parent {
  display: inline-flex;
  flex-direction: column;
  background: white;
  border: 1px solid #cecece;
  padding: 3px;
  margin-right: 5px;
}
.d2 {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d2d2d2;
}
.d2 > div {
  margin-right: 5px;
}
.list {
  overflow-y: auto;
  margin-bottom: 6px;
  margin-top: 0;
  max-height: 220px;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  background: white;

  li {
    color: #2b2b2b;
    font-size: 15px;
  }
  li:hover .form-check-label, .form-check-label.active {
    background: #c8d0d8;
    color: #181a1b;
    border-radius: 5px;
  }
  .form-check {
    display: flex;
    padding-left: initial;
    align-items: center;
    .form-check-label {
      flex: 1;
      margin-left: 6px;
      border: 1px solid transparent;
    }
  }
  input {
    border: none;
  }
}
.list-title {
  text-align: center;
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 2px;
  margin-bottom: 3px;
  font-weight: normal;
}
</style>
