<template lang="html">
    <div class="facebook-pages">
        <div class="add-page">
            <b-button id="plus-btn" :squared="true" variant="outline-primary" size="md" class="mb-2"
                @click="addNewPage()">
                <b-icon icon="plus" font-scale="3"></b-icon>
            </b-button>
        </div>
        <div id="pages-table">
            <template>
                <div class="overflow-auto">
                    <b-table id="reportTable" :items="items" :fields="fields" hover responsive="sm" :busy="isBusy">
                        <template #table-busy>
                            <div class="text-center text-danger my-2">
                                <b-spinner class="align-middle"></b-spinner>
                                <strong>Loading...</strong>
                            </div>
                        </template>
                        <template v-slot:cell(edit)="items" class="edit-tab">
                            <button id="edit_btn" @click="editPage(items.item)">
                                <b-icon icon="pencil"></b-icon>
                            </button>
                        </template>
                    </b-table>
                </div>
            </template>
        </div>
        <Modal class="modal-edit" :modalShow.sync="modalShow" :text="this.modalTitle" :operation=this.operation
            v-if="this.modalShow" :currentPage.sync="currentPage"></Modal>
    </div>

</template>
<script>

import Modal from "../../components/shared/modalFacebookPageManagment";

export default {
    components: { Modal },
    data() {
        return {
            fields: [{ key: 'id', sortable: true }, { key: 'title', sortable: true }, { key: "active", sortable: true }, { key: "edit", sortable: false }],
            items: [],
            currentPage: {},
            operation: "",
            modalShow: false,
            isBusy: false,
            modalTitle: "",
        }
    },
    watch: {
        currentPage(newPage, oldPage) {

            if (this.modalShow == false) {
                this.isBusy = !this.isBusy;                
                this.getAllFacebookPages();
            }
        },
    },
    created() {
        this.isBusy = !this.isBusy;
        this.getAllFacebookPages();
    },
    methods: {
        getAllFacebookPages: async function () {
            try {
                const r = await this.$http.get(this.resources.cs.getAllFacebookPages);
                const data = await r.json();
                this.items = data;
                this.isBusy = !this.isBusy;
            } catch (err) {                
                this.notifyError("Could fetch page.");
            }
        }, editPage: async function (page) {
            this.operation = "edit",
                this.modalTitle = "Edit facebook page"
            this.modalShow = !this.modalShow;
            this.currentPage = page
        },
        addNewPage: async function () {
            this.operation = "add",
                this.modalTitle = "Add new facebook page"
            this.modalShow = !this.modalShow;
            this.currentPage = {
                id: 0,
                title: "",
                active: false,
            }
        }

    }
}
</script>


  
