<template>
    <div>
        <b>Select date range: </b>

        <date-range-picker ref="dateRange" v-model="dateRangeComputed" v-slot:input="picker"
            :controlContainerClass="'form-input'" :locale-data="locale" :date-format="dateFormat" :ranges="ranges"
            class="pointer" :autoApply="autoApply">
        </date-range-picker>
    </div>

</template>


<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import moment from "moment";

export default {
    components: { DateRangePicker },
    props: ['dateRange'],
    model: {
        prop: 'dateRange',
        event: 'update'
    },
    data() {
        const today = moment().set('hours', 0).set('minutes', 0).set('seconds', 0).toDate();

        const yesterday = moment().subtract(1, 'days').set('hours', 0).set('minutes', 0).set('seconds', 0).toDate();

        const last7Days = moment().subtract(7, 'days').set('hours', 0).set('minutes', 0).set('seconds', 0).toDate();

        let last30Days = moment().subtract(30, 'days').set('hours', 0).set('minutes', 0).set('seconds', 0).toDate();

        let thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);


        return {
            autoApply: true,
            ranges: {
                'Today': [today, today],
                'Yesterday': [yesterday, yesterday],
                'Last 7 days': [last7Days, today],
                'Last 30 days': [last30Days, today],
                'This month': [thisMonth, today],
                'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
            },
            locale: {
                direction: 'ltr',
                format: 'dd-mm-yyyy',
                separator: ' → ',
                applyLabel: 'APPLY',
                cancelLabel: 'Cancel',
                weekLabel: 'W',
                customRangeLabel: 'Custom Range',
                daysOfWeek: moment.weekdaysMin(),
                monthNames: moment.monthsShort(),
                firstDay: 1,

            },
        }
    },
    methods: {
        dateFormat(classes, date) {
            if (!classes.disabled) {
                classes.disabled = date.getTime() > new Date()
            }
            return classes
        }
    },
    computed: {
        dateRangeComputed: {
            get: function () {
                return this.dateRange;
            },
            set: function (newValue) {
                this.$emit('update', newValue)
            }
        }
    }
}

</script>
<style lang="css" scoped>
.pointer {
    cursor: pointer;
}
</style>

