<template>
  <div class="container mt-5">
    <div class="modal_container">
      <b-modal
        v-model="modalShow"
        class="domain_modal"
        :id="modalId"
        size="lg"
        @hide="onHide"
      >
        <template id="bootstrap-overrides" #modal-header clas
          ><span id="add-title" v-if="operation === 'add'">Add new Role:</span>
          <div class="edit-title" v-if="operation === 'edit'">
            <span id="space-header"> Make changes to:</span>
            <span id="modal-header-red" class="text-red"
              ><b>{{ text }}</b></span
            >
          </div>
        </template>

        <div class="modal-input-container">
          <label class="modal_label-md">Name</label>
          <input
            type="text"
            v-model="nameListener"
            class="def input-c-141 modal_input"
            :placeholder="
              operation === 'add' ? 'Enter name ' : currentRole.name
            "
            required
          />
          <span
            class="validation-sapn"
            v-if="msg.name && operation === 'add'"
            >{{ msg.name }}</span
          >
        </div>

        <div class="modal-input-container2">
          <label class="modal_label-md">Permissions</label>
          <b-input-group>
            <b-form-checkbox-group
              id="boxes"
              v-model="selectedPermissions"
              :options="optionsPermissions"
              value-field="item"
              text-field="name"
              name="boxes"
              stacked
            />
          </b-input-group>
        </div>

        <template class="footer-container" busy="true" v-slot:modal-footer>
          <b-button
            id="modal-del-btn"
            v-if="operation === 'edit'"
            variant="danger"
            @click="deleteRole(currentRole)"
          >
            Delete
          </b-button>
          <div v-if="operation === 'edit'" class="unseen-element"></div>
          <b-button
            variant="success"
            v-if="operation === 'edit'"
            @click="submitEditChanges(currentRole)"
          >
            Save
          </b-button>
          <b-button v-if="operation === 'edit'" @click="cancel()">
            Cancel
          </b-button>

          <div v-if="operation === 'add'">
            <b-button
              type="submit"
              variant="primary"
              @click="submitAddChanges(currentRole)"
              >Submit
            </b-button>
            <b-button @click="cancel()">
              Cancel
            </b-button>
          </div>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import utils from "../../views/utils";

export default {
  props: {
    operation: String,
    currentRole: {},
    modalId: String,
    modalShow: Boolean,
    text: String,
    selectedPermissionsObj: {},
    roleList: [],
    opCode: Number,
  },

  data() {
    return {
      show: true,
      loaded: false,
      index: 0,
      approvedStatusToggle: 0,
      permsList: [],
      selectedPermissions: this.selectedPermissionsObj,
      optionsPermissions: [],
      nameListener: "",
      emailListener: "",
      passwordListener: "",
      givenRoles: [],
      sentRoleNames: [],
      msg: [],
    };
  },
  beforeUpdate() {},
  created: async function() {
    if (this.operation === "edit") {
      this.msg["email"] = "";
    }
    const r2 = await this.$http.get(this.resources.Admin.newPerm);
    const data1 = await r2.json();
    for (let i = 0; i < data1.length; i++) {
      this.optionsPermissions.push({
        name: data1[i].name,
        item: data1[i].id,
      });
    }
    this.loaded = true;
  },
  methods: {
    cancel() {
      this.$emit("update:modalShow", false);
    },
    onHide(event) {
      if (event.trigger === "backdrop") {
        this.$emit("update:modalShow", false);
        event.preventDefault();
        this.handleBackdrop();
      }
    },
    onSubmit(event) {
      event.preventDefault();
    },
    onReset(event) {
      event.preventDefault();
      this.modalName = "";

      utils.clearValidationState();
    },
    resetValues() {
      this.nameListener = "";
      this.selectedPermissions = [];
    },
    submitEditChanges: async function(role) {
      let newRole = {
        ...role,
        name: this.nameListener == "" ? role.name : this.nameListener,
        Permissions: this.selectedPermissions.map(Number),
      };
      try {
        const data = await this.$http.put(
          this.resources.Admin.editRole(newRole.id),
          newRole
        );
        this.$emit("update:modalShow", false);
        this.$emit("update:currentRole", newRole);
        this.resetValues();
        this.notifySuccess(`Role has been edited`);
      } catch (err) {
        this.notifyError("Could not edit role.");
      }
    },
    submitAddChanges: async function(role) {
      if (this.nameListener == "") {
        this.notifyError("New role must have a name");
      } else {
        let newRole = {
          ...role,
          name: this.nameListener,
          Permissions: this.selectedPermissions.map(Number),
        };
        try {
          const data = await this.$http.post(
            this.resources.Admin.newRole,
            newRole
          );
          this.$emit("update:modalShow", false);
          this.$emit("update:currentRole", newRole);
          this.resetValues();
          this.notifySuccess(`New role '${newRole.name}' has been created`);
        } catch (err) {
          this.notifyError("Could not insert role.");
        }
      }
    },
    deleteRole: async function(role) {
      try {
        const r = await this.$http.delete(
          this.resources.Admin.deleteRole(role.id)
        );
        this.$emit("update:modalShow", false);
        this.$emit("update:currentRole", {});
        this.resetValues();
        this.notifySuccess(`Role ${role.name} has been deleted`);
      } catch (err) {
        this.notifyError(`Could not delete role ${role.name}.`);
      }
    },
  },
};
</script>

<style scoped>
.modal_label-md {
  font-weight: bold;
  width: 30%;
}

.validation-sapn {
  padding-top: 0px;
  margin-top: 0px;
  font-size: 10px;
  color: red;
  padding-left: 10px;
}

#boxes2 {
  margin-left: 4%;
}

.text-red {
  color: red;
}

.modal_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 20px;
  column-gap: 10px;
  min-width: 282px;
}

.modal-input-container {
  display: flex;
  flex-wrap: initial;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 15px;
}

.modal-input-container2 {
  display: flex;
  align-items: right;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

#space-header {
  padding-right: 10px;
}

.footer-container {
  width: 150%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-dialog {
  min-width: 255px;
}

.unseen-element {
  width: 75%;
}
</style>
