<template lang="html">
    <div>
        <div>
            <b-button-toolbar class="mb-3" key-nav aria-label="Load reports">
                <b-button v-for="btn in reportButtons" class="mr-3" :disabled="activeReport === btn.name"
                    :pressed.sync="isToggled" variant="success" @click="ReportView(btn.name)">
                    <b-icon :icon=btn.icon class="mr-2"></b-icon>{{ btn.label }}
                </b-button>
            </b-button-toolbar>
            <RevenueChart v-if='chartData && activeReport == "revenue"' v-model='chartData'></RevenueChart>
            <OverallChart v-if='chartData && activeReport == "overall"' v-model='chartData'></OverallChart>

        </div>
        <section class="report-form">
            <div class="row">
                <div class="date-range-3 col-3">
                    <DateRange v-model="form.dateRange" type="object"></DateRange>
                </div>
                <div class="b-2412">
                    <span class="s-325 float-left">Breakdowns:</span>
                    <ul class="metrics-list">
                        <li class="metric-item" v-for='d in breakdownOptions'>
                            <label class="capital ml-1 mb-0" :for='"dim-" + d.label'>{{ d.label }}</label>
                            <input class="ml-1 mr-n2" v-model='breakdowns' :id='"dim-" + d.label' :value="d"
                                type="checkbox">
                        </li>
                    </ul>
                </div>
            </div>
            <div class="btns-run-report">
                <button type="button" class="btn btn-success" @click='fetchReport' :disabled='processingReport'>
                    Run Report
                    <Spinner :def='0' v-show='processingReport' />
                </button>
                &nbsp;
                <button type="button" class="btn btn-default ml-3" @click="downloadReportCsv"
                    :disabled="processingCsvReport">
                    Download Report CSV
                    <Spinner :def="0" v-show="processingCsvReport" />
                </button>
            </div>
        </section>
        <div v-if='items && items.length > 0' class="report-table">
            <template>
                <div>
                    <b-table id="reportTable" :items="items" :fields="dataFields" :filter="filter"
                        :sort-by.sync="sortBy" :sort-desc.sync="desc" hover :per-page="perPage.val" 
                        :current-page="currentPage" :sort-compare-options="{numeric: true}" responsive="sm">
                        <template slot="top-row" slot-scope="data">
                            <td v-for="(field, i) in data.fields" class="sum-row">
                                {{ field.sumFunction }}
                            </td>
                        </template>
                    </b-table>
                    <b-row align-h="center" align-content="center">
                        <b-col cols="auto">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" page-class="customPagination"
                                :per-page="perPage.val" aria-controls="reportTable">
                            </b-pagination>
                        </b-col>
                        <b-col cols="auto">
                            <multiselect class="numPerPage" label="label" track-by="val" :multiple="false"
                                :close-on-select="true" :options="pageNumberOptions" v-model="perPage"></multiselect>
                        </b-col>
                    </b-row>

                </div>
            </template>
        </div>
    </div>
</template>
<script>

import moment from 'moment'
import DateRange from '../../../components/shared/DateRange.vue'
import Multiselect from "vue-multiselect";
import RevenueChart from "../../../components/shared/RevenueChart.vue";
import OverallChart from "../../../components/shared/OverallChart.vue";

export default {
    components: { DateRange, Multiselect, RevenueChart, OverallChart },
    data() {

        return {
            activeReport: "overall",
            isToggled: false,
            chartData: {},
            processingReport: false,
            processingCsvReport: false,
            totalRows: 0,
            perPage: { val: 10, label: "10" },
            currentPage: 1,
            items: [],
            reportButtons: [{ name: "overall", label: "Overall report", icon: "clipboard-data" }, { name: "revenue", label: "Revenue report", icon: "bar-chart-fill" }],
            pageNumberOptions: [{ val: 5, label: "5" }, { val: 10, label: "10" }, { val: 20, label: "20" }, { val: 50, label: "50" },
            { val: Number.MAX_SAFE_INTEGER, label: "Show all" }],
            dataFields: [],
            sortByDefaults: {
                overall: {field: 'hour', desc: false},
                revenue: {field: 'revenue', desc: true} 
            } ,
            breakdownsByReport: {
                overall: [{ key: "hour", label: "hour" }],
                revenue: [{ key: "ssp", label: "ssp" }]
            },
            breakdownOptionsByReport: {
                overall: [{ key: "date", label: "date" }, { key: "rev_country", label: "country" }, { key: "hour", label: "hour" }, { key: "os", label: "os" }],
                revenue: [{ key: "date", label: "date" }, { key: "rev_country", label: "country" }, { key: "hour", label: "hour" }, { key: "rev_os", label: "os" },
                { key: "ssp", label: "ssp" }, { key: "source", label: "source" }]
            },
            filter: '',
            form: {
                dateRange: { startDate: moment().set('hours', 0).set('minutes', 0).set('seconds', 0).toDate(), endDate: moment().set('hours', 0).set('minutes', 0).set('seconds', 0).toDate() },
            },
            breakdowns: [{ key: "date", label: "date" }],
            sort: {
                field: "clicks",
                asc: false
            }
        };
    },
    computed: {
        breakdownOptions() {
            return this.breakdownOptionsByReport[this.activeReport];
        },
        sortBy() {
            return this.sortByDefaults[this.activeReport].field;
        },
        desc() {
            return this.sortByDefaults[this.activeReport].desc;
        }
    },
    methods: {
        setRoiClass: function (val) {
            return val < 100 ? "roi_bad" : val > 130 ? "roi_good" : "roi_ok";
        },
        getTotalReport() {
            let sumFields = (this.activeReport == "overall") ? ["revenue", "cost_impressions", "clicks", "pnl", "spend"] : ["revenue", "impressions"]
            let rows = this.items
            if (!rows) {
                return {}
            }
            let sumRow = {}
            sumFields.forEach(s => { sumRow[s] = 0 })
            rows.forEach(r => {
                sumFields.forEach(s => { sumRow[s] += r[s] })
            })

            sumRow.rpm = (sumRow.revenue / sumRow.impressions) * 1e3

            if (this.activeReport == "overall") {
                sumRow.ctr = sumRow.cost_impressions > 0 ? (sumRow.clicks / sumRow.cost_impressions) * 100 : 0
                sumRow.cpc = sumRow.clicks > 0 ? (sumRow.spend / sumRow.clicks) : 0
                sumRow.uv = sumRow.clicks > 0 ? (sumRow.revenue / sumRow.clicks) : 0
                sumRow.roi = sumRow.spend > 0 ? (sumRow.revenue / sumRow.spend) * 100 : 0
            }
            return sumRow
        },
        fetchSSPChartData(reportType) {
            let params = {
                start_dt: moment(this.form.dateRange.startDate).format("YYYY-MM-DD"),
                end_dt: moment(this.form.dateRange.endDate).format("YYYY-MM-DD"),
                breakdowns: ["hour"],
            }
            this._fetchReport(params)
                .then(r => r.json())
                .then(data => {
                    data = Object.values(data.data)
                    data.sort((a, b) => a.hour > b.hour ? 1 : b.hour > a.hour ? -1 : 0)
                    this.chartData = {
                        labels: data.map(elem => `${elem.hour}:00`),
                        datasets: [
                            {
                                label: reportType == 'revenue' ? 'RPM' : 'UV',
                                borderColor: '#388db5',
                                backgroundColor: '#c0ebff69',
                                data: data.map(elem => reportType == 'revenue' ? elem.rpm : elem.uv).slice(0,reportType == 'revenue' ? data.length : moment().minute() > 30 ? data.length - 1 : data.length - 2),
                                yAxisID: 'A'
                            },
                            {
                                label: reportType == 'revenue' ? 'Revenue' : 'CPC',
                                borderColor: 'purple',
                                backgroundColor: '#ffe6ff69',
                                data: data.map(elem => reportType == 'revenue' ? elem.revenue : elem.cpc),
                                yAxisID: reportType == 'revenue' ? 'B' : 'A'
                            }
                        ]
                    }
                })
        },
        _fetchReport(params) {
            return this.activeReport == "overall" ? this.$http.get(this.resources.Reports.performance, { params }) : this.$http.get(this.resources.Reports.sspRevenue, { params });
        },
        async downloadReportCsv() {
            this.processingCsvReport = true;
            var data = {
                header: this.dataFields.map(dataField => dataField.label),
                rows: this.items.map(item => ({
                    cells: this.dataFields.map(dataField => ({ coloumnName: dataField.label, value: item[dataField.key].toString() }))
                })),
            };
            try {
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${this.getUserToken()}`,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }
                await this.downloadURI(this.resources.cs.csv, `${this.activeReport}_report.csv`, requestOptions);
            } catch (err) {
                console.error(err);
                this.notifyError("Could not download csv file.");
            }
            this.processingCsvReport = false;
        },
        async fetchReport() {

            let params = {
                start_dt: moment(this.form.dateRange.startDate).format("YYYY-MM-DD"),
                end_dt: moment(this.form.dateRange.endDate).format("YYYY-MM-DD"),
                breakdowns: this.breakdowns.map(breakdown => breakdown.label)
            }
            if (this.activeReport == "overall") {
                if (["os", "hour"].some(v => params.breakdowns.includes(v)) && params.breakdowns.length > 1) {
                    this.notifyError("Can't add a breakdown to OS / Hour")
                    return
                }
            }
            this.processingReport = true
            try {
                const r = await this._fetchReport(params);
                const data = await r.json() || {};
                if (Object.keys(data.data).length !== 0) {
                    data.data = Object.values(data.data)
                    if (this.activeReport == "overall") {
                        data.data = data.data.filter(v => v.spend > 10 || v.revenue > 10)
                    }
                    let bd = this.breakdowns
                    data.data.forEach(r => { r.date = moment(r.date).format("DD/MM/YY") });
                    this.items = data.data
                    this.totalRows = this.items.length;
                    this.CreateDataField();
                    this.dataFields.push(...bd.map(breakdown => ({
                        key: breakdown.key, label: this.$options.filters.capitalize(breakdown.label), sortable: true, orderBy: 1,
                        sortDirection: 'asc'
                    })));
                    this.dataFields.sort((a, b) => a.orderBy - b.orderBy);
                    this.showAlert = false;
                    this.processingReport = false
                }
                else {
                    this.items = [];
                    this.processingReport = false;
                    this.notifyWarning("No results", 5000);
                }
            }
            catch (err) {
                console.error(err);
            }
        },
        ReportView(reportType) {
            this.chartData = {};
            this.activeReport = reportType;
            this.items = [];
            this.breakdowns = this.breakdownsByReport[reportType];
            this.dataFields = this.CreateDataField();
            this.fetchSSPChartData(reportType);
        },
        CreateDataField() {
            this.dataFields = [];
            this.dataFields.push({
                key: "revenue",
                label: "Revenue",
                orderBy: 4,
                sumFunction: this.$options.filters.currency(this.getTotalReport().revenue,'$',0),
                sortable: true,
                sortDirection: 'desc',
                formatter: value => { return this.$options.filters.currency(value,'$',0) }
            })
            if (this.activeReport == "revenue") {
                this.dataFields.push(
                    { key: "impressions", label: "Impressions", sortable: true, orderBy: 5, sumFunction: this.getTotalReport().impressions, formatter: value => { return value }},
                    { key: "rpm", label: "RPM", sortable: true, orderBy: 6, sumFunction: this.$options.filters.currency(this.getTotalReport().rpm, "$"), formatter: value => { return this.$options.filters.currency(value, "$") } });
            }
            if (this.activeReport == "overall") {
                this.dataFields.push(
                    { key: "clicks", orderBy: 3, label: "Clicks", sortable: true, sumFunction: Number(this.getTotalReport().clicks).toLocaleString(), formatter: value => { return Number(value).toLocaleString() } },
                    { key: "cpc", label: "CPC", orderBy: 4, sortable: true, sumFunction: this.$options.filters.currency(this.getTotalReport().cpc, "$",3), formatter: value => { return this.$options.filters.currency(value, "$",3) } },
                    { key: "ctr", label: "CTR", orderBy: 6, sortable: true, sumFunction: this.getTotalReport().ctr != null ? this.getTotalReport().ctr.toFixed(2) + "%" : "0%", formatter: value => { return value.toFixed(2) + '%' } },
                    { key: "spend", label: "Cost", orderBy: 7, sortable: true, sumFunction: this.$options.filters.currency(this.getTotalReport().spend, "$",0), formatter: value => { return this.$options.filters.currency(value, "$",0) } },
                    { key: "pnl", label: "P&L", orderBy: 8, sortable: true, sumFunction: this.$options.filters.currency(this.getTotalReport().pnl, "$",0), formatter: value => { return this.$options.filters.currency(value, "$",0) } },
                    { key: "roi", label: "ROI", orderBy: 9, sortable: true, tdClass: value => this.setRoiClass(value), sumFunction: this.getTotalReport().roi.toFixed(2) + "%", formatter: value => { return value.toFixed(2) + '%' } },
                    { key: "uv", label: "UV", orderBy: 10, sortable: true, sumFunction: this.$options.filters.currency(this.getTotalReport().uv, "$",3), formatter: value => { return this.$options.filters.currency(value, "$",3) } });
            }
            return this.dataFields;
        },
    },
    created() {
        if (this.reportButtons.some(report => report.name === this.$route.query.report)) {
            if (moment(this.$route.query.start_dt).isValid() && moment(this.$route.query.end_dt).isValid()) {
                this.form.dateRange.startDate = this.$route.query.start_dt;
                this.form.dateRange.endDate = this.$route.query.end_dt;
            }
            this.ReportView(this.$route.query.report);
            if (this.$route.query.breakdowns) {
                const breakdownUrlParams = this.$route.query.breakdowns.split('&');
                this.breakdowns = this.breakdownOptionsByReport[this.$route.query.report].filter(breakdown => breakdownUrlParams.some(bd => bd === breakdown.key));
            }
            this.fetchReport();
            return;
        }
        this.ReportView(this.activeReport);
    }
};
</script>