<template>
  <header class="app-header navbar">
    <button
      class="navbar-toggler mobile-sidebar-toggler d-lg-none"
      type="button"
      @click="mobileSidebarToggle"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- <b-link class="navbar-brand" to="#"></b-link> -->
    <button
      class="navbar-toggler sidebar-toggler d-md-down-none"
      type="button"
      @click="sidebarToggle"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <img src="/img/logo-final-1.png" alt="" class="logo-s mr-auto" />

    <div id="notification-dropdown-div">
      <b-dropdown
        class="dropdown"
        size="lg"
        variant="link"
        toggle-class="text-decoration-none"
        no-caret
      >
        <template #button-content>
          <div>
            <i class="fa fa-bell-o fa-lg" aria-hidden="true"></i
            ><b-badge
              variant="danger"
              v-show="NumOfUnseenCampNotifications > 0"
              >{{ NumOfUnseenCampNotifications }}</b-badge
            >
          </div>
        </template>
        <b-card class="mb-2 logo-s">
          <b-card-title>Notifications</b-card-title>
        </b-card>
        <div v-if="notifications.length > 0">
          <b-card v-for="n in notifications" class="mb-2">
            <div class="flex">
              <div class="flex left-img-Div">
                <div>
                  <img class="profile-thumbnail" src="@/assets/errorImg.png" />
                </div>
              </div>
              <div class="flex right-msg-div">
                <b-card-title class="no-margin">{{ n.title }}</b-card-title>
                <b-card-text class="add-margin-bottom">
                  {{ n.msg }}
                </b-card-text>
                <small class="text-muted">{{
                  timeSince(n.timeFormatted)
                }}</small>
                <div class="add-margin-top">
                  <b-button
                    @click="
                      () => {
                        ShowMetadata(n.metadata);
                      }
                    "
                    class="btn btn-primary"
                    >Read More</b-button
                  >
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div v-else>
          <b-card>
            <div class="flex centered_column_flex_direction">
              <img
                class="profile-thumbnail"
                src="@/assets/no-error-smiley.png"
              />
              <b-card-title>No Notifications</b-card-title>
            </div>
          </b-card>
        </div>
      </b-dropdown>
    </div>

    <button
      class="navbar-toggler aside-menu-toggler d-md-down-none right-box"
      type="button"
      @click="asideToggle"
    >
      <img class="profile-thumbnail" src="@/assets/default-profile.jpg" />
      <span class="user-username">
        {{ user.name }}
      </span>
    </button>

    <b-modal ref="my-modal" id="modal-1" title="MetaData" ok-only>
      <div class="break-word">{{ current_MetaData }}</div>
    </b-modal>
  </header>
</template>
<script>
import moment from "moment";
export default {
  name: "c-header",
  data() {
    return {
      lastInsertTime: null,
      limitNumOfNotifications: 30,
      notifications: [],
      current_MetaData: "",
      NumOfUnseenCampNotifications: 0,
      lastSeenNotificationsTime: null,
    };
  },
  methods: {
    ShowMetadata(metadata) {
      this.$refs["my-modal"].show();
      this.current_MetaData = metadata;
    },

    timeSince(time) {
      const now = moment(new Date());
      const givenDate = moment(new Date(time));
      const duration = moment.duration(now.diff(givenDate));
      const days = duration.days();
      if (days >= 1) {
        return `${days} days ago`;
      }
      const hours = duration.hours();
      if (hours >= 1) {
        return `${hours} hours ago`;
      }
      const minutes = duration.minutes();
      if (minutes >= 1) {
        return `${minutes} minutes ago`;
      }
      const seconds = duration.seconds();
      return `${seconds} seconds ago`;
    },
    sidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-hidden");
    },
    sidebarMinimize(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-minimized");
    },
    mobileSidebarToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("sidebar-mobile-show");
    },
    fetchNotifications: async function(limit, time) {
      let params = {};
      if (time !== null) {
        params.time = time;
      }
      if (limit !== null) {
        params.limit = limit;
      }

      try {
        let res = await this.$http.get(
          this.resources.Reports.campaignNotifications,
          {
            params,
          }
        );
        let data = await res.json();

        if (data.length > this.limitNumOfNotifications) {
          this.notifications = data.slice(0, this.limitNumOfNotifications);
          this.NumOfUnseenCampNotifications = this.limitNumOfNotifications;
        } else if (data.length === this.limitNumOfNotifications) {
          this.notifications = data;
          this.NumOfUnseenCampNotifications = this.limitNumOfNotifications;
        } else {
          if (data.length) {
            data = data.concat(this.notifications);
            this.notifications = data.slice(0, this.limitNumOfNotifications);
          }
          this.NumOfUnseenCampNotifications = this.notifications.filter(
            (notif) => notif.timeFormatted > this.lastSeenNotificationsTime
          ).length;
        }
      } catch (err) {
        this.notifyError("Could not fetch campaign notifications");
        console.error(err);
      }
    },
    fetchHandler: async function(limit, lastInsertTime) {
      this.lastInsertTime = moment
        .utc(new Date())
        .format("YYYY-MM-DD HH:mm:ss");
      await this.fetchNotifications(limit, lastInsertTime);
    },
    asideToggle(e) {
      e.preventDefault();
      document.body.classList.toggle("aside-menu-hidden");
    },
  },
  mounted() {
    document.body.classList.add("aside-menu-hidden");
    this.$root.$on("bv::dropdown::hide", (bvEvent) => {
      this.NumOfUnseenCampNotifications = 0;
      this.lastSeenNotificationsTime = moment
        .utc(new Date())
        .format("YYYY-MM-DD HH:mm:ss");
      localStorage.setItem("lastSeenTime", this.lastSeenNotificationsTime);
    });
  },
  created: async function() {
    this.lastSeenNotificationsTime = localStorage.getItem("lastSeenTime");

    await this.fetchHandler(this.limitNumOfNotifications, null);
    setInterval(async () => {
      await this.fetchHandler(null, this.lastInsertTime);
    }, 50000);
  },
};
</script>

<style scoped>
.profile-thumbnail {
  width: 40px;
  border-radius: 50%;
}
.right-box {
  margin-right: 20px;
}
.user-username {
  text-transform: capitalize;
}
.logo-s {
  height: 45px;
}
.dropdown /deep/ .dropdown-menu {
  overflow-y: auto;
  height: 70vh;
  width: 55vh;
}

.left-img-Div {
  padding: 25px;
}
.right-msg-div {
  min-width: 50%;
  flex-direction: column;
  justify-content: space-between;
  padding-right: 25px;
}
.no-margin {
  margin: 0px !important;
}
.add-margin-bottom {
  margin-bottom: 15px;
}
.add-margin-top {
  margin-top: 15px;
}
.centered_column_flex_direction {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 55vh;
  width: 50vh;
}
.overflow {
  overflow: auto;
}
.break-word {
  word-wrap: break-word;
}
</style>
