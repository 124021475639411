export default {
    "id": {
      title: "ID",
      type: String
    },
    "status": {
      title: "status",
      type: String
    },
    "name": {
      title: "Adset Name",
      type: String,
    },
    "date": {
      title: "Date",
      type: Date
    },
    "targeting_website": {
      title: "Targeting Website",
      type: String
    },
    "targeting_country": {
      title: "Targeting Country",
      type: String
    },
    "targeting_platform": {
      title: "Targeting Platform",
      type: String
    },
    "targeting_language": {
      title: "Targeting Language",
      type: String
    },
    "event_name": {
      title: "Event",
      type: String
    },
    "country": {
      title: "Country",
      type: String
    },
    "hour": {
      title: "Hour",
      type: String
    },
    "ssp": {
      title: "SSP",
      type: String
    },
    "created_at_formated": {
      title: "Created At",
      type: String
    },
    "bid_strategy": {
      title: "Bid Strategy",
      type: String
    },
    "bid_computed": {
      title: "Bid",
      editable: true,
      type: String,
      max_field_name: "max_bid",
      max_field_value: 0.5,
      min_field_name: "min_bid",
      min_field_value: 0.01
    },
    "daily_budget_float": {
      title: "Daily Budget",
      after: '$',
      editable: true,
      type: Number,
      max_field_name: "max_daily_budget",
      max_field_value: 10000,
      min_field_name: "min_daily_budget",
      min_field_value: 1
    },
    "active_users": {
      type: Number,
      title: "Active Users"
    },
    "inline_link_clicks": {
      type: Number,
      title: "Clicks"
    },
    "cost_per_inline_link_click": {
      type: Number,
      title: "CPC",
      decimalDigits: 3,
      after: '$'
    },
    "last_3_hours_cpc": {
      type: Number,
      title: "Last 3Hrs CPC",
      decimalDigits: 3,
      after: '$'
    },
    "last_1_hour_clicks": {
      type: Number,
      title: "Last Hour Clicks"
    },    
    "last_3_hours_clicks": {
      type: Number,
      title: "Last 3Hrs Clicks"
    },
    "last_6_hours_clicks": {
      type: Number,
      title: "Last 6Hrs Clicks"
    },
    "last_12_hours_clicks": {
      type: Number,
      title: "Last 12Hrs Clicks"
    },
    "last_24_hours_clicks": {
      type: Number,
      title: "Last 24Hrs Clicks"
    },
    "ctr": {
      type: Number,
      title: "CTR",
      after: '%',
      decimalDigits: 2
    },
    "admanager_ecpm": {
      type: Number,
      title: "AM ECPM",
      decimalDigits: 2,
      after: '$'
    },
    "spend": {
      type: Number,
      title: "Cost",
      decimalDigits: 2,
      after: '$'
    },
    "revenue": {
      type: Number,
      title: "Revenue",
      decimalDigits: 2,
      after: '$'
    },
    "revenue_v2": {
      type: Number,
      title: "Revenue V2",
      decimalDigits: 2,
      after: '$'
    },
    "admanager_rev": {
      type: Number,
      title: "AM Rev",
      decimalDigits: 2,
      after: '$'
    },
    "dfp_revenue": {
      type: Number,
      title: "DFP Rev",
      decimalDigits: 2,
      after: '$'
    },
    "header_bidding_revenue": {
      type: Number,
      title: "H/B Rev",
      decimalDigits: 2,
      after: '$'
    },
    "a9_revenue": {
      type: Number,
      title: "A9 Rev",
      decimalDigits: 2,
      after: '$'
    },
    "primis_rev": {
      type: Number,
      title: "Primis Rev",
      decimalDigits: 2,
      after: '$'
    },
    "aniview_rev": {
      type: Number,
      title: "Aniv Rev",
      decimalDigits: 2,
      after: '$'
    },
    "aniview_imps": {
      type: Number,
      title: "Aniv Imps"
    },
    "avantis_rev": {
      type: Number,
      title: "Avan Rev",
      decimalDigits: 2,
      after: '$'
    },
    "avantis_imps": {
      type: Number,
      title: "Avan Imps"
    },
    "vidazoo_rev": {
      type: Number,
      title: "Vidazoo Rev",
      decimalDigits: 2,
      after: '$'
    },
    "vidazoo_imps": {
      type: Number,
      title: "Vidazoo Imps"
    },
    "underdog_rev": {
      type: Number,
      title: "Underdog Rev",
      decimalDigits: 2,
      after: '$'
    },
    "underdog_imps": {
      type: Number,
      title: "Underdog Imps"
    },
    "rise_rev": {
      type: Number,
      title: "Rise Rev",
      decimalDigits: 2,
      after: '$'
    },
    "ssp_impressions": {
      type: Number,
      title: "Ad Impressions"
    },
    "rpm": {
      type: Number,
      title: "RPM",
      decimalDigits: 2,
      after: '$'
    },
    "rpm_v2": {
      type: Number,
      title: "RPM V2",
      decimalDigits: 2,
      after: '$'
    },
    "roi": {
      type: Number,
      title: "ROI",
      decimalDigits: 2,
      after: '%',
      getClass: function(val) {
        if(val < 100) {
          return {roi_bad: true}
        }
        if(val > 130) {
          return {roi_good: true}
        }
        return {roi_ok: true}
      }
    },
    "roi_v2": {
      type: Number,
      title: "ROI V2",
      decimalDigits: 2,
      after: '%',
      getClass: function(val) {
        if(val < 100) {
          return {roi_bad: true}
        }
        if(val > 130) {
          return {roi_good: true}
        }
        return {roi_ok: true}
      }
    },
    "last_1_hour_roi": {
      type: Number,
      title: "Last 1 Hr ROI",
      decimalDigits: 2,
      after: '%',
      getClass: function(val) {
        if(val < 100) {
          return {roi_bad: true}
        }
        if(val > 130) {
          return {roi_good: true}
        }
        return {roi_ok: true}
      }
    },
    "last_3_hours_roi": {
      type: Number,
      title: "Last 3 Hrs ROI",
      decimalDigits: 2,
      after: '%',
      getClass: function(val) {
        if(val < 100) {
          return {roi_bad: true}
        }
        if(val > 130) {
          return {roi_good: true}
        }
        return {roi_ok: true}
      }
    },  
    "last_6_hours_roi": {
      type: Number,
      title: "Last 6 Hrs ROI",
      decimalDigits: 2,
      after: '%',
      getClass: function(val) {
        if(val < 100) {
          return {roi_bad: true}
        }
        if(val > 130) {
          return {roi_good: true}
        }
        return {roi_ok: true}
      }
    },
    "last_12_hours_roi": {
      type: Number,
      title: "Last 12 Hrs ROI",
      decimalDigits: 2,
      after: '%',
      getClass: function(val) {
        if(val < 100) {
          return {roi_bad: true}
        }
        if(val > 130) {
          return {roi_good: true}
        }
        return {roi_ok: true}
      }
    },
    "last_24_hours_roi": {
      type: Number,
      title: "Last 24 Hrs ROI",
      decimalDigits: 2,
      after: '%',
      getClass: function(val) {
        if(val < 100) {
          return {roi_bad: true}
        }
        if(val > 130) {
          return {roi_good: true}
        }
        return {roi_ok: true}
      }
    },
    "profit_and_loss": {
      type: Number,
      title: "P&L",
      decimalDigits: 2,
      after: '$'
    },
    "profit_and_loss_v2": {
      type: Number,
      title: "P&L V2",
      decimalDigits: 2,
      after: '$'
    },
    "user_value": {
      type: Number,
      title: "UV",
      after: '$',
      decimalDigits: 4,
    },
    "user_value_v2": {
      type: Number,
      title: "UV V2",
      after: '$',
      decimalDigits: 4,
    },
    "bounce_rate": {
      type: Number,
      title: "Bounce Rate",
      decimalDigits: 2,
      after: '%'
    },
    "sessions": {
      type: Number,
      title: "Sessions"
    },
    "page_views": {
      type: Number,
      title: "Page Views"
    },
    "pages_per_session": {
      type: Number,
      title: "Pages / Session",
      decimalDigits: 2
    },
    // "page_views_per_session": {
    //   type: Number,
    //   title: "Pages / Session",
    //   decimalDigits: 2
    // },
    "avg_session_duration": {
      type: Number,
      title: "Avg. Session Duration"
    },
    "is_learning": {
      type: Boolean,
      title: "Learning"
    }
  }
;
