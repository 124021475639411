<template lang="html">
  <div class="adset-wizard-container">
    <h3 class="h-4124">Campaign Wizard</h3>
    <form @submit.prevent="submit">
      <Tabs :tabs="['Campaign', 'Adset', 'Ads']" :activeTab="1">
        <div class="tab-container-31 campaign-tab" slot="t-1">
          <div class="field-23">
            <label>Campaign Name</label>
            <input class="def txt-input-32" v-model="form.campaign.name" />
          </div>

          <div class="field-23">
            <label>Accounts</label>
            <multiselect
              class="select-2"
              label="name"
              track-by="id"
              :multiple="true"
              :close-on-select="false"
              v-model="form._accounts"
              :options="accounts"
              @input="updateAdsetsAccountEvents()"
            ></multiselect>
          </div>

          <div class="field-23">
            <label>Objective</label>
            <select class="def" v-model="form.campaign.objective">
              <option :value="objective" v-for="objective in objectiveOptions">
                {{ objective }}
              </option>
            </select>
          </div>
        </div>
        <div class="tab-container-31" slot="t-2">
          <div v-for="(adset, i) in form.adsets">
            <div class="field-23">
              <label>Targeting Preset</label>
              <select
                class="def"
                v-model="adset.selectedTargetingPreset"
                @change="updateAdsetCountries(i)"
              >
                <option value="">Custom</option>
                <option
                  v-for="preset in targetingPresets"
                  :value="preset.Countries"
                  >{{ preset.PresetName }}</option
                >
              </select>
            </div>

            <div class="field-23">
              <label>Adset Name</label>
              <input class="def txt-input-32" v-model="adset.name" />
            </div>

            <div class="field-23">
              <label>Event Type</label>
              <multiselect
                class="select-2"
                :multiple="false"
                :close-on-select="true"
                :show-labels="false"
                v-model="adset.promoted_object.custom_event_type"
                :options="eventTypes"
              ></multiselect>
            </div>

            <div class="field-23" v-if="adset.promoted_object.custom_event_type == 'OTHER' && form.campaign.accountEvents.length">
              <label>Events</label>

              <multiselect
                class="select-2"
                label="label"
                track-by="val"
                :multiple="true"
                :close-on-select="false"
                v-model="adset._events"
                :options="form.campaign.accountEvents"
              ></multiselect>
            </div>

            <div class="field-23">
              <label>Conversion Event Location</label>
              <select class="def" v-model="adset.destination_type">
                <option :value="v" v-for="(o, v) in destinationTypeOptions">
                  {{ o }}
                </option>
              </select>
            </div>

            <div class="field-23">
              <label>Daily Budget</label>
              <input
                class="def"
                type="number"
                min="0"
                :max="adset.maxDailyBudget"
                v-model.number="adset.daily_budget"
              />
            </div>

            <div class="field-23">
              <label>Language</label>
              <select class="def" v-model="adset.targeting.locales">
                <option :value="o" v-for="(o, n) in languageOptions">
                  {{ n }}
                </option>
              </select>
            </div>

            <div class="field-23">
              <label>Gender</label>
              <select class="def" v-model="adset.targeting.genders">
                <option :value="o" v-for="(o, n) in genderOptions">
                  {{ n }}
                </option>
              </select>
            </div>

            <div class="field-23">
              <label>Ages</label>
              <input
                class="def"
                type="number"
                min="16"
                max="65"
                v-model.number="adset.targeting.age_min"
              />&nbsp;
              <input
                class="def"
                type="number"
                min="16"
                max="65"
                v-model.number="adset.targeting.age_max"
              />
            </div>

            <div class="field-23">
              <label>Country Targeting</label>
              <multiselect
                class="select-2"
                label="label"
                track-by="val"
                :multiple="true"
                :close-on-select="false"
                v-model="adset._countries"
                :options="countries"
              ></multiselect>
            </div>

            <div class="field-23">
              <label>Create adset/country</label>
              <input
                type="checkbox"
                v-model="adset._adsetPerCountry"
                class="def"
                :disabled="adset._countries.length === 0"
              />
            </div>

            <div class="field-23">
              <label>Platform Targeting</label>
              <multiselect
                class="select-2"
                label="label"
                track-by="val"
                :multiple="true"
                :close-on-select="false"
                v-model="adset._platforms"
                :options="platformOptions"
              ></multiselect>
            </div>
            <div class="field-23">
              <label>OS Targeting</label>
              <select class="def" v-model="adset.targeting.user_os">
                <option :value="o.val" v-for="o in osOptions">
                  {{ o.label }}
                </option>
              </select>
            </div>
            <div class="field-23">
              <label>Video Player</label>
              <div>
                <label for="None-1">None</label>
                <input
                  v-model="adset.videoPlayer"
                  value=""
                  id="None-1"
                  type="radio"
                  @change="updateAdsetTrackingCode($event, i)"
                />
                <label for="Rise-1">Rise</label>
                <input
                  v-model="adset.videoPlayer"
                  value="Rise"
                  id="Rise-1"
                  type="radio"
                  @change="updateAdsetTrackingCode($event, i)"
                />

                <label for="Avantis-1">Avantis</label>
                <input
                  v-model="adset.videoPlayer"
                  value="Avantis"
                  id="Avantis-1"
                  type="radio"
                  @change="updateAdsetTrackingCode($event, i)"
                />

                <label for="Vidazoo-1">Vidazoo</label>
                <input
                  v-model="adset.videoPlayer"
                  value="Vidazoo"
                  id="Vidazoo-1"
                  type="radio"
                  @change="updateAdsetTrackingCode($event, i)"
                />
              </div>
            </div>

            <div class="field-23">
              <label>Tracking Code</label>
              <input
                readonly="true"
                class="def t-url-1"
                v-model="adset.tracking_code"
              />
            </div>

            <div class="rem-item-container-1">
              <i
                @click="cloneAdset(i)"
                class="fa fa-clone clone-item"
                aria-hidden="true"
              ></i>
              <i
                @click="removeAdset(i)"
                class="fa fa-times-circle-o rem-item"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div class="b-c-251">
            <button type="button" class="btn _btn-white" @click="addAdset">
              Add Item
            </button>
          </div>
        </div>
        <div class="tab-container-31" slot="t-3">
          <ul class="items-list-1">
            <li class="item-152" v-for="(adset, adsetIndex) in form.adsets">
              <h2>Ads of {{ adset.name }} adset:</h2>
              <div v-for="(ad, i) in adset.ads">
                <div class="field-23">
                  <label>Ad Name</label>
                  <input v-model="ad.name" class="def txt-input-32" />
                </div>

                <div class="field-23">
                  <label>Headline</label>
                  <input
                    v-model="ad.creative.object_story_spec.link_data.name"
                    class="def txt-input-32"
                  />
                </div>

                <div class="field-23">
                  <label>Primary Text</label>
                  <input
                    v-model="ad.creative.object_story_spec.link_data.message"
                    class="def txt-input-32"
                  />
                </div>

                <div class="field-23">
                  <label>Description</label>
                  <input
                    v-model="
                      ad.creative.object_story_spec.link_data.description
                    "
                    class="def txt-input-32"
                  />
                </div>

                <div class="field-23">
                  <label>Page</label>
                  <select
                    class="def"
                    v-model="ad.creative.object_story_spec.page_id"
                  >
                    <option :value="page.id.toString()" v-for="page in pageOptions">{{
                      page.title
                    }}</option>
                  </select>
                </div>
                <div class="field-23">
                  <label>URL</label>
                  <input
                    v-model="ad.creative.object_story_spec.link_data.link"
                    class="def txt-input-32"
                  />
                </div>

                <div class="field-23 image-field">
                  <label>Image</label>
                  <label
                    class="label-141"
                    :for="'file51-' + adsetIndex + '-' + i"
                  >
                    <span class="image-zone">{{
                      ad.images[0] ? ad.images[0].name : "Choose Image"
                    }}</span>
                  </label>
                  <input
                    v-show="0"
                    class="def txt-input-32"
                    type="file"
                    accept="image/*"
                    :ref="'file-' + adsetIndex + '-' + i"
                    :id="'file51-' + adsetIndex + '-' + i"
                    @change="handleFileUploads(adsetIndex, i)"
                  />
                </div>

                <div class="rem-item-container-1">
                  <i
                    @click="cloneAdIntoGivenAdsetWithIndex(i, adsetIndex)"
                    class="fa fa-clone clone-item"
                    aria-hidden="true"
                  ></i>
                  <i
                    @click="removeAd(i, adsetIndex)"
                    class="fa fa-times-circle-o rem-item"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div class="b-c-251">
                <button
                  type="button"
                  class="btn _btn-white"
                  @click="addAd(adsetIndex)"
                >
                  Add Item
                </button>
              </div>
            </li>
          </ul>
        </div>
      </Tabs>

      <br />
      <button type="submit" class="btn btn-primary" :disabled="ongoingSubmit">
        Submit
      </button>

      <template v-if="ongoingSubmit">
        <Spinner class="loading-12" />
        <span
          >Creating {{ createdCampaigns + 1 }}/{{
            campsToCreateAmount
          }}
          campaigns...</span
        >
      </template>
    </form>
  </div>
</template>
<script>
import langs from "@/assets/langs";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  props: {
    accounts: {},
    onClose: {},
    fbData: {
      required: true,
    },
    data: {},
  },
  data() {
    let pageOptions = this.fbData.pages;
    let trackingCode =
      "utm_source=Facebook&utm_medium=FB&utm_campaign={{adset.name}}&utm_term={{adset.id}}&layout=inf3";
    return {
      toggle: true,
      createdCampaigns: 0,
      campsToCreateAmount: 0,
      objectiveOptions: ["CONVERSIONS"],
      eventTypes: ['RATE', 'TUTORIAL_COMPLETION', 'CONTACT', 'CUSTOMIZE_PRODUCT', 'DONATE', 
      'FIND_LOCATION', 'SCHEDULE', 'START_TRIAL', 'SUBMIT_APPLICATION', 'SUBSCRIBE', 'ADD_TO_CART', 
      'ADD_TO_WISHLIST', 'INITIATED_CHECKOUT', 'ADD_PAYMENT_INFO', 'PURCHASE', 'LEAD', 'COMPLETE_REGISTRATION', 
      'CONTENT_VIEW', 'SEARCH', 'SERVICE_BOOKING_REQUEST', 'MESSAGING_CONVERSATION_STARTED_7D', 'LEVEL_ACHIEVED', 
      'ACHIEVEMENT_UNLOCKED', 'SPENT_CREDITS', 'LISTING_INTERACTION', 'D2_RETENTION', 'D7_RETENTION', 'OTHER'],
      langs,
      ongoingSubmit: false,
      pageOptions,
      targetingPresets: [],
      countries: (countr) => {
        Object.keys(countr).map((v) => ({
          val: v,
          label: countr[v],
        }));
      },
      platforms: {
        desktop: "Desktop",
        mobile: "Mobile",
      },
      platformOptions: [
        {
          val: "mobile",
          label: "Mobile",
        },
        {
          val: "desktop",
          label: "Desktop",
        },
      ],
      osOptions: [
        {
          val: ["Android"],
          label: "Android",
        },
        {
          val: ["iOS"],
          label: "iOS",
        },
        {
          val: [],
          label: "All",
        },
      ],
      accountOptions: null,
      campaignOptions: [],
      conversionLocationOptions: {},

      destinationTypeOptions: {
        WEBSITE: "Website",
        APP: "App",
        MESSENGER: "Messenger",
        APPLINKS_AUTOMATIC: "Applinks Automatic",
        FACEBOOK: "Facebook",
      },
      languageOptions: {
        English: [6, 24],
        Spanish: [23, 7],
        German: [5],
        French: [9, 44],
        Italian: [10],
        Russian: [17],
        Chinese: [1004],
        Japanese: [11, 70],
        Arabic: [28],
        Portugese: [16, 31],
      },
      genderOptions: {
        All: [1, 2],
        Men: [1],
        Women: [2],
      },
      emptyAd: {
        name: null,
        conversion_domain: null,
        images: [],
        status: "PAUSED",
        creative: {
          name: null,
          object_story_spec: {
            link_data: {
              link: null,
              message: null,
              name: null,
              attachment_style: "link",
              description: null,
              picture: null,
            },
            page_id: null,
          },
        },
      },
      trackingCode: trackingCode,
      form: {
        campaign: {
          name: null,
          accountEvents: [],
          objective: "CONVERSIONS",
          status: "ACTIVE",
          special_ad_categories: "NONE",
        },
        adsets: [
          {
            name: null,
            bid_amount: 0.2,
            maxBid: 0.2,
            daily_budget: 7,
            maxDailyBudget: 25,
            billing_event: "IMPRESSIONS",
            optimization_goal: "OFFSITE_CONVERSIONS",
            promoted_object: {
              pixel_id: null,
              custom_event_type: "OTHER",
              pixel_rule: null,
            },
            destination_type: "WEBSITE",
            targeting: {
              age_min: 21,
              age_max: 65,
              genders: [1, 2],
              locales: [6, 24],
              user_os: ["iOS"],
              device_platforms: [],
              geo_locations: {
                countries: ["US"],
                country_groups: [],
              },
              facebook_positions: ["feed"],
              publisher_platforms: ["facebook"],
            },
            is_dynamic_creative: false,
            status: "PAUSED",
            ads: [],
            selectedTargetingPreset: null,
            _countries: [],
            _adsetPerCountry: false,
            _platforms: [],
            videoPlayer: null,
            tracking_code: trackingCode,
            _events: [],
          },
        ],
        _accounts: [],
        account: null,
        cpc: 0.01,
        items: [],
      },
    };
  },
  created() {
    this.getCountriesList();
    this.fetchTargetingPresets();
    if (this.data) {
      const targeting = this.data.campaign.name.lastIndexOf('-');
      const name = targeting == -1 ? this.data.campaign.name : this.data.campaign.name.substring(0, this.data.campaign.name.lastIndexOf('-') - 1);
      this.form.campaign.name = name;
      this.form.adsets[0].name = name;
      this.form.adsets[0].ads = [
        ...this.form.adsets[0].ads,
        ...this.data.adset.ads.map((ad) => ({ ...ad, images: [] })),
      ];
      return;
    }
    this.addAd(0);
    this.setAccountOptions();
  },
  watch: {
    ["form.account"]() {
      this.setCampOptions();
    },
  },
  methods: {
    updateAdsetsAccountEvents() {
      let accounts = this.form._accounts;
      let events = [];
      accounts.forEach((ac) => {
        events.push(...ac.conversion_events);
      });
      events = events.filter(
        (v) =>
          !accounts.find(
            (ac) => !ac.conversion_events.find((v2) => v2.id === v.id)
          )
      );
      let uniqMap = {};
      events = events.filter((v) => !uniqMap[v.id] && (uniqMap[v.id] = 1));

      this.form.campaign.accountEvents = events.map((v) => ({
        label: v.name,
        val: v.rule,
      }));
    },
    updateAdsetTrackingCode(event, i) {
      let vp = this.form.adsets[i].videoPlayer;
      let trackingCode = this.trackingCode;
      if (vp == "Rise") {
        trackingCode += "&vtype=1";
      } else if (vp === "Avantis") {
        trackingCode += "&vtype=2";
      } else if (vp === "Vidazoo") {
        trackingCode += "&vtype=3";
      }
      this.form.adsets[i].tracking_code = trackingCode;
    },
    updateAdsetCountries(index) {
      this.form.adsets[index]._countries = this.form.adsets[
        index
      ].selectedTargetingPreset.map((element) => ({
        val: element.Code,
        label: element.Name,
      }));
    },
    async getCountriesList() {
      try {
        const response = await this.$http.get(this.resources.Admin.countries);
        const data = await response.json();
        this.countries = Object.keys(data).map((v) => ({
          val: data[v].Code,
          label: data[v].Name,
          id: data[v].Id,
        }));
      } catch (err) {
        console.error(err);
        this.notifyError("Oups...could not fetch countries list.");
        return;
      }
    },
    async fetchTargetingPresets() {
      if (this.$store.state.global.targetingPresets) {
        return;
      }
      try {
        const response = await this.$http.get(
          this.resources.fb.targetingPresets
        );
        const data = await response.json();
        this.targetingPresets = data;
      } catch (err) {
        console.error(err);
        this.notifyError("Oups...could not fetch country presets list.");
        return;
      }
    },
    isValidaImage(file) {
      return new Promise((res, rej) => {
        var image = new Image();

        let sizeKb = file.size / 1000;
        image.onload = function () {
          let width = this.width;
          let height = this.height;
          if (sizeKb > 2500) {
            rej("The image size exceeds 2.5 MB limit");
            return;
          }

          if (width < 600 || height < 400) {
            rej("The image resolution is too low");
            return;
          }

          let ratio = width / height;
          res();
        };

        image.onerror = function () {
          rej("Invalid image. Please select an image file.");
        };

        image.src = URL.createObjectURL(file);
      });
    },
    cloneAdset(i) {
      let newAdset = _.cloneDeep(this.form.adsets[i]);
      this.form.adsets.push(newAdset);
    },
    cloneAdIntoGivenAdsetWithIndex(adIndex, adsetIndex) {
      let adsetToCopy = this.form.adsets[adsetIndex];
      let adToCopy = adsetToCopy.ads[adIndex];
      let newAd = this.addAd(adsetIndex);
      Object.assign(
        newAd.creative.object_story_spec.link_data,
        adToCopy.creative.object_story_spec.link_data
      );
      newAd.creative.object_story_spec.page_id =
        adToCopy.creative.object_story_spec.page_id;
      newAd.images[0] = adToCopy.images[0];
    },

    removeAd(i, adsetIndex) {
      this.form.adsets[adsetIndex].ads.splice(i, 1);
    },
    removeAdset(i) {
      this.form.adsets.splice(i, 1);
    },
    addAdset() {
      let newAdset = {
        name: null,
        bid_amount: 0.2,
        maxBid: 0.2,
        daily_budget: 7,
        maxDailyBudget: 25,
        billing_event: "IMPRESSIONS",
        optimization_goal: "OFFSITE_CONVERSIONS",
        promoted_object: {
          pixel_id: null,
          custom_event_type: "OTHER",
          pixel_rule: null,
        },
        destination_type: "WEBSITE",
        targeting: {
          age_min: 21,
          age_max: 65,
          genders: [1, 2],
          locales: [6, 24],
          user_os: ["iOS"],
          device_platforms: [],
          geo_locations: {
            countries: ["US"],
            country_groups: [],
          },
          facebook_positions: ["feed"],
          publisher_platforms: ["facebook"],
        },
        is_dynamic_creative: false,
        status: "PAUSED",
        ads: [],
        selectedTargetingPreset: null,
        _countries: [],
        _adsetPerCountry: false,
        _platforms: [],
        videoPlayer: null,
        tracking_code: this.trackingCode,
        _events: [],
      };
      let newAdsetsLength = this.form.adsets.push(newAdset);
      this.addAd(newAdsetsLength - 1);
      return newAdset;
    },
    createNewAdd() {
      let newAd = {
        name: "Ad-" + Math.floor(Math.random() * 10000),
        conversion_domain: null,
        images: [],
        status: "PAUSED",
        creative: {
          name: null,
          object_story_spec: {
            link_data: {
              link: null,
              message: null,
              name: null,
              attachment_style: "link",
              description: null,
              picture: null,
            },
            page_id: null,
          },
        },
      };
      return newAd;
    },
    addAd(adsetIndex) {
      let newAd = this.createNewAdd();
      this.form.adsets[adsetIndex].ads.push(newAd);
      return newAd;
    },
    handleFileUploads(adsetIndex, i) {
      let fileInput = this.$refs["file-" + adsetIndex + "-" + i][0];
      this.$set(
        this.form.adsets[adsetIndex].ads[i].images,
        0,
        fileInput.files[0]
      );
      this.form.adsets[adsetIndex].ads[
        i
      ].creative.object_story_spec.link_data.picture = null;
    },
    async submit() {
      let form = _.cloneDeep(this.form);
      let adsetAdsImagesProms = [];

      if (!form.campaign.name) {
        this.notifyError("Please enter campaign name");
        return;
      }

      if (!form.adsets || form.adsets.length === 0) {
        this.notifyError("Invalid number of adsets");
        return;
      }

      if (form._accounts && form._accounts.length == 0) {
        this.notifyError("Please choose account");
        return;
      }
      for (const account of form._accounts) {
        let adDomain = account.domain;
        adDomain = adDomain && adDomain.substr("https://".length);
        if (!adDomain) {
          this.notifyError("No domain is linked to this account");
          return;
        }
        if (!account.pixelId) {
          this.notifyError("No pixel is connected to account: ", account.name);
          return;
        }
      }

      form.adsets.forEach((adset) => {
        adset.targeting.geo_locations.countries = adset._countries.map(
          (v) => v.val
        );
        adset.targeting.device_platforms = adset._platforms.map((v) => v.val);
        adset.targeting.geo_locations.country_groups = adset.targeting.geo_locations.countries.length === 0 ? ["worldwide"] : [];

          if (adset._events.length === 0 && adset.promoted_object.custom_event_type == "OTHER") {
          this.notifyError("Please choose an adset event");
          return;
        }
        adset._events = adset.promoted_object.custom_event_type == "OTHER" ? 
        adset._events : 
        [{label: adset.promoted_object.custom_event_type, val: adset.promoted_object.custom_event_type}];

        if (adset.targeting.device_platforms.length === 0) {
          this.notifyError("Please choose at least one platform");
          return;
        }
          

        if (!adset.ads || adset.ads.length === 0) {
          this.notifyError("Invalid adset ads");
          return;
        }

        if (
          [adset.name, adset.bid_amount, adset.daily_budget].some((v) => !v)
        ) {
          this.notifyError("Invalid adset fields");
          return;
        }

        if (adset.bid_amount > adset.maxBid) {
          this.notifyError("bid amount is higher than Max bid ");
          return;
        }

        if (adset.daily_budget > adset.maxDailyBudget) {
          this.notifyError("daily budget is higher than Max daily budget ");
          return;
        }

        if (adset.targeting.geo_locations.countries.length === 0) {
          this.notifyError("Please enter country targeting");
          return;
        }

        adset.bid_amount *= 100;
        adset.daily_budget *= 100;

        adset.ads.forEach((ad) => {
          if (
            ad.images[0] &&
            !ad.creative.object_story_spec.link_data.picture
          ) {
            ad.images.forEach((image) => {
              let prom = this.isValidaImage(image);
              adsetAdsImagesProms.push(prom);
            });
          }
        });
      });

      try {
        await Promise.all(adsetAdsImagesProms);
        let allAds = [];
        for (let index = 0; index < form.adsets.length; index++) {
          let adset = form.adsets[index];
          for (let i = 0; i < adset.ads.length; i++) {
            let ad = adset.ads[i];
            if (!ad.status) {
              ad.status = "PAUSED";
            }
            if (
              !ad.images[0] &&
              !ad.creative.object_story_spec.link_data.picture
            ) {
              this.notifyError(
                `Missing image for #${i + 1} ad of #${index + 1} adset `
              );
              return;
            }

            let headline = ad.creative.object_story_spec.link_data.name;
            let AdPrimaryText = ad.creative.object_story_spec.link_data.message;
            let AdPage = ad.creative.object_story_spec.page_id;

            if (!AdPage) {
              this.notifyError("Please choose a page for each ad");
              return;
            }

            if (!headline || !AdPrimaryText) {
              this.notifyError(
                "Please enter Headline and Primary Text of an ad"
              );
              return;
            }
            ad.creative.name = headline;

            if (!ad.creative.object_story_spec.link_data.link) {
              this.notifyError("Please enter URL");
              return;
            }

            if (!ad.creative.object_story_spec.link_data.link.includes("?")) {
              ad.creative.object_story_spec.link_data.link +=
                "?" + adset.tracking_code;
            }

            let _account = form._accounts && form._accounts[0];
            if (
              !ad.creative.object_story_spec.link_data.link.includes(
                _account.domain
              )
            ) {
              this.notifyError(
                "Wrong url domain for account: " + _account.name
              );
              return;
            }

            allAds.push(ad);
          }
        }
        await this.$validate.fbAds(allAds);
        await this._submitAfterValidation(form);
      } catch (err) {
        this.notifyError(err);
      }
    },

    async uploadImage(ad) {
      if (ad.images[0] && !ad.creative.object_story_spec.link_data.picture) {
        const formData = new FormData();
        formData.append("file", ad.images[0]);
        const r = await this.$http.post(
          this.resources.taboola.imageUpload,
          formData
        );
        const data = await r.json();
        const cdnURL = data.cdn_url;
        ad.creative.object_story_spec.link_data.picture = cdnURL;
      }
    },

    async createCamp(campObj) {
      await this.$http.post(
        this.resources.fb.createCampaign(campObj.account.id),
        campObj
      );
      this.createdCampaigns++;
    },
    async _submitAfterValidation(form) {
      try {
        this.ongoingSubmit = true;

        const proms = form.adsets.flatMap((adset) =>
          adset.ads.map(async (ad) => await this.uploadImage(ad))
        );
        await Promise.all(proms);

        const payload = form._accounts.map((account) => ({
          account: account,
          campaign: form.campaign,
          adsets: form.adsets.flatMap((adset) =>
            adset._events.flatMap((ev) => {
              const adsetCopy = _.cloneDeep(adset);
              adsetCopy._events = [ev];
              adsetCopy.promoted_object.pixel_rule = adsetCopy.promoted_object.custom_event_type == "OTHER" ? ev.val : null;
              const adDomain =
                account.domain && account.domain.substr("https://".length);
                adsetCopy.promoted_object.pixel_id =
                account.pixelId;
                adsetCopy.ads.forEach((ad) => {
                ad.conversion_domain = adDomain;
              });
              const deviceLabel =
              adsetCopy.targeting.device_platforms.length > 1 ? (adsetCopy.targeting.user_os.length > 0 
              && adsetCopy.targeting.user_os[0] == "Android" ? "Alldan" : "Alld") :
              (adsetCopy.targeting.device_platforms[0] == "desktop"
                  ? "DS"
                  : adsetCopy.targeting.device_platforms[0] == "mobile"
                  ? adsetCopy.targeting.user_os.length === 0
                    ? "MB"
                    : adsetCopy.targeting.user_os[0] === "iOS"
                    ? "IOS"
                    : "An"
                  : "");

              let adsetName = adsetCopy.name;
              const t01 = adsetName.indexOf(" -");
              if (t01 > -1) {
                adsetName = adsetName.substr(0, t01);
              }
              const eventName = adsetCopy._events[0].label;
              adsetCopy._adsetPerCountry =
                adsetCopy._adsetPerCountry &&
                adsetCopy.targeting.geo_locations.countries.length > 0;
              if (!adsetCopy._adsetPerCountry) {
                adsetCopy.name = `${adsetName} - ${eventName} ${
                  account.webSiteSymbol
                } FB ${
                  adsetCopy.targeting.geo_locations.countries.length > 1 || 
                  adsetCopy.targeting.geo_locations.countries.length === 0
                    ? "WW"
                    : adsetCopy.targeting.geo_locations.countries[0]
                } ${deviceLabel}`;
                return [adsetCopy];
              }
              return adsetCopy.targeting.geo_locations.countries.map(
                (country) => {
                  let adsetCopyWithOneCountry = _.cloneDeep(adsetCopy);
                  adsetCopyWithOneCountry.name = `${adsetName} - ${eventName} ${account.webSiteSymbol} FB ${country} ${deviceLabel}`;
                  adsetCopyWithOneCountry.targeting.geo_locations.countries = [
                    country,
                  ];
                  return adsetCopyWithOneCountry;
                }
              );
            })
          ),
        }));

        this.campsToCreateAmount = payload.length;
        const campRequests = payload.map((campObj) => this.createCamp(campObj));

        const results = await Promise.allSettled(campRequests);
        const failedResults = results.filter(
          (result) => result.status == "rejected"
        );
        if (failedResults.length == results.length) throw new Error();

        if (!failedResults.length) {
          this.notifySuccess(
            `${this.createdCampaigns}/${this.campsToCreateAmount} campaigns created`,
            5000
          );
          return;
        }

        this.notifyWarning(
          `${this.campsToCreateAmount - failedResults.length}/${
            this.campsToCreateAmount
          } campaigns created`,
          9000
        );
      } catch (err) {
        console.error(err);
        this.notifyError("Could not create campaign");
      } finally {
        this.ongoingSubmit = false;
        this.onClose();
      }
    },
    setCampOptions() {
      let account = this.form.account;
      if (!account) {
        return;
      }
      this.campaignOptions = account.campaigns.map((camp) => ({
        value: camp,
        label: camp.name,
      }));
    },
    setAccountOptions() {
      let accounts = this.accounts;
      this.accountOptions = accounts;
    },
  },
};
</script>
<style lang="css" scoped>
.field-23 {
  display: flex;
  margin-bottom: 6px;
}
.field-23 label {
  width: 170px;
  text-align: right;
  margin-right: 16px;
  font-size: 12px;
  font-weight: bold;
  color: #5d5d5d;
}
.h-4124 {
  font-size: 14px;
  font-family: tahoma;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 5px;
  font-weight: bold;
  color: dimgrey;
}
.adset-wizard-container {
  padding: 0 15px;
  width: 700px;
}
.select-2 {
  max-width: 67%;
}
.t-url-1 {
  width: 390px;
  font-size: 13px;
  color: #5f5f5f;
}
.txt-input-32 {
  width: 358px;
  height: 41px;
  font-size: 15px;
}
.item-152 {
  border-bottom: 1px solid #cacaca;
  margin-bottom: 8px;
  padding: 13px 0;
}
.item-152:last-child {
  border-bottom: 0;
}
.rem-item-container-1 {
  display: flex;
  justify-content: flex-end;
  margin-right: 33px;
  margin-top: 17px;
  border-bottom: 1px solid #d19898;
  margin-bottom: 17px;
  padding-top: 5px;
  padding-bottom: 15px;
}
.rem-item {
  font-size: 26px;
  cursor: pointer;
  color: #535353;
}
.rem-item:hover {
  color: black;
}
.clone-item {
  font-size: 26px;
  cursor: pointer;
  color: #535353;
  margin-right: 35px;
  border-right: 1px solid #969696;
  padding-right: 35px;
}
.clone-item:hover {
  color: black;
}
.b-c-251 {
  margin-top: 17px;
  padding-top: 10px;
}
.image-zone {
  border: 1px solid gainsboro;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
}
.image-zone:hover {
  background: #fafafa;
}
.image-field {
  margin: 15px 0;
}
.image-field label.label-141 {
  width: inherit;
  font-size: 11px;
}
.loading-12 {
  font-size: 16px !important;
  margin-left: 22px;
  color: #357ba3;
}
.tab-container-31 {
  max-height: 60vh;
  overflow-y: auto;
}
.campaign-tab {
  height: 30vh;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
