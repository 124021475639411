<template lang="html">
  <div class="adsetReport">
    <h2>Adset "{{ adset.name }}"</h2>

    <ReportForm :id="adsetId" level="adset"></ReportForm>

    <br />
    <h3>Adset Ads</h3>
    <Table
      v-if="adset"
      :search="false"
      :data="adset.ads"
      :autoFetch="false"
      :fields="{ name: 'Ad Name' }"
      :permissions="{}"
      :askBeforeDelete="1"
      :onRowClick="navigateToAd"
    />

    <router-view :adset="adset"></router-view>
  </div>
</template>

<script>
import ReportForm from "./shared/ReportForm";

export default {
  components: {
    ReportForm,
  },
  data() {
    return {
      adset: null,
      adsetReport: null,
    };
  },
  created() {
    let adsetId = this.adsetId;
    let fbAdsets = this.$store.state.data.fbAdsets;
    if (!fbAdsets) {
      return;
    }
    let adset = fbAdsets.find((v) => v.id == adsetId);
    this.adset = adset;
  },
  computed: {
    adsetId() {
      return this.$route.params.adsetId;
    },
  },
  methods: {
    navigateToAd(ad) {
      this.$router.push({
        name: "CampaignsReportsAd",
        params: { adId: ad.id },
      });
    },
  },
};
</script>

<style lang="css" scoped></style>
