export default {
  items: [
    {
      title: true,
      name: "Management",
      class: "",
      wrapper: {
        element: "",
        attributes: {},
      },
    },
    {
      name: "Admin Users",
      url: "/admin",
      icon: "icon-book-open",
      children: [
        {
          name: "Users Management",
          url: "/admin/edit-user",
        },
        {
          name: "Roles Management",
          url: "/admin/edit-roles",
        },
        {
          name: "Permissions Management",
          url: "/admin/edit-permissions",
        },
        {
          name: "Edit Presets",
          url: "/admin/edit-presets",
        },
        {
          name: "Facebook pages",
          url: "/admin/fb-pages",
        },
      ],
    },
    {
      title: true,
      name: "Content",
      class: "",
      wrapper: {
        element: "",
        attributes: {},
      },
    },
    {
      name: "Performance",     
      url: "/performance/reports",      
    },
    {
      name: "Campaigns",
      icon: "icon-book-open",
      url: "/",
      children: [
        {
          name: "FB",
          url: "/fb/reports",
        },
        {
          name: "Twitter",
          url: "/twitter/reports",
        },
        {
          name: "Taboola",
          url: "/taboola/reports",
        },
        {
          name: "Outbrain",
          url: "/outbrain/reports",
        },
      ],
    },
    {
      name: "Automation Rules",
      icon: "icon-book-open",
      url: "/automation/rules"
    },
  ],
};
