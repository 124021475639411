<template>
  <b-modal
    :id="editMode ? 'modal-edit' : 'modal-add'"
    size="lg"
    :title="editMode ? 'Edit Rule' : 'Add Rule'"
    v-model="showModalLocal"
    hide-footer
  >
    <div class="botForm">
            <b-form-select
              v-model="form.active"
              :options="ruleStatusOptions"
            ></b-form-select>
            <b-form-group
              class="mt-2"
              label="Title"
              label-for="input-title"
              invalid-feedback="Must enter title!"
              :state="titleState"
            >
              <b-form-input
                id="input-title"
                v-model="form.title"
                :state="titleState"
                trim
              ></b-form-input>
            </b-form-group>

          <h3 class="mt-2">When</h3>
              <span><label class="mr-2">Start Time</label><i @click="addTime" class="fa fa-plus-circle add-filter-btn pointer-cursor"></i></span>
              <b-container>
                <b-form>
                <b-row v-for="(_, i) in form.times">
                  <b-col class="p-1">
                    <b-form-select
                      required
                      class="def"
                      v-model="form.times[i].day"
                      :options="daysOptions"
                    />
                  </b-col>
                  <b-col class="p-1">
                    <b-form-input v-model="form.times[i].time" type="time" />
                  </b-col>
                  <b-col class="p-1">
                    <i
                      @click="removeTime(i)"
                      class="fa fa-times x-filter-btn"
                      aria-hidden="true"
                    ></i>
                  </b-col>
                </b-row>
                <b-form-invalid-feedback :state="timesState">
                  You Must Enter Scheduling Times.
                </b-form-invalid-feedback>
              </b-form>
              </b-container>
            <b-form-group
              class="mt-2"
              label="Condition Time Frame"
              label-for="input-time-frame"
            >
              <b-form-select
                id="input-time-frame"
                v-model="form.timeFrame"
                :options="timeFrameOptions"
              ></b-form-select>
            </b-form-group>

            <b-form>
          <FilteringForm
            :filteringForm="form.filters"
            :fields="filterableFields"
            :saveFilterEnable="false"
          />
          <b-form-invalid-feedback :state="filtersExistState">
                  You Must Have Filters.
                </b-form-invalid-feedback>
                <b-form-invalid-feedback :state="filtersValuesState">
                  You Must enter values for all of the filters.
                </b-form-invalid-feedback>
          </b-form>

          <h3 class="mt-2">Action</h3>
          <b-form>
            <b-form-group
              label="Field"
              label-for="input-field"
            >
              <b-form-select
                id="input-field"
                v-model="form.actionField"
                :options="actionFieldOptions"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label="Change Type"
              label-for="input-change-type"
            >
              <b-form-select
                id="input-change-type"
                v-model="form.changeType"
                :options="changeTypeOptions"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label="Change by"
              label-for="input-change-by"
              invalid-feedback="You cannot enter zero to change by field!"
              :state="changeToState"
            >
              <b-form-input
                id="input-change-by"
                type="number"
                v-model="form.changeTo"
                required
                number
                lazy-formatter
                :formatter="changeToFormatter"
                :state="changeToState"
                :disabled="form.changeType === SetToUV ? true : false"
                :step="
                form.changeType === RelativePercentage ||
                form.actionField === DailyBudget
                  ? '1'
                  : '0.01'
              "
              ></b-form-input>
            </b-form-group>
            <b-form-invalid-feedback :state="changeTypeAndActionState">
              You cannot have changeType is set to uv with actionField set to  Daily Budget.
            </b-form-invalid-feedback>
          </b-form>

        <button
          type="submit"
          class="btn btn-success submitButtons float-right"
          name="action"
          @click.prevent="formSubmit(!editMode ? postForm : updateForm)"
          :disabled="!(filtersExistState && filtersValuesState && changeToState && changeTypeAndActionState && timesState && titleState)"
        >
          {{!editMode ? "Submit" : `Update${row.forApprove && this.userHasPermission('automation.approve') ? " And Approve" : ""}`}}
        </button>
    </div>
  </b-modal>
</template>

<script>
import FilteringForm from "@/views/reports/shared/FilteringForm.reports";
import _fields from "@/assets/fb-fields";
import moment from "moment";
import * as _ from "lodash";

const DailyBudget = "Daily Budget";
const Bid = "Bid";
const Relative = "Relative";
const RelativePercentage = "RelativePercentage";
const SetToUV = "SetToUV";
const RealTime = "Real Time";
const Yesterday = "Yesterday";

export default {
  components: {
    FilteringForm,
  },
  model: {
    prop: "modalShow",
    event: "modalShowChange",
  },
  props: {
    row: {
      type: Object,
      default(rawProps) {
        return {
          title: "",
          active: true,
          when: {
            filterBasedDateAsDaysAgo: RealTime,
            repeat: 24,
            startTimeExtended: [{ day: 0, hour: 0, minutes: 0 }],
          },
          filters: [],
          actions: [{ field: Bid, changeTo: 0.01, changeType: Relative }],
        };
      },
    },
    editMode: Boolean,
    modalShow: Boolean,
    addNewRow: {},
    deleteRow: {},
    updateRow: {},
  },
  data() {
    return {
      actionFieldOptions : [
      { text: Bid, value: Bid },
        { text: DailyBudget, value: DailyBudget },
      ],
      timeFrameOptions: [
        { text: RealTime, value: RealTime },
        { text: Yesterday, value: Yesterday },
      ],
      ruleStatusOptions: [
        { text: "Active", value: true },
        { text: "Disabled", value: false },
      ],
      hoursOptions: new Array(24).fill(true).map((_, i) => ({
        value: i,
        text: this.$options.filters.formatTime(i),
      })),
      minutesOptions: new Array(60).fill(true).map((_, i) => ({
        value: i,
        text: this.$options.filters.formatTime(i),
      })),
      filterableFields: _.omit({created_at: {
          title: "Created At",
          type: Date,
        },
        name: {
          title: "Adset Name",
          type: String,
        }, ..._.omit({..._fields,bid_amount: _fields.bid_computed},'bid_computed')},'created_at_formated'),
      DailyBudget,
      Bid,
      Relative,
      RelativePercentage,
      SetToUV,
      RealTime,
      Yesterday,
      daysOptions: [
        { value: 0, text: "All Days" },
        ...moment
          .localeData("en")
          .weekdays()
          .map((value, index) => ({ value: index + 1, text: value })),
      ],
      form: {
        repeat: this.row?.when ? this.row.when.repeat : 24,
        timeFrame: this.row?.when
          ? this.row.when.filterBasedDateAsDaysAgo
          : RealTime,
        actionField: this.row?.actions ? this.row.actions[0].field : Bid,
        changeTo: this.row?.actions ? this.row.actions[0].changeTo : 0.01,
        changeType: this.row?.actions
          ? this.row.actions[0].changeType
          : Relative,
        active: this.row?.active ? this.row.active : true,
        title: this.row?.title ? this.row.title : "",
        times: this.row?.when
          ? this.convertTimeToClientDateTimeFormat(
              this.row.when.startTimeExtended
            )
          : [{ day: 0, time: "00:00" }],
        showAbsolute: false,
        filters: this.row?.filters ? this.row.filters : [],
      },
    };
  },
  filters: {
    formatTime(hour) {
      if (hour < 10) {
        return "0" + hour;
      } else {
        return hour;
      }
    },
  },
  methods: {
    addTime() {
      if (this.form.times !== null) {
        this.form.times.push({ day: 0, time: "00:00" });
      } else {
        this.notifyError("cannot add time this is an old version operation");
      }
    },
    removeTime(index) {
      if (this.form.times !== null) {
        this.form.times.splice(index, 1);
      }
    },
    changeToFormatter(value) {
      return this.form.actionField === DailyBudget ||
        this.form.changeType === RelativePercentage
          ? Math.floor(Number(value)).toString()
          : value;
    },
    postForm: async function (operation, id) {
      await this.addNewRow(operation);
    },

    deleteForm: async function (operation, id) {
      await this.deleteRow(id,this.row.forApprove);
    },

    updateForm: async function (operation, id) {
      await this.updateRow(operation,id,this.row.forApprove);
    },

    formSubmit: async function (action) {
      const operation = {
        active: this.form.active,
        title: this.form.title,
        when: {
          startTimeExtended: this.form.times.map((dateTime) => ({
            day: dateTime.day,
            hour: Number(dateTime.time.split(":")[0]),
            minutes: Number(dateTime.time.split(":")[1]),
          })),
          repeat: this.form.repeat,
          filterBasedDateAsDaysAgo: this.form.timeFrame,
        },
        filters: this.form.filters.map((filter) =>
          ({...filter,val: !isNaN(filter.val) ? Number(filter.val) : filter.val})
        ),
        actions: [
          {
            field: this.form.actionField,
            changeTo: this.form.changeTo,
            changeType: this.form.changeType,
          },
        ],
      };
      this.$nextTick(() => {
          this.$bvModal.hide(this.editMode ? 'modal-edit' : 'modal-add');
      });
      action(operation, this.editMode ? this.row.id : -1);
    },
    convertTimeToClientDateTimeFormat(startTimeArr) {
      return startTimeArr.map((dateTime) => ({
        day: dateTime.day,
        time: `${this.$options.filters.formatTime(
          dateTime.hour
        )}:${this.$options.filters.formatTime(dateTime.minutes)}`,
      }));
    },
  },
  computed: {
    changeTypeOptions() {
      return [{ text: Relative, value: Relative },
        { text: "Relative Percentage", value: RelativePercentage }, 
        this.form.actionField === DailyBudget ? { text: 'Absolute', value: 'Absolute' } : 
        { text: 'Set to UV', value: 'SetToUV' }] 
    },
    filtersExistState() {
      return this.form.filters != null && this.form.filters.length > 0
    },
    filtersValuesState() {
      return this.form.filters != null && this.form.filters.filter(filter => filter.val == null || filter.val == "").length == 0
    },
    changeToState() {
      return this.form.changeTo != null && this.form.changeTo != 0
    },
    changeTypeAndActionState() {
      return !(this.form.changeType === SetToUV && this.form.actionField === DailyBudget)
    },
    timesState() {
      return this.form.times != null && this.form.times.length > 0
    },
    titleState() {
      return this.form.title != null && this.form.title.length > 0;
    },
    showModalLocal: {
      get: function () {
        return this.modalShow ?? false;
      },
      set: function (value) {
        this.$emit("modalShowChange", value);
      },
    },
  },
};
</script>

<style lang="css" scoped>

.submitButtons {
  margin-top: 15px;
  margin-bottom: 15px;
}
.pointer-cursor {
  cursor: pointer;
}

.x-filter-btn {
  margin-left: 6px;
  cursor: pointer;
  padding: 5px;
  border-radius: 15px;
  font-size: 16px;
}
.x-filter-btn:hover {
  background: #e2e2e2;
}
</style>
