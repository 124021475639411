<template lang="html">
  <div class="adset-wizard-container">
    <h3 class="h-4124">Campaign Wizard</h3>
    <form class="" @submit.prevent="beforeSubmit">
      <Tabs :tabs="['Campaign', 'Ad group', 'Ad']" :activeTab="0">
        <div class="tab-container-31" slot="t-1">
          <div class="field-23">
            <label for="">Campaign Name</label>
            <input class="def txt-input-32" v-model="form.campaign.name" />
          </div>

          <div class="field-23">
            <label for="">Accounts:</label>
            <select class="def" v-model="form.campaign.account_id">
              <option :value="account.id" v-for="account in accounts">
                {{ account.name }}
              </option>
            </select>
          </div>

          <div class="field-23">
            <label for="">Objective</label>
            <select class="def" v-model="form.campaign.objective">
              <option :value="objective" v-for="objective in objectiveOptions">
                {{ objective }}
              </option>
            </select>
          </div>

          <div class="field-23">
            <label for="">Daily campaign budget</label>
            <input
              type="number"
              class="def txt-input-32"
              v-model.number="form.campaign.daily_budget"
              :max="maxDailyBudget"
            />
          </div>

          <!-- <div class="field-23">
            <label for="">Total campaign budget (optional)</label>
            <input
              type="number"
              class="def txt-input-32"
              v-model.number="form.campaign.total_budget"
              :max="maxDailyBudget"
            />
          </div> -->
        </div>
        <div class="tab-container-31" slot="t-2">
          <div class="field-23">
            <label for="">Ad group Name</label>
            <input class="def txt-input-32" v-model="form.campaign.name" />
          </div>

          <div class="field-23">
            <label for="">Language</label>
            <multiselect
              class="select-2"
              label="label"
              track-by="val"
              :multiple="true"
              :close-on-select="false"
              v-model="form.targetingCriteriaValues._languages"
              :options="languages"
            ></multiselect>
          </div>

          <div class="field-23">
            <label for="">Age Options</label>
            <select
              class="def"
              v-model="form.targetingCriteriaValues.ageOption"
            >
              <option v-for="(o, v) in ageOptions" :value="v">
                {{ o }}
              </option>
            </select>
          </div>

          <div
            class="field-23"
            v-show="form.targetingCriteriaValues.ageOption === 'CUSTOM'"
          >
            <label for="">Min Age</label>
            <select class="def" v-model="form.targetingCriteriaValues.minAge">
              <option v-for="(o, v) in minAgeOptions" :value="v">
                {{ o }}
              </option>
            </select>
          </div>

          <div
            class="field-23"
            v-show="form.targetingCriteriaValues.ageOption === 'CUSTOM'"
          >
            <label for="">Max Age</label>
            <select class="def" v-model="form.targetingCriteriaValues.maxAge">
              <option v-for="(o, v) in maxAgeOptions" :value="v">
                {{ o }}
              </option>
            </select>
          </div>

          <div class="field-23">
            <label for="">Goal</label>
            <select class="def" v-model="form.adGroup.goal">
              <option v-for="(o, v) in goalOptions" :value="v">
                {{ o }}
              </option>
            </select>
          </div>

          <div class="field-23">
            <label for="">Conversion event</label>
            <select
              class="def"
              v-model="form.adGroup.primary_web_event_tag"
              :disabled="form.adGroup.goal !== 'WEBSITE_CONVERSIONS'"
            >
              <option v-for="(o, v) in conversionEvent" :value="v">
                {{ o }}
              </option>
            </select>
          </div>

          <div class="field-23">
            <label for="">Bid strategy</label>
            <select class="def" v-model="form.adGroup.bid_strategy">
              <option
                v-for="(o, v) in bidStrategyOptions"
                :value="v"
                v-show="form.adGroup.goal !== 'SITE_VISITS' || v !== 'TARGET'"
              >
                {{ o }}
              </option>
            </select>
          </div>
          <div class="field-23">
            <label for="" v-show="form.adGroup.bid_strategy !== 'AUTO'"
              >Bid Value</label
            >
            <input
              type="number"
              class="def txt-input-32"
              v-model.number="form.adGroup.bid_amount"
              v-show="form.adGroup.bid_strategy !== 'AUTO'"
              :step="0.01"
              :max="maxBidSet"
            />
          </div>
          <div class="field-23">
            <label for="">Gender</label>
            <select class="def" v-model="form.targetingCriteriaValues.gender">
              <option v-for="(o, v) in genderOptions" :value="o">
                {{ v }}
              </option>
            </select>
          </div>

          <div class="field-23">
            <label for="">Country Targeting</label>
            <multiselect
              class="select-2"
              label="label"
              track-by="val"
              :multiple="true"
              :close-on-select="false"
              v-model="form.targetingCriteriaValues._countries"
              :options="tarCountries"
            ></multiselect>
          </div>
          <div class="field-23">
            <label for="">Devices</label>
            <multiselect
              class="select-2"
              label="type"
              track-by="type"
              :multiple="true"
              :close-on-select="false"
              v-model="form.targetingCriteriaValues._devices"
              :options="devices"
            ></multiselect>
          </div>

          <div class="time-div">
            <div class="field-23">
              <label for="">start time</label>
              <div class="date-range-here" v-show="specifyStartTime">
                <DatePicker
                  :onChange="
                    (v) => {
                      form.adGroup.start_dt = v;
                    }
                  "
                  :value="form.adGroup.start_dt"
                />
                <select class="def" v-model="form.adGroup.StartTimeHour">
                  <option :value="hour.val" v-for="hour in hoursOptions">
                    {{ hour.label }}
                  </option>
                </select>
              </div>
            </div>

            <div class="field-23">
              <label for="">end time</label>
              <div class="date-range-here">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="form.adGroup.RunIndefinitely"
                  name="checkbox-1"
                  v-bind:value="false"
                  v-bind:unchecked-value="true"
                >
                  Run indefinitely
                </b-form-checkbox>
              </div>
            </div>
            <div class="field-23 center">
              <div
                class="date-range-here"
                v-show="form.adGroup.RunIndefinitely"
              >
                <DatePicker
                  :onChange="
                    (v) => {
                      form.adGroup.end_dt = v;
                    }
                  "
                  :value="form.adGroup.end_dt"
                />
                <select class="def" v-model="form.adGroup.EndTimeHour">
                  <option :value="hour.val" v-for="hour in hoursOptions">
                    {{ hour.label }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-container-31" slot="t-3">
          <ul class="">
            <li class="item-152" v-for="(ad, i) in form.ads">
              <div class="field-23">
                <label for="">Card Id</label>
                {{ ad.card.id }}
              </div>
              <div class="field-23">
                <label for="">Card Title</label>
                <input
                  class="def txt-input-32"
                  v-model="ad.card.website_title"
                />
              </div>
              <div class="field-23">
                <label for="">Tweet Text</label>
                <input class="def txt-input-32" v-model="ad.tweet.full_text" />
              </div>

              <div class="field-23">
                <label for="">Website URL</label>
                <input
                  type="url"
                  pattern="https://.*"
                  class="def txt-input-32"
                  v-model.trim="ad.card.website_url"
                />
              </div>

              <div class="field-23 image-field">
                <label for="">Image</label>
                <label class="label-141" :for="'file51-' + i">
                  <span class="image-zone">{{
                    form.images[i] ? form.images[i].name : "Choose Image"
                  }}</span>
                </label>
                <input
                  v-show="0"
                  class="def txt-input-32"
                  type="file"
                  accept="image/*"
                  :ref="'file-' + i"
                  :id="'file51-' + i"
                  @change="handleFileUploads(i)"
                />
              </div>

              <div class="rem-item-container-1">
                <i
                  @click="cloneAd(i)"
                  class="fa fa-clone clone-item"
                  aria-hidden="true"
                ></i>
                <i
                  @click="removeAd(i)"
                  class="fa fa-times-circle-o rem-item"
                  aria-hidden="true"
                ></i>
              </div>
            </li>
          </ul>
          <div class="b-c-251">
            <button type="button" class="btn _btn-white" @click="addAd">
              Add Item
            </button>
          </div>
        </div>
      </Tabs>
      <button
        type="submit"
        class="btn btn-primary margin-top"
        :disabled="ongoingSubmit"
      >
        Submit
      </button>
      <template v-if="ongoingSubmit">
        <Spinner class="loading-12" />
        <span>Creating campaign...</span>
      </template>
    </form>
  </div>
</template>

<script>
import moment from "moment-timezone";
import countries from "@/assets/countries";
import targetingLangs from "@/assets/targetingLangs";
import targetingCountries from "@/assets/targetingCountries";
import Multiselect from "vue-multiselect";
export default {
  components: {
    Multiselect,
  },
  props: {
    accounts: {},
    onClose: {},
    data: {},
  },
  data() {
    let hoursOptions = [];
    for (let i = 0; i <= 23; i++) {
      hoursOptions.unshift({
        val: i < 10 ? "0" + i + ":30" : "" + i + ":30",
        label: i < 10 ? "0" + i + ":30" : "" + i + ":30",
      });
      hoursOptions.unshift({
        val: i < 10 ? "0" + i + ":00" : "" + i + ":00",
        label: i < 10 ? "0" + i + ":00" : "" + i + ":00",
      });
    }

    return {
      specifyStartTime: true,
      // specifyEndTime: false,
      maxBidSet: 0.4,
      maxDailyBudget: 20,
      rawCountries: countries,
      countries: Object.keys(countries).map((v) => ({
        val: v,
        label: countries[v],
      })),
      tarCountries: targetingCountries.data.map((obj) => ({
        label: obj.name,
        val: obj.targeting_value,
        code: obj.country_code,
      })),
      languages: Object.keys(targetingLangs).map((v) => ({
        val: v,
        label: targetingLangs[v],
      })),
      devices: [
        { type: "iOS", value: "0" },
        { type: "Android", value: "1" },
        { type: "Desktop and laptop computers", value: "4" },
      ],
      devicesToUtms: {
        "0": "IOS",
        "1": "AN",
        "4": "DS",
      },
      conversionEvent: {
        pk7bf: "vv1",
        pk7bg: "vv2",
        pk7bh: "vv3",
        pk7bi: "vv4",
        pk7bj: "vv5",
      },
      accountIdToUtmName: {
        "18ce558goyr": "AC", //Absolutelyconnected
        "18ce558gox0": "AP", //Avocadoposts
        "18ce558nsfo": "EL", //Eternallifestyle
        "18ce558goxs": "WT", //Worldtravelling
      },
      websitesShortcuts: {
        Avocadoposts: "AP",
        Absolutelyconnected: "AC",
        Eternallifestyle: "EL",
        Worldtravelling: "WT",
      },
      hoursOptions,
      objectiveOptions: ["WEBSITE_CLICKS"],
      genderOptions: {
        Any: "ANY",
        Female: "FEMALE",
        Male: "MALE",
      },
      ongoingSubmit: false,
      goalOptions: {
        SITE_VISITS: "Site Visits",
        LINK_CLICKS: "Link clicks",
        WEBSITE_CONVERSIONS: "Conversions",
      },
      ageOptions: {
        ALL: "ALL",
        CUSTOM: "CUSTOM",
      },
      minAgeOptions: {
        "13": "13",
        "18": "18",
        "21": "21",
        "25": "25",
        "35": "35",
        "50": "50",
      },
      maxAgeOptions: {
        "24": "24",
        "34": "34",
        "49": "49",
        "54": "54",
        AndUp: "And up",
      },
      bidStrategyOptions: {
        AUTO: "Autobid",
        TARGET: "Target cost",
        MAX: "Maximum bid",
      },
      form: {
        ads: [],
        images: [],
        campaign: {
          account_id: "18ce558goyr",
          name: "",
          daily_budget: 10,
          // total_budget: null,
          objective: "WEBSITE_CLICKS",
        },
        adGroup: {
          goal: "SITE_VISITS",
          bid_strategy: "AUTO",
          primary_web_event_tag: "",
          bid_amount: null,
          start_dt: null,
          end_dt: null,
          EndTimeHour: "",
          StartTimeHour: "",
          start_time: "",
          end_time: "",
          RunIndefinitely: true,
        },
        targetingCriteriaValues: {
          gender: "ANY",
          _countries: [],
          _devices: [],
          _languages: [],
          ageOption: "ALL",
          minAge: "",
          maxAge: "",
        },
      },
    };
  },
  created() {
    if (this.data) {
      //if its a duplicate campaign
      Object.assign(this.form.ads, this.data.ads);
      this.form.campaign.name = this.data.campaign.name;
      this.form.campaign.account_id = this.data.campaign.account_id;
      this.duplicateCampaignNameUtms();
      return;
    }
    this.addAd();
  },
  methods: {
    isValidaImage(file) {
      return new Promise((res, rej) => {
        var image = new Image();

        let sizeKb = file.size / 1000;
        image.onload = function() {
          let width = this.width;
          let height = this.height;
          if (sizeKb > 5000) {
            rej("The image size exceeds 5 MB limit");
            return;
          }

          if (width < 800) {
            rej("The image width needs to be bigger than 800px");
            return;
          }

          let ratio = width / height;
          if (!(ratio === 1 || ratio === 1.91)) {
            rej(
              "The image ratio needs to be width / height = 1 or width / height = 1.91"
            );
            return;
          }
          res();
        };

        image.onerror = function() {
          rej("Invalid image. Please select an image file.");
        };

        image.src = URL.createObjectURL(file);
      });
    },
    campaignFailsPostReq(title, msg, err) {
      let params = {
        title: title,
        msg: msg,
        metadata: err.bodyText,
      };
      this.$http.post(this.resources.Reports.campaignNotifications, params);
      this.ongoingSubmit = false;
    },
    async beforeSubmit() {
      if (this.form.campaign.name === "") {
        this.notifyError("campaign name is required");
        return;
      }
      if (this.form.targetingCriteriaValues._languages.length === 0) {
        this.notifyError("language is required");
        return;
      }
      if (
        this.form.adGroup.goal === "WEBSITE_CONVERSIONS" &&
        this.form.adGroup.primary_web_event_tag == ""
      ) {
        this.notifyError("Conversion event is required");
        return;
      }
      if (this.form.targetingCriteriaValues._countries.length === 0) {
        this.notifyError("Country is required");
        return;
      }
      if (this.form.targetingCriteriaValues._devices.length === 0) {
        this.notifyError("Device is required");
        return;
      }
      if (this.form.ads.length === 0) {
        this.notifyError("Ad is required");
        return;
      }
      for (let i = 0; i < this.form.ads.length; i++) {
        if (this.form.ads[i].card.website_title === "") {
          this.notifyError("Card title is required for every ad");
          return;
        }
        if (this.form.ads[i].card.website_url === "") {
          this.notifyError("Website url is required for every ad");
          return;
        }
        if (this.form.ads[i].tweet.full_text === "") {
          this.notifyError("Tweet is required for every ad");
          return;
        }
      }
      if (
        this.form.campaign.daily_budget <= 0 ||
        this.form.campaign.daily_budget >= 20
      ) {
        this.notifyError(
          "Daily budget must be greater then zero and less than 20$ "
        );
        return;
      }

      // if (this.form.campaign.total_budget <= 0) {
      //   this.notifyError("Total budget must be greater then zero");
      //   return;
      // }

      this.form.adGroup.start_time = moment(
        `${this.form.adGroup.start_dt} ${this.form.adGroup.StartTimeHour}`
      )
        .utc()
        .format();

      if (this.form.adGroup.RunIndefinitely) {
        this.form.adGroup.end_time = moment(
          `${this.form.adGroup.end_dt} ${this.form.adGroup.EndTimeHour}`
        )
          .utc()
          .format();
      }

      let imagesCheckProms = [];
      this.form.images.forEach((image) => {
        if (image != null) {
          let prom = this.isValidaImage(image);
          imagesCheckProms.push(prom);
        }
      });
      try {
        await Promise.all(imagesCheckProms);
        this.addToCampaignNameUtms();
        await this.submit();
      } catch (err) {
        this.notifyError(err);
      }
    },
    async submit() {
      this.ongoingSubmit = true;
      let proms = [];
      this.form.images.forEach((image, i) => {
        if (this.form.ads[i].card.media_key === null) {
          proms.push(this.imageUpload(i, image));
        }
      });
      try {
        await Promise.all(proms);
        await this.$http.post(
          this.resources.twitter.createCampaignCSHARP(
            this.form.campaign.account_id
          ),
          this.form
        );
        this.ongoingSubmit = false;
        this.onClose();
        this.notifySuccess("Campaign created successfully", 6000);
      } catch (err) {
        this.campaignFailsPostReq(
          "Failed to create campaign",
          "Failed to create Twitter campaign, with the name: " +
            this.form.campaign.name,
          err
        );
      }
    },
    async imageUpload(index, image) {
      let formData = new FormData();
      formData.append("file", image);
      let response = await this.$http.post(
        this.resources.twitter.imageUploadCSHARP(this.form.campaign.account_id),
        formData
      );
      let json = await response.json();
      this.form.ads[index].card.media_key = json.media_key;
      this.form.ads[index].card.picture = json.media_url;
    },
    addToCampaignNameUtms() {
      this.form.campaign.name += " - ";

      if (this.form.adGroup.goal === "LINK_CLICKS") {
        this.form.campaign.name += "LC ";
      }

      if (this.form.adGroup.goal === "SITE_VISITS") {
        this.form.campaign.name += "SVO";
      }

      if (this.form.adGroup.goal === "WEBSITE_CONVERSIONS") {
        this.form.campaign.name +=
          this.conversionEvent[this.form.adGroup.primary_web_event_tag] + " ";
      }

      this.form.campaign.name +=
        this.accountIdToUtmName[this.form.campaign.account_id] + " ";

      if (this.form.targetingCriteriaValues._devices.length === 1) {
        let deviceVal = this.form.targetingCriteriaValues._devices[0].value;
        this.form.campaign.name += this.devicesToUtms[deviceVal] + " ";
      }
      if (this.form.targetingCriteriaValues._devices.length > 1) {
        this.form.campaign.name += "All ";
      }
      if (this.form.targetingCriteriaValues._countries.length === 1) {
        this.form.campaign.name +=
          this.form.targetingCriteriaValues._countries[0].code + " ";
      }
      if (this.form.targetingCriteriaValues._countries.length > 1) {
        this.form.campaign.name += "WW ";
      }
    },
    duplicateCampaignNameUtms() {
      let substrings = this.form.campaign.name.split("-");
      this.form.campaign.name = substrings[0];
    },
    handleFileUploads(i) {
      let fileInput = this.$refs["file-" + i][0];
      this.$set(this.form.images, i, fileInput.files[0]);
      this.form.ads[i].card.media_key = null;
    },
    cloneAd(i) {
      let v = this.form.ads[i];
      let newAd = this.addAd();
      Object.assign(newAd.card, v.card);
      Object.assign(newAd.tweet, v.tweet);
      this.form.images.push(this.form.images[i]);
    },
    removeAd(i) {
      this.form.ads.splice(i, 1);
      this.form.images.splice(i, 1);
    },
    addAd() {
      let newAd = {
        card: {
          name: "Ad-" + Math.floor(Math.random() * 10000),
          website_title: "",
          website_url: "",
          media_key: null,
          picture: null,
        },
        tweet: {
          full_text: "",
        },
      };

      this.form.ads.push(newAd);
      return newAd;
    },
  },
};
</script>

<style lang="css" scoped>
.margin-top {
  margin-top: 5px;
}
.field-23 {
  display: flex;
  margin-bottom: 6px;
}
.field-23 label {
  width: 170px;
  text-align: right;
  margin-right: 16px;
  font-size: 12px;
  font-weight: bold;
  color: #5d5d5d;
}
.rem-item-container-1 {
  display: flex;
  justify-content: flex-end;
  margin-right: 33px;
  margin-top: 17px;
}
.rem-item {
  font-size: 26px;
  cursor: pointer;
  color: #535353;
}
.rem-item:hover {
  color: black;
}
.clone-item {
  font-size: 26px;
  cursor: pointer;
  color: #535353;
  margin-right: 35px;
  border-right: 1px solid #969696;
  padding-right: 35px;
}
.clone-item:hover {
  color: black;
}
.adset-wizard-container {
  padding: 0 15px;
  width: 700px;
}
.h-4124 {
  font-size: 14px;
  font-family: tahoma;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 5px;
  font-weight: bold;
  color: dimgrey;
}
.tab-container-31 {
  max-height: 60vh;
  overflow-y: auto;
}
.txt-input-32 {
  width: 358px;
  height: 41px;
  font-size: 15px;
}
.select-2 {
  max-width: 67%;
}
.image-field {
  margin: 15px 0;
}
.image-field label.label-141 {
  width: inherit;
  font-size: 11px;
}
.loading-12 {
  font-size: 16px !important;
  margin-left: 22px;
  color: #357ba3;
}
.item-152 {
  border-bottom: 1px solid #cacaca;
  margin-bottom: 8px;
  padding: 13px 0;
}
.item-152:last-child {
  border-bottom: 0;
}
.b-c-251 {
  margin-top: 17px;
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;
}
.date-range-here {
  display: flex;
  margin-top: 5px;
}
.center {
  justify-content: center;
}
.time-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
</style>
