import Validator from 'validator-v2'

const urlRegex = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
const domRegex = /^(?:(?:(?:[a-zA-z\-]+)\:\/{1,3})?(?:[a-zA-Z0-9])(?:[a-zA-Z0-9-\.]){1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+|\[(?:(?:(?:[a-fA-F0-9]){1,4})(?::(?:[a-fA-F0-9]){1,4}){7}|::1|::)\]|(?:(?:[0-9]{1,3})(?:\.[0-9]{1,3}){3}))(?:\:[0-9]{1,5})?$/


let password = {
  required: true,
  length: [4, 60]
}

let tmPixelValidator = new Validator({
  name: {
    required: true,
    minLen: 4,
    onError: {
      any: "Please fill in pixel name (min 4 chars)"
    }
  },
  url: {
    required: true,
    regexMatch: urlRegex,
    onError: {
      regexMatch: "Pixel URL is invalid",
      any: "Please fill in pixel URL"
    }
  }
})

let taboolaItem = new Validator({
  title: {
    required: true,
    length: [2,50],
    onError: {
      any: "Please enter an item title (2-50 chars) for creative"
    }
  },
  url: {
    required: true,
    url: true,
    onError: {
      any: "Please enter a valid url for creative"
    }
  }
})

let fbAd = new Validator({
  name: {
    required: true,
    length: [2,50],
    onError: {
      any: "Please enter an ad title (2-50 chars)"
    }
  },
  creative: new Validator({
    object_story_spec: new Validator({
      link_data: new Validator({
        link: {
          required: true,
          url: true,
          onError: {
            any: "Please enter a valid url for creative"
          }
        }
      })
    })
  })
})

let fns = {
  signup: new Validator({
    name: {
      required: true,
      length: [4, 60]},
    email: {
      required: true,
      email: true
    },
    password: password,
    password_confirmation: password,
    // recaptcha: {length: [6]}
  }, {
  }),
  login: new Validator({
    email: {
      required: true,
      email: true
    },
    password: password
  }),
  taboolaItems: new Validator({
    each: taboolaItem
  }),
  fbAds: new Validator({
    each: fbAd
  })
}

function bindCheck(what) {
  return what.check.bind(what)
}
export default {
  signup: bindCheck(fns.signup),
  login: bindCheck(fns.login),
  taboolaItems: bindCheck(fns.taboolaItems),
  fbAds: bindCheck(fns.fbAds)
}
