<template lang="html">
  <div class="">
    <CSV />
    <Generate />
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
