<template>
    <div class="container mt-5">
        <div class="modal_container">
            <b-modal v-model="modalShow" class="domain_modal" :id="modalId" size="md" @hide="onHide">
                <template id="bootstrap-overrides" #modal-header clas><span id="add-title"
                        v-if="operation === 'add'">Add new Facebook Page:</span>
                    <div class="edit-title" v-if="operation === 'edit'">
                        <span id="space-header">Edit Facebook Page</span>
                    </div>
                </template>
                <div class="modal-inputs">
                    <b-form-group label-cols="4" label-cols-lg="2" label="Page Id:" label-for="input-pageId">
                        <b-form-input id="input-pageId" type="text" v-model="currentPage.id"
                            :disabled="operation === 'edit'">
                        </b-form-input>
                    </b-form-group>
                    <b-form-group label-cols="4" label-cols-lg="2" label="Title:" label-for="input-title">
                        <b-form-input id="input-title" type="text" v-model="currentPage.title"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Active:" label-cols="2" label-for="check-active" class="mb-0">
                        <b-form-checkbox id="check-active" v-model="currentPage.active"></b-form-checkbox>
                    </b-form-group>
                </div>
                <div v-if="loading">
                    <span>Please wait...</span>
                </div>
                <template busy="true" v-slot:modal-footer>
                    <div class="footer-container" v-if="operation === 'edit'">
                        <b-button id="modal-del-btn" variant="danger" @click="deletePage(currentPage)">Delete</b-button>
                        <div class="unseen-element"></div>
                        <b-button variant="success" class="mr-2" @click="submitEditChanges(currentPage)">Save</b-button>
                        <b-button @click="cancel()">Cancel</b-button>
                    </div>
                    <div v-if="operation === 'add'">
                        <b-button class="mr-2" type="submit" variant="primary" @click="submitAddChanges(currentPage)">
                            Submit
                        </b-button>
                        <b-button @click="cancel()">
                            Cancel
                        </b-button>
                    </div>
                </template>
            </b-modal>
        </div>
    </div>
</template>  
<script>

import utils from "../../views/utils";
export default {
    props: {
        operation: String,
        currentPage: {},
        modalId: String,
        modalShow: Boolean,
        text: String,
    },
    data() {

        return {
            show: true,
            loading: false
        };
    },
    methods: {
        cancel() {
            this.$emit("update:modalShow", false);
        },
        onHide(event) {
            if (event.trigger === "backdrop") {
                this.$emit("update:modalShow", false);
                event.preventDefault();
                this.handleBackdrop();
            }
        },
        onSubmit(event) {
            event.preventDefault();
        },
        onReset(event) {
            event.preventDefault();
            this.modalName = "";
            utils.clearValidationState();
        },
        submitEditChanges: async function (page) {
            try {
                this.loading = true;
                await this.$http.post(this.resources.cs.updateFacebookPage, JSON.stringify(page));
                this.$emit("update:modalShow", false);
                this.$emit("update:currentPage", {});

                this.notifySuccess(`Page has been edited`);

            } catch (err) {

                this.notifyError("Could not edit page.");
            } finally {
                this.loading = false;
            }
        },
        submitAddChanges: async function (page) {
            if (page.id == "") {
                this.notifyError("New page must have an id");
                return;
            }
            if (page.title == "") {
                this.notifyError("New page must have a title");
                return;
            }
            try {
                this.loading = true;
                await this.$http.post(this.resources.cs.addFacebookPage, JSON.stringify(page));
                this.$emit("update:modalShow", false);
                this.$emit("update:currentPage", {});

                this.notifySuccess(
                    `New Page '${page.title}' has been created`
                );
            } catch (err) {

                this.notifyError("Could not insert page.");
            } finally {
                this.loading = false;
            }
        },
        deletePage: async function (page) {
            try {
                this.loading = true;
                const r = await this.$http.post(this.resources.cs.deleteFacebookPage(page.id));
                this.$emit("update:modalShow", false);
                this.$emit("update:currentPage", {});

                this.notifySuccess(`Page ${page.title} has been deleted`);
            } catch (err) {

                this.notifyError(`Could not delete page ${page.title}.`);
            }
            finally {
                this.loading = false;
            }
        },
    },
}
</script>
  
<style scoped>
.unseen-element {
    width: 60%;
}

.modal_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 20px;
    column-gap: 10px;
}

.footer-container {
    width: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#space-header {
    padding-right: 10px;
}
</style>
  