<template lang="html">
  <div class="" v-if='ad'>
    <br>
    <h3>Ad "{{ad.name}}"</h3>

    <ReportForm :id='adId' level="ad"></ReportForm>
  </div>
</template>

<script>
import ReportForm from './shared/ReportForm'

export default {
  props: {
    adset: {}
  },
  components: {
    ReportForm
  },
  data() {
    return {
      ad: null,
      adReport: null
    }
  },
  computed: {
    adId() {
      return this.$route.params.adId
    }
  },
  watch: {
    adId() {
      this.updateAd()
    }
  },
  methods: {
    updateAd() {
      if(!this.adset) {
        return
      }
      this.ad = this.adset.ads.find(v => v.id == this.adId)
    }
  },
  created() {
    this.updateAd()
  }
}
</script>

<style lang="css" scoped>
.report {
  border: 1px solid gainsboro;
  padding: 8px;
  border-radius: 2px;
  background: whitesmoke;
  display: flex;
  flex-wrap: wrap;
}
.sec-312 {
  font-size: 24px;
  display: flex;
  margin-right: 15px;
  width: 30%;
}
.sec-312 span:first-child {

}
.sec-312 span:nth-child(2) {

}
</style>
