<template lang="html">
  <div class="add-user">
    <b-button
      id="plus-btn"
      size="sm"
      variant="outline-primary"
      class="mb-2"
      v-if="userHasPermission('users')"
      @click="addNewUser"
    >
      <b-icon font-scale="3" icon="plus" aria-label="Help"></b-icon>
    </b-button>
    <div class="sl-list" v-if="users">
      <div v-if="users">
        <b-table
          id="bootsrap-table"
          sticky-header="700px"
          striped
          hover
          :fields="fields"
          :items="users"
          fixed
          responsive
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template v-slot:head()="data">
            <span
              id="tooltip-bootsrap-template"
              v-b-tooltip.hover
              :title="data.field.tooltip"
            >
              {{ data.label }}
            </span>
          </template>
          <template v-slot:cell(edit)="data" class="edit-tab">
            <button id="edit_btn" @click="editUserItem(data.item)">
              <b-icon icon="pencil"></b-icon>
            </button>
          </template>

          <template v-slot:cell(roles)="data" class="badge-roles">
            <b-badge
              v-for="item in data.item.Roles"
              :key="item"
              variant="info"
              >{{ item.name }}</b-badge
            >
          </template>
        </b-table>
      </div>

      <Modal
        class="modal-edit"
        :modalShow.sync="modalShowEdit"
        :status.sync="currentUser.status"
        :operation="'edit'"
        v-if="modalShowEdit"
        :currentUser.sync="currentUser"
        :selectedRolesObj="currentUser.Roles"
        :text.sync="modalText"
      ></Modal>
      <Modal
        class="modal-add"
        :modalShow.sync="modalShowAdd"
        :status.sync="currentUser.status"
        :operation="'add'"
        v-if="modalShowAdd"
        :currentUser.sync="currentUser"
        :date="''"
        :text="''"
      ></Modal>
    </div>
  </div>
</template>

<script>
const DOMAIN_STATUS_ACTIVE = "ACTIVE";
const DOMAIN_STATUS_PAUSED = "PAUSED";

import UserForm from "@/components/shared/UserForm";
import Modal from "../../components/shared/modalUsersManagment";

export default {
  components: {
    UserForm,
    Modal,
  },
  data() {
    return {
      fields: [
        {
          key: "Edit",
          thStyle: {
            width: "5%",
          },
        },
        {
          key: "id",
          sortable: true,
          tooltip: "Id",
          thStyle: {
            width: "5%",
          },
        },
        {
          key: "name",
          sortable: true,
          tooltip: "Name",
          thStyle: {
            width: "10%",
          },
        },
        {
          key: "email",
          sortable: true,
          tooltip: "email",
          thStyle: {
            width: "20%",
          },
        },
        {
          key: "status",
          sortable: true,
          tdClass: (value) => {
            if (value == "ACTIVE") {
              return "text-green";
            } else {
              return "text-red";
            }
          },
          tooltip: "Status",
          thStyle: {
            width: "10%",
          },
        },
        {
          key: "roles",
          sortable: true,
          tooltip: "user current roles available",
        },
      ],
      isBusy: false,
      currentUser: {},
      modalShowEdit: false,
      modalShowAdd: false,
      performSubmit: 0,
      modalText: "",
      modalName: "",
      users: null,
      isLoading: false,
      item: null,
      updateUsers: 0,
      sort: {
        field: "status",
        asc: true,
      },
      selectedUser: null,
      newUserForm: {
        name: null,
        email: null,
        password: null,
      },
    };
  },
  watch: {
    currentUser(newVal, oldVal) {
      if (this.modalShowAdd == false && this.modalShowEdit == false) {
        this.isBusy = !this.isBusy;
        this.getData();
      }
    },
    item(val) {
      this.selectedUser = val;
    },
  },
  beforeUpdate() {
    this.sortReport();
  },
  created: async function() {
    try {
      const r = await this.$http.get(this.resources.Admin.users);
      const data = await r.json();
      this.users = data;
    } catch (err) {
      this.notifyError("Could not fetch users");
    }
  },
  mounted() {
    setInterval(this.getdata, 60000);
  },
  destroyed() {
    clearInterval(this.intervalData);
  },
  methods: {
    getData: async function() {
      try {
        const r = await this.$http.get(this.resources.Admin.users);
        const data = await r.json();
        this.users = data;
        this.isBusy = !this.isBusy;
      } catch (err) {
        this.notifyError("Could not fetch users");
      }
    },
    addNewUser() {
      this.modalShowAdd = !this.modalShowAdd;
      this.currentUser = {
        status: DOMAIN_STATUS_ACTIVE,
        id: 0,
        date: "",
        name: "",
        password: "",
        hashed_password: "",
        email: "",
        blocked: false,
        perm: [],
        roles: [],
        Roles: [],
      };
    },
    sortReport() {
      let users = this.users;
      let sort = this.sort;
      if (!sort.field) return;

      let sortField = sort.field;
      users.sort((a, b) => {
        if (!["status"].includes(sortField)) {
          a = a.stats;
          b = b.stats;
        }
        a = a[sortField] || 0;
        b = b[sortField] || 0;

        let f;
        if (a > b) {
          f = 1;
        } else if (a < b) {
          f = -1;
        } else {
          f = 0;
        }
        if (!sort.asc) {
          f = f * -1;
        }
        return f;
      });
    },
    editUserItem(user) {
      this.currentUser = user;
      this.modalShowEdit = !this.modalShowEdit;
      this.modalText = ` ${user.name}  ?`;
    },
  },
};
</script>

<style lang="css" scoped>
#edit_btn {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
</style>
