<template lang="html">
  <div class="ReportFormTemplate">
    <Spinner v-if="loadingPage" />
    <template v-else>
      <ReportForm :accountId="accountId"></ReportForm>
    </template>
  </div>
</template>

<script>
import ReportForm from "./shared/ReportForm.twitter";

const FB_SOURCE = "FB";
const TWITTER_SOURCE = "TWITTER";

export default {
  props: {
    source: {
      default: FB_SOURCE,
    },
  },
  components: {
    ReportForm,
  },
  data() {
    return {
      fbData: null,
      adsets: null,
      adsetWizardOpened: false,
      loadingPage: false,
    };
  },
  created() {
    this.fbData = this.$store.state.data.fbData;
    this.adsets = this.$store.state.data.fbAdsets;
  },
  computed: {
    accountId() {
      return this.$route.params.accountId;
    },
  },
  watch: {
    accountId() {
      this.reloadPageVisual();
    },
  },
  methods: {
    reloadPageVisual() {
      this.loadingPage = true;
      window.setTimeout(() => {
        this.loadingPage = false;
      }, 350);
    },
    openAdsetWizard() {
      this.adsetWizardOpened = true;
    },
    navigateToAdset(adset) {},
  },
};
</script>

<style lang="css" scoped>
.list12 {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  font-size: smaller;
  border-top: 1px solid gainsboro;
  border-left: 1px solid gainsboro;
}
.data123 {
  font-size: 26px;
}

.data123 span {
  padding-left: 5px;
  padding-top: 7px;
}
.data123 span:hover {
  cursor: pointer;
  background: #e8e8e8;
}
.data123 li {
  display: flex;
  flex-direction: column;
}
</style>
