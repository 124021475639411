<template lang="html">
  <form class="_row" @submit.prevent="formSubmit">
    <div class="_row">
      <div>
        <template slot="card-title">
          User Details {{ form.id ? `(ID: ${form.id})` : "" }}
        </template>
        <FormField
          placeholder="Enter name"
          :value="form.name"
          :onUpdate="
            (e) => {
              form.name = e;
            }
          "
          labelText="Contact Name"
        />
        <FormField
          type="email"
          placeholder="Enter email"
          :value="form.email"
          :onUpdate="
            (e) => {
              form.email = e;
            }
          "
          labelText="Email Address"
        />
        <template v-if="!editUser">
          <FormField
            type="email"
            placeholder="Enter password"
            :value="form.password"
            :onUpdate="
              (e) => {
                form.password = e;
              }
            "
            labelText="Password"
          />
          <FormField
            type="password"
            placeholder="Repeat password"
            :value="form.repeat_password"
            :onUpdate="
              (e) => {
                form.repeat_password = e;
              }
            "
            labelText="Repeat Password"
          />
        </template>
      </div>

      <div v-if="editUser">
        <template slot="card-title">Change Password</template>
        <FormField
          type="password"
          placeholder="Enter password"
          labelText="Password"
          :onUpdate="
            (e) => {
              form.password = e;
            }
          "
        />
        <FormField
          type="password"
          placeholder="Enter password"
          labelText="Repeat Password"
          :onUpdate="
            (e) => {
              form.repeat_password = e;
            }
          "
        />
      </div>

      <div v-show="display.roles">
        <template slot="card-title">Roles</template>
        <CheckList
          :onChange="
            (v) => {
              form.roles = v;
            }
          "
          :items="rolesList.items"
          :values="form.roles"
        />
      </div>
    </div>

    <div class="extra-details-card" v-if="0">
      <!--  HIDDEN -->
      <template slot="card-title">Extra Details</template>
      <form>
        <FormField
          placeholder="Enter company name"
          :value="form.company_name"
          :onUpdate="
            (e) => {
              form.company_name = e;
            }
          "
          labelText="Company Name"
        />
        <FormField
          placeholder="Enter skype username"
          :value="form.skype"
          :onUpdate="
            (e) => {
              form.skype = e;
            }
          "
          labelText="Skype"
        />
        <FormField
          placeholder="Enter phone"
          :value="form.phone"
          :onUpdate="
            (e) => {
              form.phone = e;
            }
          "
          labelText="Phone"
        />
        <FormField
          placeholder="Enter office phone"
          :value="form.office_phone"
          :onUpdate="
            (e) => {
              form.office_phone = e;
            }
          "
          labelText="Office Phone"
        />
        <FormField
          placeholder="Select a country"
          labelText="Country"
          :def="false"
        >
          <select class="form-control" v-model="form.country">
            <option :value="null" disabled>Select country</option>
            <option :value="key" v-for="(country, key) in countriesList">{{
              country
            }}</option>
          </select>
        </FormField>
        <FormField
          placeholder="Enter city name"
          :value="form.city"
          :onUpdate="
            (e) => {
              form.city = e;
            }
          "
          labelText="City"
        />
        <FormField
          placeholder="Enter address"
          :value="form.address"
          :onUpdate="
            (e) => {
              form.address = e;
            }
          "
          labelText="Address"
        />
        <FormField
          placeholder="Enter website url"
          :value="form.website"
          :onUpdate="
            (e) => {
              form.website = e;
            }
          "
          labelText="Website"
        />
      </form>
    </div>
    <slot></slot>
    <div class="form-bottom-btns">
      <template slot="card-title">Roles</template>
      <MoveLists
        :assigned="userAssignedRoles"
        :all="rolesListObj"
        list-a-title="Assigned Roles"
        list-b-title="Not Assigned Roles"
      />
    </div>
    <div class="form-bottom-btns">
      <template slot="card-title">Controls</template>
      <div>
        <button
          v-show="btns.reset"
          type="button"
          class="btn btn-primary"
          @click="formReset"
        >
          Reset
        </button>
        <button v-show="btns.save" type="submit" class="btn btn-success">
          Save
        </button>
        <button
          v-show="btns.delete"
          type="submit"
          class="btn btn-danger"
          @click="formDelete"
        >
          Delete
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import * as act from "@/act-types";

export default {
  props: {
    btns: {
      default: (e) => ({ save: 1, reset: 1 }),
    },
    editUser: {},
    display: {
      default: (e) => ({
        roles: true,
      }),
    },
    onSubmit: {
      required: true,
    },
    onReset: {},
    onDelete: {},
    performSubmit: {},
  },
  name: "UserForm",
  data() {
    return {
      form: {
        name: null,
        email: null,
        password: null,
        repeat_password: null,
        company_name: null,
        skype: null,
        phone: null,
        office_phone: null,
        country: null,
        city: null,
        address: null,
        website: null,
        roles: [],
      },
      htmlId: null,
      countriesList: null,
      rolesList: [],
      rolesListObj: [],
      userAssignedRoles: [],
      userNonAssignedRoles: [],
    };
  },
  methods: {
    fillFormForUser() {
      let editUser = this.editUser;
      let roles = editUser.roles;
      Object.assign(this.form, editUser, { roles });
    },
    formSubmit() {
      let form = this.form;
      form.roles = Object.keys(this.userAssignedRoles).map((v) => Number(v));
      this.onSubmit(form);
    },
    formDelete() {
      this.onDelete();
    },
    formReset() {
      let form = this.form;
      if (this.editUser) {
        this.fillFormForUser();
      } else {
        form.nullify();
      }
      this.onReset && this.onReset();
    },
    getUserAssignedRoles() {
      let obj = {};
      let userRoles = this.form.roles;
      let rolesList = this.rolesList;
      if (!userRoles) {
        return [];
      }
      userRoles.forEach((v) => {
        obj[v] = rolesList.find((e) => e.id === v).name;
      });
      return obj;
    },
  },
  watch: {
    editUser() {
      this.fillFormForUser();
      this.userAssignedRoles = this.getUserAssignedRoles();
    },
  },
  computed: {},
  created() {
    this.$store.dispatch("getRolesList").then((rolesObject) => {
      this.rolesList = rolesObject;

      let obj = {};
      this.rolesList.forEach((v) => {
        obj[v.id] = v.name;
      });
      this.rolesListObj = obj;

      this.userAssignedRoles = this.getUserAssignedRoles();
    });
    if (this.editUser) {
      this.fillFormForUser();
    }
    this.$store.dispatch(act.GET_NEXT_ID).then((id) => {
      this.htmlId = id;
    });
  },
};
</script>

<style>
.extra-details-card .form-group label {
  width: 97px;
}
</style>
<style lang="css">
.pubids-generate-group {
  display: flex;
}
.extra-details-card form {
  display: flex;
  flex-wrap: wrap;
}
.extra-details-card .form-group {
  width: 46%;
  display: flex;
  margin-right: 15px;
}
._row {
  display: flex;
  flex-wrap: wrap;
}
._row > * {
  margin-right: 16px;
}
.form-bottom-btns {
  width: 100%;
}
</style>
