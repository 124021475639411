<template lang="html">
  <div class="">
    <h3>Outbrain Report</h3>
    <section class="report-form">
      <div class="">
        <span>Choose Account:</span>
        <ul v-show='allAccounts' class="accounts-list">
          <li :class='{active: selectedAccount===null}' @click='selectAccount(null)'>All</li>
          <li :class='{active: selectedAccount===account}' v-for='account in allAccounts' @click='selectAccount(account)'>
            {{account.name}}
          </li>
        </ul>
      </div>

      <div class="date-range-3">
        <span class="s-151">Choose Date Range:</span>
        <div class="">

          <div class="div-15">
            <i class="active-circle" v-show='selectedDateRange'></i>
            <ul class="date-range-options-list">
              <li v-for='v in dateRangeOptions' @click='setFixedDateRange(v)' :class='{active: v === selectedDateRange}'>{{v}}</li>
            </ul>
          </div>

          <div class="">
            <div class="div-15">
              <i class="active-circle" v-show='!selectedDateRange'></i>
              <span>Or custom date range:</span>
            </div>
            <div class="date-range-241">
              <DatePicker :onChange='v => { form.start_dt = v; selectedDateRange = null } ' :value='form.start_dt' />
              <DatePicker :onChange='v => { form.end_dt = v; selectedDateRange = null } ' :value='form.end_dt' />
            </div>
          </div>
        </div>
      </div>

      <div class="search-box-container date-range-3">
        <span class="s-5231">Search</span>
        <div class="">
          <label for="">Search adgroup:</label>
          <input v-model='searchForm.adgroup' class="def">
        </div>

        <div class="">
          <label for="">Campaign Status: </label>
          <select class="def" v-model='searchForm.status' @change='filterReport'>
            <option value="ALL">ALL</option>
            <option value="RUNNING">ACTIVE</option>
            <option value="PAUSED">PAUSED</option>
          </select>
        </div>
      </div>

      <div class="search-box-container date-range-3">
        <FilteringForm :filteringForm='filteringForm' :fields='filterableFields' />
      </div>

      <div class="b-2412">
        <span class="s-325">Metrics:</span>

        <ul class="metrics-list">
          <li class="metric-item" v-for='metric, metricKey in fields'>
            <label :for='metricKey'>{{metric.title}}</label>
            <input v-model='metrics' :id='metricKey' :value="metricKey" type="checkbox">
          </li>
        </ul>
      </div>

      <!-- <div class="b-2412" v-show='0'>
        <span class="s-325">Breakdowns:</span>

        <ul class="breakdowns-list">
          <li class="">
            <label for="">Date</label>
            <input v-model='form.breakdowns' value="date" type="checkbox">
          </li>
          <li class="">
            <label for="">Hour</label>
            <input v-model='form.breakdowns' value="hour" type="checkbox">
          </li>
        </ul>
      </div> -->

      <br>
      <button type="button" class="btn btn-success" @click='fetchReport' :disabled='processingReport'>
        Run Report
        <Spinner :def='0' v-show='processingReport' />
      </button>
    </section>
    <br>
    <div v-if='reportDataFiltered' class="report-table">
      <ul class="adsets-list">
        <li class="row12 header-row header-row-after">
          <span class="external-link-td"></span>
          <span class="row-name">Name(ID)</span>
          <span class="status-column">Status</span>
          <span v-for='_, f in fields' v-show='metrics.includes(f)' @click='onColumnClick(f)'
           class="sortable-column" :class='{active: sort.field === f}'>
            {{fields[f].title}}
          </span>
        </li>
        <li class="row12 sum-row sum-row-ob">
          <span class="external-link-td"></span>
          <span class="row-name">{{sumReport["adgroupsCount"]}} Adgroups</span>
          <span class="status-column"></span>
          <span v-for='_, f in fields' v-show='metrics.includes(f)' :class='{noafter: sumReport[f] === undefined, percent: fields[f].after==="%", dollar: fields[f].after==="$"}'>
            {{sumReport[f] | ifFloatDecimalN(fields[f].decimalDigits || 4)}}
          </span>
        </li>
        <template v-for='adgroup in reportDataFiltered.adgroups'>
          <li class="row12 row12-th value-row">
            <span class="external-link-td">
              <a :href='getAdsetExternalLink(adgroup)' target="_blank">
                <i class="fa fa-external-link ext-link-to-platform" aria-hidden="true"></i>
              </a>
            </span>
            <span class="row-name">{{adgroup.name}}({{adgroup.id}})</span>
            <span :class='{"status-column": true}'>
              <i @click='toggleAdsetStatus(adgroup)' v-if='adgroup.enabled' class="toggle-icon fa fa-toggle-on" aria-hidden="true"></i>
              <i @click='toggleAdsetStatus(adgroup)' v-else class="toggle-icon fa fa-toggle-off" aria-hidden="true"></i>
            </span>
            <span v-for='_, f in fields' v-show='metrics.includes(f)' :class='{noafter: adgroup[f] === undefined, percent: fields[f].after==="%", dollar: fields[f].after==="$"}'>
              <span class="edit-column-btn" v-if='userHasPermission("outbrain.edit") && fields[f].editable' @click='openEditPopup(adgroup, f)'>
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
              </span>
              {{adgroup[f] | ifFloatDecimalN(fields[f].decimalDigits || 4)}}</span>
          </li>
        </template>
      </ul>

      <ScreenBlocker :onClose='e => editAdsetPopup.adset=null' v-if='editAdsetPopup.adset'>
        <div class="edit-adset-popup">
          <div class="edit-adset-adset-635">
            <span class="edit-adset-name-626">
              {{editAdsetPopup.adset.name}}
            </span>
          </div>
          <form class="edit-adset-form-line-24" @submit.prevent='confirmAdsetEdit'>
            <label for="">{{fields[editAdsetPopup.field].title}}:</label>
            <input class="def" type="number"
            :step='fields[editAdsetPopup.field].decimalDigits ? "0.001" : "0"'
            v-model.number='editAdsetPopup.value'>
            <button type="submit" :disabled='ongoingActions.editAdset[editAdsetPopup.adset.id]' class="btn btn-primary">Save</button>
          </form>
        </div>
      </ScreenBlocker>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import FilteringForm from '@/views/reports/shared/FilteringForm.reports'
export default {
  props: {
    accountId: {
      required: true
    }
  },
  components: {
    FilteringForm
  },
  data() {
    let dtNow = new Date()
    let fields = {
      "created_at_formated": {
        title: "Created At"
      },
      "camp_daily_budget": {
        title: "Daily Budget",
        editable: true,
        after: '$'
      },
      "active_users": {
        title: "Active Users"
      },
      "bid_amount": {
        title: "Bid",
        decimalDigits: 3,
        editable: true,
        after: '$'
      },
      "clicks": {
        title: "Clicks"
      },
      "cpc": {
        title: "CPC",
        decimalDigits: 3,
        after: '$'
      },
      "ctr": {
        title: "CTR",
        decimalDigits: 3,
        after: '%'
      },
      "admanager_ecpm": {
        title: "AM ECPM",
        decimalDigits: 2,
        after: '$'
      },
      "spend": {
        title: "Cost",
        decimalDigits: 2,
        after: '$'
      },
      "revenue": {
        title: "Revenue",
        decimalDigits: 2,
        after: '$'
      },
      "admanager_rev": {
        title: "AM Rev",
        decimalDigits: 2,
        after: '$'
      },
      "primis_rev": {
        title: "Primis Rev",
        decimalDigits: 2,
        after: '$'
      },
      "aniview_rev": {
        title: "Anv Rev",
        decimalDigits: 2,
        after: '$'
      },
      "aniview_imps": {
        title: "Anv Imps"
      },
      "avantis_rev": {
        title: "Avantis Rev",
        decimalDigits: 2,
        after: '$'
      },
      "avantis_imps": {
        title: "Avantis Imps"
      },
      "vidazoo_rev": {
        title: "Vidazoo Rev",
        decimalDigits: 2,
        after: '$'
      },
      "vidazoo_imps": {
        title: "Vidazoo Imps"
      },
      "roi": {
        title: "ROI",
        decimalDigits: 2,
        after: '%'
      },
      "profit_and_loss": {
        title: "P&L",
        after: '$'
      },
      "user_value": {
        title: "UV",
        after: '$'
      },
      "bounce_rate": {
        title: "Bounce Rate",
        decimalDigits: 2,
        after: '%'
      },
      "page_views_per_session": {
        title: "Pages / Session",
        decimalDigits: 2
      },
      "avg_session_duration": {
        title: "Avg. Session Duration"
      }
    }

    let filterableFields = Object.assign({
      "created_at": {
        title: "Created At"
      }
    }, fields)
    delete filterableFields["created_at_formated"]

    return {
      processingReport: false,
      form: {
        start_dt: dtNow,
        end_dt: (new Date()),
        breakdowns: []
      },
      filteringForm: [],
      filterableFields,
      searchForm: {
        adgroup: null,
        ad: null,
        status: "ALL"
      },
      sort: {
        field: "clicks",
        asc: false
      },
      editAdsetPopup: {
        adset: null,
        field: null,
        value: null
      },
      selectedAccount: null,
      allAccounts: null,
      selectedDateRange: "",
      openAdgroups: {},
      submitedBreakdowns: [],
      reportData: null,
      reportDataFiltered: null,
      ongoingActions: {
        toggleAdsetStatus: {},
        editAdset: {}
      },
      metrics: [
        "camp_daily_budget",
        "active_users",
        "bid_amount",
        "cpc",
        "ctr",
        "clicks",
        "spend",
        "revenue",
        "roi",
        "profit_and_loss",
        "user_value"
      ],
      fields,
      dateRangeOptions: [
        "Today",
        "Yesterday",
        "Last 7 days",
        "Last 30 days",
        "This Month",
        "Last Month"
      ]
    }
  },
  watch: {
    ['searchForm.adgroup'](val) {
      this.filterReport()
    },
    ['searchForm.ad'](val) {
      this.filterReport()
    }
  },
  computed: {
    sumReport() {
      let adgroups = this.reportDataFiltered.adgroups
      let totalReport = this.getTotalReport(adgroups)

      return Object.assign({}, totalReport, {
        adgroupsCount: this.getAdgroupsCount(adgroups),
        adsCount: this.getAdsCount(adgroups)
      })
    }
  },
  methods: {
    getAdsetExternalLink(adset) {
      let today = moment()
      let d1 = today.format("YYYYMMDD")
      return `
      https://my.outbrain.com/amplify/site/marketers/${adset.advertiser_id}/reports/campaigns?campaignId=${adset.id}&from=${d1}&to=${d1}
      `
    },
    openEditPopup(adset, f) {
      this.editAdsetPopup = {
        adset: adset,
        field: f,
        value: adset[f]
      }
    },
    confirmAdsetEdit() {
      let ef = this.editAdsetPopup
      let adset = ef.adset

      if(this.ongoingActions.editAdset[adset.id]) {
        return
      }

      this.$set(this.ongoingActions.editAdset, adset.id, true)

      window.setTimeout(() => {
        this.$set(this.ongoingActions.editAdset, adset.id, false)
      }, 1200)

      let params = {
        [ef.field]: ef.value,
        budget_id: adset.budget_id
      }

      this.$http.put(this.resources.outbrain.campaign(adset.id), null, {params})
        .then(() => {
          adset[ef.field] = ef.value
          this.closeEditPopup()
        })
        .catch(err => {
          this.notifyError("An error has occured")
        })
        .then(() => {
          this.ongoingActions.editAdset[adset.id] = false
        })
    },
    closeEditPopup() {
      this.editAdsetPopup = {
        adset: null,
        field: null,
        value: null
      }
    },
    toggleAdsetStatus(adset) {
      if(!this.userHasPermission("outbrain.edit")) {
        return
      }
      if(this.ongoingActions.toggleAdsetStatus[adset.id]) {
        return
      }

      this.ongoingActions.toggleAdsetStatus[adset.id] = true

      let status = adset.status
      let enabled = adset.enabled
      let toStatus

      if(status.toLowerCase().includes("rejected")) {
        this.notifyError("Can not change campaign status")
        return
      }

      if(enabled) {
        toStatus = "CAMPAIGN_DISABLED"
        enabled = false
      } else {
        toStatus = "CAMPAIGN_ON_AIR"
        enabled = true
      }

      let params = {
        enabled: enabled
      }

      this.$http.put(this.resources.outbrain.campaign(adset.id), null, {params})
        .then(() => {
          window.setTimeout(() => {
            adset.status = toStatus
            adset.enabled = enabled
            this.ongoingActions.toggleAdsetStatus[adset.id] = false
          }, 20)
        })
        .catch(err => {
          this.notifyError("An error has occured")
          this.ongoingActions.toggleAdsetStatus[adset.id] = false
        })
    },
    selectAccount(account) {
      this.selectedAccount = account
    },
    fetchData() {
      return this.$http.get(this.resources.Reports.outbrainData)
        .then(r => r.json())
        .then(data => {
          let accounts = data && data.accounts
          if(!accounts || accounts.length === 0) {
            return
          }
          this.allAccounts = accounts
        })
    },
    onColumnClick(field) {
      if(this.sort.field === field) {
        this.sort.asc = !this.sort.asc
      } else {
        this.sort.field = field
        this.sort.asc = false
      }
      this.sortReport()
    },
    filterReport() {
      let adgroupSearch = this.searchForm.adgroup
      let adSearch = this.searchForm.ad
      let status = this.searchForm.status
      let reportData = this.reportData

      // if(!adgroupSearch && !adSearch) {
      //   this.reportDataFiltered = Object.assign({}, reportData)
      //   return
      // }

      let data = Object.assign({}, reportData)

      if(status == "RUNNING" || status == "PAUSED") {
        data.adgroups = data.adgroups.filter( v=> v.status === status)
      }

      if(adgroupSearch) {
        adgroupSearch = adgroupSearch.toLowerCase()
        data.adgroups = reportData.adgroups.filter( v=> v.id == adgroupSearch || v.name.toLowerCase().includes(adgroupSearch))
      }

      // if(adSearch) {
      //   adSearch = adSearch.toLowerCase()
      //   data.adgroups = data.adgroups.filter(adgroup => {
      //     let f = false
      //     adgroup.ads && adgroup.ads.forEach(ad => {
      //       if(ad.id == adSearch || ad.name.toLowerCase().includes(adSearch)) {
      //         f = true
      //       }
      //     })
      //     return f
      //   })
      //
      //   this.openAdgroups = {}
      //   data.adgroups.forEach(v => {
      //     this.$set(this.openAdgroups, v.id, true)
      //   })
      // }

      let filteringForm = this.filteringForm

      data.adgroups = data.adgroups.filter( adset => {
        let passedFilters = true

        for(let i = 0; i < filteringForm.length; i++) {
          let fo = filteringForm[i]

          let field = fo.field
          let givenVal = adset[field]
          let requiredVal = fo.val
          let op = fo.operator

          if(!field || !requiredVal || !op) {
            continue
          }

          if(field === "created_at") {
            givenVal = givenVal.substr(0, givenVal.indexOf("T"))
          }

          if(op === '>') {
            passedFilters = givenVal > requiredVal
          } else if(op === '<') {
            passedFilters = givenVal < requiredVal
          } else {
            passedFilters = givenVal == requiredVal
          }

          if(!passedFilters) {
            break
          }
        }

        return passedFilters
      })

      this.reportDataFiltered = data
    },
    sortReport() {
      let data = this.reportDataFiltered
      if(!data || !data.adgroups) {
        return
      }

      let sort = this.sort
      if(!sort.field) {
        return
      }

      let sortField = sort.field
      if(sort.field === "created_at_formated") {
        sortField = "created_at"
      }

      data.adgroups.sort((a, b) => {
        a = a[sortField]
        b = b[sortField]

        let f
        if(a > b) {
          f = 1
        } else if(a < b) {
          f = -1
        } else {
          f = 0
        }

        if(!sort.asc) {
          f = f * -1
        }

        return f
      })
    },
    getTotalReport(adgroups) {
      let r = {
        // inline_link_clicks:0,
        camp_daily_budget:0,
        impressions:0,
        clicks:0,
        spend:0,
        revenue: 0,
        profit_and_loss: 0,
        roi: 0,
        ctr: 0,
        aniview_rev: 0,
        primis_rev: 0,
        am_rev: 0,
        aniview_imps: 0,
        admanager_rev: 0,
        avantis_rev: 0,
        avantis_imps: 0,
        vidazoo_rev: 0,
        vidazoo_imps: 0
      }

      adgroups.forEach(adgroup => {
        // r.daily_budget_float += adgroup.daily_budget_float
        r.impressions += adgroup.impressions
        r.clicks += adgroup.clicks
        r.spend += adgroup.spend
        r.revenue += adgroup.revenue
        r.aniview_rev += adgroup.aniview_rev
        r.primis_rev += adgroup.primis_rev
        r.am_rev += adgroup.am_rev
        r.aniview_imps += adgroup.aniview_imps
        r.admanager_rev += adgroup.admanager_rev
        r.camp_daily_budget += adgroup.camp_daily_budget
        r.avantis_rev += adgroup.avantis_rev
        r.avantis_imps += adgroup.avantis_imps
        r.vidazoo_rev += adgroup.vidazoo_rev
        r.vidazoo_imps += adgroup.vidazoo_imps

        // r.inline_link_clicks += adgroup.inline_link_clicks

       })

       if(r.clicks > 0) {
         r.user_value = r.revenue / r.clicks
       }

       if(r.spend > 0) {
         r.roi = (r.revenue / r.spend) * 100
       }

       if(r.impressions > 0) {
         r.ctr = (r.clicks / r.impressions) * 100
       }

       r.profit_and_loss = r.revenue - r.spend

      if(r.clicks > 0) {
        r.cpc = r.spend / r.clicks
      }
      // if(r.inline_link_clicks > 0) {
      //   r.cost_per_inline_link_click = r.spend / r.inline_link_clicks
      // }
      return r
    },
    getAdsCount(adgroups) {
      if(!adgroups) {
        return 0
      }
      let c = 0
      adgroups.forEach(adgroup => { c += adgroup.ads ? adgroup.ads.length : 0})
      return c
    },
    getAdgroupsCount(adgroups) {
      if(!adgroups) {
        return 0
      }
      return Object.keys(adgroups).length
    },
    setFixedDateRange(val) {
      this.selectedDateRange = val
      let dtNow = new Date()
      let endDt, startDt
      let d = new Date()
      switch(val) {
        case "Today":
          startDt = dtNow
          endDt = d
          break

        case "Yesterday":
          d.setDate(dtNow.getDate()-1)
          startDt = d
          endDt = d
          break

        case "Last 7 days":
          d.setDate(dtNow.getDate()-7)
          startDt = d
          endDt = dtNow
          break

        case "Last 30 days":
          d.setDate(dtNow.getDate()-30)
          startDt = d
          endDt = dtNow
          break

        case "This Month":
          d.setDate(1)
          startDt = d
          endDt = dtNow
          break

          case "Last Month":
            let ms = new Date()
            let lms = new Date()
            ms.setDate(0)
            lms.setDate(0)
            lms.setDate(1)
            startDt = lms
            endDt = ms
            break
      }

      this.form.start_dt = startDt
      this.form.end_dt = endDt
    },
    isAdgroupOpen(id) {
        return this.openAdgroups[id]
    },
    toggleAdgroupExpand(id) {
      this.$set(this.openAdgroups, id, !this.openAdgroups[id])
    },
    arrangeReport(data) {
      let adgroups = data.adgroups

      for(let key in adgroups) {
        let adgroup = adgroups[key]
        adgroup.created_at_formated = moment(adgroup.created_at).format("DD/MM HH:mm")
      }
      return data
    },
    fetchReport() {
      let accountId = this.selectedAccount && this.selectedAccount.id
      if(!accountId) {
        accountId = ""
      }
      let params = {
        start_dt: moment(this.form.start_dt).format("YYYY-MM-DD"),
        end_dt: moment(this.form.end_dt).format("YYYY-MM-DD"),
        account_id: accountId
      }

      this.processingReport = true
      this.$http.get(this.resources.Reports.outbrainCampAllInsights, {params})
        .then(r => r.json())
        .then(data => {
          data.adgroups = data.adgroups || {}
          data = this.arrangeReport(data)
          data.adgroups = Object.values(data.adgroups)
          this.reportData = data
          this.filterReport()
          this.sortReport()
        })
        .catch(() => {})
        .then(() => {
          this.processingReport = false
        })
    }
  },
  created() {
    this.setFixedDateRange("Today")
    this.fetchData()
    // this.fetchReport()
  }
}
</script>

<style scoped>
  .sum-row-ob {
    margin-bottom: 47px;
  }
</style>
