export default {
  'en': 'English',
  'pt': 'Portuguese',
  'fr': 'French',
  'es': 'Spanish',
  'ar': 'Arabic',
  'de': 'German',
  'it': 'Italian',
  'ja': 'Japanese',
};
