export default {
  Auth: {
    login: "auth/login",
    logout: "auth/logout",
    signup: "",
  },
  Admin: {
    users: "admin/users",
    countries: "fb/countries",
    getUserInfo: (userID) => `admin/users/${userID}`,
    editUser: (userID) => `admin/users/${userID}`,
    deleteUser: userID => `admin/users/${userID}`,
    editPassword: userID => `admin/users/pass/${userID}`,
    getRolePerms: (roleID) => `admin/roles/${roleID}`,
    editRole: (roleID) => `admin/roles/${roleID}`,
    newRole: "admin/roles",
    deleteRole: roleID => `admin/roles/${roleID}`,
    
    newPerm: "admin/perms",
    newPreset: "admin/presets",
    deletePerm: (permId) => `admin/perms/${permId}`,
    editPerm:    (newPermId)=> `admin/perms/${newPermId}`,
    deletePreset: (presetId) => `admin/presets/${presetId}`,
    editPreset: (presetId) => `admin/presets/${presetId}`,
  },
  Reports: {
    fbAllData: "reports/fb/all",
    fbCampAllInsights:`${process.env.VUE_APP_CSHARP_API}facebook/reports/campaign/insights/`,
    fbLiveCampAllInsights: `reports/fb/campaign/live-insights`,
    twitterData: "reports/twitter/all",
    twitterCampAllInsights: `reports/twitter/campaign/insights`,
    taboolaData: "reports/taboola/all",
    taboolaCampAllInsights: `reports/taboola/campaign/insights`,
    outbrainData: "reports/outbrain/all",
    outbrainCampAllInsights: `reports/outbrain/campaign/insights`,
    fbAdInsights: (id) => `reports/fb/ad/${id}/insights`,
    fbAdsetInsights: (id) => `reports/fb/adset/${id}/insights`,
    sspRevenue: `reports/ssp`,
    performance: `reports/performance/overall`,
    csvReport: `reports/csv`,
    campaignNotifications: `reports/notifications`,
    numOfUnseenCampaignNotifications: `reports/last_seen_notifications`,
  },
  logs: {
    adsetChanges: (id) => `logs/adsets/${id}/changes`,
  },
  fb: {
    adsetUpdate: (id) => `fb/adsets/${id}`,                    
    adset: (account_id, adset_id) => `${process.env.VUE_APP_CSHARP_API}facebook/${account_id}/adsets/${adset_id}`,
    adsetBidChanges: (id) => `fb/adsets/${id}/bid-changes`,
    adsets: `fb/adsets`,
    targetingPresets: "fb/targeting-presets",
    createCampaign: (accountID) => `${process.env.VUE_APP_CSHARP_API}facebook/${accountID}/campaigns`,
  },
  twitter: {
    adset: (id) => `twitter/adsets/${id}`,
    adsetCSHARP: (id) => `${process.env.VUE_APP_CSHARP_API}twitter/adsets/${id}`,

    adsets: `twitter/adsets`,
    adsetsCSHARP: `${process.env.VUE_APP_CSHARP_API}twitter/adsets`,


    imageUpload: (accountID) => `twitter/adsets/imgUpload/${accountID}`,
    imageUploadCSHARP: (accountID) => `${process.env.VUE_APP_CSHARP_API}twitter/adsets/imgUpload/${accountID}`,

    createCampaign: (accountID) => `twitter/adsets/${accountID}`,
    createCampaignCSHARP: (accountID) => `${process.env.VUE_APP_CSHARP_API}twitter/adsets/${accountID}`,

    duplicateAdset: (accountID, id) => `twitter/${accountID}/adsets/${id}`,
    duplicateAdsetCSHARP: (accountID, id) => `${process.env.VUE_APP_CSHARP_API}twitter/${accountID}/adsets/${id}`, 
  },
  taboola: {
    campaignsUpdate: `taboola/campaigns`,
    campaign: (id) => `taboola/campaigns/${id}`,
    campaigns: (accountID) => `taboola/accounts/${accountID}/campaigns`,
    imageUpload: `taboola/images`,
  },
  outbrain: {
    campaign: (id) => `outbrain/campaigns/${id}`,
  },
  bot: {
    campaign: (id) => `outbrain/campaigns/${id}`,
  },
  cs: {
    csv: `${process.env.VUE_APP_CSHARP_API}csv`,
    allAccounts: (systemName) => `${process.env.VUE_APP_CSHARP_API}accounts/${systemName}`,
    getPermittedFacebookPages: `${process.env.VUE_APP_CSHARP_API}facebookpages/getPermittedFacebookPages`,    
    getAllFacebookPages: `${process.env.VUE_APP_CSHARP_API}facebookpages/getAllFacebookPages`,    
    addFacebookPage:`${process.env.VUE_APP_CSHARP_API}facebookpages/addFacebookPage`,
    deleteFacebookPage: (pageId)=>`${process.env.VUE_APP_CSHARP_API}facebookpages/deleteFacebookPage/${pageId}`,
    updateFacebookPage:`${process.env.VUE_APP_CSHARP_API}facebookpages/updateFacebookPage`,    
  },
};
