<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="7" lg="6" sm="10">
          <b-card no-body class="mx-4">
            <b-card-body class="p-4">
              <div v-if="tokenIsValid !== null">
                  <div v-if="tokenIsValid">
                    <form class="" @submit.prevent='submit'>
                      <h1>Recover your account</h1>
                      <p class="text-muted">Choose a new password</p>
                      <b-input-group class="mb-3">
                        <b-input-group-prepend>
                          <b-input-group-text><i class="icon-lock"></i></b-input-group-text>
                        </b-input-group-prepend>
                        <input type="password" class="form-control" placeholder="Password" v-model='form.password'>
                        <button class="btn btn-success" type="submit">Change password</button>
                      </b-input-group>
                    </form>
                  </div>
                  <div v-else>
                    <p>Bad Token</p>
                  </div>
              </div>

            <router-link :to="{name: 'log-in'}">Back to log in</router-link>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style scoped>
  p {
    margin-bottom: 1rem;
  }
  .logo-container {
    margin: 0 1.5rem;
    display: flex;
    justify-content: center;
    margin-bottom: 18px;
  }
</style>

<script>
export default {
  data() {
    return {
      form: {
        token: this.$route.params.token,
        password: null
      },
      tokenIsValid: null
    }
  },
  methods: {
    submit() {
      if (!this.form.password) {
        this.notifyError("Please fill in fields")
        return
      }
      this.sendForm()
        .then(r => {
          this.notifySuccess("Your password has changed")
          this.$router.push('/')
        })
        .catch(err =>{
          this.notifyError("Error occured")
        })
    },
    sendForm() {
      return this.$http.post('auth/resetlink', this.form)
    }
  },
  created() {
    this.sendForm()
      .then(() => {
        this.tokenIsValid = true
      })
      .catch(() => {
        this.tokenIsValid = false
      })
  }
}
</script>

<style lang="css">
</style>
