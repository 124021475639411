<template lang="html">
  <div class="facebookAccounts">
    <h1>here</h1>
    <ul>
      <li v-for="account in accounts">
        <router-link
          :to="{ name: 'FbAccount', params: { accountId: account.id } }"
          >{{ account.name }}</router-link
        >
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    let fbData = this.$store.state.data.fbData;
    let accounts = fbData && fbData.accounts;
    return {
      accounts: Object.values(accounts),
    };
  },
};
</script>

<style lang="css" scoped></style>
