var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"_row",on:{"submit":function($event){$event.preventDefault();return _vm.formSubmit($event)}}},[_c('div',{staticClass:"_row"},[_c('div',[_c('template',{slot:"card-title"},[_vm._v(" User Details "+_vm._s(_vm.form.id ? ("(ID: " + (_vm.form.id) + ")") : "")+" ")]),_c('FormField',{attrs:{"placeholder":"Enter name","value":_vm.form.name,"onUpdate":function (e) {
            _vm.form.name = e;
          },"labelText":"Contact Name"}}),_c('FormField',{attrs:{"type":"email","placeholder":"Enter email","value":_vm.form.email,"onUpdate":function (e) {
            _vm.form.email = e;
          },"labelText":"Email Address"}}),(!_vm.editUser)?[_c('FormField',{attrs:{"type":"email","placeholder":"Enter password","value":_vm.form.password,"onUpdate":function (e) {
              _vm.form.password = e;
            },"labelText":"Password"}}),_c('FormField',{attrs:{"type":"password","placeholder":"Repeat password","value":_vm.form.repeat_password,"onUpdate":function (e) {
              _vm.form.repeat_password = e;
            },"labelText":"Repeat Password"}})]:_vm._e()],2),(_vm.editUser)?_c('div',[_c('template',{slot:"card-title"},[_vm._v("Change Password")]),_c('FormField',{attrs:{"type":"password","placeholder":"Enter password","labelText":"Password","onUpdate":function (e) {
            _vm.form.password = e;
          }}}),_c('FormField',{attrs:{"type":"password","placeholder":"Enter password","labelText":"Repeat Password","onUpdate":function (e) {
            _vm.form.repeat_password = e;
          }}})],2):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.display.roles),expression:"display.roles"}]},[_c('template',{slot:"card-title"},[_vm._v("Roles")]),_c('CheckList',{attrs:{"onChange":function (v) {
            _vm.form.roles = v;
          },"items":_vm.rolesList.items,"values":_vm.form.roles}})],2)]),(0)?_c('div',{staticClass:"extra-details-card"},[_c('template',{slot:"card-title"},[_vm._v("Extra Details")]),_c('form',[_c('FormField',{attrs:{"placeholder":"Enter company name","value":_vm.form.company_name,"onUpdate":function (e) {
            _vm.form.company_name = e;
          },"labelText":"Company Name"}}),_c('FormField',{attrs:{"placeholder":"Enter skype username","value":_vm.form.skype,"onUpdate":function (e) {
            _vm.form.skype = e;
          },"labelText":"Skype"}}),_c('FormField',{attrs:{"placeholder":"Enter phone","value":_vm.form.phone,"onUpdate":function (e) {
            _vm.form.phone = e;
          },"labelText":"Phone"}}),_c('FormField',{attrs:{"placeholder":"Enter office phone","value":_vm.form.office_phone,"onUpdate":function (e) {
            _vm.form.office_phone = e;
          },"labelText":"Office Phone"}}),_c('FormField',{attrs:{"placeholder":"Select a country","labelText":"Country","def":false}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.country),expression:"form.country"}],staticClass:"form-control",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.form, "country", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":""},domProps:{"value":null}},[_vm._v("Select country")]),_vm._l((_vm.countriesList),function(country,key){return _c('option',{domProps:{"value":key}},[_vm._v(_vm._s(country))])})],2)]),_c('FormField',{attrs:{"placeholder":"Enter city name","value":_vm.form.city,"onUpdate":function (e) {
            _vm.form.city = e;
          },"labelText":"City"}}),_c('FormField',{attrs:{"placeholder":"Enter address","value":_vm.form.address,"onUpdate":function (e) {
            _vm.form.address = e;
          },"labelText":"Address"}}),_c('FormField',{attrs:{"placeholder":"Enter website url","value":_vm.form.website,"onUpdate":function (e) {
            _vm.form.website = e;
          },"labelText":"Website"}})],1)],2):_vm._e(),_vm._t("default"),_c('div',{staticClass:"form-bottom-btns"},[_c('template',{slot:"card-title"},[_vm._v("Roles")]),_c('MoveLists',{attrs:{"assigned":_vm.userAssignedRoles,"all":_vm.rolesListObj,"list-a-title":"Assigned Roles","list-b-title":"Not Assigned Roles"}})],2),_c('div',{staticClass:"form-bottom-btns"},[_c('template',{slot:"card-title"},[_vm._v("Controls")]),_c('div',[_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.btns.reset),expression:"btns.reset"}],staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.formReset}},[_vm._v(" Reset ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.btns.save),expression:"btns.save"}],staticClass:"btn btn-success",attrs:{"type":"submit"}},[_vm._v(" Save ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(_vm.btns.delete),expression:"btns.delete"}],staticClass:"btn btn-danger",attrs:{"type":"submit"},on:{"click":_vm.formDelete}},[_vm._v(" Delete ")])])],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }