import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

// Containers
import Full from "@/containers/Full";
import Admin from "@/views/admin/";
import Home from "@/views/Home";

// Views
import Auth from "@/views/auth/";

import Page404 from "@/views/Page404.vue";
import TestPage from "@/views/TestPage.vue";
import Unavailable from "@/views/Unavailable.vue";

import Store from "@/store/store";
const state = Store.state;
import Bot from "@/views/bot";
import Campaigns from "@/views/campaigns";
import Activity from "@/views/activity";
import Twitter from "@/views/twitter";
import Taboola from "@/views/taboola";
import Outbrain from "@/views/outbrain";
import moment from 'moment';
import RedirectComponent from "@/views/Redirect.vue";
import fb from "../views/fb";

let router = new Router({
  mode: "history",
  linkActiveClass: "open active",
  scrollBehavior: () => ({}),
  routes: [
    {
      path: "/auth",
      name: "Auth",
      redirect: "/auth/log-in",
      component: { render: (c) => c("router-view") },
      children: [
        {
          path: "log-in",
          name: "Login",
          component: Auth.Login,
          meta: { authed: false },
        },
        {
          path: "sign-up",
          name: "SignUp",
          component: Auth.SignUp,
          meta: { authed: false },
        },
        {
          path: "forgot-password",
          name: "ForgotPassword",
          component: Auth.ForgotPassword,
          meta: { authed: false },
        },
        {
          path: "resetlink/:token",
          name: "ResetLink",
          component: Auth.ResetLink,
        },
      ],
    },
    {
      path: "/",
      meta: {
        authed: true,
        skip: true,
      },
      component: Full,
      redirect: { name: "Home" },
      children: [
        {
          path: "home",
          name: "Home",
          component: Home,
        },
        {
          path: "admin",
          name: "Admin",
          meta: {
            permission: "users",
          },
          component: { render: (c) => c("router-view") },
          children: [
            {
              path: "edit-user",
              name: "AdminEditUser",
              component: Admin.EditUser,
            },
            {
              path: "edit-roles",
              name: "AdminEditRoles",
              component: Admin.EditRoles,
              meta: {
                permission: "roles"
              }
            },
            {
              path: "edit-permissions",
              name: "AdminEditPermissions",
              component: Admin.EditPermissions,
            },
            {
              path: "edit-presets",
              name: "AdminEditPresets",
              component: Admin.EditPresets,
            },
            {
              path:"fb-pages",
              name:"FBPages",
              component:fb.Pages,

            },
          ],
        },
        {
          path: "performance",
          name: "SSPReportsG",
          component: { render: (c) => c("router-view") },
          meta: {
            permission: "performance.view",
          },
          children: [         
            {
              name: "CommonReports",
              path: "reports",
              component: Activity.Reports.Report,
              meta: {
                permission: "performance.view",
              },
            }
          ],
        },
        {
          path: "fb",
          name: "FBCampaigns",
          meta: {
            permission: "fb.view",
          },
          component: { render: (c) => c("router-view") },
          children: [
            {
              name: "FbAccount",
              path: "reports",
              component: Campaigns.Reports,
              meta: {
                permission: "fb.view",
              },
            },

          ],
        },
        {
          path: "automation",
          name: "Automation",
          meta: {
            permission: "automation.view",
          },
          component: { render: (c) => c("router-view") },
          children: [
            {
              name: "AutomationRules",
              path: "rules",
              component: Bot,
              meta: {
                permission: "automation.view",
              },
            }
          ],
        },
        {
          path: "twitter",
          name: "TwitterCampaigns",
          meta: {
            permission: "twitter.view",
          },
          component: { render: (c) => c("router-view") },
          children: [
            {
              name: "TwitterAccount",
              path: "reports",
              component: Twitter.Reports,
              meta: {
                permission: "twitter.view",
              },
            },
          ],
        },
        {
          path: "taboola",
          name: "TaboolaCampaigns",
          meta: {
            permission: "taboola.view",
          },
          component: { render: (c) => c("router-view") },
          children: [
            {
              name: "TaboolaReport",
              path: "reports",
              component: Taboola.Report,
              meta: {
                permission: "taboola.view",
              },
            },
          ],
        },
        {
          path: "outbrain",
          name: "OutbrainCampaigns",
          meta: {
            permission: "outbrain.view",
          },
          component: { render: (c) => c("router-view") },
          children: [
            {
              name: "OutbrainReport",
              path: "reports",
              component: Outbrain.Report,
              meta: {
                permission: "outbrain.view",
              },
            },
          ],
        },
        {
          name: "Redirect",
          path: "/redirect",
          component: { render: (c) => c("router-view") },
          meta: {
            skip: true,
          },
        },
      ],
    },
    {
      path: "/test",
      component: TestPage,
    },
    {
      name: "Unavailable",
      path: "/unavailable",
      component: Unavailable,
      beforeEnter: (to, from, next) => {
        if (state.version !== "ERROR") {
          next("/");
        } else {
          next(true);
        }
      },
    },
    {
      path: "*",
      component: Page404,
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (state.version !== null) {
    return next(true);
  }
  Vue.http
    .get(`${process.env.VUE_APP_CSHARP_API}auth/version`)
    .then((r) => r.json())
    .then((r) => r.data)
    .then((data) => {
      state.version = data;
      next(true);
    })
    .catch((err) => {
      state.version = "ERROR";
      next({ name: "Unavailable" });
    });
});

router.beforeEach((to, from, next) => {
  if (state.user !== null) {
    return next(true);
  }
  Vue.http
    .get(`${process.env.VUE_APP_CSHARP_API}auth`)
    .then((r) => r.json())
    .then((data) => {
      state.user = data.user;
      if (data.token) {
        localStorage.setItem("token-2", data.token);
        window.setCookie("token-2", data.token, 365 * 10);
      }
    })
    .catch((err) => {
      state.user = false;
    })
    .then(() => {
      state.initialLoad = true;
      next(true);
    });
});
router.beforeEach((to, from, next) => {
  if (
    to.matched.some(
      (record) =>
        typeof record.meta.authed !== "undefined" &&
        record.meta.authed !== !!state.user
    )
  ) {
    return next({ name: state.user ? "Home" : "Login" });
  }
  next(true);
});

router.beforeEach((to, from, next) => {
  if (to.name==="Home")
  {
    const date =moment(String(new Date().toString())).format("YYYY-MM-DD");
    next({ name: "CommonReports", query: {report:"overall", start_dt:date,  end_dt:date, breakdowns:"date"}});    
  }    
  next(true);
});

export default router;
