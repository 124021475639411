export default {
"data": [
        {
            "name": "Afghanistan",
            "country_code": "AF",
            "location_type": "COUNTRIES",
            "targeting_value": "9ac7aa903ba29bd1",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Aland Islands",
            "country_code": "AX",
            "location_type": "COUNTRIES",
            "targeting_value": "33a212b5c4f9e952",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Albania",
            "country_code": "AL",
            "location_type": "COUNTRIES",
            "targeting_value": "82779978c58253a6",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Algeria",
            "country_code": "DZ",
            "location_type": "COUNTRIES",
            "targeting_value": "d73762e172ac7c37",
            "targeting_type": "LOCATION"
        },
        {
            "name": "American Samoa",
            "country_code": "AS",
            "location_type": "COUNTRIES",
            "targeting_value": "17f8f93731f3b9da",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Andorra",
            "country_code": "AD",
            "location_type": "COUNTRIES",
            "targeting_value": "0a51cbf77aee0006",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Angola",
            "country_code": "AO",
            "location_type": "COUNTRIES",
            "targeting_value": "e771af67815e87e8",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Anguilla",
            "country_code": "AI",
            "location_type": "COUNTRIES",
            "targeting_value": "efe591bd99ea52c0",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Antarctica",
            "country_code": "AQ",
            "location_type": "COUNTRIES",
            "targeting_value": "4d25f30d2fbf1463",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Antigua and Barbuda",
            "country_code": "AG",
            "location_type": "COUNTRIES",
            "targeting_value": "e0f46cd26fe3d713",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Argentina",
            "country_code": "AR",
            "location_type": "COUNTRIES",
            "targeting_value": "4d3b316fe2e52b29",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Armenia",
            "country_code": "AM",
            "location_type": "COUNTRIES",
            "targeting_value": "1315b8e69a2d1511",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Aruba",
            "country_code": "AW",
            "location_type": "COUNTRIES",
            "targeting_value": "fa07565662daf560",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Australia",
            "country_code": "AU",
            "location_type": "COUNTRIES",
            "targeting_value": "3f14ce28dc7c4566",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Austria",
            "country_code": "AT",
            "location_type": "COUNTRIES",
            "targeting_value": "30410557050f13a5",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Azerbaijan",
            "country_code": "AZ",
            "location_type": "COUNTRIES",
            "targeting_value": "efc23cd34689b068",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Bahamas",
            "country_code": "BS",
            "location_type": "COUNTRIES",
            "targeting_value": "b631437cf2f16804",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Bahrain",
            "country_code": "BH",
            "location_type": "COUNTRIES",
            "targeting_value": "e3e9c55876b99760",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Bangladesh",
            "country_code": "BD",
            "location_type": "COUNTRIES",
            "targeting_value": "9f4e63426a3f3da2",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Barbados",
            "country_code": "BB",
            "location_type": "COUNTRIES",
            "targeting_value": "80f128c34d2549c6",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Belarus",
            "country_code": "BY",
            "location_type": "COUNTRIES",
            "targeting_value": "333a5811d6b0c1cb",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Belgium",
            "country_code": "BE",
            "location_type": "COUNTRIES",
            "targeting_value": "78bfaf3f12c05982",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Belize",
            "country_code": "BZ",
            "location_type": "COUNTRIES",
            "targeting_value": "26bbb44363843596",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Benin",
            "country_code": "BJ",
            "location_type": "COUNTRIES",
            "targeting_value": "ee84ba0d0454651a",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Bermuda",
            "country_code": "BM",
            "location_type": "COUNTRIES",
            "targeting_value": "360981d61a4350f3",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Bhutan",
            "country_code": "BT",
            "location_type": "COUNTRIES",
            "targeting_value": "3a6b696db46e9e1d",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Bolivia",
            "country_code": "BO",
            "location_type": "COUNTRIES",
            "targeting_value": "c4320f726d222937",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Bonaire, Saint Eustatius and Saba",
            "country_code": "BQ",
            "location_type": "COUNTRIES",
            "targeting_value": "6469452a7c914c92",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Bosnia and Herzegovina",
            "country_code": "BA",
            "location_type": "COUNTRIES",
            "targeting_value": "7dc97830ee71b3cb",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Botswana",
            "country_code": "BW",
            "location_type": "COUNTRIES",
            "targeting_value": "8a927a7056322151",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Bouvet Island",
            "country_code": "BV",
            "location_type": "COUNTRIES",
            "targeting_value": "3c3046e2f25a7a09",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Brazil",
            "country_code": "BR",
            "location_type": "COUNTRIES",
            "targeting_value": "1b107df3ccc0aaa1",
            "targeting_type": "LOCATION"
        },
        {
            "name": "British Indian Ocean Territory",
            "country_code": "IO",
            "location_type": "COUNTRIES",
            "targeting_value": "88f6c97979c5c22d",
            "targeting_type": "LOCATION"
        },
        {
            "name": "British Virgin Islands",
            "country_code": "VG",
            "location_type": "COUNTRIES",
            "targeting_value": "7fe44a4ec805e4e2",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Brunei",
            "country_code": "BN",
            "location_type": "COUNTRIES",
            "targeting_value": "b4260f742fbc8d32",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Bulgaria",
            "country_code": "BG",
            "location_type": "COUNTRIES",
            "targeting_value": "1ef1183ed7056dc1",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Burkina Faso",
            "country_code": "BF",
            "location_type": "COUNTRIES",
            "targeting_value": "04ba472d56551ba1",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Burundi",
            "country_code": "BI",
            "location_type": "COUNTRIES",
            "targeting_value": "6286fa85f57c2aad",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Cambodia",
            "country_code": "KH",
            "location_type": "COUNTRIES",
            "targeting_value": "df4a2798d032e321",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Cameroon",
            "country_code": "CM",
            "location_type": "COUNTRIES",
            "targeting_value": "97cabb75f5a4787a",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Canada",
            "country_code": "CA",
            "location_type": "COUNTRIES",
            "targeting_value": "3376992a082d67c7",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Cape Verde",
            "country_code": "CV",
            "location_type": "COUNTRIES",
            "targeting_value": "910f8f064186be58",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Cayman Islands",
            "country_code": "KY",
            "location_type": "COUNTRIES",
            "targeting_value": "cc896d5380524db4",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Central African Republic",
            "country_code": "CF",
            "location_type": "COUNTRIES",
            "targeting_value": "12ff487cb5a05ff7",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Chad",
            "country_code": "TD",
            "location_type": "COUNTRIES",
            "targeting_value": "9d86cf820e7f9341",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Chile",
            "country_code": "CL",
            "location_type": "COUNTRIES",
            "targeting_value": "47a3cf27863714de",
            "targeting_type": "LOCATION"
        },
        {
            "name": "China",
            "country_code": "CN",
            "location_type": "COUNTRIES",
            "targeting_value": "4797714c95971ac1",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Christmas Island",
            "country_code": "CX",
            "location_type": "COUNTRIES",
            "targeting_value": "be13fe9d62b80d9e",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Cocos (Keeling) Islands",
            "country_code": "CC",
            "location_type": "COUNTRIES",
            "targeting_value": "7766f18018c690dc",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Colombia",
            "country_code": "CO",
            "location_type": "COUNTRIES",
            "targeting_value": "0639360bd49a15e3",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Comoros",
            "country_code": "KM",
            "location_type": "COUNTRIES",
            "targeting_value": "133b43ff55d804f4",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Cook Islands",
            "country_code": "CK",
            "location_type": "COUNTRIES",
            "targeting_value": "21862bdb3b42ea33",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Costa Rica",
            "country_code": "CR",
            "location_type": "COUNTRIES",
            "targeting_value": "e44e7dc5a2e1c3f1",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Cote d'Ivoire",
            "country_code": "CI",
            "location_type": "COUNTRIES",
            "targeting_value": "7e0b3752623e1d72",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Croatia",
            "country_code": "HR",
            "location_type": "COUNTRIES",
            "targeting_value": "8d65596349ee2e01",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Curacao",
            "country_code": "CW",
            "location_type": "COUNTRIES",
            "targeting_value": "b0b7e818ce3fec3f",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Cyprus",
            "country_code": "CY",
            "location_type": "COUNTRIES",
            "targeting_value": "f0af1239cbebb474",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Czech Republic",
            "country_code": "CZ",
            "location_type": "COUNTRIES",
            "targeting_value": "6b5d375c346e3be9",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Democratic Republic of the Congo",
            "country_code": "CD",
            "location_type": "COUNTRIES",
            "targeting_value": "17cd9eb8e2c0152c",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Denmark",
            "country_code": "DK",
            "location_type": "COUNTRIES",
            "targeting_value": "c29833e68a86e703",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Djibouti",
            "country_code": "DJ",
            "location_type": "COUNTRIES",
            "targeting_value": "fa1e0cb60ddedc05",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Dominica",
            "country_code": "DM",
            "location_type": "COUNTRIES",
            "targeting_value": "937d9ebcc1f8365b",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Dominican Republic",
            "country_code": "DO",
            "location_type": "COUNTRIES",
            "targeting_value": "695f560eb8922977",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Ecuador",
            "country_code": "EC",
            "location_type": "COUNTRIES",
            "targeting_value": "4e43cac8250a8b20",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Egypt",
            "country_code": "EG",
            "location_type": "COUNTRIES",
            "targeting_value": "c659cb4912229666",
            "targeting_type": "LOCATION"
        },
        {
            "name": "El Salvador",
            "country_code": "SV",
            "location_type": "COUNTRIES",
            "targeting_value": "43bf7210cd175772",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Equatorial Guinea",
            "country_code": "GQ",
            "location_type": "COUNTRIES",
            "targeting_value": "648e07ff5133ca21",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Eritrea",
            "country_code": "ER",
            "location_type": "COUNTRIES",
            "targeting_value": "774ccf26b8961ffa",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Estonia",
            "country_code": "EE",
            "location_type": "COUNTRIES",
            "targeting_value": "e222580e9a58b499",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Ethiopia",
            "country_code": "ET",
            "location_type": "COUNTRIES",
            "targeting_value": "c3b85cf1d8b226f2",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Falkland Islands",
            "country_code": "FK",
            "location_type": "COUNTRIES",
            "targeting_value": "5fe7735f51fb69d9",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Faroe Islands",
            "country_code": "FO",
            "location_type": "COUNTRIES",
            "targeting_value": "5b1c430f0a25322e",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Fiji",
            "country_code": "FJ",
            "location_type": "COUNTRIES",
            "targeting_value": "2eca8f364790ebb5",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Finland",
            "country_code": "FI",
            "location_type": "COUNTRIES",
            "targeting_value": "e7c97cdfef3a741a",
            "targeting_type": "LOCATION"
        },
        {
            "name": "France",
            "country_code": "FR",
            "location_type": "COUNTRIES",
            "targeting_value": "f3bfc7dcc928977f",
            "targeting_type": "LOCATION"
        },
        {
            "name": "French Guiana",
            "country_code": "GF",
            "location_type": "COUNTRIES",
            "targeting_value": "991b4344edc2d520",
            "targeting_type": "LOCATION"
        },
        {
            "name": "French Polynesia",
            "country_code": "PF",
            "location_type": "COUNTRIES",
            "targeting_value": "ea5eca163987d547",
            "targeting_type": "LOCATION"
        },
        {
            "name": "French Southern Territories",
            "country_code": "TF",
            "location_type": "COUNTRIES",
            "targeting_value": "c027ef7e8f9b3ee2",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Gabon",
            "country_code": "GA",
            "location_type": "COUNTRIES",
            "targeting_value": "1a94ec7b94ebb007",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Gambia",
            "country_code": "GM",
            "location_type": "COUNTRIES",
            "targeting_value": "59f7c708c61d85dc",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Georgia",
            "country_code": "GE",
            "location_type": "COUNTRIES",
            "targeting_value": "60fcb78e1f3a23dd",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Germany",
            "country_code": "DE",
            "location_type": "COUNTRIES",
            "targeting_value": "fdcd221ac44fa326",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Ghana",
            "country_code": "GH",
            "location_type": "COUNTRIES",
            "targeting_value": "c08f5915ceeeb806",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Gibraltar",
            "country_code": "GI",
            "location_type": "COUNTRIES",
            "targeting_value": "3137081efbd2677e",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Greece",
            "country_code": "GR",
            "location_type": "COUNTRIES",
            "targeting_value": "2ee7eeaa84dbe65a",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Greenland",
            "country_code": "GL",
            "location_type": "COUNTRIES",
            "targeting_value": "2e61574e29165969",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Grenada",
            "country_code": "GD",
            "location_type": "COUNTRIES",
            "targeting_value": "ba1b68ac534ec439",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Guadeloupe",
            "country_code": "GP",
            "location_type": "COUNTRIES",
            "targeting_value": "4e9baf84e2232342",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Guam",
            "country_code": "GU",
            "location_type": "COUNTRIES",
            "targeting_value": "eb1b809cd6457a58",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Guatemala",
            "country_code": "GT",
            "location_type": "COUNTRIES",
            "targeting_value": "13d479b108707983",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Guernsey",
            "country_code": "GG",
            "location_type": "COUNTRIES",
            "targeting_value": "82c0b3139851e08b",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Guinea",
            "country_code": "GN",
            "location_type": "COUNTRIES",
            "targeting_value": "2d3edd5369d26d84",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Guinea-bissau",
            "country_code": "GW",
            "location_type": "COUNTRIES",
            "targeting_value": "9ba85a4b41cff9a3",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Guyana",
            "country_code": "GY",
            "location_type": "COUNTRIES",
            "targeting_value": "23184b2af9649efa",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Haiti",
            "country_code": "HT",
            "location_type": "COUNTRIES",
            "targeting_value": "d3e226ca18cd4ae8",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Heard Island and Mcdonald Islands",
            "country_code": "HM",
            "location_type": "COUNTRIES",
            "targeting_value": "d8b99808049c05db",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Honduras",
            "country_code": "HN",
            "location_type": "COUNTRIES",
            "targeting_value": "d862adecd71aa2a2",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Hong Kong SAR China",
            "country_code": "HK",
            "location_type": "COUNTRIES",
            "targeting_value": "35fd5bacecc4c6e5",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Hungary",
            "country_code": "HU",
            "location_type": "COUNTRIES",
            "targeting_value": "81b8dcbe189773f2",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Iceland",
            "country_code": "IS",
            "location_type": "COUNTRIES",
            "targeting_value": "c3932d3da7922986",
            "targeting_type": "LOCATION"
        },
        {
            "name": "India",
            "country_code": "IN",
            "location_type": "COUNTRIES",
            "targeting_value": "b850c1bfd38f30e0",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Indonesia",
            "country_code": "ID",
            "location_type": "COUNTRIES",
            "targeting_value": "ce7988d3a8b6f49f",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Iraq",
            "country_code": "IQ",
            "location_type": "COUNTRIES",
            "targeting_value": "b62cd77425868341",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Ireland",
            "country_code": "IE",
            "location_type": "COUNTRIES",
            "targeting_value": "ea679934779f45c7",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Isle of Man",
            "country_code": "IM",
            "location_type": "COUNTRIES",
            "targeting_value": "a079e6ba1e5a8c28",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Israel",
            "country_code": "IL",
            "location_type": "COUNTRIES",
            "targeting_value": "2edb6e240797c549",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Italy",
            "country_code": "IT",
            "location_type": "COUNTRIES",
            "targeting_value": "c799e2d3a79f810e",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Jamaica",
            "country_code": "JM",
            "location_type": "COUNTRIES",
            "targeting_value": "d66ca907ceb62690",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Japan",
            "country_code": "JP",
            "location_type": "COUNTRIES",
            "targeting_value": "06ef846bfc783874",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Jersey",
            "country_code": "JE",
            "location_type": "COUNTRIES",
            "targeting_value": "5d6b876b71a00ef1",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Jordan",
            "country_code": "JO",
            "location_type": "COUNTRIES",
            "targeting_value": "fc6282dff859b848",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Kazakhstan",
            "country_code": "KZ",
            "location_type": "COUNTRIES",
            "targeting_value": "6d73a696edc5306f",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Kenya",
            "country_code": "KE",
            "location_type": "COUNTRIES",
            "targeting_value": "17ad6a68301cd28b",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Kiribati",
            "country_code": "KI",
            "location_type": "COUNTRIES",
            "targeting_value": "f77ef3a8c01d1bc0",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Kosovo",
            "country_code": "XK",
            "location_type": "COUNTRIES",
            "targeting_value": "ce840961e49976cf",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Kuwait",
            "country_code": "KW",
            "location_type": "COUNTRIES",
            "targeting_value": "d303522079ceb9a7",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Kyrgyzstan",
            "country_code": "KG",
            "location_type": "COUNTRIES",
            "targeting_value": "56ac89b367a68a34",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Laos",
            "country_code": "LA",
            "location_type": "COUNTRIES",
            "targeting_value": "07fefc42d1da90c0",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Latvia",
            "country_code": "LV",
            "location_type": "COUNTRIES",
            "targeting_value": "d0e642e8a900f679",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Lebanon",
            "country_code": "LB",
            "location_type": "COUNTRIES",
            "targeting_value": "583bd538eb3129d1",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Lesotho",
            "country_code": "LS",
            "location_type": "COUNTRIES",
            "targeting_value": "1273b0ca0f5d5ce5",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Liberia",
            "country_code": "LR",
            "location_type": "COUNTRIES",
            "targeting_value": "8d2ea4d337845954",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Libya",
            "country_code": "LY",
            "location_type": "COUNTRIES",
            "targeting_value": "70637f5c6cb00ec9",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Liechtenstein",
            "country_code": "LI",
            "location_type": "COUNTRIES",
            "targeting_value": "db367491e0c03477",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Lithuania",
            "country_code": "LT",
            "location_type": "COUNTRIES",
            "targeting_value": "d5cde4dddd7e6f94",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Luxembourg",
            "country_code": "LU",
            "location_type": "COUNTRIES",
            "targeting_value": "a9928b6eaeb54f86",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Macau",
            "country_code": "MO",
            "location_type": "COUNTRIES",
            "targeting_value": "d029ddcdc4fc44b6",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Macedonia",
            "country_code": "MK",
            "location_type": "COUNTRIES",
            "targeting_value": "9778812ea3fffe48",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Madagascar",
            "country_code": "MG",
            "location_type": "COUNTRIES",
            "targeting_value": "2b1888a4da352846",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Malawi",
            "country_code": "MW",
            "location_type": "COUNTRIES",
            "targeting_value": "a0583a9994e6bf1b",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Malaysia",
            "country_code": "MY",
            "location_type": "COUNTRIES",
            "targeting_value": "8633ee56a589f49c",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Maldives",
            "country_code": "MV",
            "location_type": "COUNTRIES",
            "targeting_value": "2c614b2aca285e79",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Mali",
            "country_code": "ML",
            "location_type": "COUNTRIES",
            "targeting_value": "44d34054431f2b36",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Malta",
            "country_code": "MT",
            "location_type": "COUNTRIES",
            "targeting_value": "1d834adff5d584df",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Marshall Islands",
            "country_code": "MH",
            "location_type": "COUNTRIES",
            "targeting_value": "e1ab84996b3f6609",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Martinique",
            "country_code": "MQ",
            "location_type": "COUNTRIES",
            "targeting_value": "15bddd8209796b5e",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Mauritania",
            "country_code": "MR",
            "location_type": "COUNTRIES",
            "targeting_value": "32c0b41931e32f4d",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Mauritius",
            "country_code": "MU",
            "location_type": "COUNTRIES",
            "targeting_value": "ee9d992aa12a6fa0",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Mayotte",
            "country_code": "YT",
            "location_type": "COUNTRIES",
            "targeting_value": "2e1db4ccd414851e",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Mexico",
            "country_code": "MX",
            "location_type": "COUNTRIES",
            "targeting_value": "25530ba03b7d90c6",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Micronesia",
            "country_code": "FM",
            "location_type": "COUNTRIES",
            "targeting_value": "d1531c51dd2bc3a4",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Moldova",
            "country_code": "MD",
            "location_type": "COUNTRIES",
            "targeting_value": "a89b926651acf416",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Monaco",
            "country_code": "MC",
            "location_type": "COUNTRIES",
            "targeting_value": "6c990e36e6fa8033",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Mongolia",
            "country_code": "MN",
            "location_type": "COUNTRIES",
            "targeting_value": "8bd61930ae4f642d",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Montenegro",
            "country_code": "ME",
            "location_type": "COUNTRIES",
            "targeting_value": "c9506eee1d5f5143",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Montserrat",
            "country_code": "MS",
            "location_type": "COUNTRIES",
            "targeting_value": "f59f40c0fd8a944a",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Morocco",
            "country_code": "MA",
            "location_type": "COUNTRIES",
            "targeting_value": "b5fc43481ea8b89a",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Mozambique",
            "country_code": "MZ",
            "location_type": "COUNTRIES",
            "targeting_value": "b64dd3d6de94f13d",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Namibia",
            "country_code": "NA",
            "location_type": "COUNTRIES",
            "targeting_value": "3df4e3a5f8fa480a",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Nauru",
            "country_code": "NR",
            "location_type": "COUNTRIES",
            "targeting_value": "68b11f0b17d9dd5a",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Nepal",
            "country_code": "NP",
            "location_type": "COUNTRIES",
            "targeting_value": "f1d3a53f8a3cc7e9",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Netherlands",
            "country_code": "NL",
            "location_type": "COUNTRIES",
            "targeting_value": "879d7cfc66c9c290",
            "targeting_type": "LOCATION"
        },
        {
            "name": "New Caledonia",
            "country_code": "NC",
            "location_type": "COUNTRIES",
            "targeting_value": "2f4cc128bb4fb146",
            "targeting_type": "LOCATION"
        },
        {
            "name": "New Zealand",
            "country_code": "NZ",
            "location_type": "COUNTRIES",
            "targeting_value": "0b039ce18ddf1cb9",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Nicaragua",
            "country_code": "NI",
            "location_type": "COUNTRIES",
            "targeting_value": "d2a0bb4acbd6b824",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Niger",
            "country_code": "NE",
            "location_type": "COUNTRIES",
            "targeting_value": "d5fbf8130a41420c",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Nigeria",
            "country_code": "NG",
            "location_type": "COUNTRIES",
            "targeting_value": "59b27337f38d658b",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Niue",
            "country_code": "NU",
            "location_type": "COUNTRIES",
            "targeting_value": "0560f64d7b342583",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Norfolk Island",
            "country_code": "NF",
            "location_type": "COUNTRIES",
            "targeting_value": "8d9c9c8fd0993ecf",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Northern Mariana Islands",
            "country_code": "MP",
            "location_type": "COUNTRIES",
            "targeting_value": "f342cd7d716c8acb",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Norway",
            "country_code": "NO",
            "location_type": "COUNTRIES",
            "targeting_value": "0ce8b9a7b2742f7e",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Oman",
            "country_code": "OM",
            "location_type": "COUNTRIES",
            "targeting_value": "ec1d87074d3793f0",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Pakistan",
            "country_code": "PK",
            "location_type": "COUNTRIES",
            "targeting_value": "64a07f675a8a699a",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Palau",
            "country_code": "PW",
            "location_type": "COUNTRIES",
            "targeting_value": "6afef0dd95bd25ca",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Palestinian Territories",
            "country_code": "PS",
            "location_type": "COUNTRIES",
            "targeting_value": "df2a47f9371161bc",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Panama",
            "country_code": "PA",
            "location_type": "COUNTRIES",
            "targeting_value": "9d8ae4b0fac2036a",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Papua New Guinea",
            "country_code": "PG",
            "location_type": "COUNTRIES",
            "targeting_value": "85cb260e850b80e2",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Paraguay",
            "country_code": "PY",
            "location_type": "COUNTRIES",
            "targeting_value": "6cf2fd60a82329fc",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Peru",
            "country_code": "PE",
            "location_type": "COUNTRIES",
            "targeting_value": "2dfa9ecb0179a4e4",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Philippines",
            "country_code": "PH",
            "location_type": "COUNTRIES",
            "targeting_value": "fb151ef38fa2ac0d",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Pitcairn",
            "country_code": "PN",
            "location_type": "COUNTRIES",
            "targeting_value": "f8bdf6c2726e5cd4",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Poland",
            "country_code": "PL",
            "location_type": "COUNTRIES",
            "targeting_value": "d9074951d5976bdf",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Portugal",
            "country_code": "PT",
            "location_type": "COUNTRIES",
            "targeting_value": "8198e85105936d3c",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Qatar",
            "country_code": "QA",
            "location_type": "COUNTRIES",
            "targeting_value": "a54c21f6aedb2967",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Republic of the Congo",
            "country_code": "CG",
            "location_type": "COUNTRIES",
            "targeting_value": "326674ea1ad6f260",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Reunion",
            "country_code": "RE",
            "location_type": "COUNTRIES",
            "targeting_value": "6f8bf4f18e6799fe",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Romania",
            "country_code": "RO",
            "location_type": "COUNTRIES",
            "targeting_value": "f7531639e8db5e12",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Russia",
            "country_code": "RU",
            "location_type": "COUNTRIES",
            "targeting_value": "5714382051c06d1e",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Rwanda",
            "country_code": "RW",
            "location_type": "COUNTRIES",
            "targeting_value": "84f644e036f3dcb0",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Saint Barthelemy",
            "country_code": "BL",
            "location_type": "COUNTRIES",
            "targeting_value": "d32a345c8a9139c1",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Saint Helena, Ascension and Tristan Da Cunha",
            "country_code": "SH",
            "location_type": "COUNTRIES",
            "targeting_value": "d4c3e6700fba84cb",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Saint Kitts and Nevis",
            "country_code": "KN",
            "location_type": "COUNTRIES",
            "targeting_value": "ce1899c5b27d0caa",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Saint Lucia",
            "country_code": "LC",
            "location_type": "COUNTRIES",
            "targeting_value": "015fd2ff54877b34",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Saint Martin (French part)",
            "country_code": "MF",
            "location_type": "COUNTRIES",
            "targeting_value": "ef292c6bcce65340",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Saint Pierre and Miquelon",
            "country_code": "PM",
            "location_type": "COUNTRIES",
            "targeting_value": "e88c91f9f97e3d41",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Saint Vincent and the Grenadines",
            "country_code": "VC",
            "location_type": "COUNTRIES",
            "targeting_value": "bb94af3e1fdbeb7f",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Samoa",
            "country_code": "WS",
            "location_type": "COUNTRIES",
            "targeting_value": "ee293e7b8b7d15d0",
            "targeting_type": "LOCATION"
        },
        {
            "name": "San Marino",
            "country_code": "SM",
            "location_type": "COUNTRIES",
            "targeting_value": "d9a15239f67eec6d",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Sao Tome and Principe",
            "country_code": "ST",
            "location_type": "COUNTRIES",
            "targeting_value": "8acdb1fe9ee444a0",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Saudi Arabia",
            "country_code": "SA",
            "location_type": "COUNTRIES",
            "targeting_value": "08b2d74428e2ca88",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Senegal",
            "country_code": "SN",
            "location_type": "COUNTRIES",
            "targeting_value": "c5d6f8cac1a9be34",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Serbia",
            "country_code": "RS",
            "location_type": "COUNTRIES",
            "targeting_value": "58a08d4ddc8aab9f",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Seychelles",
            "country_code": "SC",
            "location_type": "COUNTRIES",
            "targeting_value": "04c9e28a7a635403",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Sierra Leone",
            "country_code": "SL",
            "location_type": "COUNTRIES",
            "targeting_value": "1e228be254cad957",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Singapore",
            "country_code": "SG",
            "location_type": "COUNTRIES",
            "targeting_value": "2509b9adc1fedfd2",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Sint Maarten (Dutch part)",
            "country_code": "SX",
            "location_type": "COUNTRIES",
            "targeting_value": "98b249f9ea821f69",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Slovakia",
            "country_code": "SK",
            "location_type": "COUNTRIES",
            "targeting_value": "34ed2e67dd5a22bb",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Slovenia",
            "country_code": "SI",
            "location_type": "COUNTRIES",
            "targeting_value": "58f54743b1a62911",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Solomon Islands",
            "country_code": "SB",
            "location_type": "COUNTRIES",
            "targeting_value": "c2cc3aca039a8280",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Somalia",
            "country_code": "SO",
            "location_type": "COUNTRIES",
            "targeting_value": "2e09439cac5c7992",
            "targeting_type": "LOCATION"
        },
        {
            "name": "South Africa",
            "country_code": "ZA",
            "location_type": "COUNTRIES",
            "targeting_value": "dd9c0d7d7e07eb49",
            "targeting_type": "LOCATION"
        },
        {
            "name": "South Georgia and the South Sandwich Islands",
            "country_code": "GS",
            "location_type": "COUNTRIES",
            "targeting_value": "98c7a0e48eb6b42b",
            "targeting_type": "LOCATION"
        },
        {
            "name": "South Korea",
            "country_code": "KR",
            "location_type": "COUNTRIES",
            "targeting_value": "c00e5392b3fa46fb",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Spain",
            "country_code": "ES",
            "location_type": "COUNTRIES",
            "targeting_value": "ecdce75d48b13b64",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Sri Lanka",
            "country_code": "LK",
            "location_type": "COUNTRIES",
            "targeting_value": "173c2bb9d42baaa5",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Suriname",
            "country_code": "SR",
            "location_type": "COUNTRIES",
            "targeting_value": "3afcd8fd83f599e3",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Svalbard and Jan Mayen",
            "country_code": "SJ",
            "location_type": "COUNTRIES",
            "targeting_value": "8df637cb3293b094",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Swaziland",
            "country_code": "SZ",
            "location_type": "COUNTRIES",
            "targeting_value": "02127c41c77b85ad",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Sweden",
            "country_code": "SE",
            "location_type": "COUNTRIES",
            "targeting_value": "82b141af443cb1b8",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Switzerland",
            "country_code": "CH",
            "location_type": "COUNTRIES",
            "targeting_value": "4e7c21fd2af027c6",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Taiwan",
            "country_code": "TW",
            "location_type": "COUNTRIES",
            "targeting_value": "204a435ce97d5de4",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Tajikistan",
            "country_code": "TJ",
            "location_type": "COUNTRIES",
            "targeting_value": "66fd08f1746d8702",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Tanzania",
            "country_code": "TZ",
            "location_type": "COUNTRIES",
            "targeting_value": "3a31097caf999c9f",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Thailand",
            "country_code": "TH",
            "location_type": "COUNTRIES",
            "targeting_value": "974c290e10850494",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Timor-leste",
            "country_code": "TL",
            "location_type": "COUNTRIES",
            "targeting_value": "b83e1dfa68cc396c",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Togo",
            "country_code": "TG",
            "location_type": "COUNTRIES",
            "targeting_value": "9b3e472bb2f84d08",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Tokelau",
            "country_code": "TK",
            "location_type": "COUNTRIES",
            "targeting_value": "64075bd521936443",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Tonga",
            "country_code": "TO",
            "location_type": "COUNTRIES",
            "targeting_value": "aceeec48dbb9ede8",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Trinidad and Tobago",
            "country_code": "TT",
            "location_type": "COUNTRIES",
            "targeting_value": "47bf5243296fa7e3",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Tunisia",
            "country_code": "TN",
            "location_type": "COUNTRIES",
            "targeting_value": "5ddc8b97bfa4fa9d",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Turkey",
            "country_code": "TR",
            "location_type": "COUNTRIES",
            "targeting_value": "682c5a667856ef42",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Turkmenistan",
            "country_code": "TM",
            "location_type": "COUNTRIES",
            "targeting_value": "3d77e0dd1c4ed51c",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Turks and Caicos Islands",
            "country_code": "TC",
            "location_type": "COUNTRIES",
            "targeting_value": "06cd7798172f191e",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Tuvalu",
            "country_code": "TV",
            "location_type": "COUNTRIES",
            "targeting_value": "6286b0ee1f351799",
            "targeting_type": "LOCATION"
        },
        {
            "name": "U.S. Virgin Islands",
            "country_code": "VI",
            "location_type": "COUNTRIES",
            "targeting_value": "a88342b3900c93d6",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Uganda",
            "country_code": "UG",
            "location_type": "COUNTRIES",
            "targeting_value": "939067979a7f3b95",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Ukraine",
            "country_code": "UA",
            "location_type": "COUNTRIES",
            "targeting_value": "084d0d0155787e9d",
            "targeting_type": "LOCATION"
        },
        {
            "name": "United Arab Emirates",
            "country_code": "AE",
            "location_type": "COUNTRIES",
            "targeting_value": "3f63906fc8aa5a7d",
            "targeting_type": "LOCATION"
        },
        {
            "name": "United Kingdom",
            "country_code": "GB",
            "location_type": "COUNTRIES",
            "targeting_value": "6416b8512febefc9",
            "targeting_type": "LOCATION"
        },
        {
            "name": "United States",
            "country_code": "US",
            "location_type": "COUNTRIES",
            "targeting_value": "96683cc9126741d1",
            "targeting_type": "LOCATION"
        },
        {
            "name": "United States Minor Outlying Islands",
            "country_code": "UM",
            "location_type": "COUNTRIES",
            "targeting_value": "08fbcf4cbc4bc5b9",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Uruguay",
            "country_code": "UY",
            "location_type": "COUNTRIES",
            "targeting_value": "d0f5be1262dee9aa",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Uzbekistan",
            "country_code": "UZ",
            "location_type": "COUNTRIES",
            "targeting_value": "8f3fa3e2dedc1db5",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Vanuatu",
            "country_code": "VU",
            "location_type": "COUNTRIES",
            "targeting_value": "8cd83cbcaf4f8620",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Vatican City",
            "country_code": "VA",
            "location_type": "COUNTRIES",
            "targeting_value": "f0bae89c752f9a54",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Venezuela",
            "country_code": "VE",
            "location_type": "COUNTRIES",
            "targeting_value": "043383f8b2b79918",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Vietnam",
            "country_code": "VN",
            "location_type": "COUNTRIES",
            "targeting_value": "2371490f9d073edc",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Wallis and Futuna",
            "country_code": "WF",
            "location_type": "COUNTRIES",
            "targeting_value": "e49f634ed2e1554e",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Western Sahara",
            "country_code": "EH",
            "location_type": "COUNTRIES",
            "targeting_value": "48c457daa287c999",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Yemen",
            "country_code": "YE",
            "location_type": "COUNTRIES",
            "targeting_value": "e5ac80d8b565c01f",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Zambia",
            "country_code": "ZM",
            "location_type": "COUNTRIES",
            "targeting_value": "5e8d90e3eaa45d69",
            "targeting_type": "LOCATION"
        },
        {
            "name": "Zimbabwe",
            "country_code": "ZW",
            "location_type": "COUNTRIES",
            "targeting_value": "d65fbac6845dcfd4",
            "targeting_type": "LOCATION"
        }
    ],
}
