<template>
  <aside class="aside-menu">
    <ul class="links">
      <li v-for='(link) in links' :class='!link ? "break" : ""' :key='link'>
        <!-- TODO uncomment when routes exist  -->
        <!-- <router-link v-if='link' :to='href'>{{link}}</router-link> -->
        <a href="#">{{link}}</a>
      </li>
      <li class='break'></li>
      <li>
        <a @click='logout' role="button">Log Out</a>
      </li>
    </ul>
  </aside>
</template>

<script>
export default {
  name: 'c-aside',
  data() {
    return {
      links: {
        "/settings": "Settings",
        "/profile": "Profile"
      }
    }
  },
  methods: {
    logout() {
      localStorage.removeItem("token-2")
      window.eraseCookie("token-2")
      window.location.href = ""
    }
  }
}
</script>

<style scoped>
.links {
  margin-top: 4px;
}
.links li {
  display: flex;
  flex-direction: column;
  padding: 4px 0;
  padding-left: 23px;
}
.links li:hover {
  background: whitesmoke;
}
.links li > a {
  color: #333;
}
.break {
  border-bottom: 1px solid #ededed;
  margin-bottom: 3px;
}
</style>
