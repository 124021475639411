<template lang="html">
  <div class="editPresetsPage">
   
    <div v-if="userHasPermission('preset.create')">
      <b-buton slot="show-title" class="btn btn-outline-success mb-3" @click="$bvModal.show('edit-pressets-modal')" >+ New Preset</b-buton>
<b-modal id="edit-pressets-modal" hide-footer :title="'New Presset'" size="sm">
   <form class="newForm" @submit.prevent="addPreset">
        <FormField
          :autocomplete="off"
          placeholder="Enter name"
          :value="newPreset.PresetName"
          :class="'input-width'"
          :onUpdate="
            (e) => {
              newPreset.PresetName = e;
            }
          "
          labelText="Name"
        />
        <div class="input-width">
          <label>Select Countries :</label>
          <multiselect
            class="multiselect input-width"
            label="label"
            track-by="val"
            :multiple="true"
            :close-on-select="false"
            :options="countries"
            v-model="newPreset.Countries"
          ></multiselect>
          <button type="submit" class="btn btn-primary mt-2 float-right">
            Submit
          </button>
        </div>
      </form>
</b-modal>
   
    </div>
    <div class="accordion">
      <div class="card">
        <ul class="preset-list">
          <li v-for="preset in targetingPresets">
            <div class="card-header" id="headingOne">
              <span class="preset-name">{{ preset.PresetName }}</span>
              <button
                class="btn btn-link"
                data-toggle="collapse"
                :data-target="'#' + preset.PresetName"
                aria-expanded="true"
                :aria-controls="preset.presetName"
              >
                <i
                  v-if="userHasPermission('permissions.create')"
                  class="fa fa-pencil-square-o "
                  aria-hidden="true"
                  @click="editPreset(preset, preset.PresetName)"
                ></i>
              </button>
              <i
                v-if="userHasPermission('permissions.create')"
                class="fa fa-trash trash-btn"
                aria-hidden="true"
                @click="deletePreset(preset, preset.PresetName)"
              ></i>
            </div>
            <div
              :id="preset.PresetName"
              class="collapse "
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div class="card-body">
                <form @submit.prevent="submitEditingPreset()">
                  <FormField
                    autocomplete="off"
                    placeholder="Enter name"
                    :value="oldPreset.PresetName"
                    :onUpdate="
                      (e) => {
                        oldPreset.PresetName = e;
                      }
                    "
                    labelText="Name"
                  />
                  <div>
                    <label for="">Select Countries :</label>
                    <multiselect
                      class="multiselect"
                      label="label"
                      track-by="val"
                      :multiple="true"
                      :close-on-select="false"
                      :options="countries"
                      v-model="oldPreset.Countries"
                    ></multiselect>
                    <button type="submit" class="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script
  src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"
  integrity="sha384-ka7Sk0Gln4gmtz2MlQnikT1wXgYsOg+OMhuP+IlRH9sENBO0LRn5q+8nbTov4+1p"
  crossorigin="anonymous"
></script>
<script>
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
  },
  data() {
    return {
      countries: (countr) => {
        Object.keys(countr).map((v) => ({
          val: v,
          label: countr[v],
        }));
      },
      targetingPresets: [],
      newPreset: {
        PresetName: null,
        Countries: [],
      },
      oldPreset: {
        Id: null,
        PresetName: null,
        Countries: [],
      },
    };
  },

  created() {
    this.getCountriesList();
    this.fetchTargetingPresets();
  },
  methods: {
    async addPreset() {
      let form = this.newPreset;
      form.Countries = form.Countries.map((element) => {
        return element.id;
      });
      try {
        await this.$http.post(this.resources.Admin.newPreset, form);
        this.notifySuccess(`New Preset '${form.PresetName}' has been created`);
        this.fetchTargetingPresets();
        this.newPreset.PresetName = null;
        this.newPreset.Countries = [];
      } catch (err) {
        console.error(err);
        this.notifyError("Could not create preset.");
      }
    },
    async deletePreset(preset, presetName) {
      let confirm = window.confirm(
        `Are you sure that you wish to delete preset: ${presetName} ?`
      );
      if (!confirm) {
        return false;
      }
      try {
        await this.$http.delete(this.resources.Admin.deletePreset(preset.Id));
        this.notifySuccess(`Preset '${presetName}' has been deleted.`);
        const result = this.targetingPresets.filter(
          (element) => element != preset
        );
        this.targetingPresets = result;
      } catch (err) {
        console.error(err);
        this.notifyError("Could not delete preset.");
      }
    },

    editPreset(preset, presetName) {
      this.oldPreset.Id = preset.Id;
      this.oldPreset.PresetName = presetName;
      this.oldPreset.Countries = preset.Countries.map((value) => ({
        val: value.Code,
        label: value.Name,
        id: value.Id,
      }));
    },
    async submitEditingPreset() {
      try {
        const form = { ...this.oldPreset };
        form.Countries = form.Countries.map((element) => {
          return element.id;
        });

        await this.$http.put(this.resources.Admin.editPreset(form.Id), form);
        this.notifySuccess(`Preset '${form.PresetName}' has been updated`);
        this.fetchTargetingPresets();
      } catch (err) {
        console.error(err);
        this.notifyError("Could not update preset.");
      }
    },

    async getCountriesList() {
      try {
        const response = await this.$http.get(this.resources.Admin.countries);
        const data = await response.json();
        this.countries = Object.keys(data).map((v) => ({
          val: data[v].Code,
          label: data[v].Name,
          id: data[v].Id,
        }));
      } catch (err) {
        this.notifyError("Could not get countries list.");
        console.error(err);
      }
    },
    async fetchTargetingPresets() {
      try {
        const response = await this.$http.get(
          this.resources.fb.targetingPresets
        );
        const data = await response.json();
        this.targetingPresets = data;
      } catch (err) {
        console.error(err);
        this.notifyError("Could not fetch countries presets.");
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="css" scoped>
.fa-pencil-square-o {
  color: rgb(0, 119, 255);
  font-size: 32px;
  font-weight: 300;
  margin-right: 20px;
}
.preset-list {
  max-height: 400px;
  overflow-y: auto;
  border: 1px solid gainsboro;
  padding: 8px;
}
.preset-name {
  display: inline-block;
  width: 550px;
}
.multiselect {
  max-width: 67%;
}
.input-width
{
  max-width: 300px;
  width: 100%;
}
.innerForm {
  width: 50%;
}
</style>
