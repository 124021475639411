export default {
   "en":"English",
   "pt":"Portuguese",
   "fr":"French",
   "es":"Spanish",
   "ar":"Arabic",
   "he":"Hebrew",
   "de":"German",
   "tr":"Turkish",
   "it":"Italian",
   "nl":"Dutch",
   "hu":"Hungarian",
   "ro":"Romanian",
   "pl":"Polish",
   "ru":"Russian",
   "ja": "Japanese",
   "cn":"Chinese",
   "id":"Indonesian",
   "bg":"Bulgarian",
   "hi":"Hindi",
   "vn":"Vietnamese",
   "th":"Thai",
   "sv":"Swedish",
   "cs":"Czech",
   "tl":"Filipino",
   "el":"Greek",
   "uk":"Ukrainian",
   "da":"Danish",
   "no":"Norwegian",
   "ka":"Georgian",
   "ko":"Korean",
   "bn":"Bengali",
   "ur":"Urdu",
   "be":"Belarusian",
   "ms":"Malay",
   "lt":"Lithuanian",
   "lv":"Latvian",
   "hr":"Croatian",
   "sq":"Albanian",
   "fi":"Finnish",
   "sr":"Serbian"
}
