<template lang="html">
  <div class="reportForm">
    <h3>Facebook Report</h3>

    <section class="report-form">
      <div class="">
        <span>Choose Account:</span>

        <select class="def" v-model="selectedAccount" @change="filterReport">
          <option :value="null">ALL</option>
          <option v-for="account in allAccounts" :value="account">{{
            account.name
          }}</option>
        </select>
      </div>

      <div class="date-range-3">
        <span class="s-151">Choose Date Range:</span>

        <div class="">
          <div class="div-15">
            <i class="active-circle" v-show="selectedDateRange"></i>
            <ul class="date-range-options-list">
              <li
                v-for="v in dateRangeOptions"
                @click="setFixedDateRange(v)"
                :class="{ active: v === selectedDateRange }"
              >
                {{ v }}
              </li>
            </ul>
          </div>

          <div class="">
            <div class="div-15">
              <i class="active-circle" v-show="!selectedDateRange"></i>
              <span>Or custom date range:</span>
            </div>
            <div class="date-range-241">
              <DatePicker
                :onChange="
                  (v) => {
                    form.start_dt = v;
                    selectedDateRange = null;
                  }
                "
                :value="form.start_dt"
              />
              <DatePicker
                :onChange="
                  (v) => {
                    form.end_dt = v;
                    selectedDateRange = null;
                  }
                "
                :value="form.end_dt"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="search-box-container date-range-3">
        <div class="">
          <label for="">Search adset:</label>
          <input v-model="searchForm.adset" class="def" />
        </div>
        <div class="">
          <label for="">Campaign Status: </label>
          <select
            class="def"
            v-model="searchForm.status"
            @change="filterReport"
          >
            <option value="ALL">ALL</option>
            <option value="ACTIVE">ACTIVE</option>
            <option value="PAUSED">PAUSED</option>
          </select>
        </div>
      </div>

      <div class="search-box-container date-range-3">
        <FilteringForm
          :filteringForm="filteringForm"
          :fields="filterableFields"
        />
      </div>

      <div class="b-2412">
        <span class="s-325">Breakdowns:</span>

        <ul class="metrics-list">
          <li class="metric-item" v-for="d in breakdownsOptions">
            <label class="capital" :for="'dim-' + d">{{ d }}</label>
            <input
              v-model="breakdowns"
              :id="'dim-' + d"
              :value="d"
              type="checkbox"
            />
          </li>
        </ul>
      </div>

      <div class="b-2412">
        <span class="s-325">Metrics:</span>

        <ul class="metrics-list">
          <li
            class="metric-item"
            v-for="(metric, metricKey) in fields"
            v-show="!breakdownsOptions.includes(metricKey)"
          >
            <label :for="metricKey">{{ metric.title }}</label>
            <input
              v-model="metrics"
              :id="metricKey"
              :value="metricKey"
              type="checkbox"
            />
          </li>
        </ul>
      </div>

      <div class="b-2412" v-show="0">
        <span class="s-325">Breakdowns:</span>
        <ul class="metrics-list">
          <li class="">
            <label for="">Date</label>
            <input v-model="form.breakdowns" value="date" type="checkbox" />
          </li>
          <li class="">
            <label for="">Hour</label>
            <input v-model="form.breakdowns" value="hour" type="checkbox" />
          </li>
        </ul>
      </div>

      <br />
      <button
        type="button"
        class="btn btn-success"
        @click="fetchReport"
        :disabled="processingReport"
      >
        Run Report
        <Spinner :def="0" v-show="processingReport" />
      </button>
      <button
        type="button"
        v-if="userHasPermission('fb.create')"
        class="btn btn-primary create-camp-btn"
        @click="
          (e) => {
            adsetWizardOpened = true;
          }
        "
      >
        Create Campaign
      </button>
    </section>
    <br />
    <div v-if="reportDataFiltered" class="report-table">
      <ul class="adsets-list">
        <li class="row12">
          <span class="">
            <label for="">
              <input
                type="checkbox"
                @change="toggleSelectAllAdsets"
                v-model="allAdsetsSelectedCheckbox"
              />
              {{ selectedAdsets.length }} Adsets selected
            </label>
          </span>
          <span class="actions-column">
            <button
              @click="actionSetActive"
              class="btn action-btn-25"
              type="button"
            >
              Set Active
            </button>
            <button
              @click="actionSetInactive"
              class="btn action-btn-25"
              type="button"
            >
              Set Inactive
            </button>
            <button
              @click="actionSetDailyBudget"
              class="btn action-btn-25"
              type="button"
            >
              Set Daily Budget
            </button>
            <button
              @click="actionSetBid"
              class="btn action-btn-25"
              type="button"
            >
              Set Bid
            </button>
          </span>
        </li>
        <li class="row12 header-row header-row-after">
          <span class="checkbox-column"></span>
          <span class="plus-td"></span>
          <span class="plus-td">Expand</span>
          <span class="external-link-td"></span>
          <span
            class="row-name sortable-column"
            :class="{ active: sort.field === 'id' }"
            @click="onColumnClick('id')"
            >Name(ID)</span
          >
          <span class="status-column">Status</span>
          <span
            v-for="(_, f) in fields"
            v-show="metrics.includes(f)"
            @click="onColumnClick(f)"
            class="sortable-column"
            :class="{ active: sort.field === f }"
          >
            {{ fields[f].title }}
          </span>
        </li>
        <li class="row12 sum-row">
          <span class="checkbox-column"></span>
          <span class="plus-td"></span>
          <span class="plus-td"></span>
          <span class="external-link-td"></span>
          <span class="row-name">{{ sumReport["adsetsCount"] }} Adsets</span>
          <span class="status-column"></span>
          <span
            v-for="(_, f) in fields"
            v-show="metrics.includes(f)"
            :class="
              Object.assign(
                {
                  noafter: sumReport[f] === undefined,
                  percent: fields[f].after === '%',
                  dollar: fields[f].after === '$',
                },
                fields[f].getClass && fields[f].getClass(sumReport[f])
              )
            "
          >
            {{
              sumReport[f] | ifFloatDecimalN(fields[f].decimalDigits || 4)
            }}</span
          >
        </li>
        <template v-for="adset in reportDataFiltered.adsets">
          <li class="row12 row12-th value-row">
            <span class="checkbox-column">
              <label :for="adset.id">
                <input
                  type="checkbox"
                  :id="adset.id"
                  :value="adset"
                  v-model="selectedAdsets"
                />
              </label>
            </span>
            <span class="plus-td">
              <i
                class="fa fa-clone dup-icon"
                aria-hidden="true"
                @click="duplicateAdset(adset)"
              ></i>
            </span>
            <span class="plus-td expand-column">
              <template v-if="lastReportBreakdowns.length === 0">
                <button
                  class="def expand-btn-3"
                  @click="toggleAdsetExpand(adset, 'ssp')"
                >
                  SSP
                </button>
              </template>
            </span>
            <span class="external-link-td">
              <a :href="getAdsetExternalLink(adset)" target="_blank">
                <i
                  class="fa fa-external-link ext-link-to-platform"
                  aria-hidden="true"
                ></i>
              </a>
            </span>
            <span class="row-name">{{ adset.name }}({{ adset.id }})</span>
            <span :class="{ 'status-column': true }">
              <i
                @click="toggleAdsetStatus(adset)"
                v-if="adset['status'] === 'ACTIVE'"
                class="toggle-icon fa fa-toggle-on"
                aria-hidden="true"
              ></i>
              <i
                @click="toggleAdsetStatus(adset)"
                v-else
                class="toggle-icon fa fa-toggle-off"
                aria-hidden="true"
              ></i>
            </span>
            <span
              v-for="(_, f) in fields"
              v-show="metrics.includes(f)"
              :class="
                Object.assign(
                  {
                    noafter: adset[f] === undefined,
                    percent: fields[f].after === '%',
                    dollar: fields[f].after === '$',
                  },
                  fields[f].getClass && fields[f].getClass(adset[f])
                )
              "
            >
              <span
                class="edit-column-btn"
                v-if="userHasPermission('fb.edit') && fields[f].editable"
                @click="openEditPopup(adset, f)"
              >
                <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
              </span>
              {{
                adset[f] | ifFloatDecimalN(fields[f].decimalDigits || 4)
              }}</span
            >
          </li>
        </template>
      </ul>

      <ScreenBlocker
        :onClose="(e) => (editAdsetPopup.adset = null)"
        v-if="editAdsetPopup.adset"
      >
        <div class="edit-adset-popup">
          <div class="edit-adset-adset-635">
            <span class="edit-adset-name-626">
              {{ editAdsetPopup.adset.name }}
            </span>
          </div>
          <form
            class="edit-adset-form-line-24"
            @submit.prevent="confirmAdsetEdit"
          >
            <template v-if="editAdsetPopup.field === 'bid_computed'">
              <label for="">Bid Amount:</label>
              <input
                class="def"
                type="number"
                :disabled="
                  editAdsetPopup.value.bid_strategy ===
                    'LOWEST_COST_WITHOUT_CAP'
                "
                step="0.01"
                max="0.40"
                v-model.number="editAdsetPopup.value.bid_amount"
              />

              <label for="">Bid Strategy:</label>
              <select class="def" v-model="editAdsetPopup.value.bid_strategy">
                <option value="LOWEST_COST_WITHOUT_CAP">Lowest cost</option>
                <option value="LOWEST_COST_WITH_BID_CAP">Bid Cap</option>
                <option value="COST_CAP">Cost cap</option>
              </select>
            </template>
            <template v-else>
              <label for="">{{ fields[editAdsetPopup.field].title }}:</label>
              <input
                class="def"
                type="number"
                :step="
                  fields[editAdsetPopup.field].decimalDigits ? '0.01' : '0'
                "
                v-model.number="editAdsetPopup.value"
              />
            </template>
            <button
              type="submit"
              :disabled="ongoingActions.editAdset[editAdsetPopup.adset.id]"
              class="btn btn-primary"
            >
              Save
            </button>
          </form>
        </div>
      </ScreenBlocker>

      <ScreenBlocker
        :onClose="closeEditPopup"
        v-if="editMultipleAdsetsPopup.adsets"
      >
        <template v-if="editMultipleAdsetsPopup.field === 'bid_computed'">
          <div class="edit-adset-popup">
            <div class="edit-adset-adset-635">
              <span
                class="edit-adset-name-626"
                v-for="adset in editMultipleAdsetsPopup.adsets"
              >
                <span class="edit-adset-name-11">
                  {{ adset.name }}({{ adset.id }})
                </span>
                <span class="edit-old-val-3">
                  {{
                    adset[editMultipleAdsetsPopup.field] | ifFloatDecimalN(2)
                  }}
                </span>
                <span class="edit-new-val-3">
                  {{ adset["updated_val"] | ifFloatDecimalN(2) }}
                </span>
              </span>
            </div>
            <form
              class="edit-adset-form-line-24"
              @submit.prevent="confirmMultipleAdsetEdit"
            >
              <label for="">Bid Amount:</label>
              <input
                class="def"
                type="number"
                :disabled="
                  editMultipleAdsetsPopup.value.bid_strategy ===
                    'LOWEST_COST_WITHOUT_CAP'
                "
                step="0.01"
                max="0.40"
                v-model.number="editMultipleAdsetsPopup.value.bid_amount"
              />

              <label for="">Bid Strategy:</label>
              <select
                class="def"
                v-model="editMultipleAdsetsPopup.value.bid_strategy"
              >
                <option value="LOWEST_COST_WITHOUT_CAP">Lowest cost</option>
                <option value="LOWEST_COST_WITH_BID_CAP">Bid Cap</option>
                <option value="COST_CAP">Cost cap</option>
              </select>

              <button
                type="submit"
                :disabled="ongoingActions.editMultipleAdsets"
                class="btn btn-primary"
              >
                Save
              </button>
            </form>
          </div>
        </template>
        <template v-else>
          <div class="edit-adset-popup">
            <div class="edit-adset-adset-635">
              <span
                class="edit-adset-name-626"
                v-for="adset in editMultipleAdsetsPopup.adsets"
              >
                <span class="edit-adset-name-11">
                  {{ adset.name }}({{ adset.id }})
                </span>
                <span class="edit-old-val-3">
                  {{
                    adset[editMultipleAdsetsPopup.field] | ifFloatDecimalN(2)
                  }}
                </span>
                <span class="edit-new-val-3">
                  {{ adset["updated_val"] | ifFloatDecimalN(2) }}
                </span>
              </span>
            </div>
            <form
              class="edit-adset-form-line-24"
              @submit.prevent="confirmMultipleAdsetEdit"
            >
              <template v-if="editMultipleAdsetsPopup.field === 'status'">
                <span
                  >Are you sure that you want to update these adsets' status to
                  "{{ editMultipleAdsetsPopup.value }}"?</span
                >
                <button
                  type="submit"
                  :disabled="ongoingActions.editMultipleAdsets"
                  class="btn btn-primary"
                >
                  Update
                </button>
              </template>
              <template v-else>
                <select
                  class="def"
                  @change="onEditAdsetValueChange"
                  v-model="editMultipleAdsetsPopup.setType"
                >
                  <option value="absolute" default>Set</option>
                  <option value="changeBy">Increase/Decrease by</option>
                </select>
                <label for=""
                  >{{ fields[editMultipleAdsetsPopup.field].title }}:</label
                >
                <input
                  class="def"
                  type="number"
                  :step="
                    fields[editMultipleAdsetsPopup.field].decimalDigits
                      ? '0.01'
                      : '0'
                  "
                  @input="onEditAdsetValueChange"
                  v-model.number="editMultipleAdsetsPopup.value"
                />
                <button
                  type="submit"
                  :disabled="ongoingActions.editMultipleAdsets"
                  class="btn btn-primary"
                >
                  Save
                </button>
              </template>
            </form>
          </div>
        </template>
      </ScreenBlocker>
    </div>

    <ScreenBlocker v-if="adsetWizardOpened && fbData" :onClose="onWizardClose">
      <AdsetWizard
        :accounts="allAccounts"
        :onClose="onWizardClose"
        :fbData="fbData"
        :data="toDuplicateAdset"
      ></AdsetWizard>
    </ScreenBlocker>

    <ScreenBlocker :allowContent="0" v-if="blockScreen"></ScreenBlocker>
  </div>
</template>

<script>
import moment from "moment";
import FilteringForm from "@/views/reports/shared/FilteringForm.reports";
import AdsetWizard from "../components/AdsetWizard.fb";

export default {
  props: {},
  components: {
    FilteringForm,
    AdsetWizard,
  },
  data() {
    let dtNow = new Date();

    let fields = {
      date: {
        title: "Date",
      },
      country: {
        title: "Country",
      },
      created_at_formated: {
        title: "Created At",
      },
      bid_computed: {
        title: "Bid Strategy",
        editable: true,
      },
      daily_budget_float: {
        title: "Daily Budget",
        after: "$",
        editable: true,
      },
      active_users: {
        title: "Active Users",
      },
      inline_link_clicks: {
        title: "Clicks",
      },
      cost_per_inline_link_click: {
        title: "CPC",
        decimalDigits: 3,
        after: "$",
      },
      ctr: {
        title: "CTR",
        after: "%",
        decimalDigits: 2,
      },
      admanager_ecpm: {
        title: "AM ECPM",
        decimalDigits: 2,
        after: "$",
      },
      spend: {
        title: "Cost",
        decimalDigits: 2,
        after: "$",
      },
      revenue: {
        title: "Revenue",
        decimalDigits: 2,
        after: "$",
      },
      admanager_rev: {
        title: "AM Rev",
        decimalDigits: 2,
        after: "$",
      },
      ssp: {
        title: "SSP",
        getClass: function(val) {
          return { "ssp-name": true };
        },
      },
      total_live_rev: {
        title: "Total Estimated Rev",
        decimalDigits: 2,
        after: "$",
      },
      prebid_rev: {
        title: "Prebid Rev",
        decimalDigits: 2,
        after: "$",
      },
      other_rev: {
        title: "Other Rev",
        decimalDigits: 2,
        after: "$",
      },
      video_rev: {
        title: "Video Rev",
        decimalDigits: 2,
        after: "$",
      },
      prebid_no_bid_estimated_rev: {
        title: "Add. Estimated Rev",
        decimalDigits: 2,
        after: "$",
      },
      nobid_auctions: {
        title: "No Bid Auctions",
      },
      today_sessions: {
        title: "Today Sessions",
      },
      video_uv: {
        title: "Today Video UV",
        after: "$",
      },
      other_uv: {
        title: "Today Other UV",
        after: "$",
      },
      last_hour_sessions: {
        title: "L.Hour Sessions",
      },
      primis_rev: {
        title: "Primis Rev",
        decimalDigits: 2,
        after: "$",
      },
      aniview_rev: {
        title: "Aniv Rev",
        decimalDigits: 2,
        after: "$",
      },
      aniview_imps: {
        title: "Aniv Imps",
      },
      avantis_rev: {
        title: "Avan Rev",
        decimalDigits: 2,
        after: "$",
      },
      avantis_imps: {
        title: "Avan Imps",
      },
      vidazoo_rev: {
        title: "Vidazoo Rev",
        decimalDigits: 2,
        after: "$",
      },
      vidazoo_imps: {
        title: "Vidazoo Imps",
      },
      underdog_rev: {
        title: "Underdog Rev",
        decimalDigits: 2,
        after: "$",
      },
      underdog_imps: {
        title: "Underdog Imps",
      },
      roi: {
        title: "ROI",
        decimalDigits: 2,
        after: "%",
        getClass: function(val) {
          if (val < 100) {
            return { roi_bad: true };
          }
          if (val > 130) {
            return { roi_good: true };
          }
          return { roi_ok: true };
        },
      },
      profit_and_loss: {
        title: "P&L",
        decimalDigits: 2,
        after: "$",
      },
      user_value: {
        title: "UV",
        after: "$",
      },
      today_uv: {
        title: "Today UV",
        after: "$",
      },
      last_hour_uv: {
        title: "L.Hour UV",
        after: "$",
      },
      prebid_uv: {
        title: "Today Prebid UV",
        after: "$",
      },
      prebid_no_bid_estimated_uv: {
        title: "Add. Estimated UV",
        after: "$",
      },
      bounce_rate: {
        title: "Bounce Rate",
        decimalDigits: 2,
        after: "%",
      },
      page_views_per_session: {
        title: "Pages / Session",
        decimalDigits: 2,
      },
      avg_session_duration: {
        title: "Avg. Session Duration",
      },
    };

    let filterableFields = Object.assign(
      {
        created_at: {
          title: "Created At",
        },
      },
      fields
    );
    delete filterableFields["created_at_formated"];

    return {
      allAdsetsSelectedCheckbox: false,
      selectedAdsets: [],
      fbData: null,
      processingReport: false,
      form: {
        start_dt: dtNow,
        end_dt: new Date(),
        breakdowns: [],
      },
      searchForm: {
        adset: null,
        ad: null,
        status: "ALL",
      },
      lastReportBreakdowns: [],
      blockScreen: false,
      toDuplicateAdset: null,
      filteringForm: [],
      selectedAccount: null,
      allAccounts: null,
      ongoingActions: {
        toggleAdsetStatus: {},
        editAdset: {},
        editMultipleAdsets: false,
      },
      sort: {
        field: "inline_link_clicks",
        asc: false,
      },
      editAdsetPopup: {
        adset: null,
        field: null,
        value: null,
      },
      editMultipleAdsetsPopup: {
        adsets: null,
        field: null,
        value: null,
        setType: "absolute",
      },
      metrics: [
        "daily_budget_float",
        "active_users",
        "inline_link_clicks",
        "cost_per_inline_link_click",
        "total_live_rev",
        "prebid_rev",
        "other_rev",
        "admanager_rev",
        "today_uv",
        "last_hour_uv",
        "ctr",
        "spend",
        "revenue",
        "roi",
        "profit_and_loss",
        "user_value",
        "bid_computed",
      ],
      adsetWizardOpened: false,
      selectedDateRange: "",
      openAdsets: {},
      submitedBreakdowns: [],
      reportData: null,
      reportDataFiltered: null,
      breakdowns: [],
      breakdownsOptions: ["date", "country", "ssp"],
      fields,
      filterableFields,
      dateRangeOptions: [
        "Today",
        "Yesterday",
        "Last 7 days",
        "Last 30 days",
        "This Month",
        "Last Month",
      ],
      adsetMicroReport: {},
    };
  },
  watch: {
    ["searchForm.adset"](val) {
      this.filterReport();
    },
    ["searchForm.ad"](val) {
      this.filterReport();
    },
  },
  computed: {
    sumReport() {
      let adsets = this.reportDataFiltered.adsets;
      let totalReport = this.getTotalReport(adsets);
      return Object.assign({}, totalReport, {
        adsetsCount: this.getAdsetsCount(adsets),
        adsCount: this.getAdsCount(adsets),
      });
    },
  },
  methods: {
    onWizardClose() {
      this.adsetWizardOpened = false;
      this.toDuplicateAdset = null;
    },
    duplicateAdset(adset) {
      this.blockScreen = true;
      this.$http
        .get(this.resources.fb.adset(adset.account_id, adset.id))
        .then((r) => r.json())
        .then((data) => {
          this.toDuplicateAdset = data;
          this.adsetWizardOpened = true;
        })
        .catch((e) => {})
        .then(() => {
          this.blockScreen = false;
        });
    },
    confirmMultipleAdsetEdit() {
      let ef = this.editMultipleAdsetsPopup;
      let adsets = ef.adsets;

      if (this.ongoingActions.editMultipleAdsets) {
        return;
      }
      let adsetsPayload = [];

      let invalidAction = false;
      adsets.forEach((adset) => {
        if (adset.updated_val <= 0) {
          invalidAction = true;
          return;
        }
        let params = {
          id: adset.id,
          account_id: adset.account_id,
          [ef.field]: adset.updated_val,
        };

        if (ef.field === "bid_computed") {
          if (ef.value.bid_strategy === "LOWEST_COST_WITHOUT_CAP") {
            ef.value.bid_amount = 0;
          }
          params.bid_strategy = ef.value.bid_strategy;
          params.bid_amount = ef.value.bid_amount;
          delete params[ef.field];
        }

        adsetsPayload.push(params);
      });

      if (invalidAction) {
        this.notifyError("Invalid zero/negative value for an adset");
        return;
      }

      let params = {
        adsets: adsetsPayload,
      };

      this.ongoingActions.editMultipleAdsets = true;

      this.$http
        .put(this.resources.fb.adsets, params)
        .then(() => {
          adsets.forEach((adset) => {
            if (ef.field === "bid_computed") {
              adset.bid_amount = ef.value.bid_amount;
              adset.bid_strategy = ef.value.bid_strategy;
              adset.updated_val = ef.value.bid_strategy.includes(
                "LOWEST_COST_WITHOUT_CAP"
              )
                ? "Lowest cost"
                : ef.value.bid_amount + "$";
            }
            adset[ef.field] = adset.updated_val;
          });
          this.closeEditPopup();
        })
        .catch((err) => {
          this.notifyError("An error has occured");
        })
        .then(() => {
          this.ongoingActions.editMultipleAdsets = false;
        });
    },
    closeEditPopup() {
      (this.editAdsetPopup = {
        adset: null,
        field: null,
        value: null,
      }),
        this.selectedAdsets.forEach((adset) => {
          delete adset["updated_val"];
        });
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: null,
        field: null,
        value: null,
      });
    },
    onEditAdsetValueChange() {
      let ef = this.editMultipleAdsetsPopup;
      let val = ef.value;
      if (!val) {
        val = 0;
      }
      ef.adsets.forEach((ad) => {
        if (ef.setType === "changeBy") {
          ad["updated_val"] = ad[ef.field] + val;
        } else {
          ad["updated_val"] = val;
        }
      });
    },
    actionSetActive() {
      if (this.selectedAdsets.length === 0) {
        return;
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: "status",
        value: "ACTIVE",
      });
      this.selectedAdsets.forEach((adset) => {
        adset.updated_val = "ACTIVE";
      });
    },
    toggleSelectAllAdsets() {
      let adsets = this.reportDataFiltered.adsets;
      if (!this.allAdsetsSelectedCheckbox) {
        this.selectedAdsets = [];
      } else {
        this.selectedAdsets = adsets.slice(0);
      }
    },
    actionSetInactive() {
      if (this.selectedAdsets.length === 0) {
        return;
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: "status",
        value: "PAUSED",
      });
      this.selectedAdsets.forEach((adset) => {
        adset.updated_val = "PAUSED";
      });
    },
    actionSetDailyBudget() {
      if (this.selectedAdsets.length === 0) {
        return;
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: "daily_budget_float",
        value: 0,
      });
    },
    actionSetBid() {
      if (this.selectedAdsets.length === 0) {
        return;
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: "bid_computed",
        value: {
          bid_amount: 0,
          bid_strategy: "LOWEST_COST_WITH_BID_CAP",
        },
      });
    },
    getAdsetExternalLink(adset) {
      let today = moment();
      let d1 = today.format("YYYY-MM-DD");
      let d2 = today.add(1, "days").format("YYYY-MM-DD");
      let dateStr = `${d1}_${d2}`;
      return `https://business.facebook.com/adsmanager/manage/adsets?act=${adset.account_id}&&columns=name%2Cdelivery%2Ccampaign_name%2Cbid%2Cbudget%2Clast_significant_edit%2Cresults%2Creach%2Cimpressions%2Ccost_per_result%2Cquality_score_organic%2Cquality_score_ectr%2Cquality_score_ecvr%2Cspend%2Cend_time%2Cschedule%2Cfrequency%2Cunique_actions%3Alink_click%2Cactions%3Alike%2Cactions%3Alink_click%2Cactions%3Aoffsite_conversion.fb_pixel_purchase%2Cactions%3Aonsite_conversion.purchase&attribution_windows=default&date=${dateStr}%2Ctoday&comparison_date=&filter_set=SEARCH_BY_CAMPAIGN_ID-STRING%1EEQUAL%1E"${adset.id}"`;
    },
    openEditPopup(adset, f) {
      let value;
      if (f === "bid_computed") {
        value = {
          bid_amount: adset.bid_amount,
          bid_strategy: adset.bid_strategy,
        };
      } else {
        value = adset[f];
      }
      this.editAdsetPopup = {
        adset: adset,
        field: f,
        value: value,
      };
    },
    confirmAdsetEdit() {
      let ef = this.editAdsetPopup;
      let adset = ef.adset;
      let adsetId = adset.id;

      if (this.ongoingActions.editAdset[adsetId]) {
        return;
      }

      this.$set(this.ongoingActions.editAdset, adsetId, true);

      let params = {
        [ef.field]: ef.value,
        camp_id: adset.campaign_id,
      };

      if (ef.field === "bid_computed") {
        if (ef.value.bid_strategy === "LOWEST_COST_WITHOUT_CAP") {
          ef.value.bid_amount = 0;
        }
        params.bid_strategy = ef.value.bid_strategy;
        params.bid_amount = ef.value.bid_amount;
        delete params[ef.field];
      }

      this.$http
        .put(this.resources.fb.adsetUpdate(adsetId), null, { params })
        .then(() => {
          if (ef.field === "bid_computed") {
            adset.bid_amount = ef.value.bid_amount;
            adset.bid_strategy = ef.value.bid_strategy;
            ef.value = ef.value.bid_strategy.includes("LOWEST_COST_WITHOUT_CAP")
              ? "Lowest cost"
              : ef.value.bid_amount + "$";
          }
          adset[ef.field] = ef.value;
          this.closeEditPopup();
        })
        .catch((err) => {
          this.notifyError("An error has occured");
        })
        .then(() => {
          this.ongoingActions.editAdset[adsetId] = false;
        });
    },
    toggleAdsetStatus(adset) {
      if (!this.userHasPermission("fb.edit")) {
        return;
      }

      let adsetID = adset.id;
      if (this.ongoingActions.toggleAdsetStatus[adsetID]) {
        return;
      }

      this.ongoingActions.toggleAdsetStatus[adsetID] = true;

      let status = adset.status;
      let toStatus;
      if (status == "ACTIVE") {
        toStatus = "PAUSED";
      } else {
        toStatus = "ACTIVE";
      }

      let params = {
        status: toStatus,
      };

      this.$http
        .put(this.resources.fb.adsetUpdate(adsetID), null, { params })
        .then(() => {
          window.setTimeout(() => {
            adset.status = toStatus;
            this.ongoingActions.toggleAdsetStatus[adsetID] = false;
          }, 20);
        })
        .catch((err) => {
          this.notifyError("An error has occured");
          this.ongoingActions.toggleAdsetStatus[adsetID] = false;
        });
    },
    selectAccount(account) {
      this.selectedAccount = account;
    },
    onColumnClick(field) {
      if (this.sort.field === field) {
        this.sort.asc = !this.sort.asc;
      } else {
        this.sort.field = field;
        this.sort.asc = false;
      }
      this.sortReport();
    },
    fetchFbData() {
      if (this.$store.state.data.fbData) {
        this.fbData = this.$store.state.data.fbData;
        this.allAccounts = Object.values(this.fbData.accounts);
        return;
      }
      return this.$http
        .get(this.resources.Reports.fbAllData)
        .then((r) => r.json())
        .then((data) => {
          let accounts = data && Object.values(data.accounts);
          if (!accounts || accounts.length === 0) {
            return;
          }
          this.$store.state.data.fbData = data;
          this.fbData = data;
          this.allAccounts = accounts;
        });
    },
    arrangeReport(data) {
      let adsets = data.adsets;

      for (let key in adsets) {
        let adset = adsets[key];
        adset.created_at_formated = moment(adset.created_at).format(
          "DD/MM HH:mm"
        );
        adset.bid_computed = adset.bid_strategy.includes(
          "LOWEST_COST_WITHOUT_CAP"
        )
          ? "Lowest cost"
          : adset.bid_amount + "$";
      }
      return data;
    },
    sortReport() {
      let data = this.reportDataFiltered;
      if (!data || !data.adsets) {
        return;
      }

      let sort = this.sort;
      if (!sort.field) {
        return;
      }

      let sortField = sort.field;
      if (sort.field === "created_at_formated") {
        sortField = "created_at";
      }

      data.adsets.sort((a, b) => {
        a = a[sortField];
        b = b[sortField];

        let f;
        if (a > b) {
          f = 1;
        } else if (a < b) {
          f = -1;
        } else {
          f = 0;
        }

        if (!sort.asc) {
          f = f * -1;
        }

        return f;
      });
    },
    filterReport() {
      let adsetSearch = this.searchForm.adset;
      let adSearch = this.searchForm.ad;
      let reportData = this.reportData;
      let status = this.searchForm.status;

      let data = Object.assign({}, reportData);

      if (adsetSearch) {
        adsetSearch = adsetSearch.toLowerCase();
        data.adsets = reportData.adsets.filter(
          (v) =>
            v.id == adsetSearch || v.name.toLowerCase().includes(adsetSearch)
        );
      }

      if (status == "ACTIVE" || status == "PAUSED") {
        data.adsets = data.adsets.filter((v) => v.status === status);
      }

      let filteringForm = this.filteringForm;

      data.adsets = data.adsets.filter((adset) => {
        let passedFilters = true;

        for (let i = 0; i < filteringForm.length; i++) {
          let fo = filteringForm[i];

          let field = fo.field;
          let givenVal = adset[field];
          let requiredVal = fo.val;
          let op = fo.operator;

          if (!field || !requiredVal || !op) {
            continue;
          }

          if (field === "created_at") {
            givenVal = givenVal.substr(0, givenVal.indexOf("T"));
          }

          if (op === ">") {
            passedFilters = givenVal > requiredVal;
          } else if (op === "<") {
            passedFilters = givenVal < requiredVal;
          } else {
            passedFilters = givenVal == requiredVal;
          }

          if (!passedFilters) {
            break;
          }
        }

        return passedFilters;
      });

      this.reportDataFiltered = data;
    },
    getTotalReport(adsets) {
      let r = {
        daily_budget_float: 0,
        impressions: 0,
        clicks: 0,
        spend: 0,
        revenue: 0,
        profit_and_loss: 0,
        inline_link_clicks: 0,
        total_live_rev: 0,
        prebid_rev: 0,
        other_rev: 0,
        video_rev: 0,
        prebid_no_bid_estimated_rev: 0,
        admanager_rev: 0,
        primis_rev: 0,
        aniview_rev: 0,
        ctr: 0,
        aniview_imps: 0,
        avantis_rev: 0,
        avantis_imps: 0,
        vidazoo_rev: 0,
        vidazoo_imps: 0,
        underdog_rev: 0,
        underdog_imps: 0,
        active_users: 0,
        nobid_auctions: 0,
        today_sessions: 0,
        last_hour_sessions: 0,
      };

      let ctr = 0;
      adsets.forEach((adset) => {
        r.daily_budget_float += adset.daily_budget_float;
        r.impressions += adset.impressions;
        r.clicks += adset.clicks;
        r.spend += adset.spend;
        r.revenue += adset.revenue;
        r.inline_link_clicks += adset.inline_link_clicks;
        r.prebid_rev += adset.prebid_rev;
        r.other_rev += adset.other_rev;
        r.admanager_rev += adset.admanager_rev;
        r.primis_rev += adset.primis_rev;
        r.aniview_rev += adset.aniview_rev;
        r.aniview_imps += adset.aniview_imps;
        r.avantis_rev += adset.avantis_rev;
        r.avantis_imps += adset.avantis_imps;
        r.vidazoo_rev += adset.vidazoo_rev;
        r.vidazoo_imps += adset.vidazoo_imps;
        r.underdog_rev += adset.underdog_rev;
        r.underdog_imps += adset.underdog_imps;
        r.active_users += adset.active_users;
        r.total_live_rev += adset.total_live_rev;
        r.nobid_auctions += adset.nobid_auctions;
        r.today_sessions += adset.today_sessions;
        r.last_hour_sessions += adset.last_hour_sessions;
        r.video_rev += adset.video_rev;
        r.prebid_no_bid_estimated_rev += adset.prebid_no_bid_estimated_rev;

        ctr += adset.ctr;
      });

      if (adsets.length > 0) {
        ctr = ctr / adsets.length;
      }

      r.ctr = ctr;

      r.profit_and_loss = r.revenue - r.spend;

      if (r.spend > 0) {
        r.roi = (r.revenue / r.spend) * 100;
      }

      if (r.impressions > 0) {
        r.ctr = (r.inline_link_clicks / r.impressions) * 100;
      }

      if (r.clicks > 0) {
        r.cpc = r.spend / r.clicks;
      }
      if (r.inline_link_clicks > 0) {
        r.user_value = r.revenue / r.inline_link_clicks;
        r.today_uv = r.total_live_rev / r.inline_link_clicks;
        r.cost_per_inline_link_click = r.spend / r.inline_link_clicks;
      }

      return r;
    },
    getAdsCount(adsets) {
      if (!adsets) {
        return 0;
      }
      let c = 0;
      adsets.forEach((adset) => {
        c += adset.ads ? adset.ads.length : 0;
      });
      return c;
    },
    getAdsetsCount(adsets) {
      if (!adsets) {
        return 0;
      }
      return Object.keys(adsets).length;
    },
    setFixedDateRange(val) {
      this.selectedDateRange = val;
      let dtNow = new Date();
      let endDt, startDt;
      let d = new Date();
      switch (val) {
        case "Today":
          startDt = dtNow;
          endDt = d;
          break;

        case "Yesterday":
          d.setDate(dtNow.getDate() - 1);
          startDt = d;
          endDt = d;
          break;

        case "Last 7 days":
          d.setDate(dtNow.getDate() - 7);
          startDt = d;
          endDt = dtNow;
          break;

        case "Last 30 days":
          d.setDate(dtNow.getDate() - 30);
          startDt = d;
          endDt = dtNow;
          break;

        case "This Month":
          d.setDate(1);
          startDt = d;
          endDt = dtNow;
          break;

        case "Last Month":
          let ms = new Date();
          let lms = new Date();
          ms.setDate(0);
          lms.setDate(0);
          lms.setDate(1);
          startDt = lms;
          endDt = ms;
          break;
      }

      this.form.start_dt = startDt;
      this.form.end_dt = endDt;
    },
    isAdsetOpen(id) {
      return this.openAdsets[id];
    },
    toggleAdsetExpand(adset, breakdown) {
      let lastReport = this.adsetMicroReport[adset.id];
      if (lastReport && lastReport.breakdown === breakdown) {
        this.$delete(this.adsetMicroReport, adset.id);
        return;
      }
      let payload = {
        start_dt: moment(adset.start_dt).format("YYYY-MM-DD"),
        end_dt: moment(adset.end_dt).format("YYYY-MM-DD"),
        campaign_id: adset.id,
        breakdowns: [breakdown],
      };
      if (breakdown === "date") {
        let dtNow = new Date();
        let d = new Date();
        d.setDate(dtNow.getDate() - 7);
        let startDt = d;
        let endDt = dtNow;
        payload.start_dt = moment(startDt).format("YYYY-MM-DD");
        payload.end_dt = moment(endDt).format("YYYY-MM-DD");
      }

      this._fetchReport(payload)
        .then((r) => r.json())
        .then((data) => {
          this.metrics.push(breakdown);
          this.$set(this.adsetMicroReport, adset.id, {
            breakdown: breakdown,
            adsets: data.adsets,
          });
        });
    },
    _fetchReport(params) {
      return this.$http.get(this.resources.Reports.fbLiveCampAllInsights, {
        params,
      });
    },
    fetchReport() {
      this.adsetMicroReport = {};
      this.selectedAdsets = [];
      this.allAdsetsSelectedCheckbox = false;

      let accountId = this.selectedAccount && this.selectedAccount.id;
      if (!accountId) {
        accountId = "";
      }

      let params = {
        start_dt: moment(this.form.start_dt).format("YYYY-MM-DD"),
        end_dt: moment(this.form.end_dt).format("YYYY-MM-DD"),
        account_id: accountId,
        breakdowns: this.breakdowns,
      };

      this.processingReport = true;
      this._fetchReport(params)
        .then((r) => r.json())
        .then((data) => {
          data = data || {};
          data.adsets = data.adsets || {};

          data = this.arrangeReport(data);
          data.adsets = Object.values(data.adsets);
          this.reportData = data;
          this.filterReport();
          this.sortReport();

          let bd = this.breakdowns;
          let metrics = this.metrics;

          this.breakdownsOptions.forEach((v) => {
            let i = metrics.indexOf(v);
            if (i > -1) {
              metrics.splice(i, 1);
            }
          });
          bd.forEach((v) => {
            metrics.push(v);
          });

          this.lastReportBreakdowns = bd;
        })
        .catch(() => {})
        .then(() => {
          this.processingReport = false;
        });
    },
  },
  created() {
    this.fetchFbData();
    this.setFixedDateRange("Today");
  },
};
</script>
