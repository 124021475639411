<template lang="html">
  <div class="sb-412">
    <div class="container-41" v-if="allowContent">
      <button type="button" @click="onClose" class="close-btn">X</button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    onClose: {},
    allowContent: {
      default: true,
    },
  },
};
</script>

<style lang="css" scoped>
.sb-412 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #8080808f;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 999;
}
.container-41 {
  background: white;
  /* min-width: 500px;
  min-height: 600px; */
  padding: 6px 9px;
  border: 1px solid #b1b0b0;
  border-radius: 3px;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
