<template lang="html">
  <div class="ReportsForm2">
    <h3>Facebook Report</h3>

    <section class="report-form">
      <div class="accounts-field-33">
        <label for="">Accounts:</label>
        <multiselect
          v-if="allAccounts"
          label="name"
          track-by="id"
          :multiple="true"
          :close-on-select="false"
          v-model="selectedAccounts"
          :options="allAccounts"
        ></multiselect>
      </div>

      <div class="date-range-3">
        <span class="s-151">Choose Date Range:</span>

        <div class="">
          <div class="div-15">
            <i class="active-circle" v-show="selectedDateRange"></i>
            <ul class="date-range-options-list">
              <li
                v-for="v in dateRangeOptions"
                @click="setFixedDateRange(v)"
                :class="{ active: v === selectedDateRange }"
              >
                {{ v }}
              </li>
            </ul>
          </div>

          <div class="">
            <div class="div-15">
              <i class="active-circle" v-show="!selectedDateRange"></i>
              <span>Or custom date range:</span>
            </div>
            <div class="date-range-241">
              <DatePicker
                :onChange="
                  (v) => {
                    form.start_dt = v;
                    selectedDateRange = null;
                  }
                "
                :value="form.start_dt"
              />
              <DatePicker
                :onChange="
                  (v) => {
                    form.end_dt = v;
                    selectedDateRange = null;
                  }
                "
                :value="form.end_dt"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="search-box-container date-range-3">
        <div class="">
          <label for="">Search adsets:</label>
          <input v-model="searchForm.adset" class="def" />
        </div>
        <div class="">
          <label for="">Exclude adsets:</label>
          <input v-model="searchForm.excludeAdset" class="def" />
        </div>

        <div class="">
          <label for="">Campaign Status: </label>
          <select
            class="def"
            v-model="searchForm.status"
            @change="filterReport"
          >
            <option value="ALL">ALL</option>
            <option value="ACTIVE">ACTIVE</option>
            <option value="PAUSED">PAUSED</option>
          </select>
        </div>
      </div>

      <div class="search-box-container date-range-3">
        <FilteringForm
          :filteringForm="filteringForm"
          :fields="filterableFields"
        />
      </div>

      <div class="b-2412" v-show="0">
        <span class="s-325">Breakdowns:</span>

        <ul class="metrics-list">
          <li class="metric-item" v-for="d in breakdownOptions">
            <label class="capital" :for="'dim-' + d">{{ d }}</label>
            <input
              v-model="breakdowns"
              :id="'dim-' + d"
              :value="d"
              type="checkbox"
            />
          </li>
        </ul>
      </div>

      <div class="b-2412">
        <span class="s-325">Metrics:</span>

        <multiselect
          class="select-2"
          label="title"
          track-by="value"
          :multiple="true"
          :close-on-select="false"
          v-model="metricsSelectCompatible"
          :options="fieldsList"
        ></multiselect>
      </div>

      <div class="b-2412" v-show="0">
        <span class="s-325">Breakdowns:</span>
        <ul class="metrics-list">
          <li class="">
            <label for="">Date</label>
            <input v-model="form.breakdowns" value="date" type="checkbox" />
          </li>
          <li class="">
            <label for="">Hour</label>
            <input v-model="form.breakdowns" value="hour" type="checkbox" />
          </li>
        </ul>
      </div>

      <b-container class="p-0 m-0 mt-4 mw-100">
        <b-row>
          <b-col cols="8">
            <button
          type="button"
          class="btn btn-success"
          @click="fetchReport"
          :disabled="processingReport"
        >
          Run Report
          <Spinner :def="0" v-show="processingReport" />
        </button>

        <b-button 
        variant="secondary"
        class="ml-2"
          @click="downloadReportCsv"
          :disabled="processingCsvReport"
        >
          Download Report CSV
          <Spinner :def="0" v-show="processingCsvReport" />
        </b-button>
          </b-col>
          <b-col cols="4" class="d-flex justify-content-end">
            <button
          type="button"
          v-if="userHasPermission('fb.create')"
          :disabled="!fbData"
          class="btn btn-primary"
          @click="
            (e) => {
              adsetWizardOpened = true;
            }
          "
        >
          Create Campaign
        </button>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <br />
    <div v-if="reportDataFiltered" class="report-table">
      <span class="am_last_fetched_info_box"
        >AM last fetched:
        {{ reportData.meta.ad_manager_last_updated | formatTime }}</span
      >
      <ul class="adsets-list">
        <li class="actions-row-12">
          <div class="adsets-selection-c1">
            <input
              type="checkbox"
              id="s-25124"
              v-show="false"
              @change="toggleSelectAllAdsets"
              v-model="allAdsetsSelectedCheckbox"
            />
            <label
              for="s-25124"
              class="s-checkbox-4 s-checkbox-4-panel"
              :class="{ checked: allAdsetsSelectedCheckbox }"
            >
              <i class="fa fa-check-square" aria-hidden="true"></i>
            </label>
            <span> {{ selectedAdsets.length }} Adsets selected </span>
          </div>
          <div class="actions-column flex">
            <button
              @click="actionSetActive"
              class="btn action-btn-25"
              type="button"
            >
              Set Active
            </button>
            <button
              @click="actionSetInactive"
              class="btn action-btn-25"
              type="button"
            >
              Set Inactive
            </button>
            <button
              @click="actionSetDailyBudget"
              class="btn action-btn-25"
              type="button"
            >
              Set Daily Budget
            </button>
            <button
              @click="actionSetBid"
              class="btn action-btn-25"
              type="button"
            >
              Set Bid
            </button>
          </div>
        </li>

        <li class="row12 header-row header-row-after">
          <span class="checkbox-column"></span>
          <span class="plus-td"></span>
          <span class="plus-td expand-column">Expand</span>
          <span class="external-link-td"></span>
          <span
            class="row-name sortable-column"
            :class="{ active: sort.field === 'name' }"
            @click="onColumnClick('name')"
            >Name</span
          >
          <span
            class="status-column sortable-column"
            :class="{ active: sort.field === 'status' }"
            @click="onColumnClick('status')"
            >Status</span
          >
          <span
            v-for="(_, f) in fields"
            v-show="
              metricsSelectCompatible.some((v) => v.value === f) ||
                breakdowns.includes(f)
            "
            @click="onColumnClick(f)"
            class="sortable-column"
            :class="{ active: sort.field === f }"
          >
            {{ fields[f].title }}
          </span>
        </li>
        <li class="row12 sum-row">
          <span class="checkbox-column"></span>
          <span class="plus-td"></span>
          <span class="plus-td expand-column"></span>
          <span class="external-link-td"></span>
          <span class="row-name">{{ sumReport["adsetsCount"] }} Adsets</span>
          <span class="status-column"></span>
          <span
            v-for="(_, f) in fields"
            v-show="
              metricsSelectCompatible.some((v) => v.value === f) ||
                breakdowns.includes(f)
            "
            :class="
              Object.assign(
                {},
                fields[f].getClass && fields[f].getClass(sumReport[f])
              )
            "
          >
            {{ sumReport[f] | formatColumnValue(fields[f]) }}
          </span>
        </li>
        <template v-for="adset in uiReportDisplay">
          <li class="row12 row12-th value-row">
            <span class="checkbox-column">
              <input
                type="checkbox"
                v-show="false"
                :id="adset.id"
                :value="adset"
                v-model="selectedAdsets"
              />
              <label
                :for="adset.id"
                class="s-checkbox-4 s-checkbox-4-fill-container"
                :class="{ checked: selectedAdsets.includes(adset) }"
              >
                <i class="fa fa-check-square" aria-hidden="true"></i>
              </label>
            </span>
            <span class="plus-td">
              <i
                class="fa fa-clone dup-icon"
                aria-hidden="true"
                @click="duplicateAdset(adset)"
              ></i>
            </span>
            <span class="plus-td expand-column">
              <button
                title="Expand country"
                class="def expand-btn-3"
                @click="toggleAdsetExpand(adset, 'country')"
              >
                C
              </button>
              <button
                title="Expand date"
                class="def expand-btn-3"
                @click="toggleAdsetExpand(adset, 'date')"
              >
                D
              </button>
              <button
                title="Expand ssp"
                class="def expand-btn-3"
                @click="toggleAdsetExpand(adset, 'ssp')"
              >
                R
              </button>
              <button
                title="Expand hour"
                class="def expand-btn-3"
                @click="toggleAdsetExpand(adset, 'hour')"
              >
                H
              </button>
            </span>
            <span class="external-link-td">
              <a :href="getAdsetExternalLink(adset)" target="_blank">
                <i
                  class="fa fa-external-link ext-link-to-platform"
                  aria-hidden="true"
                ></i>
              </a>
            </span>
            <span class="row-name">{{ adset.name }}</span>
            <span :class="{ 'status-column': true }">
              <i
                @click="toggleAdsetStatus(adset)"
                v-if="adset['status'] === 'ACTIVE'"
                class="toggle-icon fa fa-toggle-on"
                aria-hidden="true"
              ></i>
              <i
                @click="toggleAdsetStatus(adset)"
                v-else
                class="toggle-icon fa fa-toggle-off"
                aria-hidden="true"
              ></i>

              <span class="status-learning">{{
                adset.is_learning ? "LEARNING" : ""
              }}</span>
            </span>

            <span
              v-for="(_, f) in fields"
              v-show="
                metricsSelectCompatible.some((v) => v.value === f) ||
                  breakdowns.includes(f)
              "
              :class="
                Object.assign(
                  {},
                  fields[f].getClass && fields[f].getClass(adset[f])
                )
              "
            >
              <template v-if="f === 'bid_computed'">
                <span
                  class="edit-column-btn"
                  v-if="userHasPermission('fb.edit')"
                >
                  <i
                    v-if="adset.last_bid_change.hasOwnProperty('is_increase')"
                    :class="{
                      'last-bid-change': true,
                      'is-increase': adset.last_bid_change.is_increase,
                    }"
                  ></i>
                  <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    title="View last bid changes"
                    @click="viewAdsetOpChanges(adset, 'bid')"
                  ></i>
                  <i
                    class="fa fa-pencil-square-o"
                    aria-hidden="true"
                    title="Edit bid"
                    @click="
                      openEditPopup(
                        adset,
                        'bid_computed',
                        fields[f].max_field_name,
                        fields[f].min_field_name
                      )
                    "
                  ></i>
                </span>
                {{ adset[f] | formatColumnValue(fields[f]) }}
              </template>
              <template
                v-else-if="metricsSelectCompatible.some((v) => v.value === f)"
              >
                <span
                  class="edit-column-btn"
                  v-if="userHasPermission('fb.edit') && fields[f].editable"
                >
                  <i
                    v-if="adset.last_budget_change.hasOwnProperty('is_increase')"
                    :class="{
                      'last-bid-change': true,
                      'is-increase': adset.last_budget_change.is_increase,
                    }"
                  ></i>
                  <i
                    class="fa fa-info-circle"
                    aria-hidden="true"
                    title="View last budget changes"
                    @click="viewAdsetOpChanges(adset, 'daily_budget')"
                  ></i>
                  <i
                    class="fa fa-pencil-square-o"
                    aria-hidden="true"
                    @click="
                      openEditPopup(
                        adset,
                        f,
                        fields[f].max_field_name,
                        fields[f].min_field_name
                      )
                    "
                  ></i>
                </span>
                {{ adset[f] | formatColumnValue(fields[f]) }}
              </template>
            </span>
          </li>

          <li
            v-for="row in adsetMicroReport[adset.id] &&
              adsetMicroReport[adset.id].adsets"
            class="row12 row12-country-td value-row"
          >
            <span class="checkbox-column"></span>
            <span class="plus-td"></span>
            <span class="plus-td expand-column"></span>
            <span class="external-link-td"></span>
            <span class="row-name"></span>
            <span
              :class="{
                'status-column': true,
                'status-td-active': row['status'] === 'ACTIVE',
              }"
            ></span>
            <span
              v-for="(_, f) in fields"
              v-show="
                metricsSelectCompatible.some((v) => v.value === f) ||
                  breakdowns.includes(f)
              "
            >
              {{ row[f] | formatColumnValue(fields[f]) }}
            </span>
          </li>
        </template>
      </ul>

      <div class="paging-container">
        <button :disabled="resultsPage === 1" @click="(e) => resultsPage--">
          Previous
        </button>
        <button
          :disabled="resultsPage === maxResultPage"
          @click="(e) => resultsPage++"
        >
          Next
        </button>
        <button class="active">{{ resultsPage }}</button>
      </div>

      <ScreenBlocker
        :onClose="(e) => (editAdsetPopup.adset = null)"
        v-if="editAdsetPopup.adset"
      >
        <div class="edit-adset-popup">
          <div class="edit-adset-adset-635">
            <span class="edit-adset-name-626">
              {{ editAdsetPopup.adset.name }}
            </span>
          </div>
          <form
            class="edit-adset-form-line-24"
            @submit.prevent="confirmAdsetEdit"
          >
            <template v-if="editAdsetPopup.field === 'bid_computed'">
              <div class="edit-adset-div">
                <label for="">Bid Amount:</label>
                <input
                  class="def"
                  type="number"
                  :disabled="
                    editAdsetPopup.value.bid_strategy ===
                      'LOWEST_COST_WITHOUT_CAP'
                  "
                  step="0.01"
                  :max="editAdsetPopup.max_field_value"
                  :min="editAdsetPopup.min_field_value"
                  v-model.number="editAdsetPopup.value.bid_amount"
                />
                <label for="">Max Bid:</label>
                <input
                  class="def"
                  type="number"
                  step="0.01"
                  v-model.number="editAdsetPopup.max_field_value"
                />

                <label for="">Min Bid:</label>
                <input
                  class="def"
                  type="number"
                  step="0.01"
                  v-model.number="editAdsetPopup.min_field_value"
                />

                <label for="">Bid Strategy:</label>
                <select class="def" v-model="editAdsetPopup.value.bid_strategy">
                  <option value="LOWEST_COST_WITHOUT_CAP">Lowest cost</option>
                  <option value="LOWEST_COST_WITH_BID_CAP">Bid Cap</option>
                  <option value="COST_CAP">Cost cap</option>
                </select>
              </div>
            </template>

            <template v-else>
              <div class="edit-adset-div">
                <label for="">{{ fields[editAdsetPopup.field].title }}:</label>
                <input
                  class="def"
                  type="number"
                  :step="
                    fields[editAdsetPopup.field].decimalDigits ? '0.01' : '0'
                  "
                  :max="editAdsetPopup.max_field_value"
                  :min="editAdsetPopup.min_field_value"
                  v-model.number="editAdsetPopup.value"
                />

                <label for="">Max Daily Budget:</label>
                <input
                  class="def"
                  type="number"
                  step="0.01"
                  v-model.number="editAdsetPopup.max_field_value"
                />

                <label for="">Min Daily Budget:</label>
                <input
                  class="def"
                  type="number"
                  step="0.01"
                  v-model.number="editAdsetPopup.min_field_value"
                />
              </div>
            </template>
            <button
              type="submit"
              :disabled="ongoingActions.editAdset[editAdsetPopup.adset.id]"
              class="btn btn-primary"
            >
              Update
            </button>
          </form>
        </div>
      </ScreenBlocker>

      <ScreenBlocker
        :onClose="closeEditPopup"
        v-if="editMultipleAdsetsPopup.adsets"
      >
        <template>
          <div class="edit-adset-popup">
            <div class="edit-adset-adset-635">
              <b-table-simple>
                <b-thead head-variant="dark">
                  <b-tr>
                    <b-th>name</b-th>
                    <b-th>id</b-th>
                    <b-th
                      >current
                      {{ fields[editMultipleAdsetsPopup.field].title }}</b-th
                    >
                    <b-th
                      >updated
                      {{ fields[editMultipleAdsetsPopup.field].title }}</b-th
                    >
                    <b-th v-show="editMultipleAdsetsPopup.field !== 'status'"
                      >max
                      {{ fields[editMultipleAdsetsPopup.field].title }}</b-th
                    >
                    <b-th v-show="editMultipleAdsetsPopup.field !== 'status'"
                      >min
                      {{ fields[editMultipleAdsetsPopup.field].title }}</b-th
                    >
                    <b-th></b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr
                    v-for="(adset, index) in editMultipleAdsetsPopup.adsets"
                  >
                    <b-td> {{ adset.name }}</b-td>
                    <b-td variant="success">{{ adset.id }}</b-td>
                    <b-td variant="warning">{{
                      adset[editMultipleAdsetsPopup.field]
                        | ifFloatDecimalN(4)
                        | currency("$", 3, { symbolOnLeft: false })
                    }}</b-td>
                    <b-td variant="danger">{{
                      adset["updated_val"]
                        | ifFloatDecimalN(4)
                        | currency("$", 3, { symbolOnLeft: false })
                    }}</b-td>
                    <b-td v-show="editMultipleAdsetsPopup.field !== 'status'">{{
                      (editMultipleAdsetsPopup.field === "daily_budget_float"
                        ? adset["max_daily_budget"]
                        : adset["max_bid"])
                        | currency("$", 3, { symbolOnLeft: false })
                    }}</b-td>
                    <b-td v-show="editMultipleAdsetsPopup.field !== 'status'">{{
                      (editMultipleAdsetsPopup.field === "daily_budget_float"
                        ? adset["min_daily_budget"]
                        : adset["min_bid"])
                        | currency("$", 3, { symbolOnLeft: false })
                    }}</b-td>
                    <b-td>
                      <span class="checkbox-column">
                        <input
                          type="checkbox"
                          v-show="false"
                          :id="adset.name"
                          :value="adset"
                          v-model="checkboxSelected"
                        />
                        <label
                          :for="adset.name"
                          class="s-checkbox-4 s-checkbox-4-fill-container"
                          :class="{ checked: checkboxSelected.includes(adset) }"
                        >
                          <i class="fa fa-check-square" aria-hidden="true"></i>
                        </label>
                      </span>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
            <form
              class="edit-adset-form-line-24"
              @submit.prevent="confirmMultipleAdsetEdit"
            >
              <template v-if="editMultipleAdsetsPopup.field === 'status'">
                <span
                  >Are you sure that you want to update these adsets' status to
                  "{{ editMultipleAdsetsPopup.value }}"?</span
                >
                <button
                  type="submit"
                  :disabled="ongoingActions.editMultipleAdsets"
                  class="btn btn-primary"
                >
                  Update
                </button>
              </template>
              <template v-else>
                <div
                  class="edit-adset-div"
                  v-if="editMultipleAdsetsPopup.field === 'daily_budget_float'"
                >
                  <div class="flex-center">
                    <b-form-checkbox
                      v-model="editMultipleAdsetsPopup.edit_field_chosed"
                    >
                    </b-form-checkbox>
                    <select
                      class="def"
                      @change="onEditAdsetValueChange"
                      v-model="editMultipleAdsetsPopup.setType"
                    >
                      <option value="absolute" default>Set</option>
                      <option value="changeBy">Increase/Decrease by</option>
                      <option value="changeByPercentage"
                        >Increase/Decrease by %</option
                      >
                    </select>
                    <label for=""
                      >{{ fields[editMultipleAdsetsPopup.field].title }}:</label
                    >
                    <input
                      class="def"
                      type="number"
                      :step="
                        fields[editMultipleAdsetsPopup.field].decimalDigits
                          ? '0.01'
                          : '0'
                      "
                      @input="onEditAdsetValueChange"
                      v-model.number="editMultipleAdsetsPopup.value"
                    />
                  </div>
                  <div class="flex-center">
                    <b-form-checkbox
                      v-model="editMultipleAdsetsPopup.max_field_chosed"
                    >
                    </b-form-checkbox>
                    <label for="">Max Daily Buget:</label>
                    <input
                      class="def"
                      type="number"
                      :step="'0.01'"
                      v-model.number="editMultipleAdsetsPopup.max_field_value"
                    />
                  </div>
                  <div class="flex-center">
                    <b-form-checkbox
                      v-model="editMultipleAdsetsPopup.min_field_chosed"
                    >
                    </b-form-checkbox>
                    <label for="">Min Daily Buget:</label>
                    <input
                      class="def"
                      type="number"
                      :step="'0.01'"
                      v-model.number="editMultipleAdsetsPopup.min_field_value"
                    />
                  </div>
                </div>
                <div
                  class="edit-adset-div"
                  v-if="editMultipleAdsetsPopup.field === 'bid_computed'"
                >
                  <div class="flex-center">
                    <b-form-checkbox
                      v-model="editMultipleAdsetsPopup.edit_field_chosed"
                    >
                    </b-form-checkbox>
                    <select
                      class="def"
                      v-model="editMultipleAdsetsPopup.setType"
                    >
                      <option value="absolute" default>Set</option>
                      <option
                        value="changeBy"
                        :disabled="
                          editMultipleAdsetsPopup.adsets.some(
                            (v) => v.bid_strategy === 'LOWEST_COST_WITHOUT_CAP'
                          )
                        "
                        >Increase/Decrease by</option
                      >
                      <option
                        value="changeByPercentage"
                        :disabled="
                          editMultipleAdsetsPopup.adsets.some(
                            (v) => v.bid_strategy === 'LOWEST_COST_WITHOUT_CAP'
                          )
                        "
                        >Increase/Decrease by %</option
                      >
                    </select>

                    <div
                      v-if="
                        editMultipleAdsetsPopup.setType === 'changeByPercentage'
                      "
                    >
                      <label for="">Change by:</label>
                      <input
                        class="def"
                        type="number"
                        :disabled="
                          editMultipleAdsetsPopup.value.bid_strategy ===
                            'LOWEST_COST_WITHOUT_CAP'
                        "
                        :step="1"
                        :max="200"
                        v-model.number="
                          editMultipleAdsetsPopup.value.bid_amount
                        "
                      />%
                    </div>

                    <div v-else>
                      <label for="">Bid Amount:</label>
                      <input
                        class="def"
                        type="number"
                        :disabled="
                          editMultipleAdsetsPopup.value.bid_strategy ===
                            'LOWEST_COST_WITHOUT_CAP'
                        "
                        :step="0.01"
                        v-model.number="
                          editMultipleAdsetsPopup.value.bid_amount
                        "
                      />
                    </div>

                    <div class="">
                      <label for="">Bid Strategy:</label>
                      <select
                        class="def"
                        v-model="editMultipleAdsetsPopup.value.bid_strategy"
                      >
                        <option value="LOWEST_COST_WITHOUT_CAP"
                          >Lowest cost</option
                        >
                        <option value="LOWEST_COST_WITH_BID_CAP"
                          >Bid Cap</option
                        >
                        <option value="COST_CAP">Cost cap</option>
                      </select>
                    </div>
                  </div>
                  <div class="flex-center">
                    <b-form-checkbox
                      v-model="editMultipleAdsetsPopup.max_field_chosed"
                    >
                    </b-form-checkbox>
                    <label for="">Max Bid:</label>
                    <input
                      class="def"
                      type="number"
                      :step="0.01"
                      v-model.number="editMultipleAdsetsPopup.max_field_value"
                    />
                  </div>
                  <div class="flex-center">
                    <b-form-checkbox
                      v-model="editMultipleAdsetsPopup.min_field_chosed"
                    >
                    </b-form-checkbox>
                    <label for="">Min Bid:</label>
                    <input
                      class="def"
                      type="number"
                      :step="0.01"
                      v-model.number="editMultipleAdsetsPopup.min_field_value"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  :disabled="ongoingActions.editMultipleAdsets"
                  class="btn btn-primary"
                >
                  Save
                </button>
              </template>
            </form>
          </div>
        </template>
      </ScreenBlocker>
    </div>

    <ScreenBlocker v-if="adsetWizardOpened && fbData" :onClose="onWizardClose">
      <AdsetWizard
        :accounts="allAccounts"
        :onClose="onWizardClose"
        :fbData="fbData"
        :data="toDuplicateAdset"
      ></AdsetWizard>
    </ScreenBlocker>

    <ScreenBlocker :allowContent="0" v-if="blockScreen"></ScreenBlocker>

    <ScreenBlocker
      v-if="displayAdsetOpChanges.adset"
      :onClose="(e) => (displayAdsetOpChanges.adset = null)"
    >
      <div class="bid-changes-container">
        <span class="bid-changes-adset-title">{{
          displayAdsetOpChanges.adset.name
        }}</span>

        <div class="bid-changes-results-container">
          <span v-if="!displayAdsetOpChanges.changes">
            Loading...
          </span>
          <span v-else-if="displayAdsetOpChanges.changes.length === 0">
            No {{ displayAdsetOpChanges.fieldTitle }} changes
          </span>
          <ul v-else class="bid-changes-list">
            <li>
              <span>Time</span>
              <span>From {{ displayAdsetOpChanges.fieldTitle }}</span>
              <span>To {{ displayAdsetOpChanges.fieldTitle }}</span>
              <span>User</span>
              <span>Comment</span>
            </li>
            <li v-for="b in displayAdsetOpChanges.changes">
              <span class="r-time-12">{{ b.op_time_formatted }}</span>
              <span>{{ b.old_val }}</span>
              <span :class="b.new_val > b.old_val ? 'bid_i' : 'bid_d'">{{
                b.new_val
              }}</span>
              <span>{{ b.user_name }}</span>
              <span class="r-comment-12">{{ b.comment }}</span>
            </li>
          </ul>
        </div>
      </div>
    </ScreenBlocker>
  </div>
</template>

<script>
import moment from "moment";
import * as _ from "lodash";
import FilteringForm from "@/views/reports/shared/FilteringForm.reports";
import AdsetWizard from "../components/AdsetWizard.fb";
import Multiselect from "vue-multiselect";
import fields from "@/assets/fb-fields";

export default {
  props: {},
  components: {
    FilteringForm,
    AdsetWizard,
    Multiselect,
  },
  data() {
    let dtNow = new Date();
    fields["bid_computed"].type = Number;
    fields["bid_computed"].decimalDigits = 2;

    let filterableFields = Object.assign(
      {
        created_at: {
          title: "Created At",
          type: Date,
        },
        name: {
          title: "Adset Name",
          type: String,
        },
      },
      fields
    );
    delete filterableFields["created_at_formated"];

    return {
      checkboxSelected: [],
      resultsPage: 1,
      displayAdsetOpChanges: {
        adset: null,
        changes: null,
      },
      allAdsetsSelectedCheckbox: false,
      selectedAdsets: [],
      fbData: null,
      processingReport: false,
      processingCsvReport: false,
      form: {
        start_dt: dtNow,
        end_dt: new Date(),
        breakdowns: [],
      },
      searchForm: {
        excludeAdset: null,
        adset: null,
        ad: null,
        status: "ALL",
      },
      lastReportBreakdowns: [],
      blockScreen: false,
      toDuplicateAdset: null,
      filteringForm: [],
      selectedAccounts: [],
      allAccounts: null,
      ongoingActions: {
        toggleAdsetStatus: {},
        editAdset: {},
        editMultipleAdsets: false,
      },
      sort: {
        field: "inline_link_clicks",
        asc: false,
      },
      editAdsetPopup: {
        adset: null,
        field: null,
        value: null,
        max_field: null,
        max_field_value: null,
        min_field: null,
        min_field_value: null,
      },
      editMultipleAdsetsPopup: {
        adsets: null,
        field: null,
        value: null,
        setType: "absolute",
        max_field_value: null,
        min_field_value: null,
        edit_field_chosed: false,
        min_field_chosed: false,
        max_field_chosed: false,
      },
      metricsSelectCompatible: [
        "daily_budget_float",
        "active_users",
        "inline_link_clicks",
        "cost_per_inline_link_click",
        "ctr",
        "spend",
        "revenue_v2",
        "rpm_v2",
        "roi_v2",
        "profit_and_loss_v2",
        "user_value_v2",
        "bid_computed",
        "bid_strategy",
        "last_3_hours_roi",
      ].map((v) => ({ title: fields[v].title, value: v })),
      adsetWizardOpened: false,
      selectedDateRange: "",
      openAdsets: {},
      submitedBreakdowns: [],
      reportData: null,
      reportDataFiltered: null,
      breakdowns: [],
      breakdownOptions: ["date", "country", "ssp", "ssp_impressions"],
      fields,
      filterableFields,
      dateRangeOptions: [
        "Today",
        "Yesterday",
        "Last 7 days",
        "Last 30 days",
        "This Month",
        "Last Month",
      ],
      adsetMicroReport: {},
    };
  },
  watch: {
    ["searchForm.adset"](val) {
      this.filterReport();
    },
    ["searchForm.excludeAdset"](val) {
      this.filterReport();
    },
    ["searchForm.ad"](val) {
      this.filterReport();
    },
    filteringForm : {
      handler: function(val){
        if(this.reportData)
          this.filterReport();
      },
      deep: true
    }
  },
  computed: {
    fieldsList() {
      let arr = [];
      for (let key in this.fields) {
        let v = this.fields[key];
        if (this.breakdownOptions.includes(key)) {
          continue;
        }
        arr.push({
          title: v.title,
          value: key,
        });
      }

      return arr;
    },
    maxRowsView() {
      return 50;
    },
    maxResultPage() {
      return Math.ceil(
        this.reportDataFiltered.adsets.length / this.maxRowsView
      );
    },
    uiReportDisplay() {
      let resultsPage = this.resultsPage;
      let maxRowsView = this.maxRowsView;
      let rows = this.reportDataFiltered.adsets;
      if (window.location.href.includes("test=1")) {
        return rows;
      }
      let startIndex = (resultsPage - 1) * maxRowsView;
      return rows.slice(startIndex, startIndex + maxRowsView);
    },
    sumReport() {
      let adsets = this.reportDataFiltered.adsets;
      let totalReport = this.getTotalReport(adsets);
      return Object.assign({}, totalReport, {
        adsetsCount: this.getAdsetsCount(adsets),
        adsCount: this.getAdsCount(adsets),
      });
    },
  },
  filters: {
    formatTime(v) {
      if (!v) {
        return "n/a";
      }
      v = moment.utc(v);
      if (v.year() === 1) {
        return "n/a";
      }
      let isToday = v.isSame(new Date(), "day");
      if (isToday) {
        return "Today " + v.format("HH:mm") + " (Israel timezone)";
      } else {
        return v.format("MM-DD HH:mm");
      }
    },
  },
  methods: {
    addMetric(key) {
      return;
      this.metricsSelectCompatible.push({
        title: this.fields[key].title,
        value: key,
      });
    },
    viewAdsetOpChanges(adset, field) {
      this.displayAdsetOpChanges = {
        adset: adset,
        changes: null,
        fieldTitle: field === "bid" ? "bid" : "daily budget",
      };
      this.$http
        .get(this.resources.logs.adsetChanges(adset.id), {
          params: {
            field: field.toUpperCase(),
          },
        })
        .then((r) => r.json())
        .then((changes) => {
          this.displayAdsetOpChanges.changes = changes;
        })
        .catch((err) => {
          this.notifyError("Could not fetch bid changes");
          this.displayAdsetOpChanges.adset = null;
        });
    },
    onWizardClose() {
      this.adsetWizardOpened = false;
      this.toDuplicateAdset = null;
    },
    duplicateAdset(adset) {
      this.blockScreen = true;
      this.$http
        .get(this.resources.fb.adset(adset.account_id, adset.id))
        .then((r) => r.json())
        .then((data) => {
          this.toDuplicateAdset = data;
          this.adsetWizardOpened = true;
        })
        .catch((e) => {})
        .then(() => {
          this.blockScreen = false;
        });
    },
    confirmMultipleAdsetEdit() {
      let ef = this.editMultipleAdsetsPopup;
      let adsets = this.checkboxSelected;
      let max_field = "";
      let min_field = "";

      if (this.ongoingActions.editMultipleAdsets) {
        return;
      }
      if (
        !ef.edit_field_chosed &&
        !ef.min_field_chosed &&
        !ef.max_field_chosed
      ) {
        this.closeEditPopup();
        return;
      }
      if (
        ef.edit_field_chosed &&
        ef.setType == "absolute" &&
        (ef.value.bid_amount <= 0 || ef.value <= 0)
      ) {
        this.notifyError("bid amount or daily budget <= 0");
        return;
      }
      if (ef.max_field_chosed && ef.max_field_value <= 0) {
        this.notifyError("max field <= 0 ");
        return;
      }
      if (ef.min_field_chosed && ef.min_field_value <= 0) {
        this.notifyError("min field <= 0 ");
        return;
      }
      if (
        ef.min_field_chosed &&
        ef.max_field_chosed &&
        ef.max_field_value < ef.min_field_value
      ) {
        this.notifyError("max field < min field ");
        return;
      }

      let adsetsPayload = [];     
      let skipedAdsets=0;   

      adsets.forEach((adset) => {
        if (adset.updated_val <= 0) {
          skipedAdsets++;          
          return;
        }

        if (ef.field === "bid_computed") {
          max_field = "max_bid";
          min_field = "min_bid";
        }
        if (ef.field === "daily_budget_float") {
          max_field = "max_daily_budget";
          min_field = "min_daily_budget";
        }

        let params = {
          id: adset.id,
          account_id: adset.account_id,
          camp_id: adset.camp_id,
        };

        if (ef.max_field_chosed) {
          params[max_field] = ef.max_field_value;
        }
        if (ef.min_field_chosed) {
          params[min_field] = ef.min_field_value;
        }
        if (ef.edit_field_chosed) {
          params[ef.field] = adset.updated_val;
        }

        if (ef.field === "bid_computed" && ef.edit_field_chosed) {
          if (ef.value.bid_strategy === "LOWEST_COST_WITHOUT_CAP") {
            ef.value.bid_amount = 0;
          }
          params.bid_strategy = ef.value.bid_strategy;

          if (ef.setType === "absolute") {
            params.bid_amount = ef.value.bid_amount;
          } else if (ef.setType === "changeByPercentage") {
            let v = adset.bid_amount * (ef.value.bid_amount / 100);
            let v2 = v;
            (v = v * 100),
              (v = Math.round(v)),
              (v = v / 100),
              (v = v ? v : v2 > 0 ? 0.01 : -0.01);
            v = adset.bid_amount + v;
            if (v <= 0) {
              skipedAdsets++             
              return;
            }
            params.bid_amount = v;
          } else {
            params.bid_amount = adset.bid_amount + ef.value.bid_amount;
          }
          params.bid_amount =
            params.bid_amount && Number(params.bid_amount.toFixed(2));
          delete params[ef.field];
        }
        if (ef.field !== "status") {
          let bidValue;
          let maxValue;
          let minValue;
          if (ef.field === "daily_budget_float") {
            bidValue = params.bid_amount || params[ef.field] || adset[ef.field];
            maxValue = params[max_field] || adset["max_daily_budget"];
            minValue = params[min_field] || adset["min_daily_budget"];
          }
          if (ef.field === "bid_computed") {
            bidValue =
              params.bid_amount || params[ef.field] || adset["bid_amount"];
            maxValue = params[max_field] || adset["max_bid"];
            minValue = params[min_field] || adset["min_bid"];
          }

          if (bidValue > maxValue || bidValue < minValue) {
            skipedAdsets++;           
            return;
          }
        }        
        adsetsPayload.push(params);
      });    

      let params = {
        adsets: adsetsPayload,
      };
      this.ongoingActions.editMultipleAdsets = true;

      this.$http
        .put(this.resources.fb.adsets, params)
        .then(() => {
          adsets.forEach((adset) => {
            if (ef.field === "bid_computed" && ef.edit_field_chosed) {
              if (ef.setType === "changeBy") {
                adset.bid_amount += ef.value.bid_amount;
              } else if (ef.setType === "changeByPercentage") {
                let v = adset.bid_amount * (ef.value.bid_amount / 100);
                let v2 = v;
                (v = v * 100),
                  (v = Math.round(v)),
                  (v = v / 100),
                  (v = v ? v : v2 > 0 ? 0.01 : -0.01);
                v = adset.bid_amount + v;
                adset.bid_amount = v;
              } else {
                adset.bid_amount = ef.value.bid_amount;
              }
              adset.bid_amount =
                adset.bid_amount && Number(adset.bid_amount.toFixed(2));

              adset.bid_strategy = ef.value.bid_strategy;
              adset.updated_val = ef.value.bid_strategy.includes(
                "LOWEST_COST_WITHOUT_CAP"
              )
                ? "Lowest cost"
                : adset.bid_amount;
            }
            if (ef.max_field_chosed) {
              adset[max_field] = ef.max_field_value;
            }
            if (ef.min_field_chosed) {
              adset[min_field] = ef.min_field_value;
            }
            if (ef.edit_field_chosed) {
              adset[ef.field] = adset.updated_val;
            }
          });         
          this.closeEditPopup();         
          this.notifySuccess(`edited successfully with succeeded:${adsetsPayload.length} and skiped:${skipedAdsets}`);
        })
        .catch((err) => {
          this.notifyError(
            "An error has occured - invalid values for an adsets !"
          );
        })
        .then(() => {
          this.ongoingActions.editMultipleAdsets = false;
        });
    },
    closeEditPopup() {
      (this.editAdsetPopup = {
        adset: null,
        field: null,
        value: null,
        max_field: null,
        max_field_value: null,
        min_field: null,
        min_field_value: null,
      }),
        this.selectedAdsets.forEach((adset) => {
          delete adset["updated_val"];
        });
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: null,
        field: null,
        value: null,
        max_field_value: null,
        min_field_value: null,
        edit_field_chosed: false,
        min_field_chosed: false,
        max_field_chosed: false,
      });
    },
    onEditAdsetValueChange() {
      let ef = this.editMultipleAdsetsPopup;
      let val = ef.value;
      if (!val) {
        val = 0;
      }
      ef.adsets.forEach((ad) => {
        if (ef.setType === "changeBy") {
          ad["updated_val"] = ad[ef.field] + val;
        } else if (ef.setType === "changeByPercentage") {
          ad["updated_val"] =
            ad[ef.field] + Math.floor(ad[ef.field] * (val / 100));
        } else {
          ad["updated_val"] = val;
        }
      });
    },
    toggleSelectAllAdsets() {
      let adsets = this.reportDataFiltered.adsets;
      if (!this.allAdsetsSelectedCheckbox) {
        this.selectedAdsets = [];
      } else {
        this.selectedAdsets = adsets.slice(0);
      }
    },
    actionSetActive() {
      if (this.selectedAdsets.length === 0) {
        return;
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: "status",
        value: "ACTIVE",
        edit_field_chosed: true,
      });
      this.selectedAdsets.forEach((adset) => {
        adset.updated_val = "ACTIVE";
      });
      this.checkboxSelected = this.selectedAdsets;
    },
    actionSetInactive() {
      if (this.selectedAdsets.length === 0) {
        return;
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: "status",
        value: "PAUSED",
        edit_field_chosed: true,
      });
      this.selectedAdsets.forEach((adset) => {
        adset.updated_val = "PAUSED";
      });
      this.checkboxSelected = this.selectedAdsets;
    },
    actionSetDailyBudget() {
      if (this.selectedAdsets.length === 0) {
        return;
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: "daily_budget_float",
        value: 0,
        max_field_value: this.fields["daily_budget_float"].max_field_value,
        min_field_value: this.fields["daily_budget_float"].min_field_value,
        edit_field_chosed: false,
        min_field_chosed: false,
        max_field_chosed: false,
      });
      this.checkboxSelected = this.selectedAdsets;
    },
    actionSetBid() {
      if (this.selectedAdsets.length === 0) {
        return;
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: "bid_computed",
        value: {
          bid_amount: 0,
          bid_strategy: "LOWEST_COST_WITH_BID_CAP",
        },
        max_field_value: this.fields["bid_computed"].max_field_value,
        min_field_value: this.fields["bid_computed"].min_field_value,
        edit_field_chosed: false,
        min_field_chosed: false,
        max_field_chosed: false,
      });
      this.checkboxSelected = this.selectedAdsets;
    },
    getAdsetExternalLink(adset) {
      let today = moment();
      let d1 = today.format("YYYY-MM-DD");
      let d2 = today.add(1, "days").format("YYYY-MM-DD");
      let dateStr = `${d1}_${d2}`;
      return `https://business.facebook.com/adsmanager/manage/adsets?act=${adset.account_id}&&columns=name%2Cdelivery%2Ccampaign_name%2Cbid%2Cbudget%2Clast_significant_edit%2Cresults%2Creach%2Cimpressions%2Ccost_per_result%2Cquality_score_organic%2Cquality_score_ectr%2Cquality_score_ecvr%2Cspend%2Cend_time%2Cschedule%2Cfrequency%2Cunique_actions%3Alink_click%2Cactions%3Alike%2Cactions%3Alink_click%2Cactions%3Aoffsite_conversion.fb_pixel_purchase%2Cactions%3Aonsite_conversion.purchase&attribution_windows=default&date=${dateStr}%2Ctoday&comparison_date=&filter_set=SEARCH_BY_CAMPAIGN_ID-STRING%1EEQUAL%1E"${adset.id}"`;
    },
    openEditPopup(adset, f, max_field_name, min_field_name) {
      let value;
      if (f === "bid_computed") {
        value = {
          bid_amount: adset.bid_amount,
          bid_strategy: adset.bid_strategy,
        };
      } else {
        value = adset[f];
      }
      this.editAdsetPopup = {
        adset: adset,
        field: f,
        value: value,
        max_field: max_field_name,
        max_field_value:
          adset[max_field_name] !== 0
            ? adset[max_field_name]
            : this.fields[f].max_field_value,
        min_field: min_field_name,
        min_field_value:
          adset[min_field_name] !== 0
            ? adset[min_field_name]
            : this.fields[f].min_field_value,
      };
    },
    confirmAdsetEdit() {
      let ef = this.editAdsetPopup;
      let adset = ef.adset;
      let adsetId = adset.id;

      if (this.ongoingActions.editAdset[adsetId]) {
        return;
      }

      this.$set(this.ongoingActions.editAdset, adsetId, true);

      let params = {
        [ef.field]: ef.value,
        [ef.max_field]: ef.max_field_value,
        [ef.min_field]: ef.min_field_value,
        camp_id: adset.campaign_id,
        account_id: adset.account_id,
      };

      if (ef.field === "bid_computed") {
        if (ef.value.bid_strategy === "LOWEST_COST_WITHOUT_CAP") {
          ef.value.bid_amount = 0;
        }
        params.bid_strategy = ef.value.bid_strategy;
        params.bid_amount = ef.value.bid_amount;
        delete params[ef.field];
      }

      this.$http
        .put(this.resources.fb.adsetUpdate(adsetId), null, { params })
        .then(() => {
          if (ef.field === "bid_computed") {
            adset.bid_amount = ef.value.bid_amount;
            adset.bid_strategy = ef.value.bid_strategy;
            ef.value = ef.value.bid_strategy.includes("LOWEST_COST_WITHOUT_CAP")
              ? "Lowest cost"
              : ef.value.bid_amount;
          }
          adset[ef.field] = ef.value;
          adset[ef.max_field] = ef.max_field_value;
          adset[ef.min_field] = ef.min_field_value;
          this.closeEditPopup();
        })
        .catch((err) => {
          console.error(err);
          this.notifyError("An error has occured");
        })
        .then(() => {
          this.ongoingActions.editAdset[adsetId] = false;
        });
    },
    toggleAdsetStatus(adset) {
      if (!this.userHasPermission("fb.edit")) {
        return;
      }

      let adsetID = adset.id;
      if (this.ongoingActions.toggleAdsetStatus[adsetID]) {
        return;
      }

      this.ongoingActions.toggleAdsetStatus[adsetID] = true;

      let status = adset.status;
      let toStatus;
      if (status == "ACTIVE") {
        toStatus = "PAUSED";
      } else {
        toStatus = "ACTIVE";
      }

      let params = {
        status: toStatus,
        account_id: adset.account_id       
      };

      this.$http
        .put(this.resources.fb.adsetUpdate(adsetID), null, { params })
        .then(() => {
          window.setTimeout(() => {
            adset.status = toStatus;
            this.ongoingActions.toggleAdsetStatus[adsetID] = false;
          }, 20);
        })
        .catch((err) => {
          this.notifyError("An error has occured");
          this.ongoingActions.toggleAdsetStatus[adsetID] = false;
        });
    },
    selectAccount(account) {
      this.selectedAccounts = account;
    },
    onColumnClick(field) {
      if (this.sort.field === field) {
        this.sort.asc = !this.sort.asc;
      } else {
        this.sort.field = field;
        this.sort.asc = false;
      }
      this.sortReport();
    },
    async fetchFbData(attempt = 0) {
      if (this.$store.state.data.fbData) {
        this.fbData = this.$store.state.data.fbData;
        this.allAccounts = this.fbData.accounts;
        return;
      }
      try {
        var pagesResponse = await this.$http.get(this.resources.cs.getPermittedFacebookPages);
        var pages = await pagesResponse.json();
        const accountsResponse = await this.$http.get(
          this.resources.cs.allAccounts("facebook")
        );
        let accounts = await accountsResponse.json();
        if (!accounts || accounts.length === 0) {
          return;
        }
        accounts.sort((a, b) => (a.name > b.name ? 1 : -1));
        const data = {
          accounts,
          pages
        };
        this.$store.state.data.fbData = data;
        this.fbData = data;
        this.allAccounts = accounts;
      } catch (err) {
        console.error(err);
        if (attempt > 10) {
          this.notifyError("Could not load all FB data");
          return;
        }
        window.setTimeout(() => {
          this.fetchFbData(attempt + 1);
        }, 2000);
      }
    },
    arrangeReport(data) {
      let adsets = data.adsets;

      for (let key in adsets) {
        let adset = adsets[key];
        adset.created_at_formated = moment(adset.created_at).format(
          "DD/MM HH:mm"
        );
        adset.bid_computed = adset.bid_strategy.includes(
          "LOWEST_COST_WITHOUT_CAP"
        )
          ? "Lowest cost"
          : adset.bid_amount;
      }
      return data;
    },
    sortReport() {
      if (this.resultsPage > 1) {
        this.resultsPage = 1;
      }

      let data = this.reportDataFiltered;
      if (!data || !data.adsets) {
        return;
      }

      let sort = this.sort;
      if (!sort.field) {
        return;
      }

      let sortField = sort.field;
      if (sort.field === "created_at_formated") {
        sortField = "created_at";
      }

      data.adsets.sort((a, b) => {    
        a = a[sortField];
        b = b[sortField];      
        
        if (sortField === "bid_computed") {         
          if (typeof a === "number" && typeof b === "string") {
            return sort.asc==true?1:-1;
          }
          if (typeof a === 'string' && typeof b === 'number') {
           return sort.asc==true?-1:1;
         }         
        }
        
        let f;
        if (a > b) {          
          f = 1;
        } else if (a < b) {
          f = -1;
        } else {
          f = 0;
        }

        if (!sort.asc) {
          f = f * -1;
        }

        return f;
      });
    },
    filterReport() {
      if (this.resultsPage > 1) {
        this.resultsPage = 1;
      }
      let adsetSearch = this.searchForm.adset;
      let excludeAdsetSearch = this.searchForm.excludeAdset;
      let adSearch = this.searchForm.ad;
      let reportData = this.reportData;
      let status = this.searchForm.status;

      let data = Object.assign({}, reportData);
      data.adsets = reportData.adsets;

      if (adsetSearch) {
        adsetSearch = adsetSearch.toLowerCase();
        data.adsets = data.adsets.filter(
          (v) =>
            v.id == adsetSearch || v.name.toLowerCase().includes(adsetSearch)
        );
      }
      if (excludeAdsetSearch) {
        excludeAdsetSearch = excludeAdsetSearch.toLowerCase();
        data.adsets = data.adsets.filter(
          (v) =>
            v.id != excludeAdsetSearch &&
            !v.name.toLowerCase().includes(excludeAdsetSearch)
        );
      }

      if (status == "ACTIVE" || status == "PAUSED") {
        data.adsets = data.adsets.filter((v) => v.status === status);
      }

      let filteringForm = this.filteringForm;

      data.adsets = data.adsets.filter((adset) => {
        let passedFilters = true;

        for (let i = 0; i < filteringForm.length; i++) {
          let fo = filteringForm[i];

          let field = fo.field;
          let givenVal = adset[field];
          let requiredVal = fo.val;
          let op = fo.operator;

          if (!field || !requiredVal || !op) {
            continue;
          }

          if (field === "created_at") {
            givenVal = givenVal.substr(0, givenVal.indexOf("T"));
          }

          if (op === "includes") {
            passedFilters = givenVal
              .toLowerCase()
              .includes(requiredVal.toLowerCase());
          } else if (op === "excludes") {
            passedFilters = !givenVal
              .toLowerCase()
              .includes(requiredVal.toLowerCase());
          } else if (op === ">") {
            passedFilters = givenVal > requiredVal;
          } else if (op === "<") {
            passedFilters = givenVal < requiredVal;
          } else {
            passedFilters = givenVal == requiredVal;
          }

          if (!passedFilters) {
            break;
          }
        }

        return passedFilters;
      });

      this.reportDataFiltered = data;
    },
    getTotalReport(adsets) {
      let r = {
        daily_budget_float: 0,
        impressions: 0,
        clicks: 0,
        spend: 0,
        revenue: 0,
        profit_and_loss: 0,
        inline_link_clicks: 0,
        admanager_rev: 0,
        primis_rev: 0,
        aniview_rev: 0,
        ctr: 0,
        aniview_imps: 0,
        avantis_rev: 0,
        avantis_imps: 0,
        vidazoo_rev: 0,
        vidazoo_imps: 0,
        underdog_rev: 0,
        underdog_imps: 0,
        rise_rev: 0,
        active_users: 0,
        total_page_views: 0,
        rpm: 0,

        header_bidding_revenue: 0,
        a9_revenue: 0,
        dfp_revenue: 0,
        revenue_v2: 0,
        profit_and_loss_v2: 0,
        user_value_v2: 0,
        roi_v2: 0,

        sessions: 0,
        page_views: 0,
        pages_per_session: 0,
      };

      let ctr = 0;

      adsets.forEach((adset) => {
        r.daily_budget_float += adset.daily_budget_float;
        r.impressions += adset.impressions;
        r.clicks += adset.clicks;
        r.spend += adset.spend;
        r.revenue += adset.revenue;
        r.inline_link_clicks += adset.inline_link_clicks;
        r.admanager_rev += adset.admanager_rev;
        r.primis_rev += adset.primis_rev;
        r.aniview_rev += adset.aniview_rev;
        r.aniview_imps += adset.aniview_imps;
        r.avantis_rev += adset.avantis_rev;
        r.avantis_imps += adset.avantis_imps;
        r.vidazoo_rev += adset.vidazoo_rev;
        r.vidazoo_imps += adset.vidazoo_imps;
        r.underdog_rev += adset.underdog_rev;
        r.underdog_imps += adset.underdog_imps;
        r.rise_rev += adset.rise_rev;
        r.active_users += adset.active_users;
        r.dfp_revenue += adset.dfp_revenue;
        r.revenue_v2 += adset.revenue_v2;
        r.header_bidding_revenue += adset.header_bidding_revenue;
        r.a9_revenue += adset.a9_revenue;
        r.total_page_views +=
          adset.inline_link_clicks * adset.page_views_per_session;

        r.sessions += adset.sessions;
        r.page_views += adset.page_views;

        ctr += adset.ctr;
      });

      if (r.inline_link_clicks > 0) {
        r.page_views_per_session = r.total_page_views / r.inline_link_clicks;
        if (r.total_page_views > 0) {
          r.rpm = (r.revenue / r.total_page_views) * 1e3;
        }
      }

      if (adsets.length > 0) {
        ctr = ctr / adsets.length;
      }

      r.ctr = ctr;

      r.profit_and_loss = r.revenue - r.spend;
      r.profit_and_loss_v2 = r.revenue_v2 - r.spend;

      if (r.spend > 0) {
        r.roi = (r.revenue / r.spend) * 100;
        r.roi_v2 = (r.revenue_v2 / r.spend) * 100;
      }

      if (r.impressions > 0) {
        r.ctr = (r.inline_link_clicks / r.impressions) * 100;
      }

      if (r.clicks > 0) {
        r.cpc = r.spend / r.clicks;
      }
      if (r.inline_link_clicks > 0) {
        r.user_value = r.revenue / r.inline_link_clicks;
        r.user_value_v2 = r.revenue_v2 / r.inline_link_clicks;
        r.cost_per_inline_link_click = r.spend / r.inline_link_clicks;
      }

      if (r.sessions > 0) {
        r.pages_per_session = r.page_views / r.sessions;
      }

      if (r.page_views > 0) {
        r.rpm_v2 = (r.revenue_v2 / r.page_views) * 1000;
      }

      return r;
    },
    getAdsCount(adsets) {
      if (!adsets) {
        return 0;
      }
      let c = 0;
      adsets.forEach((adset) => {
        c += adset.ads ? adset.ads.length : 0;
      });
      return c;
    },
    getAdsetsCount(adsets) {
      if (!adsets) {
        return 0;
      }
      return Object.keys(adsets).length;
    },
    setFixedDateRange(val) {
      this.selectedDateRange = val;
      let dtNow = new Date();
      let endDt, startDt;
      let d = new Date();
      let ms = new Date();
      let lms = new Date();

      switch (val) {
        case "Today":
          startDt = dtNow;
          endDt = d;
          break;

        case "Yesterday":
          d.setDate(dtNow.getDate() - 1);
          startDt = d;
          endDt = d;
          break;

        case "Last 7 days":
          d.setDate(dtNow.getDate() - 7);
          startDt = d;
          endDt = dtNow;
          break;

        case "Last 30 days":
          d.setDate(dtNow.getDate() - 30);
          startDt = d;
          endDt = dtNow;
          break;

        case "This Month":
          d.setDate(1);
          startDt = d;
          endDt = dtNow;
          break;

        case "Last Month":
          ms.setDate(0);
          lms.setDate(0);
          lms.setDate(1);
          startDt = lms;
          endDt = ms;
          break;
      }

      this.form.start_dt = startDt;
      this.form.end_dt = endDt;
    },
    isAdsetOpen(id) {
      return this.openAdsets[id];
    },
    cleanNotNeededMetrics() {
      this.breakdowns.forEach((breakdown) => {
        if (breakdown === "ssp_impressions") {
          return;
        }
        for (let k in this.adsetMicroReport) {
          let v = this.adsetMicroReport[k].breakdown;
          if (v === breakdown) {
            return;
          }
        }
        if (breakdown === "ssp") {
          this.breakdowns.splice(this.breakdowns.indexOf("ssp_impressions"), 1);
        }
        this.breakdowns.splice(this.breakdowns.indexOf(breakdown), 1);
      });
    },
    toggleAdsetExpand(adset, breakdown) {
      let lastReport = this.adsetMicroReport[adset.id];
      if (lastReport && lastReport.breakdown === breakdown) {
        this.$delete(this.adsetMicroReport, adset.id);
        this.cleanNotNeededMetrics();
        return;
      }
      if (!this.breakdowns.includes(breakdown)) {
        this.breakdowns.push(breakdown);
      }

      if (breakdown === "ssp") {
        this.breakdowns.push("ssp_impressions");
        if (!adset.detailed_revenue) {
          return;
        }
        let sspBreakdownRows = [];
        for (let ssp in adset.detailed_revenue) {
          let val = adset.detailed_revenue[ssp];
          let r = val && val.rev;
          let ssp_impressions = val.impressions;
          if (!r) {
            continue;
          }
          ssp = this.$options.filters.prettify_string(ssp);
          let rpm;
          if (ssp_impressions > 0) {
            rpm = (r / ssp_impressions) * 1e3;
          }
          sspBreakdownRows.push({
            ssp: ssp,
            revenue_v2: r,
            rpm_v2: rpm,
            ssp_impressions,
          });
        }
        sspBreakdownRows.sort((a, b) =>
          a.revenue_v2 > b.revenue_v2 ? -1 : b.revenue_v2 > a.revenue_v2 ? 1 : 0
        );

        this.$set(this.adsetMicroReport, adset.id, {
          breakdown: breakdown,
          adsets: sspBreakdownRows,
        });

        this.cleanNotNeededMetrics();

        return;
      }

      let payload = {
        start_dt: moment(adset.start_dt).format("YYYY-MM-DD"),
        end_dt: moment(adset.end_dt).format("YYYY-MM-DD"),
        campaign_id: adset.id,
        breakdowns: [breakdown],
      };
      if (breakdown === "date") {
        let dtNow = new Date();
        let d = new Date();
        d.setDate(dtNow.getDate() - 7);
        let startDt = d;
        let endDt = dtNow;
        payload.start_dt = moment(startDt).format("YYYY-MM-DD");
        payload.end_dt = moment(endDt).format("YYYY-MM-DD");
      }

      this._fetchReport(payload)
        .then((r) => r.json())
        .then((data) => {
          let adsets = data.adsets;
          if (breakdown === "hour") {
            let keepObjects = Object.values(data.adsets)
              .sort((a, b) => (a.hour > b.hour ? -1 : 1))
              .slice(0, 4);
            adsets = {};
            for (let i = 0; i < keepObjects.length; i++) {
              adsets[i] = keepObjects[keepObjects.length - i - 1];
            }
          }
          this.addMetric(breakdown);
          this.$set(this.adsetMicroReport, adset.id, {
            breakdown: breakdown,
            adsets: adsets,
          });
          this.cleanNotNeededMetrics();
        });
    },
    _fetchReport(params) {
      return this.$http.get(this.resources.Reports.fbCampAllInsights, {
        params,
      });
    },
    getReportParams() {
      let accountIds = this.selectedAccounts.length
        ? this.selectedAccounts.map((v) => v.id)
        : this.allAccounts.map((v) => v.id);

      let params = {
        start_dt: moment(this.form.start_dt).format("YYYY-MM-DD"),
        end_dt: moment(this.form.end_dt).format("YYYY-MM-DD"),
        account_ids: accountIds,
        breakdowns: this.breakdowns,
      };

      return params;
    },
    downloadReportCsv: async function () {
      this.processingCsvReport = true;
      const adsets = this.reportDataFiltered.adsets;
      const fieldsKeys = Object.keys(this.fields);
      const metrics = [...["Id", "Name", "Status", "Is Learning"]
      .map(m => ({title: m, value: _.snakeCase(m) })),
      ...this.metricsSelectCompatible.map((v) => v)
      .sort((a, b) =>fieldsKeys.indexOf(a.value) > fieldsKeys.indexOf(b.value) ? 1 : -1)];
      const rows = adsets.map(adset => (
{
  cells:
  metrics.map(metric => ({
    coloumnName: metric.title,
    value: adset[metric.value].toString()
  }))
}));
      const data = {
        header: metrics.map(metric => metric.title),
        rows: rows,
      };
      try {
        const response = await this.$http.post(
          this.resources.cs.csv,
          JSON.stringify(data),
          { withCredentials: false },
          {
            responseType: "blob",
          }
        );

        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "file.csv");
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (err) {
        console.error(err);
        this.notifyError("Could not download csv file.");
      }
      this.processingCsvReport = false;
    },
    fetchReport() {
      this.breakdowns = [];
      this.adsetMicroReport = {};
      this.selectedAdsets = [];
      this.allAdsetsSelectedCheckbox = false;

      let params = this.getReportParams();

      this.processingReport = true;
      this._fetchReport(params)
        .then((r) => r.json())
        .then((data) => {
          data = data || {};
          data.adsets = data.adsets || {};
          data = this.arrangeReport(data);
          data.adsets = Object.values(data.adsets);
          this.reportData = data;
          this.filterReport();
          this.sortReport();

          let bd = this.breakdowns;
          let metrics = this.metricsSelectCompatible;
          this.lastReportBreakdowns = bd;
        })
        .catch(() => {})
        .then(() => {
          this.processingReport = false;
        });
    },
  },
  created() {
    this.fetchFbData();
    this.setFixedDateRange("Today");
  },
};
</script>
