<template>
  <div class="allContainer">
    <div class="app-load-container" v-if="!initialLoad">
      <div class="app-load-box">
        <h2>Loading Dashboard...</h2>
        <Spinner class="app-load" />
      </div>
    </div>
    <div class="allContainer" v-else>
      <div
        v-if="notify && notify.msg"
        :class="{
          'top-alert': true,
          error: notify.error,
          warning: notify.type === 'WARNING',
          'slide-away': notify.slideAway,
        }"
      >
        <h1>{{ notify.msg }}</h1>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "app",
  computed: {
    initialLoad() {
      return this.$store.state.initialLoad;
    },
    notify() {
      return this.appState.notify;
    },
  },
  created() {
    if (process.env.NODE_ENV === "development") {
      window.document.title += " Development";
    }
  },
};
</script>

<style>
/* Import Font Awesome Icons Set */
$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/css/font-awesome.min.css";
/* Import Simple Line Icons Set */
$simple-line-font-path: "~simple-line-icons/fonts/";
@import "~simple-line-icons/css/simple-line-icons.css";
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
</style>

<style lang="scss">
// Import Main styles for this application
@import "./scss/style";
.app-load {
  font-size: 48px !important;
  color: #b3b3b3;
}
.app-load-container {
  margin-top: 20%;
  display: flex;
}
.app-load-box {
  margin: 0 auto;
  text-align: center;
}
* {
  font-family: tahoma;
}

.btn {
  border-radius: 2px;
}
.btn-primary {
  color: #fff;
  background-color: #1d8fb7;
  border-color: #2999c0;
}
.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: #ededed;
  border: 1px solid #f2f2f2;
  color: #949494;
  border-radius: 2px;
}
.close-btn:hover {
  color: #565656;
  background: #e2e2e2;
}
.selected-items {
  background: #f1f0f0;
  padding: 2px 6px;
  border: 1px solid gainsboro;
  border-radius: 4px;
  max-width: 196px;
  display: flex;
  flex-wrap: wrap;
}
.selected-items > li {
  margin-right: 3px;
  padding: 2px 7px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}
.selected-items > li:hover {
  background: #dfdfdf;
  cursor: pointer;
}

.percent::after {
  content: "%";
}
span.dollar::after {
  content: "$";
}
.noafter::after {
  content: "" !important;
}
</style>

<style scoped>
.top-alert {
  position: fixed;
  top: 5px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  z-index: 999999;
}
.top-alert > h1 {
  background: #e2ffdc;
  padding: 8px 67px;
  font-size: 18px;
  color: #105800;
  min-width: 444px;
  text-align: center;
  border: 1px solid #d8dcd7;
  animation-name: example;
  animation-duration: 0.4s;
  height: 40px;
}
.top-alert.slide-away > h1 {
  animation-name: example2;
  animation-duration: 1s;
  transform: translateY(-45px);
}
.top-alert.error > h1 {
  background: #f4d3d3;
  color: #834747;
  border: 1px solid #cba5a5;
}
.top-alert.warning > h1 {
  background: #ffc355;
  color: #8e5c00;
  border: 1px solid #cba5a5;
  font-size: 19px;
}

@keyframes example {
  from {
    transform: translateY(-45px);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes example2 {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-45px);
  }
}
.x-btn {
  border: 0;
}
</style>

<style lang="scss" type="text/css">
@import "scss/styles/report";
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
