var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"botAllPage"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(this.userHasPermission('automation.edit')),expression:"this.userHasPermission('automation.edit')"}]},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-add",modifiers:{"modal-add":true}}],staticClass:"float-left",attrs:{"variant":"outline-success"},on:{"click":function($event){_vm.modalShow = true}}},[_vm._v(" + Add Rule ")])],1),(_vm.modalShow)?_c('botForm',{attrs:{"editMode":_vm.isEdit,"row":_vm.editRow,"addNewRow":_vm.addRule,"deleteRow":_vm.deleteRule,"updateRow":_vm.updateRule},model:{value:(_vm.modalShow),callback:function ($$v) {_vm.modalShow=$$v},expression:"modalShow"}}):_vm._e(),_c('b-table',{ref:"selectableTable",attrs:{"id":"reportTable","items":_vm.allOperations,"fields":_vm.dataFields,"selectable":"","select-mode":"single","responsive":"sm","selected-variant":"primary","filter":_vm.search,"filter-included-fields":['title'],"busy":_vm.tableBusy,"caption-top":"","hover":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"table-caption",fn:function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"text","placeholder":"Search.."},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing){ return; }_vm.search=$event.target.value}}})]},proxy:true},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(filters)",fn:function(ref){
var value = ref.value;
return [_c('div',{staticClass:"table-column-span"},_vm._l((value),function(f){return _c('span',{staticClass:"span-in-span capitalize"},[_vm._v(_vm._s(_vm.fields[f.field] && _vm.fields[f.field].title)+" "+_vm._s(f.operator)+" "+_vm._s(_vm._f("formatColumnValue")(f.val,_vm.fields[f.field] || {}))+" ")])}),0)]}},{key:"cell(active)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',{staticClass:"table-column-span"},[_c('span',{staticClass:"span-in-span"},[_c('span',{class:value == 'ACTIVE'
              ? 'activeRule'
              : 'disabledRule',on:{"click":function($event){_vm.shouldToggleStatus = true}}},[_vm._v(_vm._s(value))])]),(item.forApprove)?_c('span',{staticClass:"span-in-span"},[_vm._v("Waiting To Approve")]):_vm._e()])]}},{key:"cell(actions)",fn:function(ref){
              var value = ref.value;
return [_c('div',{staticClass:"table-column-span"},_vm._l((value),function(action){return _c('span',{staticClass:"span-in-span capitalize"},[_vm._v(" "+_vm._s(_vm._f("formatAction")(action))+" ")])}),0)]}},{key:"cell(startTimeExtended)",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"table-column-span"},_vm._l((item.when.startTimeExtended),function(t){return _c('span',{staticClass:"span-in-span capitalize"},[_vm._v(" "+_vm._s(_vm._f("wholeDate")(t))+" ")])}),0)]}},{key:"cell(manage_actions)",fn:function(ref){
              var item = ref.item;
return [(_vm.userHasPermission('automation.approve') && item.forApprove)?_c('b-button',{staticClass:"m-1",attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.approveRule(item)}}},[_c('b-icon',{attrs:{"icon":"check2-square"}}),_vm._v(" Approve ")],1):_vm._e(),_c('b-button',{staticClass:"m-1",attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.duplicateRule(item)}}},[_c('b-icon',{attrs:{"icon":"files"}}),_vm._v(" Duplicate ")],1),_c('b-button',{staticClass:"m-1",attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.deleteRule(item.id,item.forApprove)}}},[_c('b-icon',{attrs:{"icon":"trash-fill"}}),_vm._v(" Delete ")],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }