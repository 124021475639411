<template lang="html">
  <div class="">
    <h3>Taboola Report</h3>
    <section class="report-form">
      <div class="">
        <span>Choose Account:</span>
        <select class="def" v-model='selectedAccount' @change='filterReport'>
          <option :value='null'>ALL</option>
          <option v-for='account in allAccounts' :value='account'>{{account.name}}</option>
        </select>
      </div>

      <div class="date-range-3">
        <span class="s-151">Choose Date Range:</span>
        <div class="">

          <div class="div-15">
            <i class="active-circle" v-show='selectedDateRange'></i>
            <ul class="date-range-options-list">
              <li v-for='v in dateRangeOptions' @click='setFixedDateRange(v)' :class='{active: v === selectedDateRange}'>{{v}}</li>
            </ul>
          </div>

          <div class="">
            <div class="div-15">
              <i class="active-circle" v-show='!selectedDateRange'></i>
              <span>Or custom date range:</span>
            </div>
            <div class="date-range-241">
              <DatePicker :onChange='v => { form.start_dt = v; selectedDateRange = null } ' :value='form.start_dt' />
              <DatePicker :onChange='v => { form.end_dt = v; selectedDateRange = null } ' :value='form.end_dt' />
            </div>
          </div>
        </div>
      </div>

      <div class="search-box-container date-range-3">
        <span class="s-5231">Search</span>
        <div class="">
          <label for="">Search adsets:</label>
          <input v-model='searchForm.adset' class="def">
        </div>
        <div class="">
          <label for="">Exclude adsets:</label>
          <input v-model='searchForm.excludeAdset' class="def">
        </div>

        <div class="">
          <label for="">Campaign Status: </label>
          <select class="def" v-model='searchForm.status' @change='filterReport'>
            <option value="ALL">ALL</option>
            <option value="RUNNING">ACTIVE</option>
            <option value="PAUSED">PAUSED</option>
          </select>
        </div>
      </div>

      <div class="search-box-container date-range-3">
        <FilteringForm :filteringForm='filteringForm' :fields='filterableFields' />
      </div>

      <div class="b-2412">
        <span class="s-325">Metrics:</span>

        <ul class="metrics-list">
          <li class="metric-item" v-for='metric, metricKey in fields' v-show='!breakdownsOptions.includes(metricKey)'>
            <label :for='metricKey'>{{metric.title}}</label>
            <input v-model='metrics' :id='metricKey' :value="metricKey" type="checkbox">
          </li>
        </ul>
      </div>

      <!-- <div class="b-2412" v-show='0'>
        <span class="s-325">Breakdowns:</span>

        <ul class="breakdowns-list">
          <li class="">
            <label for="">Date</label>
            <input v-model='form.breakdowns' value="date" type="checkbox">
          </li>
          <li class="">
            <label for="">Hour</label>
            <input v-model='form.breakdowns' value="hour" type="checkbox">
          </li>
        </ul>
      </div> -->

      <br>

      <div class="btns-141">
        <button type="button" class="btn btn-success" @click='fetchReport' :disabled='processingReport'>
          Run Report
          <Spinner :def='0' v-show='processingReport' />
        </button>
        <button type="button" v-if='userHasPermission("taboola.create")' class="btn btn-primary create-camp-btn" @click='e => {adsetWizardOpened=true}'>Create Campaign</button>
      </div>

    </section>
    <br>
    <div v-if='reportDataFiltered' class="report-table">
      <ul class="adsets-list">
        <li class="actions-row-12">
          <div class="adsets-selection-c1">
            <input type="checkbox" id="s-25124" v-show='false' @change='toggleSelectAllAdsets' v-model='allAdsetsSelectedCheckbox'>
            <label for="s-25124" class="s-checkbox-4 s-checkbox-4-panel" :class='{checked: allAdsetsSelectedCheckbox}'>
              <i class="fa fa-check-square" aria-hidden="true"></i>
            </label>
            <span>
              {{selectedAdsets.length}} Adsets selected
            </span>
          </div>
          <div class="actions-column flex">
            <button @click='actionSetActive' class="btn action-btn-25" type="button">Set Active</button>
            <button @click='actionSetInactive' class="btn action-btn-25" type="button">Set Inactive</button>
            <button @click='actionSetDailyBudget' class="btn action-btn-25" type="button">Set Daily Budget</button>
            <button @click='actionSetBid' class="btn action-btn-25" type="button">Set Bid</button>
          </div>
        </li>

        <li class="row12 header-row header-row-after">
          <span class="checkbox-column"></span>
          <span class="external-link-td"></span>
          <span class="plus-td expand-column"></span>
          <span class="row-id">ID</span>
          <span class="row-name">Name</span>
          <span class="status-column">Status</span>
          <span v-for='_, f in fields' v-show='metrics.includes(f)' @click='onColumnClick(f)'
           class="sortable-column" :class='{active: sort.field === f}'>
            {{fields[f].title}}
          </span>
        </li>
        <li class="row12 sum-row">
          <span class="checkbox-column"></span>
          <span class="external-link-td"></span>
          <span class="plus-td expand-column"></span>
          <span class="row-id"></span>
          <span class="row-name">{{sumReport["adsetsCount"]}} Adsets</span>
          <span class="status-column"></span>
          <span v-for='_, f in fields' v-show='metrics.includes(f)' :class='Object.assign({}, fields[f].getClass && fields[f].getClass(sumReport[f]))'>
            {{sumReport[f] | ifFloatDecimalN(fields[f].decimalDigits || 4) | suffix(fields[f].after) }}
          </span>
        </li>
        <template v-for='adset in reportDataFiltered.adsets'>
          <li class="row12 row12-th value-row">
            <span class="checkbox-column">
              <input type="checkbox" v-show='false' :id='adset.id' :value='adset' v-model='selectedAdsets'>
              <label :for='adset.id' class="s-checkbox-4 s-checkbox-4-fill-container " :class='{checked: selectedAdsets.includes(adset)}'>
                <i class="fa fa-check-square" aria-hidden="true"></i>
              </label>
            </span>
            <span class="external-link-td">
              <a :href='getAdsetExternalLink(adset)' target="_blank">
                <i class="fa fa-external-link ext-link-to-platform" aria-hidden="true"></i>
              </a>
            </span>
            <span class="plus-td expand-column expand-column-block">
              <template>
                <button title="Expand last 7 days" class="def expand-btn-3" @click='toggleAdsetExpand(adset, "date")'>D</button>
                <button class="def expand-btn-3" @click='toggleAdsetExpand(adset, "ssp")'>R</button>
              </template>
            </span>
            <span class="row-id">{{adset.id}}</span>
            <span class="row-name">{{adset.name}}</span>
            <span :class='{"status-column": true}'>
              <i @click='toggleAdsetStatus(adset)' v-if='adset["status"] === "RUNNING"' class="toggle-icon fa fa-toggle-on" aria-hidden="true"></i>
              <i @click='toggleAdsetStatus(adset)' v-else class="toggle-icon fa fa-toggle-off" aria-hidden="true"></i>
            </span>
            <span v-for='_, f in fields' v-show='metrics.includes(f)' :class='Object.assign({}, fields[f].getClass && fields[f].getClass(adset[f]))'>
              <span class="edit-column-btn" v-if='userHasPermission("taboola.edit") && fields[f].editable'>
                <i class="fa fa-info-circle" aria-hidden="true" title="View last bid changes" @click='viewAdsetOpChanges(adset, f)'></i>
                <i class="fa fa-pencil-square-o" aria-hidden="true" @click='openEditPopup(adset, f)'></i>
              </span>
              <span :class='fields[f].getClass && fields[f].getClass(adset[f])'>{{adset[f] | ifFloatDecimalN(fields[f].decimalDigits || 4) | suffix(fields[f].after)}}</span>
              </span>
          </li>
          <li v-for='row in adsetMicroReport[adset.id] && adsetMicroReport[adset.id].adsets' class="row12 row12-country-td value-row">
            <span class="checkbox-column"></span>
            <span class="external-link-td"></span>
            <span class="plus-td expand-column"></span>
            <span class="row-id"></span>
            <span class="row-name"></span>
            <span :class='{"status-column": true, "status-td-active": row["status"] === "RUNNING"}'></span>
            <span v-for='_, f in fields' v-show='metrics.includes(f)'>
              {{row[f] | ifFloatDecimalN(fields[f].decimalDigits || 4) | suffix(fields[f].after)}}</span>
          </li>
        </template>
      </ul>

      <ScreenBlocker :onClose='e => editAdsetPopup.adset=null' v-if='editAdsetPopup.adset'>
        <div class="edit-adset-popup">
          <div class="edit-adset-adset-635">
            <span class="edit-adset-name-626">
              {{editAdsetPopup.adset.name}}
            </span>
          </div>
          <form class="edit-adset-form-line-24" @submit.prevent='confirmAdsetEdit'>
            <label for="">{{fields[editAdsetPopup.field].title}}:</label>
            <input class="def" type="number"
            :step='fields[editAdsetPopup.field].decimalDigits ? "0.001" : "0"'
            :max='fields[editAdsetPopup.field].max ? fields[editAdsetPopup.field].max : ""'
            v-model.number='editAdsetPopup.value'>
            <button type="submit" :disabled='ongoingActions.editAdset[editAdsetPopup.adset.id]' class="btn btn-primary">Save</button>
          </form>
        </div>
      </ScreenBlocker>

      <ScreenBlocker :onClose='closeEditPopup' v-if='editMultipleAdsetsPopup.adsets'>
        <template v-if='editMultipleAdsetsPopup.field === "bid_computed"'>
          <div class="edit-adset-popup">
            <div class="edit-adset-adset-635">
              <span class="edit-adset-name-626" v-for='adset in editMultipleAdsetsPopup.adsets'>
                <span class="edit-adset-name-11">
                  {{adset.name}}({{adset.id}})
                </span>
                <span class="edit-old-val-3">
                  {{adset[editMultipleAdsetsPopup.field] | ifFloatDecimalN(3)}}
                </span>
                <span class="edit-new-val-3">
                  {{adset["updated_val"] | ifFloatDecimalN(3)}}
                </span>
              </span>
            </div>
            <form class="edit-adset-form-line-24" @submit.prevent='confirmMultipleAdsetEdit'>

              <select class="def" v-model='editMultipleAdsetsPopup.setType'>
                <option value="absolute" default>Set</option>
                <option value="changeBy">Increase/Decrease by</option>
              </select>

              <label for="">Bid Amount:</label>
              <input class="def" type="number"
              step="0.001" max="0.40"
              v-model.number='editMultipleAdsetsPopup.value.bid_amount'>

              <label for="">Bid Strategy:</label>
              <select class="def" v-model='editMultipleAdsetsPopup.value.bid_strategy'>
                <option value="LOWEST_COST_WITHOUT_CAP">Lowest cost</option>
                <option value="LOWEST_COST_WITH_BID_CAP">Bid Cap</option>
                <option value="COST_CAP">Cost cap</option>
              </select>

              <button type="submit" :disabled='ongoingActions.editMultipleAdsets' class="btn btn-primary">Save</button>
            </form>
          </div>

        </template>
        <template v-else>
          <div class="edit-adset-popup">
            <div class="edit-adset-adset-635">
              <span class="edit-adset-name-626" v-for='adset in editMultipleAdsetsPopup.adsets'>
                <span class="edit-adset-name-11">
                  {{adset.name}}({{adset.id}})
                </span>
                <span class="edit-old-val-3">
                  {{adset[editMultipleAdsetsPopup.field] | ifFloatDecimalN(3)}}
                </span>
                <span class="edit-new-val-3">
                  {{adset["updated_val"] | ifFloatDecimalN(3)}}
                </span>
              </span>
            </div>
            <form class="edit-adset-form-line-24" @submit.prevent='confirmMultipleAdsetEdit'>
              <template v-if='editMultipleAdsetsPopup.field==="status"'>
                <span>Are you sure that you want to update these adsets' status to "{{editMultipleAdsetsPopup.value}}"?</span>
                <button type="submit" :disabled='ongoingActions.editMultipleAdsets' class="btn btn-primary">Update</button>
              </template>
              <template v-else>
                <select class="def" @change='onEditAdsetValueChange' v-model='editMultipleAdsetsPopup.setType'>
                  <option value="absolute" default>Set</option>
                  <option value="changeBy">Increase/Decrease by</option>
                </select>
                <label for="">{{fields[editMultipleAdsetsPopup.field].title}}:</label>
                <input class="def" type="number"
                :step='fields[editMultipleAdsetsPopup.field].decimalDigits ? "0.001" : ""'
                :max='fields[editMultipleAdsetsPopup.field].max ? fields[editMultipleAdsetsPopup.field].max : ""'
                @input='onEditAdsetValueChange'
                v-model.number='editMultipleAdsetsPopup.value'>
                <button type="submit" :disabled='ongoingActions.editMultipleAdsets' class="btn btn-primary">Save</button>
              </template>
            </form>
          </div>
        </template>
      </ScreenBlocker>

    </div>

    <ScreenBlocker v-if='adsetWizardOpened' :onClose='e=>adsetWizardOpened=false'>
      <AdsetWizard :accounts='allAccounts' :onClose='e=>adsetWizardOpened=false'></AdsetWizard>
    </ScreenBlocker>

    <ScreenBlocker v-if='displayAdsetOpChanges.adset' :onClose='e => displayAdsetOpChanges.adset = null'>
      <div class="bid-changes-container">
        <span class="bid-changes-adset-title">{{displayAdsetOpChanges.adset.name}}</span>

        <div class="bid-changes-results-container">
          <span v-if='!displayAdsetOpChanges.changes'>
            Loading...
          </span>
          <span v-else-if='displayAdsetOpChanges.changes.length === 0'>
            No {{displayAdsetOpChanges.fieldTitle}} changes
          </span>
          <ul v-else class="bid-changes-list">
            <li>
              <span>Time</span>
              <span>From {{displayAdsetOpChanges.fieldTitle}}</span>
              <span>To {{displayAdsetOpChanges.fieldTitle}}</span>
            </li>
            <li v-for='b in displayAdsetOpChanges.changes'>
              <span>{{b.op_time_formatted}}</span>
              <span>{{b.old_val}}</span>
              <span :class='b.new_val > b.old_val ? "bid_i" : "bid_d" '>{{b.new_val}}</span>
            </li>
          </ul>
        </div>
      </div>
    </ScreenBlocker>
  </div>
</template>

<script>
import moment from 'moment'
import FilteringForm from '@/views/reports/shared/FilteringForm.reports'
import AdsetWizard from '../components/CampaignWizard.taboola'

export default {
  props: {
    accountId: {
      required: true
    }
  },
  components: {
    FilteringForm, AdsetWizard
  },
  data() {
    let dtNow = new Date()
    let fields = {
      "date": {
        title: "Date"
      },
      "ssp": {
        title: "SSP"
      },
      "created_at_formated": {
        title: "Created At"
      },
      "camp_daily_budget": {
        title: "Daily Budget",
        editable: true,
        after: '$'
      },
      "active_users": {
        title: "Active Users"
      },
      "bid_amount": {
        title: "Bid",
        decimalDigits: 3,
        max: 0.2,
        editable: true,
        after: '$'
      },
      "clicks": {
        title: "Clicks"
      },
      "cpc": {
        title: "CPC",
        decimalDigits: 3,
        after: '$'
      },
      "ctr": {
        title: "CTR",
        decimalDigits: 3,
        after: '%'
      },
      "admanager_ecpm": {
        title: "AM ECPM",
        decimalDigits: 2,
        after: '$'
      },
      "spend": {
        title: "Cost",
        decimalDigits: 2,
        after: '$'
      },
      "revenue": {
        title: "Revenue",
        decimalDigits: 2,
        after: '$'
      },
      "revenue_v2": {
        title: "Revenue V2",
        decimalDigits: 2,
        after: '$'
      },
      "admanager_rev": {
        title: "AM Rev",
        decimalDigits: 2,
        after: '$'
      },
      "dfp_revenue": {
        title: "DFP Rev",
        decimalDigits: 2,
        after: '$'
      },
      "header_bidding_revenue": {
        title: "H/B Rev",
        decimalDigits: 2,
        after: '$'
      },
      "a9_revenue": {
        title: "A9 Rev",
        decimalDigits: 2,
        after: '$'
      },
      "primis_rev": {
        title: "Primis Rev",
        decimalDigits: 2,
        after: '$'
      },
      "aniview_rev": {
        title: "Anv Rev",
        decimalDigits: 2,
        after: '$'
      },
      "aniview_imps": {
        title: "Anv Imps"
      },
      "avantis_rev": {
        title: "Avantis Rev",
        decimalDigits: 2,
        after: '$'
      },
      "avantis_imps": {
        title: "Avantis Imps"
      },
      "vidazoo_rev": {
        title: "Vidazoo Rev",
        decimalDigits: 2,
        after: '$'
      },
      "vidazoo_imps": {
        title: "Vidazoo Imps"
      },
      "underdog_rev": {
        title: "Underdog Rev",
        decimalDigits: 2,
        after: '$'
      },
      "underdog_imps": {
        title: "Underdog Imps"
      },
      "ssp_impressions": {
        title: "Ad Impressions"
      },
      "rpm": {
        title: "RPM",
        decimalDigits: 4,
        after: '$'
      },
      "rpm_v2": {
        title: "RPM V2",
        decimalDigits: 4,
        after: '$'
      },
      "roi": {
        title: "ROI",
        decimalDigits: 2,
        after: '%',
        getClass: function(val) {
          if(val < 100) {
            return {roi_bad: true}
          }
          if(val > 130) {
            return {roi_good: true}
          }
          return {roi_ok: true}
        }
      },
      "roi_v2": {
        title: "ROI V2",
        decimalDigits: 2,
        after: '%',
        getClass: function(val) {
          if(val < 100) {
            return {roi_bad: true}
          }
          if(val > 130) {
            return {roi_good: true}
          }
          return {roi_ok: true}
        }
      },
      "profit_and_loss": {
        title: "P&L",
        after: '$'
      },
      "profit_and_loss_v2": {
        title: "P&L V2",
        decimalDigits: 2,
        after: '$'
      },
      "user_value": {
        title: "UV",
        after: '$'
      },
      "user_value_v2": {
        title: "UV V2",
        after: '$'
      },
      "bounce_rate": {
        title: "Bounce Rate",
        decimalDigits: 2,
        after: '%'
      },
      "page_views_per_session": {
        title: "Pages / Session",
        decimalDigits: 2
      },
      "avg_session_duration": {
        title: "Avg. Session Duration"
      }
    }

    let filterableFields = Object.assign({
      "created_at": {
        title: "Created At"
      },
      "name": {
        title: "Adset Name",
      }
    }, fields)
    delete filterableFields["created_at_formated"]

    return {
      displayAdsetOpChanges: {
        adset: null,
        changes: null
      },
      selectedAdsets: [],
      allAdsetsSelectedCheckbox: null,
      processingReport: false,
      form: {
        start_dt: dtNow,
        end_dt: (new Date()),
        breakdowns: []
      },
      filteringForm: [],
      filterableFields,
      searchForm: {
        searchAdset: null,
        adset: null,
        ad: null,
        status: "ALL"
      },
      sort: {
        field: "clicks",
        asc: false
      },
      editAdsetPopup: {
        adset: null,
        field: null,
        value: null
      },
      editMultipleAdsetsPopup: {
        adsets: null,
        field: null,
        value: null,
        setType: "absolute"
      },
      adsetWizardOpened: false,
      selectedAccount: null,
      allAccounts: null,
      selectedDateRange: "",
      openAdsets: {},
      submitedBreakdowns: [],
      breakdownsOptions: ["date", "ssp", "ssp_impressions"],
      reportData: null,
      reportDataFiltered: null,
      ongoingActions: {
        toggleAdsetStatus: {},
        editAdset: {},
        editMultipleAdsets: false
      },
      metrics: [
        "camp_daily_budget",
        "active_users",
        "bid_amount",
        "cpc",
        "ctr",
        "clicks",
        "spend",
        "revenue",
        "revenue_v2",
        "rpm_v2",
        "roi",
        "profit_and_loss",
        "profit_and_loss_v2",
        "user_value"
      ],
      fields,
      dateRangeOptions: [
        "Today",
        "Yesterday",
        "Last 7 days",
        "Last 30 days",
        "This Month",
        "Last Month"
      ],
      adsetMicroReport: {}
    }
  },
  watch: {
    ['searchForm.excludeAdset'](val) {
      this.filterReport()
    },
    ['searchForm.adset'](val) {
      this.filterReport()
    },
    ['searchForm.ad'](val) {
      this.filterReport()
    }
  },
  computed: {
    sumReport() {
      let adsets = this.reportDataFiltered.adsets
      let totalReport = this.getTotalReport(adsets)

      return Object.assign({}, totalReport, {
        adsetsCount: this.getAdsetsCount(adsets),
        adsCount: this.getAdsCount(adsets)
      })
    }
  },
  methods: {
    viewAdsetOpChanges(adset, field) {
      field = field.toLowerCase().includes("bid") ? "bid" : "daily_budget"
      this.displayAdsetOpChanges = {
        adset: adset,
        changes: null,
        fieldTitle: field === "bid" ? "bid" : "daily budget"
      }

      this.$http.get(this.resources.logs.adsetChanges(adset.id), {
        params: {
          field: field.toUpperCase()
        }
      })
        .then(r => r.json())
        .then(changes => {
          this.displayAdsetOpChanges.changes = changes
        })
        .catch(err => {
          this.notifyError("Could not fetch bid changes")
          this.displayAdsetOpChanges.adset = null
        })
    },
    onEditAdsetValueChange() {
        let ef = this.editMultipleAdsetsPopup
        let val = ef.value
        if(!val) {
          val = 0
        }
        ef.adsets.forEach(ad => {
          if(ef.setType === "changeBy") {
            ad["updated_val"] = ad[ef.field] + val
          } else {
            ad["updated_val"] = val
          }
        })
    },
    confirmMultipleAdsetEdit() {
      let ef = this.editMultipleAdsetsPopup
      let adsets = ef.adsets

      if(this.ongoingActions.editMultipleAdsets) {
        return
      }

      let adsetsPayload = []

      let invalidAction = false
      adsets.forEach(adset => {
        if(adset.updated_val <= 0) {
          invalidAction = true
          return
        }
        let params = {
          id: adset.id,
          advertiser_id: adset.advertiser_id,
          [ef.field]: adset.updated_val
        }

        adsetsPayload.push(params)
      })

      if(invalidAction) {
        this.notifyError("Invalid zero/negative value for an adset")
        return
      }

      let params = {
        adsets: adsetsPayload
      }

      this.ongoingActions.editMultipleAdsets = true

      this.$http.put(this.resources.taboola.campaignsUpdate, params)
        .then(() => {
          adsets.forEach(adset => {
            if(ef.field === "bid_computed") {
              adset.bid_amount = ef.value.bid_amount
              adset.bid_strategy = ef.value.bid_strategy
              adset.updated_val = ef.value.bid_strategy.includes("LOWEST_COST_WITHOUT_CAP") ? "Lowest cost" : ef.value.bid_amount + '$'
            }
            adset[ef.field] = adset.updated_val
          })
          this.closeEditPopup()
        })
        .catch(err => {
          this.notifyError("An error has occured")
        })
        .then(() => {
          this.ongoingActions.editMultipleAdsets = false
        })
    },
    actionSetActive() {
      if(this.selectedAdsets.length === 0) {
        return
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: 'status',
        value: 'RUNNING'
      })
      this.selectedAdsets.forEach(adset => {adset.updated_val="RUNNING"})
    },
    actionSetInactive() {
      if(this.selectedAdsets.length === 0) {
        return
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: 'status',
        value: 'PAUSED'
      })
      this.selectedAdsets.forEach(adset => {adset.updated_val="PAUSED"})
    },
    actionSetDailyBudget() {
      if(this.selectedAdsets.length === 0) {
        return
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: 'camp_daily_budget',
        value: 0
      })
    },
    actionSetBid() {
      if(this.selectedAdsets.length === 0) {
        return
      }
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: this.selectedAdsets,
        field: 'bid_amount',
        value: 0
      })
    },
    toggleSelectAllAdsets() {
      let adsets = this.reportDataFiltered.adsets
      if(!this.allAdsetsSelectedCheckbox) {
        this.selectedAdsets = []
      } else {
        this.selectedAdsets = adsets.slice(0)
      }
    },
    getAdsetExternalLink(adset) {
      let today = moment()
      let d1 = today.format("YYYY-MM-DD")
      return `
      https://backstage.taboola.com/backstage/1340980/reports/campaigns/campaign-management#%7B"id"%3A"m0dhn2v0cdgi"%2C"groupId"%3A"campaigns"%2C"reportId"%3A"campaign-management"%2C"dateRangeSelector"%3A%7B"id"%3A"1"%2C"term"%3A"this%20day"%2C"range"%3A0%2C"dateStart"%3A"${d1}"%2C"dateEnd"%3A"${d1}"%7D%2C"queryFilter"%3A%5B%7B"id"%3A"campaigns_fetching_method"%2C"operator"%3A"equal"%2C"value"%3A"2"%7D%5D%2C"currentDimension"%3A%7B"tab"%3A"tab-campaigns"%2C"pages"%3A%5B%7B"dim"%3A"campaigns%2Fcampaign-management%2Fcampaigns"%2C"table"%3A%7B"key"%3A"status"%2C"order"%3A"asc"%2C"page"%3A1%2C"filter"%3A"${adset.id}"%7D%7D%5D%7D%7D
      `
    },
    openEditPopup(adset, f) {
      this.editAdsetPopup = {
        adset: adset,
        field: f,
        value: adset[f]
      }
    },
    toggleAdsetExpand(adset, breakdown) {
      let lastReport = this.adsetMicroReport[adset.id]
      if(lastReport && lastReport.breakdown === breakdown) {
        this.$delete(this.adsetMicroReport, adset.id)
        return
      }

      if(breakdown === "ssp") {
        if(!adset.detailed_revenue) {
          return
        }
        let sspBreakdownRows = []
        for(let ssp in adset.detailed_revenue) {
          let val = adset.detailed_revenue[ssp]
          if(!val) {
            continue
          }
          let r = val.rev
          let ssp_impressions = val.impressions
          if(!r && !["adsense-u", "empty", "unknown"].includes(ssp)) { continue }
          ssp = this.$options.filters.prettify_string(ssp)
          let rpm
          if(ssp_impressions > 0) {
            rpm = (r / ssp_impressions) * 1e3
          }
          sspBreakdownRows.push({ssp: ssp, revenue_v2: r, rpm_v2: rpm, ssp_impressions})
        }
        sspBreakdownRows.sort((a,b) => a.revenue_v2 > b.revenue_v2 ? -1 : b.revenue_v2 > a.revenue_v2 ? 1 : 0)
        this.metrics.push(breakdown)
        this.metrics.push("ssp_impressions")
        this.$set(this.adsetMicroReport, adset.id, {breakdown: breakdown, adsets: sspBreakdownRows })
        return
      }

      let payload = {
        start_dt: moment(adset.start_dt).format("YYYY-MM-DD"),
        end_dt: moment(adset.end_dt).format("YYYY-MM-DD"),
        campaign_id: adset.id,
        breakdowns: [breakdown]
      }
      if(breakdown === "date") {
        let dtNow = new Date()
        let d = new Date()
        d.setDate(dtNow.getDate()-7)
        let startDt = d
        let endDt = dtNow
        payload.start_dt = moment(startDt).format("YYYY-MM-DD")
        payload.end_dt = moment(endDt).format("YYYY-MM-DD")
      }

      this._fetchReport(payload)
      .then(r => r.json())
      .then(data => {
        this.metrics.push(breakdown)
        this.$set(this.adsetMicroReport, adset.id, {breakdown: breakdown, adsets: data.adsets})
      })
    },
    _fetchReport(params) {
      return this.$http.get(this.resources.Reports.taboolaCampAllInsights, {params})
    },
    confirmAdsetEdit() {
      let ef = this.editAdsetPopup
      let adset = ef.adset

      if(this.ongoingActions.editAdset[adset.id]) {
        return
      }

      this.$set(this.ongoingActions.editAdset, adset.id, true)

      window.setTimeout(() => {
        this.$set(this.ongoingActions.editAdset, adset.id, false)
      }, 1200)

      let params = {
        account_id: adset.advertiser_id,
        [ef.field]: ef.value,
        camp_id: adset.campaign_id
      }

      this.$http.put(this.resources.taboola.campaign(adset.id), null, {params})
        .then(() => {
          adset[ef.field] = ef.value
          this.closeEditPopup()
        })
        .catch(err => {
          this.notifyError("An error has occured")
        })
        .then(() => {
          this.ongoingActions.editAdset[adset.id] = false
        })
    },
    closeEditPopup() {
      this.editAdsetPopup = {
        adset: null,
        field: null,
        value: null
      }
      this.selectedAdsets.forEach(adset => {
        delete adset["updated_val"]
      })
      Object.assign(this.editMultipleAdsetsPopup, {
        adsets: null,
        field: null,
        value: null
      })
    },
    toggleAdsetStatus(adset) {
      if(!this.userHasPermission("taboola.edit")) {
        return
      }

      if(this.ongoingActions.toggleAdsetStatus[adset.id]) {
        return
      }

      this.ongoingActions.toggleAdsetStatus[adset.id] = true

      let status = adset.status
      let isActive = adset.is_active
      let toStatus
      if(status == "RUNNING") {
        toStatus = "PAUSED"
        isActive = false
      } else if(status === "PAUSED") {
        toStatus = "RUNNING"
        isActive = true
      } else {
        this.notifyError("Can not change campaign status")
        return
      }

      let params = {
        account_id: adset.advertiser_id,
        is_active: isActive
      }

      this.$http.put(this.resources.taboola.campaign(adset.id), null, {params})
        .then(() => {
          window.setTimeout(() => {
            adset.status = toStatus
            this.ongoingActions.toggleAdsetStatus[adset.id] = false
          }, 20)
        })
        .catch(err => {
          this.notifyError("An error has occured")
          this.ongoingActions.toggleAdsetStatus[adset.id] = false
        })
    },
    selectAccount(account) {
      this.selectedAccount = account
    },
    fetchData() {
      return this.$http.get(this.resources.Reports.taboolaData)
        .then(r => r.json())
        .then(data => {
          let accounts = data && data.accounts
          if(!accounts || accounts.length === 0) {
            return
          }
          this.allAccounts = accounts
        })
    },
    onColumnClick(field) {
      if(this.sort.field === field) {
        this.sort.asc = !this.sort.asc
      } else {
        this.sort.field = field
        this.sort.asc = false
      }
      this.sortReport()
    },
    filterReport() {
      let adsetSearch = this.searchForm.adset
      let excludeAdsetSearch = this.searchForm.excludeAdset
      let adSearch = this.searchForm.ad
      let status = this.searchForm.status
      let reportData = this.reportData

      // if(!adsetSearch && !adSearch) {
      //   this.reportDataFiltered = Object.assign({}, reportData)
      //   return
      // }

      let data = Object.assign({}, reportData)
      data.adsets = reportData.adsets

      if(adsetSearch) {
        adsetSearch = adsetSearch.toLowerCase()
        data.adsets = data.adsets.filter( v=> v.id == adsetSearch || v.name.toLowerCase().includes(adsetSearch))
      }

      if(excludeAdsetSearch) {
        excludeAdsetSearch = excludeAdsetSearch.toLowerCase()
        data.adsets = data.adsets.filter( v=> v.id != excludeAdsetSearch && !v.name.toLowerCase().includes(excludeAdsetSearch))
      }

      if(status == "RUNNING" || status == "PAUSED") {
        data.adsets = data.adsets.filter( v=> v.status === status)
      }

      // if(adSearch) {
      //   adSearch = adSearch.toLowerCase()
      //   data.adsets = data.adsets.filter(adset => {
      //     let f = false
      //     adset.ads && adset.ads.forEach(ad => {
      //       if(ad.id == adSearch || ad.name.toLowerCase().includes(adSearch)) {
      //         f = true
      //       }
      //     })
      //     return f
      //   })
      //
      //   this.openAdsets = {}
      //   data.adsets.forEach(v => {
      //     this.$set(this.openAdsets, v.id, true)
      //   })
      // }

      let filteringForm = this.filteringForm

      data.adsets = data.adsets.filter( adset => {
        let passedFilters = true

        for(let i = 0; i < filteringForm.length; i++) {
          let fo = filteringForm[i]

          let field = fo.field
          let givenVal = adset[field]
          let requiredVal = fo.val
          let op = fo.operator

          if(!field || !requiredVal || !op) {
            continue
          }

          if(field === "created_at") {
            givenVal = givenVal.substr(0, givenVal.indexOf("T"))
          }

          if(op === 'includes') {
            passedFilters = givenVal.toLowerCase().includes(requiredVal.toLowerCase())
          } else if(op === 'excludes') {
            passedFilters = !givenVal.toLowerCase().includes(requiredVal.toLowerCase())
          } if(op === '>') {
            passedFilters = givenVal > requiredVal
          } else if(op === '<') {
            passedFilters = givenVal < requiredVal
          } else {
            passedFilters = givenVal == requiredVal
          }

          if(!passedFilters) {
            break
          }
        }

        return passedFilters
      })

      this.reportDataFiltered = data
    },
    sortReport() {
      let data = this.reportDataFiltered
      if(!data || !data.adsets) {
        return
      }

      let sort = this.sort
      if(!sort.field) {
        return
      }

      let sortField = sort.field
      if(sort.field === "created_at_formated") {
        sortField = "created_at"
      }

      data.adsets.sort((a, b) => {
        a = a[sortField]
        b = b[sortField]

        let f
        if(a > b) {
          f = 1
        } else if(a < b) {
          f = -1
        } else {
          f = 0
        }

        if(!sort.asc) {
          f = f * -1
        }

        return f
      })
    },
    getTotalReport(adsets) {
      let r = {
        camp_daily_budget:0,
        impressions:0,
        clicks:0,
        spend:0,
        revenue: 0,
        profit_and_loss: 0,
        roi: 0,
        ctr: 0,
        aniview_rev: 0,
        primis_rev: 0,
        am_rev: 0,
        aniview_imps: 0,
        admanager_rev: 0,
        avantis_rev: 0,
        avantis_imps: 0,
        vidazoo_rev: 0,
        vidazoo_imps: 0,
        underdog_rev: 0,
        underdog_imps: 0,
        active_users: 0,
        total_page_views: 0,
        rpm: 0,
        rpm_v2: 0,
        header_bidding_revenue: 0,
        a9_revenue: 0,
        dfp_revenue: 0,
        revenue_v2: 0,
        profit_and_loss_v2: 0,
        user_value_v2: 0,
        roi_v2: 0,
      }

      adsets.forEach(adset => {
        // r.daily_budget_float += adset.daily_budget_float
        r.impressions += adset.impressions
        r.clicks += adset.clicks
        r.spend += adset.spend
        r.revenue += adset.revenue
        r.aniview_rev += adset.aniview_rev
        r.primis_rev += adset.primis_rev
        r.am_rev += adset.am_rev
        r.aniview_imps += adset.aniview_imps
        r.admanager_rev += adset.admanager_rev
        r.camp_daily_budget += adset.camp_daily_budget
        r.avantis_rev += adset.avantis_rev
        r.avantis_imps += adset.avantis_imps
        r.vidazoo_rev += adset.vidazoo_rev
        r.vidazoo_imps += adset.vidazoo_imps
        r.underdog_rev += adset.underdog_rev
        r.underdog_imps += adset.underdog_imps
        r.active_users += adset.active_users
        r.dfp_revenue += adset.dfp_revenue
        r.revenue_v2 += adset.revenue_v2
        r.header_bidding_revenue += adset.header_bidding_revenue
        r.a9_revenue += adset.a9_revenue
        r.total_page_views += adset.clicks * adset.page_views_per_session
       })

       if(r.clicks > 0) {
         r.user_value = r.revenue / r.clicks
         r.page_views_per_session = r.total_page_views / r.clicks
         if(r.total_page_views > 0) {
           r.rpm = (r.revenue / r.total_page_views) * 1e3
           r.rpm_v2 = (r.revenue_v2 / r.total_page_views) * 1e3
         }
       }

       if(r.spend > 0) {
         r.roi = (r.revenue / r.spend) * 100
         r.roi_v2 = (r.revenue_v2 / r.spend) * 100
       }

       if(r.impressions > 0) {
         r.ctr = (r.clicks / r.impressions) * 100
       }

       r.profit_and_loss = r.revenue - r.spend
       r.profit_and_loss_v2 = r.revenue_v2 - r.spend

      if(r.clicks > 0) {
        r.cpc = r.spend / r.clicks
      }
      // if(r.clicks > 0) {
      //   r.cost_per_inline_link_click = r.spend / r.clicks
      // }
      return r
    },
    getAdsCount(adsets) {
      if(!adsets) {
        return 0
      }
      let c = 0
      adsets.forEach(adset => { c += adset.ads ? adset.ads.length : 0})
      return c
    },
    getAdsetsCount(adsets) {
      if(!adsets) {
        return 0
      }
      return Object.keys(adsets).length
    },
    setFixedDateRange(val) {
      this.selectedDateRange = val
      let dtNow = new Date()
      let endDt, startDt
      let d = new Date()
      switch(val) {
        case "Today":
          startDt = dtNow
          endDt = d
          break

        case "Yesterday":
          d.setDate(dtNow.getDate()-1)
          startDt = d
          endDt = d
          break

        case "Last 7 days":
          d.setDate(dtNow.getDate()-7)
          startDt = d
          endDt = dtNow
          break

        case "Last 30 days":
          d.setDate(dtNow.getDate()-30)
          startDt = d
          endDt = dtNow
          break

        case "This Month":
          d.setDate(1)
          startDt = d
          endDt = dtNow
          break

          case "Last Month":
            let ms = new Date()
            let lms = new Date()
            ms.setDate(0)
            lms.setDate(0)
            lms.setDate(1)
            startDt = lms
            endDt = ms
            break
      }

      this.form.start_dt = startDt
      this.form.end_dt = endDt
    },
    isAdsetOpen(id) {
        return this.openAdsets[id]
    },
    arrangeReport(data) {
      let adsets = data.adsets

      for(let key in adsets) {
        let adset = adsets[key]
        adset.created_at_formated = moment(adset.created_at).format("DD/MM HH:mm")
      }
      return data
    },
    fetchReport() {
      this.adsetMicroReport = {}
      this.selectedAdsets = []

      let accountId = this.selectedAccount && this.selectedAccount.id
      if(!accountId) {
        accountId = ""
      }
      let params = {
        start_dt: moment(this.form.start_dt).format("YYYY-MM-DD"),
        end_dt: moment(this.form.end_dt).format("YYYY-MM-DD"),
        account_id: accountId
      }

      this.processingReport = true
      this._fetchReport(params)
        .then(r => r.json())
        .then(data => {
          data.adsets = data.adsets || {}
          data = this.arrangeReport(data)
          data.adsets = Object.values(data.adsets)
          this.reportData = data
          this.filterReport()
          this.sortReport()

          let metrics = this.metrics
          this.breakdownsOptions.forEach(v => {
            let i = metrics.indexOf(v)
            if(i > -1) {
              metrics.splice(i, 1)
            }
          })

        })
        .catch(err=>{
          console.log(err);
        })
        .then(() => {
          this.processingReport = false
        })
    }
  },
  created() {
    this.setFixedDateRange("Today")
    this.fetchData()
    // this.fetchReport()
  }
}
</script>
