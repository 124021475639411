<template>
<Chart  :options='chartOptions' :chartData='chartDataComputed' />
</template>

<script>
import Chart from '@/components/shared/graph/Chart.js'

export default {
    components: {Chart},
    props: ['chartData'],
    model: {
      prop: 'chartData',
      event: 'update'
    },
  data() {

        return {
               chartOptions: {
                scales: {
                    yAxes: [{
                        id: 'A',
                        type: 'linear',
                        position: 'right',
                        scaleLabel: {
                            display: true,
                            labelString: "RPM"
                        },
                        ticks: {
                            callback: function (value, index, values) {
                                return `${value}$`;
                            }
                        }
                    }, {
                        id: 'B',
                        type: 'linear',
                        position: 'left',
                        scaleLabel: {
                            display: true,
                            labelString: "revenue"
                        },
                        ticks: {
                            callback: function (value, index, values) {
                                return `${value}$`;
                            }
                        }
                    }],
                    xAxes: [{
                        gridLines: {
                            display: false
                        }
                    }]
                },
                legend: {
                    display: true,
                    position: 'bottom'
                },
                plugins: {
                    tooltip: {
                        usePointStyle: true,
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },
        }
  },
   computed: {
        chartDataComputed: {
            get: function () {
                return this.chartData??{};
            },
            set: function (newValue) {
                this.$emit('update', newValue)
            }
        }
    }
}
</script>
