import Reports from './reports/Reports.campaigns'
import LiveReports from './reports/LiveReports.campaigns'
import Adset from './reports/Adset.reports'
import Ad from './reports/Ad.reports'
import LoadData from './reports/LoadData.reports'


export default {
  Reports, Adset, Ad, LoadData, LiveReports
}
