<template>
  <div class="botAllPage">
    <div v-show="this.userHasPermission('automation.edit')">
      <b-button
        class="float-left"
        v-b-modal.modal-add
        variant="outline-success"
        @click="modalShow = true"
      >
        + Add Rule
      </b-button>
    </div>
    <botForm 
        v-model="modalShow"
        v-if="modalShow"
        :editMode="isEdit"
        :row="editRow"
        :addNewRow="addRule"
        :deleteRow="deleteRule"
        :updateRow="updateRule"
      ></botForm>
    <b-table
      id="reportTable"
      :items="allOperations"
      :fields="dataFields"
      selectable
      ref="selectableTable"
      select-mode="single"
      responsive="sm"
      selected-variant="primary"
      @row-selected="onRowSelected"
      :filter="search"
      :filter-included-fields="['title']"
      :busy="tableBusy"
      caption-top
      hover >
      <template #table-caption><input
      autocomplete="off"
      class="form-control"
      type="text"
      placeholder="Search.."
      v-model="search"
    /></template>
    <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template #cell(filters)="{value}">
        <div class="table-column-span">
          <span class="span-in-span capitalize" v-for="f in value">{{
            fields[f.field] && fields[f.field].title
            }} {{ f.operator }}
            {{ f.val | formatColumnValue(fields[f.field] || {}) }}
          </span>
        </div>
      </template>
      <template #cell(active)="{item,value}">
        <div class="table-column-span">
        <span class="span-in-span">
          <span :class="
            value == 'ACTIVE'
              ? 'activeRule'
              : 'disabledRule'
          "
          @click="shouldToggleStatus = true">{{ value }}</span></span>
<span class="span-in-span" v-if="item.forApprove">Waiting To Approve</span>
        </div>
      </template>
      <template #cell(actions)="{value}">
        <div class="table-column-span">
          <span class="span-in-span capitalize" v-for="action in value">
            {{ action | formatAction }}
          </span>
        </div>
      </template>
      <template #cell(startTimeExtended)="{item}">
        <div class="table-column-span">
          <span class="span-in-span capitalize" v-for="t in item.when.startTimeExtended">
            {{ t | wholeDate }}
          </span>
        </div>
      </template>
      <template #cell(manage_actions)="{item}">
        <b-button variant="outline-success" @click="approveRule(item)" class="m-1" 
        v-if="userHasPermission('automation.approve') && item.forApprove">
          <b-icon icon="check2-square"></b-icon>
          Approve 
        </b-button>
        <b-button variant="outline-success" @click="duplicateRule(item)" class="m-1">
          <b-icon icon="files"></b-icon>
          Duplicate 
        </b-button>
        <b-button variant="outline-success" @click="deleteRule(item.id,item.forApprove)" class="m-1">
          <b-icon icon="trash-fill"></b-icon>
          Delete 
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import botForm from "@/views/bot/shared/botForm.vue";
import _fields from "@/assets/fb-fields";
import moment from "moment";

let fields = Object.assign({}, _fields);
fields.bid_strategy = fields.bid_computed;
delete fields.bid_computed;

export default {
  components: {
    botForm,
  },

  data() {
    let allOperations;
    let editRow = {};

    return {
      search: "",
      dataFields: [
      {
                key: "title",
                label: "Rule Name",
      },
      {
                key: "active",
                label: "Status",
                formatter: value => { return value ? "ACTIVE" : "PAUSED" }
      },
      {
                key: "when.filterBasedDateAsDaysAgo",
                label: "Condition Time Frame",
                formatter: value => { return this.$options.filters.realTimeOrYesterday(value) }
      },
      {
                key: "filters",
                label: "Conditions",
      },
      {
                key: "actions",
                label: "Action",
      },
      {
                key: "startTimeExtended",
                label: "Scheduling Time",
      },
      {
                key: "manage_actions",
                label: ""
      }
      ],
      currentOperation: null,
      displayAdsetOpChanges: {
        adset: null,
        changes: null,
      },
      allOperations,
      isEdit: false,
      editRow,
      fields,
      modalShow: false,
      shouldToggleStatus: false,
      tableBusy: false
    };
  },
  filters: {
    wholeDate(t) {
      const restOfDate = `${moment(t.hour, "H").format("HH")}:${moment(
        t.minutes,
        "m"
      ).format("mm")}`;
      return t.day === 0
        ? `All Days - ${restOfDate}`
        : `${moment()
            .day(t.day - 1)
            .format("dddd")} - ${restOfDate}`;
    },
    realTimeOrYesterday: (str) => str === "Yesterday" ? str : "Real Time",
    formatAction(action) {
      const {changeTo,changeType,field} = action;

      if (changeType === "SetToUV")
        return "Set bid to UV";

      if (changeType == "RelativePercentage" || changeType === "Relative")
        return changeTo == 0 ? "didnt change" :
          `${changeTo < 0 ? "Decrease" : "Increase"} ${field} by ${Math.abs(changeTo)}${changeType === "RelativePercentage" ? "%" : "$"}`;

      return `Set ${field} to ${changeTo}`;
    },
  },
  methods: {
    addRule: async function (operation) {
      try {
        if (this.userHasPermission("automation.approve")) {
          const response = await this.$http.post(
            `${process.env.VUE_APP_BOT_API}operations`,
            operation
          );
          const newId = await response.json();
          const newRow = JSON.parse(JSON.stringify(operation));
          newRow.id = newId;
          this.allOperations.unshift(newRow);
          this.notifySuccess("Rule has been added successfully");
          return;
        } 
        await this.$http.post(`${process.env.VUE_APP_BOT_API}for_approval`, operation);
          this.notifySuccess("Rule has been sent for approval");
      } catch (err) {
        console.error(err);
        this.notifyError("Error with adding a rule");
      }
    },
    deleteRule: async function (rowId, forApprove) {
      try {
        const confirm = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this rule?', {
          title: 'Delete Rule',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        });
        if (confirm) {
          this.tableBusy = true;
          await this.$http.delete(forApprove ? `${process.env.VUE_APP_BOT_API}for_approval/${rowId}` :
          `${process.env.VUE_APP_BOT_API}operations/${rowId}` 
          );
          this.allOperations = this.allOperations.filter( operation => operation.id !== rowId);
          this.tableBusy = false;
          this.notifySuccess("The rule has been Deleted successfully");
        }
      } catch (err) {
        console.error(err);
        this.notifyError("error with deleting a rule");
      }
    },
    duplicateRule(operation){
      const duplicatedRule = JSON.parse(JSON.stringify(operation));
      delete duplicatedRule.id;
      duplicatedRule.title = "";
      this.isEdit = false;
      this.editRow = duplicatedRule;
      this.modalShow = true
    },
    approveRule: async function(operation) {
      try {
        this.tableBusy = true;
        const response = await this.$http.post(
          `${process.env.VUE_APP_BOT_API}for_approval/approve/${operation.id}`,
          operation
        );
        const responseJson = await response.json();
        delete operation.forApprove;
        operation.id = responseJson.id;
        this.notifySuccess("operation has been Approved successfully");
      } catch (err) {
        console.error(err);
        this.notifyError("error with approving a rule");
      }
      finally {
        this.tableBusy = false
      }
    },
    updateRule: async function(updatedRow, oldId, forApprove) {
      if (((this.userHasPermission("automation.edit") && forApprove) || this.userHasPermission("automation.approve"))) {
      try {
        this.tableBusy = true;
        if (this.userHasPermission("automation.approve") && forApprove)
        {
          updatedRow.id = oldId;
          await this.approveRule(updatedRow);
          return;
        }
        const response = await this.$http.put(forApprove ? 
          `${process.env.VUE_APP_BOT_API}for_approval/${oldId}` :
          `${process.env.VUE_APP_BOT_API}operations/${oldId}`,
          updatedRow
          );
        const jsonResponse = await response.json();
        this.allOperations = this.allOperations.filter((o) => o.id !== oldId);
        updatedRow = JSON.parse(JSON.stringify(updatedRow));
        updatedRow.id = jsonResponse.id;
        this.allOperations.unshift(updatedRow);
        this.notifySuccess("Row has been edited successfully");
      } catch (err) {
        console.error(err);
        this.notifyError("error with updating a rule");
      }
      finally {
        this.tableBusy = false;
      }
    }
    },
    onModalHidden(bvEvent) {
      this.isEdit = false;
      this.editRow = null;
      this.modalShow = false;
      this.$refs.selectableTable.clearSelected();
    },
    onRowSelected(rows){
      if(rows.length && ((this.userHasPermission("automation.edit") && rows[0].forApprove) || this.userHasPermission("automation.approve"))) {
        if(this.shouldToggleStatus)
        {
          this.$refs.selectableTable.clearSelected();
          this.toggleStatus(rows[0]);
          this.shouldToggleStatus = false;
          return;
        }
        this.isEdit = true;
        this.editRow = JSON.parse(JSON.stringify(rows[0]));
        this.modalShow = true;
      }
    },
    toggleStatus: async function (row) {
      if (((this.userHasPermission("automation.edit") && row.forApprove) || this.userHasPermission("automation.approve"))) {
          this.tableBusy = true;
          row.active = !row.active;
          let rowWithoutIdInside = JSON.parse(JSON.stringify(row));
          delete rowWithoutIdInside.id;
          await this.updateRule(rowWithoutIdInside,row.id,row.forApprove);
      }
    },
    getForApprovalRules: async function() {
    var httpRes = await this.$http.get(
        `${process.env.VUE_APP_BOT_API}for_approval`
      );
    return {body: httpRes.body.map(rule => ({...rule, forApprove: true}))};
  },
  },
  created: async function () {
    try {
      this.tableBusy = true;
      const responses = await Promise.all([this.$http.get(
        `${process.env.VUE_APP_BOT_API}operations`
      ),this.getForApprovalRules()])
      this.allOperations = responses.flatMap(reponse => reponse.body);
    } catch (err) {
      console.error(err);
      this.notifyError("error with fetching all rules");
    }
    finally {
      this.tableBusy = false;
    }
  },
  mounted() {
    this.$root.$on("bv::modal::hide", this.onModalHidden);
  },
  beforeDestroy(){
    this.$root.$off("bv::modal::hide", this.onModalHidden);
  }
};
</script>

<style lang="css" scoped>
.activeRule {
  color: green;
}
.disabledRule {
  color: red;
}

.span-in-span {
  border-bottom: 1px solid #cccccc;
}

.span-in-span:last-child {
  border-bottom: 0;
}

.capitalize {
  text-transform: capitalize;
}

.table-column-span {
  display: flex;
  flex-direction: column;
  padding: 1px 10px;
}
</style>
