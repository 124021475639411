import Vue from "vue";
import { BootstrapVue, IconsPlugin, SpinnerPlugin } from "bootstrap-vue";
import App from "./App";
import router from "./router/router";
import store from "./store/store";
import VueResource from "vue-resource";
import _buildUrl from "build-url";
import Vue2Filters from 'vue2-filters'

window.setCookie = function (name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};
window.eraseCookie = function (name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

window.deepClone = (v) => JSON.parse(JSON.stringify(v));

window.randStr = function (length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

Array.prototype.toggle = function (value) {
  let i = this.indexOf(value);
  if (i > -1) {
    return this.splice(i, 1);
  }
  return this.push(value);
};

Object.prototype.nullify = function fn(obj = this) {
  for (let field in obj) {
    let val = obj[field];
    if (Array.isArray(val)) {
      obj[field] = [];
    } else if (val && typeof val === "object") {
      fn(val);
    } else {
      obj[field] = null;
    }
  }
};
Object.defineProperty(Array.prototype, "toggle", {
  enumerable: false,
});
Object.defineProperty(Object.prototype, "nullify", {
  enumerable: false,
});

Vue.use(require("vue-moment"));
Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(IconsPlugin);
Vue.use(SpinnerPlugin);
Vue.use(Vue2Filters);

Vue.http.options.root = process.env.VUE_APP_API;
Vue.http.options.credentials = false;
import resources from "./resources";
Vue.http.resources = resources;

import DatePicker from "@/components/shared/DatePicker";
import DateRange from "@/components/shared/DateRange";
import RevenueChart from "@/components/shared/RevenueChart";
import Tabs from "@/components/shared/Tabs";
import FormField from "@/components/shared/FormField";
import CSV from "@/components/shared/CSV";
import Generate from "@/components/shared/Generate";
import CSVnGenerate from "@/components/shared/CSV&Generate";
import CheckList from "@/components/shared/CheckList";
import InputBox from "@/components/shared/InputBox";
import Checkbox from "@/components/shared/Checkbox";
import Spinner from "@/components/shared/Spinner";
import TableShowUntilDate from "@/components/shared/TableShowUntilDate";
import MoveLists from "@/components/shared/movelists/MoveLists";
import Modal from "@/components/shared/Modal";
import ScreenBlocker from "@/components/shared/ScreenBlocker";
import vSelect from "vue-select";
import vueValidations from "./vue-validations";
import validations from "./validations";

Vue.use(vueValidations);
Vue.setValidations(validations);

Vue.component("DatePicker", DatePicker);
Vue.component("FormField", FormField);
Vue.component("DateRange", DateRange);
Vue.component("RevenueChart", RevenueChart);
Vue.component("Checkbox", Checkbox);
Vue.component("Tabs", Tabs);
Vue.component("TableShowUntilDate", TableShowUntilDate);
Vue.component("ScreenBlocker", ScreenBlocker);
Vue.component("Spinner", Spinner);
Vue.component("CheckList", CheckList);
Vue.component("CSV", CSV);
Vue.component("Generate", Generate);
Vue.component("MoveLists", MoveLists);
Vue.component("CSVnGenerate", CSVnGenerate);
Vue.component("InputBox", InputBox);
Vue.component("Select", vSelect);

Vue.component("Modal", Modal);

import { Line } from "vue-chartjs";

Vue.component("line-chart-original", {
  extends: Line,
  props: ["data", "options"],
  methods: {
    performRender() {
      this.renderChart(this.data, this.options);
    },
  },
  mounted() {
    this.performRender();
  },
  watch: {
    data() {
      this.performRender();
    },
  },
});

Vue.filter("usersInCheckList", (val) => {
  if (!val) {
    return;
  }
  let ob = {};
  val.forEach((e) => {
    ob[e.id] = `${e.name} (${e.email})`;
  });
  return ob;
});
Vue.filter("seperateWords", (str) => {
  return str.replace(/([A-Z])/g, " $1");
});
Vue.filter("capitalize", (value) => {
  if (!value) return "";
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("prettify_string", (value) => {
  if (!value) return "";
  if (value.length <= 3) {
    return value.toUpperCase();
  }
  value = value.toString();
  value = value.replace(/_/g, " ");
  value = value.replace(/-/g, " ");
  value = value.toLowerCase();
  return value
    .split(" ")
    .map((value) => value.charAt(0).toUpperCase() + value.slice(1))
    .join(" ");
});

let lastNotifyTimeout = 0;


function suffix(val, s) {
  if (!val && val != 0) {
    return "";
  }
  if (s) {
    return val + s;
  }
  return val;
}
function prettifyNumber(val, decimalDigits = 0, enabled = true) {
  return !isNaN(val) && val !== 0 && enabled
    ? Number(val).toLocaleString(undefined, {
      minimumFractionDigits: decimalDigits,
    })
    : val;
}
function ifFloatDecimalN(val, n) {
  if (isNaN(val) || val % 1 === 0) {
    return val;
  }

  let s = Number(val).toFixed(n);
  let len = s.length;

  let iOfPoint = s.indexOf(".");

  while (len > iOfPoint + 3) {
    if (s[len - 1] == 0) {
      s = s.substr(0, len - 1);
    } else {
      break;
    }
    len--;
  }
  return s;
}

Vue.mixin({
  filters: {
    formatColumnValue(val, options) {
      if (options.type === String || options.type === Date) {
        return val;
      }
      val = ifFloatDecimalN(val, options.decimalDigits);
      val = prettifyNumber(val, options.decimalDigits);
      val = suffix(val, options.after);

      return val;
    },
    suffix,
    prettifyNumber,
    ifFloatDecimalN,
    percentage(n) {
      if (isNaN(n)) {
        return 0;
      }
      return n.toFixed(2) + "%";
    },
  },
  computed: {
    validations() {
      return this.$validations[this.$route.name];
    },
    user() {
      return store.state.user;
    },
  },
  data() {
    return {
      resources: this.$http.resources,
      appState: store.state,
    };
  },
  methods: {
    _downloadCsvReport(report_path, fields) {
      let ep = this.buildURL(this.resources.Reports.csvReport, {
        fields: fields.join(","),
        report_path,
        token: this.getUserToken(),
      });
      return this.downloadURI(ep, `report-${new Date().getTime()}.csv`);
    },

    getUserToken() {
      return localStorage.getItem("token-2");
    },
    buildURL(url, params) {
      if (!url.includes("http")) {
        url = this.$http.options.root + url;
      }
      const myUrlWithParams = new URL(url);

      for (let key in params) {
        let v = params[key];
        if (Array.isArray(v)) {
          v.forEach((v1) => {
            myUrlWithParams.searchParams.append(key + "[]", v1);
          });
        } else {
          myUrlWithParams.searchParams.append(key, v);
        }
      }

      return myUrlWithParams;
    },
    buildURLOnlyPath(url, params) {
      url = "http://dddd.com/" + url;
      let l = this.buildURL(url, params);
      return l.pathname + "?" + l.searchParams;
    },
    capitalize(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    removeOverlapping(arr, r) {
      return arr.filter((v) => !r.includes(v));
    },
    nameIDArrayToObject(arr) {
      let obj = {};
      arr.forEach((v) => {
        obj[v.id] = v.name;
      });
      return obj;
    },
    userHasPermission(perm) {
      let userPerms = this.user.perms.map(perm => perm.name);
      if (!userPerms) {
        return false;
      }
      perm = perm.toLowerCase();

      return userPerms.includes(perm) || userPerms.includes("admin");
    },
    downloadFromUrl(url) {
      window.location.replace(url);
    },
    async downloadURI(uri, name, requestOptions) {
      try {
        const res = await fetch(uri, requestOptions);
        const blob = await res.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
      catch (err) {
        console.error(err);
        this.notifyError("Could not create CSV file.");
      }
    },
    buildUrl() {
      if (!arguments[0]) {
        arguments[0] = process.env.API;
      }
      return _buildUrl.apply(null, arguments);
    },
    log: console.log,
    redirect(a) {
      this.$router.push(a);
    },
    getHtmlId() {
      return "el-" + ++this.$store.state.elementId;
    },
    organizeByOrder(placements, order) {
      if (!order || !placements) {
        return placements;
      }
      order = order.split(",").map((v) => Number(v.trim()));
      let orderedList = [];
      order.forEach((nextID) => {
        let p = placements.find((v) => v.id === nextID);
        if (!p) {
          return;
        }
        orderedList.push(p);
        placements.splice(placements.indexOf(p), 1);
      });
      orderedList.push.apply(orderedList, placements);
      return orderedList;
    },
    calculateWeight(arr) {
      let totalWeight = 0;

      arr.forEach((v) => {
        totalWeight += v.weight;
      });
      let dd = 100 / totalWeight;
      return arr.map((v) => {
        let d = Object.assign({}, v);
        let n = dd * v.weight;
        n = n.toFixed(1);
        d.weight += ` (${n}%)`;
        return d;
      });
    },
    notifyError(msg, ms) {
      this.appState.notify = {
        error: true,
        msg: msg,
      };
      if (!ms) {
        if (msg.length > 20) {
          ms = 6000;
        } else {
          ms = 3000;
        }
      }
      this.resetNotifyWithin(ms);
    },
    notifySuccess(msg, ms = 3000) {
      this.appState.notify = {
        error: false,
        msg: msg,
      };
      this.resetNotifyWithin(ms);
    },
    notifyWarning(msg, ms = 3000) {
      this.appState.notify = {
        error: false,
        type: "WARNING",
        msg: msg,
      };
      this.resetNotifyWithin(ms);
    },
    resetNotifyWithin(ms) {
      window.clearTimeout(lastNotifyTimeout);
      let d = setTimeout(() => {
        this.appState.notify = Object.assign({}, this.appState.notify, {
          slideAway: true,
        });
        window.setTimeout(() => {
          this.appState.notify.msg = null;
        }, 2000);
      }, ms);
      lastNotifyTimeout = d;
    },
  },
});
Vue.http.interceptors.push((request, next) => {
  let token = localStorage.getItem("token-2");
  let userString =
    store.state.user &&
    store.state.user.name + " (" + store.state.user.id + ")";
  request.headers.set("Authorization", "Bearer " + token);
  if (userString) {
    request.headers.set("userid", userString);
  }
  next();
});

new Vue({
  el: "#app",
  router,
  template: "<App/>",
  render: (e) => e(App),
  store,
  components: {
    App,
  },
});
