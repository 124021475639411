<template lang="html">
  <i class="fa fa-circle-o-notch fa-spin" :class='{spinner: def}'></i>
</template>

<script>
export default {
  props: {
    def: {
      type: [Boolean, Number],
      default: true
    }
  }
}
</script>

<style lang="css" scoped>
.spinner {
  font-size: 28px;
}
</style>
