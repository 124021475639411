<template lang="html">
  <div class="">
    <Select class='select-role' :options='rolesList' v-model='selectedRole' />
    <button type="button" @click='submit'>Submit</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedRole: null,
      rolesList: [
        {label: "david", id: 234},
        {label: "Test", id: 434},
      ]
    }
  },
  mounted() {
    setInterval(e => {
      // console.log(Object.keys(this.listA), Object.keys(this.listB));
    }, 1200)
  },
  methods: {
    submit() {
      this.selectedRole = this.rolesList[1]
    }
  }
}
</script>

<style lang="css" scoped>
</style>
