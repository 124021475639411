<template lang="html">
  <div class="tabs-container">
    <ul class="nav nav-tabs">
      <li class="nav-item" v-for="(tab, index) in tabs">
        <a
          @click.capture="setActive(index)"
          :class="{ 'nav-link': true, active: index === activeTab_ }"
          data-toggle="tab"
          role="tab"
          :href="'#' + htmlId + '-' + index"
        >
          {{ tab }}
        </a>
      </li>
    </ul>
    <div class="tab-content">
      <div
        v-for="(tab, index) in tabs"
        v-show="index === activeTab_"
        :class="{ 'tab-pane': true, active: activeTab_ === index }"
        :name="'t-' + index"
        :id="htmlId + '-' + index"
        role="tabpanel"
      >
        <slot :name="'t-' + (index + 1)">tab slot t-{{ index + 1 }}</slot>
      </div>
    </div>
  </div>
</template>

<script>
import * as act from "@/act-types";
export default {
  props: {
    tabs: {},
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      htmlId: null,
      show: [],
      activeTab_: 0,
    };
  },
  watch: {
    activeTab(val) {
      this.activeTab_ = val;
    },
  },
  created() {
    this.activeTab_ = this.activeTab;
    this.$store.dispatch(act.GET_NEXT_ID).then((id) => {
      this.htmlId = id;
    });
  },
  methods: {
    setActive(index) {
      this.$emit("change");
      this.activeTab_ = index;
    },
  },
};
</script>

<style lang="css" scoped>
.tabs-container {
  width: 100%;
}
</style>
