<template lang="html">
  <div>
    <span class="h-52312">Filters <i @click="addFilter" class="fa fa-plus-circle add-filter-btn" aria-hidden="true"></i></span>

    <b-container class="m-0">
      <b-row>
        <b-col>
          <b-row v-for="fo in filteringForm">
            <b-col class="p-1">
              <b-form-select v-model="fo.field" :options="fieldsOptions" />
            </b-col>
            <b-col class="p-1">
              <b-form-select v-model="fo.operator"
                :options="operatorOptions[fields[fo.field].type.name] == null ? operatorOptions['Other'] : operatorOptions[fields[fo.field].type.name]" />
            </b-col>
            <b-col class="p-1">
              <b-form-input required v-model="fo.val" :type="typesConverter[fields[fo.field].type.name] == null ?  typesConverter['Other'] :
              typesConverter[fields[fo.field].type.name]"></b-form-input>
            </b-col>
            <b-col class="p-1">
              <i @click="removeFilter(fo)" class="fa fa-times x-filter-btn" aria-hidden="true"></i>
            </b-col>
          </b-row>
        </b-col>
        <b-col v-if="saveFilterEnable">
          <label>Filter Name</label>
          <input v-model="filterName" class="def" />
          <button @click="saveFilter" type="button" class="btn action-btn-25"
            v-text="selectedFilter ? 'Update Filter' : 'Save Filter'"></button>
          <button type="button" @click="deleteFilter" class="btn action-btn-25" v-show="selectedFilter">
            Delete Filter
          </button>
          <select v-model="selectedFilter" @change="onSelectedFilterChange" class="def">
            <option :value="null" default>New Filter</option>
            <option :value="f" v-for="f in loadedFilters">{{ f.title }}</option>
          </select>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      type: Object,
    },
    filteringForm: {
      type: Array,
    },
    saveFilterEnable: {
      default: true,
    },
  },
  data() {
    return {
      emptyFilteringObject: {
        field: "revenue",
        val: null,
        operator: ">",
      },
      typesConverter: {
        String: "text",
        Date: "date",
        Other: "number",
      },
      operatorOptions: {
        String: [
          { text: "Includes", value: "includes" },
          { text: "Excludes", value: "excludes" },
        ],
        Boolean: [{ text: "=", value: "=" }],
        Other: [
          { text: ">", value: ">" },
          { text: "<", value: "<" },
          { text: "=", value: "=" },
        ],
      },
      filterName: null,
      loadedFilters: [],
      selectedFilter: null,
    };
  },
  computed: {
    fieldsOptions() {
      return Object.keys(this.fields).map((field) => ({
        text: this.fields[field].title,
        value: field,
      }));
    },
  },
  created() {
    if (!this.filteringForm.length) this.addFilter();

    this.loadFilters();
  },
  methods: {
    onSelectedFilterChange() {
      if (this.selectedFilter) {
        this.setLoadedFilter(this.selectedFilter);
      } else {
        this.emptyFilter();
      }
    },
    loadFilters() {
      let f = localStorage.getItem("filter-settings");
      try {
        f = JSON.parse(f);
      } catch (e) {
        f = [];
      }

      this.loadedFilters = f || [];
    },
    setLoadedFilter(f) {
      let title = f.title;
      f = f.filter;
      this.filteringForm.length = 0;
      Array.isArray(f) &&
        f.forEach((v) => {
          this.filteringForm.push(Object.assign({}, v));
        });
      this.filterName = title;
    },
    emptyFilter() {
      this.filteringForm.length = 0;
      this.addFilter();
      this.filterName = null;
    },
    deleteFilter() {
      let allFilters = this.loadedFilters;

      allFilters.splice(allFilters.indexOf(this.selectedFilter), 1);
      allFilters = JSON.stringify(allFilters);
      localStorage.setItem("filter-settings", allFilters);

      this.selectedFilter = null;
      this.emptyFilter();
    },
    saveFilter() {
      let f = this.filteringForm.slice(0).map((v) => Object.assign({}, v));

      let allFilters = this.loadedFilters;

      if (!this.filterName) {
        return;
      }

      if (!this.selectedFilter) {
        let nf = {
          title: this.filterName,
          filter: f,
        };
        allFilters.push(nf);
        this.selectedFilter = nf;
      } else {
        this.selectedFilter.filter = f;
        this.selectedFilter.title = this.filterName;
      }

      allFilters = JSON.stringify(allFilters);
      localStorage.setItem("filter-settings", allFilters);
    },
    removeFilter(fo) {
      this.filteringForm.splice(this.filteringForm.indexOf(fo), 1);
    },
    addFilter() {
      this.filteringForm.push(Object.assign({}, this.emptyFilteringObject));
    },
  },
};
</script>

<style lang="css" scoped>
.filtering-list {
  margin-top: 6px;
  margin-bottom: 16px;
}

.filtering-list>li {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.x-filter-btn {
  margin-left: 6px;
  cursor: pointer;
  padding: 5px;
  border-radius: 15px;
  font-size: 16px;
}

.x-filter-btn:hover {
  background: #e2e2e2;
}

.add-filter-btn {
  cursor: pointer;
  padding: 5px;
  border-radius: 15px;
  font-size: 28px;
}

.add-filter-btn:hover {
  background: #e2e2e2;
}

.filtering-list input,
.filtering-list select {
  font-size: 15px;
  padding: 9px 14px;
  color: #2d2d2d;
}

.h-52312 {
  font-size: 14px;
  font-weight: bold;
}

.f-cont-2 {
  display: flex;
  flex-wrap: wrap;
}

.f-part-a-3 {
  margin-right: 75px;
  border-right: 1px solid #d2d2d2;
  padding-right: 57px;
}

.filteringFormList {
  flex-wrap: wrap;
}
</style>
