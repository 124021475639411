<template lang="html">
  <div class="home-container">
    <h4>Word-Line Admin</h4>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
.home-container {
  /* min-height: 60px; */
}
</style>
