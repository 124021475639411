import Vue from 'vue'
import Vuex from 'vuex'
import * as mut from '../mut-types'
import * as act from '../act-types'
import resources from '../resources'
import countries from '@/assets/countries'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    elementId: 0,
    countriesList: countries,
    languagesList: null,
    initialLoad: false,
    version: null,
    browsers: {
      Chrome: 'Chrome', Firefox: 'Firefox', Safari: 'Safari', IE7: 'Internet Explorer 7',
      IE8: 'Internet Explorer 8', IE9: 'Internet Explorer 9', IE10: 'Internet Explorer 10', IE11: 'Internet Explorer 11', Other: 'Other'
    },
    dimensions: {
      created_on: 'Date', geo: 'Country', pub_id: 'Publisher ID', Browser: 'Browser', src_id: 'Source'
    },
    user: null,
    notify: {
      error: null,
      msg: null
    },
    data: {
      fbData: null,
      fbAdsets: null,
      twitterData: null
    },
    others: {
      rolesList: null
    },
    global: {
      targetingPresets: null
    }
  },
  actions: {
    getRolesList(context) {
      return new Promise((res, rej) => {
        let state = context.state
        if(state.others.rolesList) {
          return res(state.others.rolesList.slice(0))
        }
        Vue.http.get('admin/roles')
          .then(r => r.json())
          .then(roles => {
            state.others.rolesList = roles; res(roles.slice(0))
          })
      })
    },
    // delete all the store ! its written all wrong ! and desnt work like it should !!!
    getPermsList(context) {
      return new Promise((res, rej) => {
        let state = context.state
        if(state.others.permsList) {
          return res(Object.assign({}, state.others.permsList))
        }
        Vue.http.get('admin/perms')
          .then(r => r.json())
          .then(perms => {
            let obj = {}
            perms.forEach(r => {
              obj[r.id] = r.name
            })
            state.others.permsList = obj; res(Object.assign({}, obj))
          })
      })
    },
    [act.GET_NEXT_ID] (context) {
      return Promise.resolve('el-' + (++context.state.elementId) )
    },
    [act.GET_DASHBOARD_ROLES] (context) {
      return new Promise((res, rej) => {
        if(context.state.dashboardRoles) {
          return res(context.state.dashboardRoles)
        }
        Vue.http.post('app/modules/users/api/roles')
          .then(r => r.json())
          .then(r => r.data)
          .then(roles => {
            roles.items = {}
            roles.forEach(role => {
                roles.items[''+role.id] = role.display_name
            })
            context.state.dashboardRoles = roles
            res(roles)
          })
      })
    },
    [act.GET_COUNTRIES_LIST] (context) {
      return new Promise((res, rej) => {
        if(context.state.countriesList) {
          return res(context.state.countriesList)
        }
        rej("No countries list")
      })
    },
    [act.GET_LANGUAGES] (context) {
      return new Promise((res, rej) => {
        if(context.state.languagesList) {
          return res(context.state.languagesList)
        }
        Vue.http.get('api/languages/minimal')
          .then(r => r.json())
          .then(r => r.data)
          .then(languages => {
            context.state.languagesList = languages
            res(languages)
          })
      })
    },
    [act.GET_PUB_IDS] (context) {
      return new Promise((res, rej) => {
        if(context.state.pubIds) {
          return res(context.state.pubIds)
        }
        Vue.http.post('app/modules/users/api/pubids')
          .then(r => r.json())
          .then(r => r.data)
          .then(pubIds => {
            pubIds.items = {}
            pubIds.forEach(p => {
              pubIds.items[''+p.pub_id] = p.pub_id + ` (${p.type}, ${!p.used ? 'free' : ''})`
            })
            context.state.pubIds = pubIds
            res(pubIds)
          })
      })
    },
    ["GET_TM_TEMPLATES"] (context) {
      return new Promise((res, rej) => {
        if(context.state.tagMahal.templates) {
          return res(context.state.tagMahal.templates)
        }
        Vue.http.get(resources.Management.tmTemplates)
          .then(r => r.json())
          .then(t => {
            t = t || []
            context.state.tagMahal.templates = t
            res(t)
          })
      })
    },
    ["GET_TM_DOMAIN_PREFIXES"] (context) {
      return new Promise((res, rej) => {
        if(context.state.tagMahal.domainPrefixes) {
          return res(context.state.tagMahal.domainPrefixes)
        }
        Vue.http.get(resources.Management.tmDomainPrefixes)
          .then(r => r.json())
          .then(t => {
            t = t || []
            context.state.tagMahal.domainPrefixes = t
            res(t)
          })
      })
    }

    // [act.GET_USERS] (context) {
    //   return new Promise((res, rej) => {
    //     if(context.state.usersList) {
    //       return res(context.state.usersList)
    //     }
    //     Vue.http.post('api/users')
    //       .then(r => r.json())
    //       .then(r => r.data)
    //       .then(countries => {
    //         context.state.countriesList = countries
    //         res(countries)
    //       })
    //   })
    // }
  }
})

export default store
