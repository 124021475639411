<template lang="html">
  <div class="move-lists">
    <div class="">
      <moveList class='d11' :onMove='getMoveFunc(1,2)' :items='assigned' :title='listAtitle' :noFilter='noFilter' />
      <moveList :onMove='getMoveFunc(2,1)' :items='notAssignedList' :isLast='true' :title='listBtitle' :noFilter='noFilter' />
    </div>
  </div>
</template>

<script>

import moveList from './List.move'
export default {
  components: {moveList},
  props: {
    assigned: {
      type: [Object, Array],
      default() { return {}}
    },
    all: {
      type: [Object, Array],
      default() { return {}}
    },
    listBtitle: {
    },
    listAtitle: {
    },
    noFilter: {
      default: false
    }
  },
  data() {
    return {
      list_1: {},
      list_2: {}
    }
  },
  computed: {
    notAssignedList() {
      let ids = Object.keys(this.assigned)
      let allList = Object.assign({}, this.all)
      ids.forEach(id => {
        delete allList[id]
      })
      return allList
    }
  },
  methods: {
    move(from, to, vals) {
      if(to === 2) {
        vals.forEach(v => {
          this.$delete(this.assigned, v)
        })
      } else {
        vals.forEach(v => {
          this.$set(this.assigned, v, this.all[v])
        })
      }
    },
    getMoveFunc(from, to) {
      return v => this.move(from, to, v)
    },
    updateLists() {
      this.list_1 = this.list1
      this.list_2 = this.list2
    }
  },
  watch: {
    list1() { this.updateLists() },
    list2() { this.updateLists() }
  },
  created() {
    this.updateLists()
  }
}
</script>

<style lang="css" scoped>
.move-lists {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.move-lists > div {
  display: flex;
}
.d11 {
  margin-right: -1px;
}
</style>
