<template lang="html">
  <div class="adset-wizard-container">
    <h3 class="h-4124">Campaign Wizard</h3>
    <form class="" @submit.prevent='submit'>

      <Tabs :tabs='["Campaign", "Items"]'>
        <div slot="t-1">

            <div class="field-23">
              <label for="">Account</label>
              <select class="def" v-model='form.account' v-if='accounts'>
                <option :value='account.id' v-for='account in accounts'>
                  {{account.name}}
                </option>
              </select>
            </div>

            <div class="field-23">
              <label for="">Campaign Name</label>
              <input class="def txt-input-32" v-model='form.name'>
            </div>

            <div class="field-23">
              <label for="">Daily Budget</label>
              <input class="def" type="number" min="10" :max='maxDailyCap' v-model.number='form.daily_cap'>
            </div>

            <div class="field-23">
              <label for="">Bid</label>
              <input class="def" type="number" step="0.001" min="0.01" :max='maxBid' v-model.number='form.cpc'>
            </div>

            <div class="field-23">
              <label for="">Bid Type</label>
              <select class="def" v-model='form.bid_type'>
                <option value="FIXED">Fixed</option>
                <option value="OPTIMIZED_CONVERSIONS">Smart Bid(Optimized)</option>
              </select>
              <!-- <multiselect class="select-2" label="label" track-by="val" :multiple='true'
              :close-on-select='false' v-model='form._os' :options='osOptions'></multiselect> -->
            </div>

            <div class="field-23">
              <label for="">Country Targeting</label>
              <multiselect class="select-2" label="label" track-by="val" :multiple='true' :close-on-select='false' v-model='form._countries' :options='countries'></multiselect>
            </div>

            <div class="field-23">
              <label for="">Platform Targeting</label>
              <multiselect class="select-2" label="label" track-by="val" :multiple='true'
              :close-on-select='false' v-model='form._platforms' :options='platformOptions'></multiselect>
            </div>

            <div class="field-23">
              <label for="">OS Targeting</label>
              <select class="def" v-model='form._os'>
                <option value="all">All</option>
                <option value="Android">Android</option>
                <option value="iOS">iOS</option>
                <option value="Windows">Windows</option>
                <option value="Mac OS X">Mac OS X</option>
                <option value="Linux">Linux</option>
              </select>
              <!-- <multiselect class="select-2" label="label" track-by="val" :multiple='true'
              :close-on-select='false' v-model='form._os' :options='osOptions'></multiselect> -->
            </div>

            <div class="field-23">
              <label for="">Video Player</label>
              <div class="">
                <label for="Avantis-1">Avantis</label>
                <input v-model='form._videoPlayer' value="Avantis" id="Avantis-1" type="radio">

                <label for="Vidazoo-1">Vidazoo</label>
                <input v-model='form._videoPlayer' value="Vidazoo" id="Vidazoo-1" type="radio">
              </div>
            </div>

            <div class="field-23">
              <label for="">Tracking Code</label>
              <input readonly="true" class="def t-url-1" v-model='form.tracking_code'>
            </div>

        </div>
        <div class="" slot="t-2">
          <ul class="items-list-1">
            <li class="item-152" v-for='(item, i) in form.items'>
              <div class="field-23">
                <label for="">Title</label>
                <input v-model='item.title' class="def txt-input-32">
              </div>
              <div class="field-23">
                <label for="">URL</label>
                <input v-model='item.url' class="def txt-input-32">
              </div>

              <div class="field-23 image-field">
                <label for="">Image</label>
                <label class="label-141" :for='"file51-" + i'>
                  <span class="image-zone">{{images[i] ? images[i].name : "Choose Image"}}</span>
                </label>
                <!-- <span class="image-name-1" v-if='images[i]'>{{images[i].name}}</span> -->
                <input v-show='0' class="def txt-input-32" type="file" accept='image/*' :ref='"file-" + i' :id='"file51-" + i' @change='handleFileUploads(i)'>
              </div>

              <div class="rem-item-container-1">
                <i @click='cloneItem(i)' class="fa fa-clone clone-item" aria-hidden="true"></i>
                <i @click='removeItem(i)' class="fa fa-times-circle-o rem-item" aria-hidden="true"></i>
              </div>
            </li>
          </ul>

          <div class="b-c-251">
            <button type="button" class="btn _btn-white" @click='addItem'>Add Item</button>
          </div>

        </div>
      </Tabs>

    <br>
    <button type="submit" class="btn btn-primary" :disabled='ongoingSubmit'>Submit</button>
    <Spinner class='loading-12' v-show='ongoingSubmit' />


  </form>

  </div>
</template>

<script>
import langs from '@/assets/langs'
import countries from '@/assets/countries'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  props: {
    accounts: {},
    onClose: {},
  },
  data() {
    let trackingCode = "utm_source=taboola&utm_medium={site}&utm_campaign={campaign_name}&layout=gallery3&utm_term={campaign_id}"
    return {
      maxBid: 0.20,
      maxDailyCap: 20,
      langs,
      trackingCode,
      ongoingSubmit: false,
      accountMeta: {
        "wordsline-absolutelyconnected": {
          brandingText: "Absolutelyconnected",
          domain: "https://absolutelyconnected.com",
          domainSymbol: "AC"
        },
        "wordsline-eternallifestyle": {
          brandingText: "Eternallifestyle",
          domain: "https://eternallifestyle.com",
          domainSymbol: "EL"
        },
        "wordsline-worldtravelling": {
          brandingText: "Worldtravelling",
          domain: "https://worldtravelling.com",
          domainSymbol: "WT"
        }
      },
      countries: Object.keys(countries).map(v=> ({val: v, label: countries[v]})),
      platforms: {
        "desktop": "Desktop",
        "mobile": "Mobile"
      },
      platformOptions: [
        {
          val: "DESK",
          label: "Desktop"
        },
        {
          val: "PHON",
          label: "Smartphone"
        },
        {
          val: "TBLT",
          label: "Tablet"
        },
      ],
      osOptions: [
        {
          val: "Android",
          label: "Android"
        },
        {
          val: "iOS",
          label: "iOS"
        }
      ],
      accountOptions: null,
      campaignOptions: [],
      langOptions: ["WW", "English"],
      images: [],
      form: {
          _videoPlayer: null,
          _os: 'all',
          _countries: [],
          _platforms: [],
          account: null,
          name: null,
          branding_text: null,
          tracking_code: trackingCode,
          cpc: 0.01,
          daily_cap: 15,
          spending_limit: 2000,
          spending_limit_model: "ENTIRE",
          country_targeting: {
              type  : "INCLUDE",
              value : []
          },
          sub_country_targeting: null,
          platform_targeting: {
              type  : "INCLUDE",
              value : []
          },
          os_targeting: {
              type: "INCLUDE",
              value: []
          },
          publisher_targeting: {
                "type": "ALL",
                "value": [],
                "href": null
          },
          daily_ad_delivery_model : "STRICT",
          publisher_bid_modifier : { values: [] },
          activity_schedule: {
            mode: "ALWAYS",
            rules: [],
            time_zone: "Israel"
          },
          items: [],
          comments: null,
          bid_type: "FIXED",
          marketing_objective : "DRIVE_WEBSITE_TRAFFIC",
          // start_date: null,
          // end_date: null,
          approval_state: "APPROVED",
          is_active: true
      }
    }
  },
  created() {
    this.addItem()
    this.setAccountOptions()
  },
  watch: {
    ['form._videoPlayer'](vp) {
      let trackingCode = this.trackingCode
      if(vp === "Avantis") {
        trackingCode += "&vtype=2"
      } else if(vp === "Vidazoo") {
       trackingCode += "&vtype=3"
     }
      return this.form.tracking_code = trackingCode
    },
    ['form.account']() {
      this.setCampOptions()
    }
  },
  methods: {
    isValidaImage(file) {
      return new Promise((res, rej) => {

      var image = new Image();

      let sizeKb = file.size / 1000
      image.onload = function() {
          let width = this.width
          let height = this.height
          if(sizeKb > 2500) {
            rej("The image size exceeds 2.5 MB limit");
            return
          }

          if (width < 600 || height < 400) {
              rej("The image resolution is too low");
              return
          }

          let ratio = width / height
          res()
      };

      image.onerror = function() {
          rej("Invalid image. Please select an image file.");
      }

      image.src = URL.createObjectURL(file);

      })

    },
    cloneItem(i) {
      let v = this.form.items[i]
      this.form.items.push(Object.assign({}, v))
      this.images.push(this.images[i])
    },
    removeItem(i) {
      this.form.items.splice(i, 1)
      this.images.splice(i, 1)
    },
    getAccountMeta(account, field) {
      return this.accountMeta[account] && this.accountMeta[account][field]
    },
    addItem() {
      let account = this.form.account
      let domain = this.getAccountMeta(account, "domain") || ""

      this.form.items.push({
        url: domain,
        title: null
      })
    },
    handleFileUploads(i) {
      let fileInput = this.$refs["file-" + i][0]
      this.$set(this.images, i, fileInput.files[0])
    },
    onGeoAdd(v) {
      this.form.targeting.geo_locations.countries.push(v.val)
    },
    onGeoDel(index) {
      this.form.targeting.geo_locations.countries.splice(index, 1)
    },
    submit() {
      let form = Object.assign({}, this.form)
      let os = form._os
      let account = form.account
      form.branding_text = this.getAccountMeta(account, "brandingText")
      form.country_targeting.value = form._countries.map(v => v.val)
      form.platform_targeting.value = form._platforms.map(v => v.val)
      if(os != "all") {
        form.os_targeting = {
          type: "INCLUDE",
          value: [
            {
              os_family: os,
              sub_categories: []
            }
          ]
        }
      } else {
        form.os_targeting = {
          type: "ALL",
          value: []
        }
      }


      if(!form.items || form.items.length === 0 || this.images.length != form.items.length) {
        this.notifyError("Invalid campaign items")
        return
      }
      if(!form._videoPlayer) {
        this.notifyError("Please choose a video player")
        return
      }

      if(form.cpc > this.maxBid) {
        this.notifyError("Max bid is: " + this.maxBid)
        return
      }

      if(form.daily_cap > this.maxDailyCap) {
        this.notifyError("Max daily budget is: " + this.maxDailyCap)
        return
      }

      if([account, form.name, form.cpc, form.daily_cap].some(v => !v)) {
        this.notifyError("Invalid campaign fields")
        return
      }

      if(form.country_targeting.value.length === 0) {
        this.notifyError("Please enter country targeting")
        return
      }
      if(form.platform_targeting.value.length === 0) {
        this.notifyError("Please enter platform targeting")
        return
      }

      let imagesCheckProms = []
      this.images.forEach(image => {
        let prom = this.isValidaImage(image)
        imagesCheckProms.push(prom)
      })

      Promise.all(imagesCheckProms)
        .then(() => {
          let isOk = true
            form.items.forEach(item => {
              if(!isOk) { return }
              if(!item.url.includes(this.getAccountMeta(account, "domain"))) {
                isOk = false
                this.notifyError("Wrong domain for account: " + account)
              }
            })
            if(!isOk) {
              return
            }
            this.$validate.taboolaItems(form.items)
            .then(() => {
              this._submitAfterValidation(form, account)
            })
            .catch(err => {
              this.notifyError(err.msg)
            })
        })
        .catch(err => {
          this.notifyError(err)
        })
    },
    _submitAfterValidation(form, account) {
      this.ongoingSubmit = true
      let proms = []

      this.images.forEach((image, i) => {
        let prom = new Promise((res, rej) => {
          let formData = new FormData()
          formData.append('file', image)
          this.$http.post(this.resources.taboola.imageUpload, formData)
          .then(r => r.json())
          .then(data => {
            let cdnURL = data.cdn_url
            form.items[i].thumbnail_url = cdnURL
            res()
          })
          .catch(rej)
        })
        proms.push(prom)
      })


      let websiteLabel = this.getAccountMeta(form.account, "domainSymbol")

      let countryLabel
      let countryTargeting = form.country_targeting.value
      if(countryTargeting.length > 1) {
        countryLabel = "WW"
      } else {
        countryLabel = countryTargeting[0]
      }

      let osLabel = form._os

      let deviceLabel
      let devicePlatform = form.platform_targeting.value[0]

      if(devicePlatform == "DESK") {
        deviceLabel = "DS"
      } else {
        if (osLabel === "all") {
            deviceLabel = "ALL"
        } else {
          deviceLabel = osLabel === "iOS" ? "IOS" : "An"
        }
      }

      let bidType = form.bid_type
      let bidTypeLabel
      if(bidType === "OPTIMIZED_CONVERSIONS") {
        bidTypeLabel = "Smartbid"
      } else {
        bidTypeLabel = "Fixedbid"
      }

      form.name = `${websiteLabel} - ${form.name} - ${deviceLabel} - ${bidTypeLabel} - ${countryLabel} `

      Promise.all(proms)
        .then(() => {
          this.$http.post(this.resources.taboola.campaigns(account), form)
          .then(() => {
            this.notifySuccess("Campaign Successfully created")
            this.ongoingSubmit = false
            this.onClose()
          })
          .catch(() => {
            this.notifyError("Could not create campaign")
            this.ongoingSubmit = false
          })
        })
        .catch(err => {
          this.notifyError("Could not create campaign")
          this.ongoingSubmit = false
        })
    },
    setCampOptions() {
      let account = this.form.account
      if(!account) { return }

      let campaignOptions = this.getOptionsFromObject(account.campaigns)
      this.campaignOptions = campaignOptions
    },
    setAccountOptions() {
      let accounts = this.accounts
      // let accountOptions = this.getOptionsFromObject(accounts)
      this.accountOptions = accounts
    },
    getOptionsFromObject(obj) {
      let accountOptions = []
      for(let key in obj) {
        accountOptions.push({
          value: obj[key],
          label: obj[key].name
        })
      }
      return accountOptions
    }
  }
}
</script>

<style lang="css" scoped>
.field-23 {
  display: flex;
  margin-bottom: 6px;
}
.field-23 label {
  width: 170px;
  text-align: right;
  margin-right: 16px;
  font-size: 12px;
  font-weight: bold;
  color: #5d5d5d;
}
.h-4124 {
  font-size: 14px;
  font-family: tahoma;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 5px;
  font-weight: bold;
  color: dimgrey;
}
.adset-wizard-container {
  padding: 0 15px;
  width: 700px;
}
.select-2 {
  max-width: 67%;
}
.t-url-1 {
  width: 390px;
  font-size: 13px;
  color: #5f5f5f;
}
.txt-input-32 {
  width: 358px;
  height: 41px;
  font-size: 15px;
}
.item-152 {
  border-bottom: 1px solid #cacaca;
  margin-bottom: 8px;
  padding: 13px 0;
}
.item-152:last-child {
  border-bottom: 0;
}
.items-list-1 {
  max-height: 60vh;
  overflow-y: auto;
}
.rem-item-container-1 {
  display: flex;
  justify-content: flex-end;
  margin-right: 33px;
  margin-top: 17px;
}
.rem-item {
  font-size: 26px;
  cursor: pointer;
  color: #535353;
}
.rem-item:hover {
  color: black;
}
.clone-item {
  font-size: 26px;
  cursor: pointer;
  color: #535353;
  margin-right: 35px;
  border-right: 1px solid #969696;
  padding-right: 35px;
}
.clone-item:hover {
  color: black;
}

.b-c-251 {
  margin-top: 17px;
  border-top: 1px solid #d8d8d8;
  padding-top: 10px;
}
.image-zone {
  border: 1px solid gainsboro;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
}
.image-zone:hover {
  background: #fafafa;
}
.image-name-1 {

}
.image-field {
  margin: 15px 0;
}
.image-field label.label-141 {
  width: inherit;
  font-size: 11px;
}
.loading-12 {
  font-size: 16px !important;
  margin-left: 22px;
  color: #357ba3;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
